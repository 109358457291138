import React, { useState } from "react";
import { faArrowLeft, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
    Content,
    Icon,
    ButtonLink,
    Button,
    FloatInput,
    Alert,
    LoadingSpinner,
} from "@webtypen/react-components";
import { SettingTitle } from "./SettingTitle";
import { ApiHelper } from "../../../helpers";

export const UsersInvite = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setEmail(e.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setAlert(null);
        const emailTemp = email.trim() + "";

        ApiHelper.request("/api/environments/users-invite", {
            email: email,
        })
            .then((response) => {
                if (response.status === "success") {
                    setAlert([
                        "success",
                        <>
                            Der Benutzer wurde eingeladen. Es wurde eine E-Mail an <b>{emailTemp}</b> gesendet.
                        </>,
                    ]);
                    // setEmail("");
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Einladen des Benutzers aufgetreten.",
                    ]);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Einladen des Benutzers aufgetreten.",
                ]);
                setIsLoading(false);
            });
    };

    return (
        <Content>
            <ButtonLink to={"/env/" + props.match.params._env + "/settings/users"} secondary className="float-right">
                <Icon icon={faArrowLeft} className="mr-1" />
                Abbrechen
            </ButtonLink>
            <SettingTitle icon={faUsers} title="Benutzer hinzufügen" />
            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
            Hier kann ein neuer Benutzer zur Firmenumgebung hinzugefügt werden. Hat dieser Benutzer bereits einen
            simplebis-Account kann die Einladung auf der Account-Startseite angenommen werden.
            <br />
            Neue Benutzer müssen sich zuerst registierten und können diese Anmeldung später annehmen.
            <form onSubmit={submit} className="mt-3">
                <FloatInput
                    type="email"
                    placeholder="E-Mail Adresse"
                    onChange={onChange}
                    value={email}
                    className="mb-2"
                    disabled={isLoading}
                />

                <div className="text-right">
                    <Button success disabled={isLoading || !email || email.trim() === ""}>
                        {isLoading ? <LoadingSpinner /> : "Einladen"}
                    </Button>
                </div>
            </form>
        </Content>
    );
};
