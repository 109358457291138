import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FloatInput, Alert, LoadingSpinner } from "@webtypen/react-components";
import { ApiHelper } from "../helpers";
import { LayoutGuest } from "../components/LayoutGuest";

export const ForgotPW = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [email, setEmail] = useState("");

    const reset = (e) => {
        e.preventDefault();

        if (!email || email.trim() === "") {
            return;
        }

        setIsLoading(true);
        setAlert(null);
        ApiHelper.request("/api/users/forgot-pw", {
            email: email,
        })
            .then((response) => {
                if (response && response.status === "success") {
                    setAlert([
                        "success",
                        "Es wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts an " + email + " gesendet",
                    ]);
                    setEmail("");
                    setIsLoading(false);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten.",
                    ]);
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
                ]);
                setIsLoading(false);
            });
    };

    return (
        <LayoutGuest title="Passwort zurücksetzen">
            {alert ? (
                <Alert className="mt-3" color={alert[0]}>
                    {alert[1]}
                </Alert>
            ) : null}

            <form onSubmit={reset} className="mb-5">
                <FloatInput
                    type="email"
                    placeholder="E-Mail Adresse"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="mb-2"
                />
                <Button block success className="mb-2" disabled={isLoading || !email || email.trim() === ""}>
                    {isLoading ? <LoadingSpinner /> : "Passwort zurücksetzen"}
                </Button>
            </form>

            <Link to="/">Zurück zum Login</Link>
        </LayoutGuest>
    );
};
