import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pathToRegexp from "path-to-regexp";
import { useDispatch } from "react-redux";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner, Alert, Button, Row, Col, Icon } from "@webtypen/react-components";
import { ApiHelper } from "../../helpers";
import { DynamicRecordFieldsRenderer } from "./DynamicRecordFieldsRenderer";
import { store, reduxSetValue } from "../../redux";

export const DynamicRecordForm = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isRemoving, setIsRemoving] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [alert, setAlert] = useState(null);

    const submit = (e) => {
        e.preventDefault();

        const state = store.getState();
        setAlert(null);
        setIsLoading(true);
        dispatch(reduxSetValue("dynamicrecordsErrors", {}));
        ApiHelper.request("/api/dynamic-records/save", {
            type: props.type,
            id: props.recordId,
            data: state.app.dynamicrecords,
            form: props.form,
        })
            .then((response) => {
                if (response.data && response.data.key) {
                    history.push(
                        pathToRegexp.compile(props.match.path)({ ...props.match.params, id: response.data.key })
                    );
                }
                setIsLoading(false);

                if (response.status === "success" && response.data.key && response.data.key.trim() !== "") {
                    setAlert(["success", "Der Datensatz wurde erfolgreich gespeichert."]);
                    dispatch(reduxSetValue("dynamicrecordsErrors", {}));
                } else {
                    setAlert(["danger", "Es ist ein unerwarteter Fehler beim Speichern aufgetreten."]);

                    dispatch(
                        reduxSetValue(
                            "dynamicrecordsErrors",
                            response &&
                                response.data &&
                                response.data.errors &&
                                Object.keys(response.data.errors).length > 0
                                ? response.data.errors
                                : {}
                        )
                    );
                }
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message.trim()
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
                ]);
                setIsLoading(false);

                dispatch(
                    reduxSetValue(
                        "dynamicrecordsErrors",
                        e && e.data && e.data.errors && Object.keys(e.data.errors).length > 0 ? e.data.errors : {}
                    )
                );
            });
    };

    const remove = (e) => {
        if (!window.confirm("Soll der Datensatz wirklich entfernt werden?")) {
            return;
        }

        setIsRemoving(true);
        ApiHelper.request("/api/dynamic-records/remove", {
            form: props.form,
            type: props.type,
            id: props.recordId,
        })
            .then((response) => {
                if (response.status === "success") {
                    if (props.onRemove) {
                        props.onRemove();
                    }
                } else {
                    setAlert([
                        "danger",
                        response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Entfernen des Datensatzes aufgetreten.",
                    ]);
                }
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message.trim()
                        : "Es ist ein unerwarteter Fehler beim Entfernen des Datensatzes aufgetreten.",
                ]);
                setIsRemoving(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        ApiHelper.request("/api/dynamic-records/form", {
            type: props.type,
            id: props.recordId,
            form: props.form,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.values &&
                    response.data.form &&
                    response.data.singular
                ) {
                    setData(response.data);
                    dispatch(reduxSetValue("dynamicrecords", response.data.values));

                    if (props.onLoad) {
                        props.onLoad({ ...response.data });
                    }
                } else if (response.status === "error" && response.message && response.message.trim() !== "") {
                    setError(response.message);
                    setData(null);
                } else {
                    setData(null);
                }
                setIsLoading(false);
                dispatch(reduxSetValue("dynamicrecordsErrors", {}));
            })
            .catch((e) => {
                setError(
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message.trim()
                        : "Es ist ein unerwarteter Fehler aufgetreten."
                );
                setIsLoading(false);
                dispatch(reduxSetValue("dynamicrecordsErrors", {}));
            });

        return () => {
            dispatch(reduxSetValue("dynamicrecords", {}));
            dispatch(reduxSetValue("dynamicrecordsErrors", {}));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type, props.recordId]);

    if (isLoading) {
        return <LoadingSpinner>Formulardaten werden geladen ...</LoadingSpinner>;
    }

    if (error) {
        return <Alert danger>{error}</Alert>;
    }

    if (!data || !data.form || !data.form.fields || data.form.fields.length < 1) {
        return <Alert danger>Formular konnte nicht geladen werden ...</Alert>;
    }

    if (isRemoving) {
        return <LoadingSpinner>Der Datensatz wird entfernt ...</LoadingSpinner>;
    }

    return (
        <form onSubmit={submit}>
            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
            <Row>
                <DynamicRecordFieldsRenderer data={data} fields={data.form.fields} />

                <Col width={12} className="text-right mt-3">
                    {props.recordId !== "new" ? (
                        <Button type="button" light className="text-danger mr-1" onClick={remove}>
                            <Icon icon={faTrashAlt} className="mr-1" /> Entfernen
                        </Button>
                    ) : null}
                    <Button success>
                        <Icon icon={faSave} className="mr-1" />
                        Speichern
                    </Button>
                </Col>
            </Row>
        </form>
    );
};
