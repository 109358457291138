import React, { useState } from "react";
import moment from "moment";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Content, Icon, ButtonLink, ButtonGroup, Button, Tabs } from "@webtypen/react-components";
import { SettingTitle } from "./SettingTitle";
import { ApiHelper, ConfirmHelper } from "../../../helpers";

export const UsersList = (props) => {
    const [update, setUpdate] = useState(0);

    const deleteUser = async () => {
        const status = await ConfirmHelper.confirm("Soll der ausgewählte Benutzer wirklich entfernt werden?", {
            confirmAttributes: { danger: true },
        });
        if (!status) {
            return;
        }
    };

    const removeInvitation = async (e) => {
        const id = e.currentTarget.getAttribute("data-id");
        const status = await ConfirmHelper.confirm("Soll der ausgewählte Benutzer wirklich entfernt werden?", {
            confirmAttributes: { danger: true },
        });
        if (!status) {
            return;
        }

        ApiHelper.request("/api/environments/users-invite/remove", {
            id: id,
        })
            .then(() => {
                setUpdate(update + 1);
            })
            .catch((e) => {
                ConfirmHelper.alert(
                    e && e.data && e.data.status === "success" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten."
                );
            });
    };

    return (
        <Content>
            <ButtonLink to={"/env/" + props.match.params._env + "/settings/users/invite"} className="float-right">
                Benutzer einladen
            </ButtonLink>

            <SettingTitle icon={faUsers} title="Benutzer" />

            <Tabs
                sections={{
                    users: {
                        title: "Benutzer",
                        render: () => (
                            <SimpleTable
                                model="EnvironmentUser"
                                data={{
                                    lookup: [
                                        {
                                            type: "user",
                                            local: "user",
                                            foreign: "_id",
                                            as: "user",
                                        },
                                    ],
                                    select: {
                                        is_active: true,
                                        user: {
                                            _id: 1,
                                            firstname: 1,
                                            name: 1,
                                            email: 1,
                                        },
                                    },
                                }}
                            >
                                <SimpleTableCell label="Vorname" mapping="user.firstname" searchable sortable />
                                <SimpleTableCell label="Nachname" mapping="user.name" searchable sortable />
                                <SimpleTableCell label="E-Mail" mapping="user.email" searchable sortable />
                                {/* <SimpleTableCell label="Ist aktiv?" mapping="is_active" searchable="boolean" sortable /> */}
                                <SimpleTableCell
                                    label="Optionen"
                                    attributes={{ className: "text-right" }}
                                    render={(row) => (
                                        <ButtonGroup>
                                            <Button type="button" data-id={row._id} xs danger onClick={deleteUser}>
                                                <Icon icon={faTrashAlt} className="mr-1" />
                                                Entfernen
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                />
                            </SimpleTable>
                        ),
                    },
                    invitations: {
                        title: "Ausstehende Einladungen",
                        render: () => (
                            <SimpleTable
                                model="EnvironmentUserInvitation"
                                where={[["status", "=", "pending"]]}
                                data={{
                                    lookup: [
                                        {
                                            type: "user",
                                            local: "user",
                                            foreign: "_id",
                                            as: "user",
                                        },
                                    ],
                                    select: {
                                        is_active: true,
                                        email: 1,
                                        status: 1,
                                        created_at: 1,
                                        user: {
                                            _id: 1,
                                            firstname: 1,
                                            name: 1,
                                            email: 1,
                                        },
                                    },
                                }}
                            >
                                <SimpleTableCell
                                    label="E-Mail"
                                    render={(row) => (row.user ? row.user.email : row.email)}
                                />
                                <SimpleTableCell label="Datum" render={(row) => moment(row.created_at).fromNow()} />
                                <SimpleTableCell
                                    label="Optionen"
                                    attributes={{ className: "text-right" }}
                                    render={(row) => (
                                        <ButtonGroup>
                                            <Button
                                                type="button"
                                                data-id={row._id}
                                                xs
                                                danger
                                                onClick={removeInvitation}
                                            >
                                                <Icon icon={faTrashAlt} className="mr-1" />
                                                Einladung entfernen
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                />
                            </SimpleTable>
                        ),
                    },
                }}
            />
        </Content>
    );
};
