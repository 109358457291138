import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Content,
    LoadingSpinner,
    Page,
    Row,
    Col,
    Icon,
    CardHeader,
    Table,
} from "@webtypen/react-components";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Link } from "react-router-dom";
import {
    ButtonDownload,
    DynamicRecordStage,
    iconSelectorLib,
    PageBuilderRenderer,
    PageTitle,
} from "../../../components";
import { Error404 } from "../../errors/Error404";
import { ApiHelper } from "../../../helpers";

export const DynamicRecordsPage = (props) => {
    const { recordType } = props;
    // const envStatus = useSelector((state) => state.app.environment.status);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        ApiHelper.request("/api/dynamic-records/pages/details", {
            type: props.match.params._recordType,
            id: props.match.params.id,
            page: props.match.params.page,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.element &&
                    response.data.element._id === props.match.params.id &&
                    response.data.element.type === props.match.params._recordType &&
                    response.data.definition &&
                    response.data.definition.elements &&
                    response.data.definition.elements.length > 0
                ) {
                    setData(response.data);
                } else {
                    setData(null);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setData(null);
                setIsLoading(false);
            });
    }, [props.match.params.id, props.match.params._recordType, props.match.params.page]);

    const handleMainAttributes = (config) => {
        const attributes = {};
        if (config.attributes) {
            if (config.attributes.style) {
                attributes.style = config.attributes.style;
            }
            if (config.attributes.className) {
                attributes.className = config.attributes.className;
            }
        }
        return attributes;
    };

    if (isLoading) {
        return (
            <Page fullHeight flex>
                <PageTitle title={recordType.singular} subtitle={"Lädt ..."} />
                <Content
                    style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <LoadingSpinner />
                </Content>
            </Page>
        );
    }

    if (!isLoading && !data) {
        return <Error404 />;
    }

    return (
        <Page fullHeight>
            <PageBuilderRenderer
                definition={data.definition}
                components={{
                    pageTitle: {
                        render: (config) => {
                            return <PageTitle {...config.attributes} />;
                        },
                    },
                    div: {
                        render: (config, index, components) => (
                            <div {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </div>
                        ),
                    },
                    text: {
                        render: (config, index, components) => (
                            <span {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </span>
                        ),
                    },
                    content: {
                        render: (config, index, components) => (
                            <Content {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </Content>
                        ),
                    },
                    link: {
                        render: (config, index, components) => {
                            return (
                                <Link {...handleMainAttributes(config)} to={config.attributes.to}>
                                    <PageBuilderRenderer
                                        clean
                                        isSubElement
                                        definition={{ elements: config.children }}
                                        components={components}
                                    />
                                </Link>
                            );
                        },
                    },
                    a: {
                        render: (config, index, components) => {
                            return (
                                <a
                                    {...handleMainAttributes(config)}
                                    href={config.attributes.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <PageBuilderRenderer
                                        clean
                                        isSubElement
                                        definition={{ elements: config.children }}
                                        components={components}
                                    />
                                </a>
                            );
                        },
                    },
                    icon: {
                        render: (config) => {
                            if (!config || !config.attributes || !config.attributes.icon) {
                                return <span>Missing icon-config</span>;
                            }

                            if (
                                !iconSelectorLib[config.attributes.icon] ||
                                !iconSelectorLib[config.attributes.icon].icon
                            ) {
                                return <span>Unknown Icon `{config.attributes.icon}`</span>;
                            }

                            return (
                                <Icon
                                    icon={iconSelectorLib[config.attributes.icon].icon}
                                    style={
                                        config && config.attributes && config.attributes.style
                                            ? config.attributes.style
                                            : null
                                    }
                                />
                            );
                        },
                    },
                    card: {
                        render: (config, index, components) => (
                            <Card {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </Card>
                        ),
                    },
                    cardHeader: {
                        render: (config, index, components) => (
                            <CardHeader {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </CardHeader>
                        ),
                    },
                    cardBody: {
                        render: (config, index, components) => (
                            <CardBody {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </CardBody>
                        ),
                    },
                    table: {
                        render: (config, index, components) => (
                            <Table {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </Table>
                        ),
                    },
                    tbody: {
                        render: (config, index, components) => (
                            <tbody {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </tbody>
                        ),
                    },
                    thead: {
                        render: (config, index, components) => (
                            <thead {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </thead>
                        ),
                    },
                    tfoot: {
                        render: (config, index, components) => (
                            <tfoot {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </tfoot>
                        ),
                    },
                    tr: {
                        render: (config, index, components) => (
                            <tr {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </tr>
                        ),
                    },
                    th: {
                        render: (config, index, components) => (
                            <th {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </th>
                        ),
                    },
                    td: {
                        render: (config, index, components) => (
                            <td {...handleMainAttributes(config)}>
                                <PageBuilderRenderer
                                    clean
                                    isSubElement
                                    definition={{ elements: config.children }}
                                    components={components}
                                />
                            </td>
                        ),
                    },
                    image: {
                        render: (config) => {
                            return (
                                <img
                                    {...handleMainAttributes(config)}
                                    src={config.attributes.src}
                                    alt={config.attributes.alt}
                                    title={config.attributes.title}
                                />
                            );
                        },
                    },
                    fileDownload: {
                        render: (config) => {
                            return (
                                <ButtonDownload
                                    method="POST"
                                    {...config.attributes}
                                    {...handleMainAttributes(config)}
                                    src={
                                        process.env.REACT_APP_API_URL.substring(
                                            process.env.REACT_APP_API_URL.length - 1
                                        ) === "/" && config.attributes.src.substring(0, 1) === "/"
                                            ? process.env.REACT_APP_API_URL + config.attributes.src.substring(1)
                                            : process.env.REACT_APP_API_URL + config.attributes.src
                                    }
                                >
                                    {config.label}
                                </ButtonDownload>
                            );
                        },
                    },
                    row: {
                        render: (config, index, components) => {
                            return (
                                <Row {...handleMainAttributes(config)}>
                                    <PageBuilderRenderer
                                        isSubElement
                                        definition={{ elements: config.children }}
                                        components={components}
                                    />
                                </Row>
                            );
                        },
                    },
                    col: {
                        render: (config, index, components) => {
                            return (
                                <Col
                                    {...handleMainAttributes(config)}
                                    width={config.attributes && config.attributes.width ? config.attributes.width : 12}
                                >
                                    <PageBuilderRenderer
                                        isSubElement
                                        definition={{ elements: config.children }}
                                        components={components}
                                    />
                                </Col>
                            );
                        },
                    },
                    map: {
                        render: (config, index, components) => {
                            return config.children && config.children.length > 0
                                ? config.children.map((child, i) => (
                                      <PageBuilderRenderer
                                          key={i}
                                          isSubElement
                                          definition={{ elements: child }}
                                          components={components}
                                      />
                                  ))
                                : null;
                        },
                    },
                    dynrecordStageLabel: {
                        render: (config) => {
                            return (
                                <DynamicRecordStage
                                    stage={
                                        config && config.attributes && config.attributes.stage
                                            ? config.attributes.stage
                                            : null
                                    }
                                    stages={recordType.stages}
                                    style={
                                        config && config.attributes && config.attributes.style
                                            ? config.attributes.style
                                            : null
                                    }
                                />
                            );
                        },
                    },
                    simpleTable: {
                        render: (config, index, components) => {
                            const columns = [];
                            if (config.children && config.children.length > 0) {
                                for (let i in config.children) {
                                    if (config.children[i].type === "simpleTableCell") {
                                        columns.push(config.children[i]);
                                    }
                                }
                            }

                            return (
                                <SimpleTable
                                    {...handleMainAttributes(config)}
                                    model={
                                        !config.attributes ||
                                        !config.attributes.type ||
                                        config.attributes.type === "DynamicRecord"
                                            ? "DynamicRecord"
                                            : "unknown"
                                    }
                                    where={
                                        config.attributes && config.attributes.where ? config.attributes.where : null
                                    }
                                    data={config.attributes && config.attributes.data ? config.attributes.data : null}
                                >
                                    {columns.map((col, i) => (
                                        <SimpleTableCell key={i} {...col.attributes} />
                                    ))}
                                </SimpleTable>
                            );
                        },
                    },
                }}
            />
        </Page>
    );
};
