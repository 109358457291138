import axios from "axios";
import { reduxSetUpload, reduxSetUploadValue, reduxUploadChangeTrigger, store } from "../redux";
import { ApiHelper } from "./ApiHelper";

class FilemanagerHelperWrapper {
    maxParallelUploads = 1;

    startUpload(params, onSuccess, onError) {
        const id = this.makeId(10);

        store.dispatch(
            reduxSetUpload(id, {
                id: id,
                status: "preparing",
                filename: params.file.name,
                filesize: params.file.size,
            })
        );

        ApiHelper.request("/api/filemanager/uploads/prepare", {
            id: id,
            filename: params.file.name,
            filesize: params.file.size,
            filetype: params.file.type,
            filemanager: params.filemanager,
            directory: params.directory,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.file &&
                    response.data.file.trim() !== ""
                ) {
                    store.dispatch(reduxSetUploadValue(id, "file_id", response.data.file));
                    store.dispatch(reduxSetUploadValue(id, "file", params.file));
                    store.dispatch(reduxSetUploadValue(id, "status", "prepared"));
                    store.dispatch(reduxUploadChangeTrigger());
                }
                onSuccess(response);

                setTimeout(() => {
                    this.handleNextUploads();
                }, 150);
            })
            .catch((e) => {
                console.error(e);

                store.dispatch(reduxSetUploadValue(id, "status", "failed"));
                store.dispatch(reduxUploadChangeTrigger());
                onError();

                setTimeout(() => {
                    this.handleNextUploads();
                }, 150);
            });
    }

    handleNextUploads() {
        const state = store.getState();

        let uploadingCount = 0;
        if (state.app.uploads) {
            const uploadKey = Object.keys(state.app.uploads);

            for (let i in uploadKey) {
                if (
                    (state.app.uploads[uploadKey[i]].status &&
                        state.app.uploads[uploadKey[i]].status === "uploading") ||
                    (state.app.uploads[uploadKey[i]].status &&
                        state.app.uploads[uploadKey[i]].status === "start-uploading")
                ) {
                    uploadingCount++;
                }
            }
        }

        if (uploadingCount >= this.maxParallelUploads) {
            return;
        }

        if (state.app.uploads) {
            const uploadKey = Object.keys(state.app.uploads);
            for (let i in uploadKey) {
                if (state.app.uploads[uploadKey[i]].status === "prepared") {
                    uploadingCount++;

                    this.upload(state.app.uploads[uploadKey[i]].id);
                }

                if (uploadingCount >= this.maxParallelUploads) {
                    break;
                }
            }
        }
    }

    upload(id) {
        const state = store.getState();

        const uploadStartFailed = () => {
            store.dispatch(reduxSetUploadValue(id, "status", "failed"));
            store.dispatch(reduxUploadChangeTrigger());
        };

        if (
            !state.app.uploads ||
            !state.app.uploads[id] ||
            !state.app.uploads[id].file ||
            !state.app.uploads[id].file_id ||
            state.app.uploads[id].file_id.trim() === ""
        ) {
            uploadStartFailed();
            store.dispatch(reduxUploadChangeTrigger());
            return;
        }

        store.dispatch(reduxSetUploadValue(id, "status", "start-uploading"));
        ApiHelper.request("/api/filemanager/uploads/start", {
            id: state.app.uploads[id].file_id,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.uploadUrl &&
                    response.data.uploadUrl.trim() !== ""
                ) {
                    store.dispatch(reduxSetUploadValue(id, "status", "uploading"));
                    store.dispatch(reduxUploadChangeTrigger());

                    axios
                        .put(response.data.uploadUrl, state.app.uploads[id].file, {
                            onUploadProgress: (progressEvent) => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                store.dispatch(reduxSetUploadValue(id, "upload_percent", percentCompleted));
                            },
                        })
                        .then((response) => {
                            ApiHelper.request("/api/filemanager/uploads/finish", {
                                id: state.app.uploads[id].file_id,
                            })
                                .then((response) => {
                                    store.dispatch(reduxSetUploadValue(id, "status", "upload-finished"));
                                    store.dispatch(reduxUploadChangeTrigger());

                                    setTimeout(() => {
                                        this.handleNextUploads();
                                    }, 150);
                                })
                                .catch((e) => {
                                    store.dispatch(reduxSetUploadValue(id, "status", "failed"));
                                    store.dispatch(reduxUploadChangeTrigger());

                                    setTimeout(() => {
                                        this.handleNextUploads();
                                    }, 150);
                                });
                        })
                        .catch((e) => {
                            store.dispatch(reduxSetUploadValue(id, "status", "failed"));

                            ApiHelper.request("/api/filemanager/uploads/failed", {
                                id: state.app.uploads[id].file_id,
                                uploadUrl: response.data.uploadUrl,
                                error: e.toString(),
                            });
                            store.dispatch(reduxUploadChangeTrigger());

                            setTimeout(() => {
                                this.handleNextUploads();
                            }, 150);
                        });
                } else {
                    uploadStartFailed();
                    store.dispatch(reduxUploadChangeTrigger());

                    setTimeout(() => {
                        this.handleNextUploads();
                    }, 150);
                }
            })
            .catch((e) => {
                uploadStartFailed();
                store.dispatch(reduxUploadChangeTrigger());

                setTimeout(() => {
                    this.handleNextUploads();
                }, 150);
            });
    }

    makeId(length) {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

const FilemanagerHelper = new FilemanagerHelperWrapper();
export { FilemanagerHelper };
