import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Icon, LoadingSpinner, Modal, ModalTitle } from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { reduxSetValueRecursive } from "../../../redux";
import { WebsiteManagerPagesBlockEdit } from "./WebsiteManagerPagesBlockEdit";

const Wrapper = styled.div`
    margin-top: 15px;

    .wrapper-title {
        font-weight: bold;
        margin-bottom: 3px;
    }
`;

const BlocksSelector = styled.div`
    flex: 1;
    overflow: auto;

    .blocks-section-title {
        padding: 8px 12px;
        font-weight: bold;
        user-select: none;
        background: #f9f9f9;
    }

    .block-element {
        padding: 8px 12px;
        user-select: none;
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.025);

        .block-description {
            font-size: 11px;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }
`;

export const WebsiteManagerPagesBlocks = (props) => {
    const dispatch = useDispatch();
    const blocks = useSelector((state) => state.app.websitePageEdit.blocks);
    const [isLoading, setIsLoading] = useState(true);
    const [design, setDesign] = useState(null);
    const [blocksList, setBlocksList] = useState(null);
    const [isAddingBlock, setIsAddingBlock] = useState(false);

    const toggleAddBlock = (e) => {
        setIsAddingBlock(!isAddingBlock);
    };

    const addBlock = (e) => {
        const newBlocks = blocks ? [...blocks] : [];
        const block = e.currentTarget.getAttribute("data-block");
        const designblock = e.currentTarget.getAttribute("data-designblock");

        const fields = {};
        if (designblock) {
            for (let i in design.blocks[block].fields) {
                if (design.blocks[block].fields[i] && design.blocks[block].fields[i].default) {
                    fields[i] = design.blocks[block].fields[i].default;
                }
            }
        }

        newBlocks.push({ type: block, designblock: designblock ? true : false, fields: fields });
        dispatch(reduxSetValueRecursive("websitePageEdit.blocks", [...newBlocks]));
        setIsAddingBlock(false);
    };

    useEffect(() => {
        setIsLoading(true);
        setDesign(null);
        setBlocksList(null);

        ApiHelper.request("/api/website-manager/blocks").then((response) => {
            setBlocksList(
                response.status === "success" && response.data && Object.keys(response.data).length > 0
                    ? response.data
                    : null
            );

            ApiHelper.request("/api/website-manager/design", {
                design: props.website.design,
            }).then((response) => {
                setDesign(response.status === "success" && response.data && response.data.name ? response.data : null);
                setIsLoading(false);
            });
        });
    }, [props.website.design]);

    if (isLoading || !design) {
        return (
            <Wrapper>
                <div className="wrapper-title">Blöcke / Seiteninhalte</div>
                <LoadingSpinner />
            </Wrapper>
        );
    }

    const blockKeys = blocksList ? Object.keys(blocksList) : null;
    const designBlockKeys = design && design.blocks ? Object.keys(design.blocks) : null;
    return (
        <Wrapper>
            {isAddingBlock ? (
                <Modal
                    open={true}
                    contentAttributes={{
                        style: {
                            margin: "auto",
                            width: "100%",
                            maxWidth: 600,
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto",
                            maxHeight: "95vh",
                        },
                    }}
                    onCloseRequest={toggleAddBlock}
                >
                    <ModalTitle>
                        <Button type="button" onClick={toggleAddBlock} className="float-right" secondary>
                            <Icon icon={faTimes} />
                        </Button>
                        <h1>Block hinzufügen</h1>
                    </ModalTitle>

                    <BlocksSelector>
                        {designBlockKeys && designBlockKeys.length > 0 ? (
                            <>
                                <div className="blocks-section-title">Design Blöcke</div>
                                {designBlockKeys.map((key, i) => {
                                    return (
                                        <div
                                            className="block-element"
                                            key={i}
                                            onClick={addBlock}
                                            data-block={key}
                                            data-designblock={true}
                                        >
                                            {design.blocks[key].name}
                                            <div className="block-description">{design.blocks[key].description}</div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}
                        {blockKeys && blockKeys.length > 0 ? (
                            <>
                                <div className="blocks-section-title">Standard Blöcke</div>
                                {blockKeys.map((key, i) => {
                                    return (
                                        <div className="block-element" key={i} onClick={addBlock} data-block={key}>
                                            {blocksList[key].name}
                                            {blocksList[key] && blocksList[key].description ? (
                                                <div className="block-description">{blocksList[key].description}</div>
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}
                    </BlocksSelector>
                </Modal>
            ) : null}

            <Button type="button" onClick={toggleAddBlock} className="float-right" xs outline secondary>
                <Icon icon={faPlus} className="mr-1" />
                Block hinzufügen
            </Button>

            <div className="wrapper-title">Blöcke / Seiteninhalte</div>
            {blocks && blocks.length > 0 ? (
                blocks.map((block, i) => (
                    <WebsiteManagerPagesBlockEdit
                        key={i}
                        index={i}
                        design={design}
                        blocksList={blocksList}
                        disabled={props.disabled}
                        blocksCount={blocks.length}
                    />
                ))
            ) : (
                <Alert light>Es wurden noch keine Blöcke zur Seite hinzugefügt</Alert>
            )}
        </Wrapper>
    );
};
