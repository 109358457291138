import React from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Content, Icon } from "@webtypen/react-components";
import { Logo } from "../Logo";

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 5000000;

    .help-bg {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
        z-index: 5000001;
    }

    .help-container {
        width: 50%;
        height: 100vh;
        background: #fff;
        position: fixed;
        top: 0px;
        right: 0px;
        z-index: 5000005;

        .help-logo {
            margin-bottom: 30px;

            span.help-title {
                color: #eee;
                background: #333;
                display: inline-block;
                padding: 3px 7px;
                border-radius: 3px;
                margin-left: 5px;
                font-size: 14px;
                position: relative;
                top: -1px;
            }
        }
    }
`;

export const HelpSection = (props) => {
    return (
        <Wrapper>
            <div className="help-bg" onClick={props.onClose} />
            <div className="help-container">
                <Content>
                    <Button light className="float-right" onClick={props.onClose}>
                        <Icon icon={faTimes} />
                    </Button>
                    <div className="help-logo">
                        <Logo style={{ fontSize: 16, display: "inline-block" }} />

                        <span className="help-title">Hilfe</span>
                    </div>
                    Die Hilfe Sektion wird hier in den nächsten Tagen veröffentlicht ...
                </Content>
            </div>
        </Wrapper>
    );
};
