import React from "react";
import styled from "styled-components";
import { Content } from "@webtypen/react-components";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import { WebsiteManagerOverview } from "./WebsiteManagerOverview";
import { WebsiteManagerDesign } from "./WebsiteManagerDesign";
import { WebsiteManagerPages } from "./WebsiteManagerPages";
import { WebsiteManagerPagesForm } from "./WebsiteManagerPagesForm";
import { WebsiteManagerSettings } from "./WebsiteManagerSettings";
import { WebsiteManagerMenus } from "./WebsiteManagerMenus";
import { PageTitleSmall } from "../../../components";

const Wrapper = styled.div``;

export const WebsiteManager = (props) => {
    const params = useParams();

    return (
        <Wrapper>
            <PageTitleSmall>
                <h1>Website-Bearbeiten</h1>
                <ul className="manager-menu">
                    <li>
                        <NavLink exact to={"/env/" + params._env + "/website-manager"}>
                            Übersicht
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/env/" + params._env + "/website-manager/pages"}>Seiten</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to={"/env/" + params._env + "/website-manager/blocks"}>Blöcke</NavLink>
                    </li> */}
                    <li>
                        <NavLink to={"/env/" + params._env + "/website-manager/design"}>Design</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/env/" + params._env + "/website-manager/menus"}>Menüs</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/env/" + params._env + "/website-manager/settings"}>Einstellungen</NavLink>
                    </li>
                </ul>
            </PageTitleSmall>

            <Switch>
                <Route exact path="/env/:_env/website-manager" component={WebsiteManagerOverview} />
                <Route exact path="/env/:_env/website-manager/pages" component={WebsiteManagerPages} />
                <Route exact path="/env/:_env/website-manager/pages/:id/edit" component={WebsiteManagerPagesForm} />
                <Route exact path="/env/:_env/website-manager/menus" component={WebsiteManagerMenus} />
                <Route exact path="/env/:_env/website-manager/settings" component={WebsiteManagerSettings} />
                <Route path="/env/:_env/website-manager/design" component={WebsiteManagerDesign} />
                <Route
                    render={() => (
                        <Content>
                            <h1>404 - Seite nicht gefunden</h1>
                            Die aufgerufene Seite existiert nicht
                        </Content>
                    )}
                />
            </Switch>
        </Wrapper>
    );
};
