import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Content, Button, Icon } from "@webtypen/react-components";
import { iconSelectorLib } from ".";

export const FloatIconSelector = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Modal
                open={isOpen}
                onCloseRequest={close}
                contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 350 } }}
            >
                {isOpen ? (
                    <>
                        <Content className="pb-0">
                            <Button type="button" onClick={close} light className="float-right">
                                <Icon icon={faTimes} />
                            </Button>
                            <h1 className="mb-2">Icon auswählen</h1>
                        </Content>

                        <div style={{ maxHeight: 300, overflow: "auto" }}>
                            {Object.keys(iconSelectorLib).map((element, i) => (
                                <div
                                    key={i}
                                    style={{
                                        float: "left",
                                        width: "33.333333%",
                                        padding: "5px 15px",
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: "center",
                                            border: "1px solid #eee",
                                            borderRadius: 3,
                                            height: 50,
                                            lineHeight: "50px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            props.onChange(element);
                                            close();
                                        }}
                                    >
                                        <Icon icon={iconSelectorLib[element].icon} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : null}
            </Modal>
            <div
                style={{
                    border: "1px solid #ddd",
                    borderRadius: 4,
                    height: 52,
                    cursor: "pointer",
                    userSelect: "none",
                }}
                onClick={open}
            >
                <div style={{ color: "#999", fontSize: 12, padding: "0px 12px", paddingTop: 11 }}>
                    {props.placeholder && props.placeholder.trim() !== "" ? props.placeholder : "Icon"}
                </div>

                <div style={{ paddingLeft: 12 }}>
                    {props.value ? (
                        iconSelectorLib && iconSelectorLib[props.value] ? (
                            <Icon icon={iconSelectorLib[props.value].icon} />
                        ) : (
                            <span className="text-danger">Unbekanntes Icon</span>
                        )
                    ) : (
                        "-"
                    )}
                </div>
            </div>
        </>
    );
};
