import React from "react";
import { Switch, Route } from "react-router-dom";
import { ContainerUser, LayoutUserarea } from "../../components";
import { Start } from "./Start";
import { EditProfile } from "./EditProfile";
import { CreateEnvironment } from "./CreateEnvironment";
import { Content } from "@webtypen/react-components";
import { AccountSettings } from "./Settings/AccountSettings";

export const AccountIndex = (props) => {
    return (
        <LayoutUserarea>
            <Switch>
                <Route exact path="/account/" component={Start} />
                <Route exact path="/account/edit" component={EditProfile} />
                <Route exact path="/account/create-environment" component={CreateEnvironment} />
                <Route path="/account/settings" component={AccountSettings} />
                <Route
                    render={() => (
                        <ContainerUser className="mt-3">
                            <Content>
                                <h1>404 - Seite nicht gefunden</h1>
                                Die aufgerufene Seite existiert nicht
                            </Content>
                        </ContainerUser>
                    )}
                />
            </Switch>
        </LayoutUserarea>
    );
};
