import React, { useEffect, useState } from "react";
import { Alert, ButtonLink, Card, Content, Icon, LoadingSpinner } from "@webtypen/react-components";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../../../helpers";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

export const WebsiteManagerPages = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setData(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                setData({});
                setIsLoading(false);
            });
    }, []);

    if (isLoading || !data || !data._id) {
        return (
            <Content>
                {isLoading ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Seiten aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>
                <ButtonLink
                    to={"/env/" + props.match.params._env + "/website-manager/pages/new/edit"}
                    type="button"
                    className="ml-1 float-right"
                    xs
                    secondary
                >
                    <Icon icon={faPlus} className="mr-1" />
                    Neue Seite anlegen
                </ButtonLink>
                Website-Seiten verwalten
            </h1>

            <Card>
                <SimpleTable model="EnvironmentWebsitePage">
                    <SimpleTableCell label="Titel" mapping="title" searchable sortable />
                    <SimpleTableCell
                        label="Optionen"
                        attributes={{ className: "text-right" }}
                        buttons={[
                            {
                                type: "button-group",
                                buttons: [
                                    {
                                        type: "button-link",
                                        label: (
                                            <>
                                                <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                            </>
                                        ),
                                        attributes: {
                                            xs: true,
                                            to: (row) => {
                                                return (
                                                    "/env/" +
                                                    props.match.params._env +
                                                    "/website-manager/pages/" +
                                                    row._id +
                                                    "/edit"
                                                );
                                            },
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </SimpleTable>
            </Card>
        </Content>
    );
};
