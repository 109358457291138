import {
    faAddressBook,
    faAddressCard,
    faBell,
    faBookmark,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faClipboard,
    faClock,
    faClone,
    faComment,
    faCopy,
    faFileAlt,
    faFileArchive,
    faFileCode,
    faFileExcel,
    faFilePdf,
    faFilePowerpoint,
    faFileWord,
    faFolder,
    faFolderOpen,
    faImages,
} from "@fortawesome/free-regular-svg-icons";
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faCalculator,
    faCalendarWeek,
    faCamera,
    faCameraRetro,
    faCampground,
    faCapsules,
    faCar,
    faFile,
    faFileInvoice,
    faFileInvoiceDollar,
    faFileVideo,
    faImage,
    faMoneyCheckAlt,
    faPercentage,
    faReceipt,
} from "@fortawesome/free-solid-svg-icons";

export const iconSelectorLib = {
    faAddressBook: {
        icon: faAddressBook,
    },
    faAddressCard: {
        icon: faAddressCard,
    },
    faBell: {
        icon: faBell,
    },
    faBookmark: {
        icon: faBookmark,
    },
    faBuilding: {
        icon: faBuilding,
    },
    faCalendar: {
        icon: faCalendar,
    },
    faCalendarAlt: {
        icon: faCalendarAlt,
    },
    faCalendarCheck: {
        icon: faCalendarCheck,
    },
    faCalculator: {
        icon: faCalculator,
    },
    faCamera: {
        icon: faCamera,
    },
    faCameraRetro: {
        icon: faCameraRetro,
    },
    faCampground: {
        icon: faCampground,
    },
    faCapsules: {
        icon: faCapsules,
    },
    faCar: {
        icon: faCar,
    },
    faClipboard: {
        icon: faClipboard,
    },
    faClock: {
        icon: faClock,
    },
    faClone: {
        icon: faClone,
    },
    faComment: {
        icon: faComment,
    },
    faFile: {
        icon: faFile,
    },
    faFileAlt: {
        icon: faFileAlt,
    },
    faFileDollar: {
        icon: faFileInvoiceDollar,
    },
    faFileInvoice: {
        icon: faFileInvoice,
    },
    faReceipt: {
        icon: faReceipt,
    },
    faFileWord: {
        icon: faFileWord,
    },
    faFileVideo: {
        icon: faFileVideo,
    },
    faFilePowerpoint: {
        icon: faFilePowerpoint,
    },
    faFilePdf: {
        icon: faFilePdf,
    },
    faFileExcel: {
        icon: faFileExcel,
    },
    faFileCode: {
        icon: faFileCode,
    },
    faFileArchive: {
        icon: faFileArchive,
    },
    faCopy: {
        icon: faCopy,
    },
    faFolder: {
        icon: faFolder,
    },
    faFolderOpen: {
        icon: faFolderOpen,
    },
    faPercentage: {
        icon: faPercentage,
    },
    faMoneyCheck: {
        icon: faMoneyCheckAlt,
    },
    faArrowUp: {
        icon: faArrowUp,
    },
    faArrowRight: {
        icon: faArrowRight,
    },
    faArrowDown: {
        icon: faArrowDown,
    },
    faArrowLeft: {
        icon: faArrowLeft,
    },
    faCalendarWeek: {
        icon: faCalendarWeek,
    },
    faImage: {
        icon: faImage,
    },
    faImages: {
        icon: faImages,
    },
};
