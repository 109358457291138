import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Content,
    FloatInput,
    FloatSelect,
    FormGroup,
    Icon,
    Input,
    LoadingSpinner,
    Row,
    Select,
    Table,
} from "@webtypen/react-components";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ConfirmHelper } from "../../../helpers";

export const EditElement = (props) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const addOption = (e) => {
        const newOptions = data.options ? data.options : [];
        newOptions.push({
            value: "",
            label: "",
        });

        setData({
            ...data,
            options: newOptions,
        });
    };

    const addDefinitionField = async (e) => {
        const name = await ConfirmHelper.prompt("Key / Speicherbezeichnung des neuen Felds");
        if (!name || name.trim() === "") {
            return;
        }

        const newDefinition = data.definition ? { ...data.definition } : {};
        newDefinition[name] = {
            title: name,
            key: name,
            type: "question",
            questionType: "string",
            columns: 12,
        };

        setData({
            ...data,
            definition: newDefinition,
        });
    };

    const onOptionChange = (e) => {
        const newOptions = [...data.options];
        const index = parseInt(e.target.getAttribute("data-index"));

        newOptions[index][e.target.name] = e.target.value;
        setData({
            ...data,
            options: newOptions,
        });
    };

    const onDefinitionChange = (e) => {
        const newDefinition = { ...data.definition };
        newDefinition[e.target.getAttribute("data-definition")][e.target.name] = e.target.value;

        setData({
            ...data,
            definition: newDefinition,
        });
    };

    const removeDefinition = async (e) => {
        const defKey = e.currentTarget.getAttribute("data-definition");
        const confirm = await ConfirmHelper.confirm("Soll das ausgewählte Feld wirklich entfernt werden?", {
            confirmAttributes: { color: "danger" },
        });
        if (!confirm) {
            return;
        }

        const newDefinition = { ...data.definition };
        if (newDefinition[defKey]) {
            delete newDefinition[defKey];
        }

        setData({
            ...data,
            definition: newDefinition,
        });
    };

    const remove = async (e) => {
        const status = await ConfirmHelper.confirm("Soll das Element wirklich aus dem Formular entfernt werden?", {
            confirmAttributes: {
                danger: true,
            },
        });

        if (status) {
            props.removeQuestion(props.definitionIndex);
            props.closeModal();
        }
    };

    const save = (e) => {
        e.preventDefault();

        props.saveQuestion(props.definitionIndex, { ...data });
        props.closeModal();
    };

    useEffect(() => {
        if (props && props.definition && props.definition.title !== undefined) {
            setData({ ...props.definition });
        } else {
            setData(null);
        }
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoading ? (
        <Content>
            <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
        </Content>
    ) : !data ? (
        <Content>
            <Alert danger>Es ist ein Fehler beim Laden des Elements aufgetreten.</Alert>
        </Content>
    ) : (
        <form onSubmit={save}>
            <Content>
                <Row>
                    <Col width={12} className="mb-3">
                        <FloatInput
                            type="text"
                            placeholder="Bezeichnung"
                            onChange={onChange}
                            value={data.title}
                            name="title"
                        />
                    </Col>
                    <Col width={4} className="mb-3">
                        <FloatInput
                            type="number"
                            placeholder="Breite / Columns"
                            onChange={onChange}
                            value={data.columns}
                            name="columns"
                            min={1}
                            max={12}
                        />
                    </Col>
                    <Col width={4}>
                        <FloatSelect
                            placeholder="Typ der Frage"
                            onChange={onChange}
                            value={data.questionType}
                            name="questionType"
                        >
                            <option value="string">Text</option>
                            <option value="text">Text (mehrzeilig)</option>
                            <option value="number">Zahl</option>
                            <option value="date">Datum</option>
                            <option value="selection">Auswahl</option>
                            <option value="selection-multi">Mehrfachauswahl</option>
                            <option value="array">Array / Liste</option>
                            <option value="signature">Signatur / Unterschrift</option>
                        </FloatSelect>
                    </Col>
                    <Col width={4} className="mb-3">
                        <FloatInput
                            type="text"
                            placeholder="Key (Speicherbezeichnung)"
                            onChange={onChange}
                            value={data.key}
                            name="key"
                        />
                    </Col>

                    {data.questionType === "selection" || data.questionType === "selection-multi" ? (
                        <Col width={12}>
                            <Card className="mb-3">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }} />
                                            <th>Wert</th>
                                            <th>
                                                <Button
                                                    type="button"
                                                    className="float-right"
                                                    onClick={addOption}
                                                    success
                                                    xs
                                                    outline
                                                >
                                                    <Icon icon={faPlus} className="mr-1" />
                                                    Hinzufügen
                                                </Button>
                                                Anzeigewert
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.options && data.options.length > 0 ? (
                                            data.options.map((option, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <Button type="button" danger outline xs>
                                                            <Icon icon={faTrashAlt} />
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Wert"
                                                            value={option.value}
                                                            onChange={onOptionChange}
                                                            name="value"
                                                            data-index={i}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Anzeigewert"
                                                            value={option.label}
                                                            onChange={onOptionChange}
                                                            name="label"
                                                            data-index={i}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={3}>
                                                    Es wurden noch keine Werte zur Auswahl hinzugefügt.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    ) : null}
                    {data.questionType === "array" ? (
                        <Col width={12}>
                            <Card className="mb-3">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }} />
                                            <th>Bezeichnung</th>
                                            <th>Typ</th>
                                            <th>
                                                <Button
                                                    type="button"
                                                    className="float-right"
                                                    onClick={addDefinitionField}
                                                    success
                                                    xs
                                                    outline
                                                >
                                                    <Icon icon={faPlus} className="mr-1" />
                                                    Hinzufügen
                                                </Button>
                                                Breite
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.definition && Object.keys(data.definition).length > 0 ? (
                                            Object.keys(data.definition).map((defKey, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            danger
                                                            outline
                                                            xs
                                                            onClick={removeDefinition}
                                                            data-definition={defKey}
                                                        >
                                                            <Icon icon={faTrashAlt} />
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            placeholder="Feldbezeichnung"
                                                            value={data.definition[defKey].title}
                                                            onChange={onDefinitionChange}
                                                            name="title"
                                                            data-definition={defKey}
                                                        />
                                                        <div style={{ fontSize: 12, marginTop: 2 }}>
                                                            <b>Key:</b> {defKey}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Select
                                                            value={data.definition[defKey].questionType}
                                                            onChange={onDefinitionChange}
                                                            name="questionType"
                                                            data-definition={defKey}
                                                        >
                                                            <option value="string">Text</option>
                                                            <option value="text">Text (mehrzeilig)</option>
                                                            <option value="number">Zahl</option>
                                                            <option value="date">Datum</option>
                                                            <option value="signature">Signatur / Unterschrift</option>
                                                        </Select>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="number"
                                                            placeholder="Columns"
                                                            value={data.definition[defKey].columns}
                                                            onChange={onDefinitionChange}
                                                            name="columns"
                                                            data-definition={defKey}
                                                            min={1}
                                                            max={12}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={3}>
                                                    Es wurden noch keine Werte zur Auswahl hinzugefügt.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    ) : null}

                    <Col width={12}>
                        <FormGroup>
                            <label>
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={data.required ? true : false}
                                    onChange={(e) => setData({ ...data, required: e.target.checked })}
                                />
                                Feld muss beantwortet / ausgefüllt werden (Pflichtfeld)
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
                <div>
                    <Button success>Speichern</Button>
                    <Button type="button" secondary className="ml-2" onClick={props.closeModal}>
                        Abbrechen
                    </Button>
                    <Button type="button" light className="text-danger ml-2" onClick={remove}>
                        Entfernen
                    </Button>
                </div>
            </Content>
        </form>
    );
};
