import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { faCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    Icon,
    Input,
    InputGroup,
    InputLabel,
    Table,
    Button,
    Dropdown,
    DropdownMenu,
    DropdownMenuElement,
    Badge,
} from "@webtypen/react-components";
import { NumericHelper } from "../../../helpers";

const Wrapper = styled.div`
    border: 1px solid #ddd;
    border-radius: 3px;

    table {
        thead {
            background: #fff;

            th:first-child {
                border-top-left-radius: 3px;
            }

            th:last-child {
                border-top-right-radius: 3px;
            }
        }

        tbody {
            background: #f8f8f8;

            .positions-options-btn {
                cursor: pointer;
                text-align: right;
                vertical-align: middle;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
`;

export const MapFieldAccountingTaxes = (props) => {
    const errors = useSelector((state) => state.app.dynamicrecordsErrors);
    const taxes = useSelector((state) => state.app.environment.taxes);

    const add = () => {
        const newTaxes = props.data.taxes ? [...props.data.taxes] : [];
        newTaxes.push({
            name: "Steuersatz",
            percent: 0,
            number: "",
        });

        props.setData(
            props.calcPos({
                ...props.data,
                taxes: [...newTaxes],
            })
        );
    };

    const addPreset = (e) => {
        const selectedTax = taxes[parseInt(e.currentTarget.getAttribute("data-index"))];
        if (!selectedTax || !selectedTax._id) {
            return;
        }

        const newTaxes = props.data.taxes ? [...props.data.taxes] : [];
        newTaxes.push({
            name: selectedTax.name,
            percent: selectedTax.percentage,
            number: selectedTax.number ? selectedTax.number : "",
        });

        props.setData(
            props.calcPos({
                ...props.data,
                taxes: [...newTaxes],
            })
        );
    };

    const remove = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const taxes = [...props.data.taxes];
        taxes.splice(index, 1);

        props.setData(
            props.calcPos({
                ...props.data,
                taxes: [...taxes],
            })
        );
    };

    const onBlur = (e) => {
        let taxes = [...props.data.taxes];
        for (let i in taxes) {
            taxes[i].percent = NumericHelper.numberFormat(NumericHelper.inputValueToFloat(taxes[i].percent));
        }

        props.setData(
            props.calcPos({
                ...props.data,
                taxes: taxes,
            })
        );
    };

    const onChange = (e) => {
        const taxes = [...props.data.taxes];
        taxes[parseInt(e.target.getAttribute("data-index"))][e.target.name] = e.target.value;

        props.setData(
            props.calcPos({
                ...props.data,
                taxes: [...taxes],
            })
        );
    };

    return (
        <Wrapper>
            <Table>
                <thead>
                    <tr>
                        <th style={{ width: 35 }}>#</th>
                        <th>Steuersatz</th>
                        <th style={{ width: 150 }} className="text-right">
                            Prozent
                        </th>
                        <th style={{ width: 170 }} className="text-right">
                            Auswertungsnummer
                        </th>
                        <th style={{ width: 110 }} className="text-right">
                            Betrag
                        </th>
                        <th style={{ width: 55 }} />
                    </tr>
                </thead>
                <tbody>
                    {!props.data.taxes || props.data.taxes.length < 1 ? (
                        <tr>
                            <td colSpan={6}>Es wurden noch keine Steuersätze hinzugefügt</td>
                        </tr>
                    ) : (
                        props.data.taxes.map((tax, i) => {
                            const taxValue =
                                (NumericHelper.inputValueToFloat(props.data.price_full) / 100) *
                                NumericHelper.inputValueToFloat(tax.percent);

                            const hasNameError =
                                errors && errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].name"]
                                    ? errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].name"]
                                    : null;
                            const hasPercentError =
                                errors &&
                                errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].percent"]
                                    ? errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].percent"]
                                    : null;
                            const hasNumberError =
                                errors &&
                                errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].number"]
                                    ? errors[(props.errorPrefix ? props.errorPrefix : "") + "taxes[" + i + "].number"]
                                    : null;

                            return (
                                <tr key={i}>
                                    <td className="text-center" style={{ verticalAlign: "middle" }}>
                                        {i + 1}
                                    </td>
                                    <td>
                                        <Input
                                            type="text"
                                            name="name"
                                            onChange={onChange}
                                            value={tax.name}
                                            data-index={i}
                                            hasError={hasNameError}
                                        />
                                        {hasNameError ? <div className="text-danger">{hasNameError}</div> : null}
                                    </td>
                                    <td>
                                        <InputGroup append={<InputLabel>%</InputLabel>}>
                                            <Input
                                                type="text"
                                                name="percent"
                                                onChange={onChange}
                                                value={tax.percent}
                                                className="text-right"
                                                data-index={i}
                                                onBlur={onBlur}
                                            />
                                        </InputGroup>
                                        {hasPercentError ? <div className="text-danger">{hasPercentError}</div> : null}
                                    </td>
                                    <td>
                                        <Input
                                            type="text"
                                            name="number"
                                            onChange={onChange}
                                            value={tax.number}
                                            data-index={i}
                                        />
                                        {hasNumberError ? <div className="text-danger">{hasNumberError}</div> : null}
                                    </td>
                                    <td className="text-right" style={{ verticalAlign: "middle" }}>
                                        {NumericHelper.numberFormat(taxValue)}
                                    </td>
                                    <td>
                                        <Button type="button" danger sm onClick={remove} data-index={i}>
                                            <Icon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                    <tr>
                        <td colSpan={3} className="pt-2 pb-2">
                            <Dropdown
                                trigger={
                                    <Button type="button" xs success outline className="ml-1">
                                        Hinzufügen
                                        <Icon icon={faCaretDown} className="ml-1" />
                                    </Button>
                                }
                            >
                                <DropdownMenu>
                                    {taxes && taxes.length > 0
                                        ? taxes.map((tax, i) => (
                                              <DropdownMenuElement key={i}>
                                                  <button type="button" data-index={i} onClick={addPreset}>
                                                      <Badge light className="float-right">
                                                          {NumericHelper.numberFormat(tax.percentage)} %
                                                      </Badge>
                                                      {tax.name}
                                                  </button>
                                              </DropdownMenuElement>
                                          ))
                                        : null}
                                    <DropdownMenuElement style={{ width: 200 }}>
                                        <button type="button" onClick={add}>
                                            Benutzerdefinierter Steuersatz
                                        </button>
                                    </DropdownMenuElement>
                                </DropdownMenu>
                            </Dropdown>
                        </td>
                        <td className="pt-2 pb-2 text-right" colSpan={2}>
                            <span className="mr-3" style={{ userSelect: "none" }}>
                                Gesamtbetrag ({NumericHelper.numberFormat(props.data.taxes_sum_percent)}%):
                            </span>
                            <span style={{ fontWeight: 700 }}>
                                {NumericHelper.numberFormat(
                                    NumericHelper.inputValueToFloat(props.data.taxes_sum_price)
                                )}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </Wrapper>
    );
};
