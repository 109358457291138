import React from "react";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Content, Icon, ButtonLink } from "@webtypen/react-components";
import { SettingTitle } from "./SettingTitle";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

export const Addresses = (props) => {
    return (
        <Content>
            <ButtonLink to={"/env/" + props.match.params._env + "/settings/addresses/new/edit"} className="float-right">
                Adresse erstellen
            </ButtonLink>

            <SettingTitle icon={faAddressBook} title="Adressen" />

            <SimpleTable model="DynamicRecord" where={[["type", "=", "envAddresses"]]}>
                <SimpleTableCell label="Name / Anschrift" mapping="name" searchable sortable />
                <SimpleTableCell label="Straße" mapping="street" searchable sortable />
                <SimpleTableCell label="Hausnummer" mapping="housenumber" searchable sortable />
                <SimpleTableCell label="PLZ" mapping="zip" searchable sortable />
                <SimpleTableCell label="Stadt" mapping="city" searchable sortable />
                <SimpleTableCell
                    label="Optionen"
                    attributes={{ className: "text-right" }}
                    buttons={[
                        {
                            type: "button-group",
                            buttons: [
                                {
                                    type: "button-link",
                                    label: (
                                        <>
                                            <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                        </>
                                    ),
                                    attributes: {
                                        xs: true,
                                        to: (row) => {
                                            return (
                                                "/env/" +
                                                props.match.params._env +
                                                "/settings/addresses/" +
                                                row._id +
                                                "/edit"
                                            );
                                        },
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </SimpleTable>
        </Content>
    );
};
