import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonLink, Card, CardBody, LoadingSpinner, Table } from "@webtypen/react-components";
import moment from "moment";
import { AuthHelper, ConfirmHelper } from "../../../helpers";

export const AccountSettingsStart = (props) => {
    const history = useHistory();
    const auth = useSelector((state) => state.app.auth);
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const logout = async (e) => {
        const status = await ConfirmHelper.confirm("Wirklich ausloggen?", { confirmAttributes: { color: "danger" } });
        if (!status) {
            return;
        }

        setIsLoggingOut(true);
        AuthHelper.logout();
        setTimeout(() => {
            history.push("/");
        }, 1500);
    };

    if (isLoggingOut) {
        return (
            <Card className="mb-3">
                <CardBody>
                    <h3 className="mt-0">Ausloggen</h3>
                    <LoadingSpinner>Account wird auf allen Geräten ausgeloggt ...</LoadingSpinner>
                </CardBody>
            </Card>
        );
    }

    return (
        <>
            <Card className="mb-3">
                <CardBody>
                    <h3 className="mt-0">Allgemein</h3>

                    <Table>
                        <tbody>
                            <tr>
                                <td>Vorname</td>
                                <td>{auth.firstname}</td>
                            </tr>
                            <tr>
                                <td>Nachname</td>
                                <td>{auth.name}</td>
                            </tr>
                            <tr>
                                <td>E-Mail</td>
                                <td>
                                    {auth.email}
                                </td>
                            </tr>
                            <tr>
                                <td>Passwort</td>
                                <td>
                                    {auth.pw_reset_date ? (
                                        "Zurückgesetzt: " + moment(auth.pw_reset_date).fromNow()
                                    ) : (
                                        <i>Noch nie zurücksetzt</i>
                                    )}
                                    <br />
                                    <ButtonLink to="/account/settings/change-pw" outline xs>
                                        Passwort ändern
                                    </ButtonLink>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <h3 className="mt-0">Überall ausloggen</h3>
                    Melde dich überall dort ab, wo du simplebis geöffnet hast, zum Beispiel in Internet-Browsern (auf
                    dem Handy, PC, Tablet).
                    <Button type="button" className="mt-2" outline secondary onClick={logout}>
                        Überall ausloggen
                    </Button>
                </CardBody>
            </Card>
        </>
    );
};
