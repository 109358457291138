import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatInput, FloatSelect, Input } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const StringField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));
    const useCustomValue = props.customValueStore ? true : false;
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    if (props.flat) {
        return (
            <Input
                type="text"
                placeholder={props.field.label}
                value={
                    useCustomValue
                        ? customValue === undefined || customValue === null
                            ? ""
                            : customValue
                        : value === undefined || value === null
                        ? ""
                        : value
                }
                onChange={onChange}
                hasError={props.hasError}
                {...props.attributes}
            />
        );
    }

    if (props.element && props.element.appearance === "select") {
        return (
            <FloatSelect
                type="text"
                placeholder={props.field.label}
                value={
                    useCustomValue
                        ? customValue === undefined || customValue === null
                            ? ""
                            : customValue
                        : value === undefined || value === null
                        ? ""
                        : value
                }
                onChange={onChange}
                hasError={props.hasError}
                style={props.definition && props.definition.style ? props.definition.style : null}
                {...props.attributes}
            >
                {props.element.notEmpty ? null : <option value=""></option>}
                {props.element.options && props.element.options.length > 0
                    ? props.element.options.map((opt, i) => (
                          <option key={i} value={opt.value}>
                              {opt.label}
                          </option>
                      ))
                    : null}
            </FloatSelect>
        );
    }

    return (
        <FloatInput
            type="text"
            placeholder={props.field.label}
            value={
                useCustomValue
                    ? customValue === undefined || customValue === null
                        ? ""
                        : customValue
                    : value === undefined || value === null
                    ? ""
                    : value
            }
            onChange={onChange}
            hasError={props.hasError}
            style={props.definition && props.definition.style ? props.definition.style : null}
            {...props.attributes}
        />
    );
};
