import {
    faDatabase,
    faHome,
    // faRocket,
    faUsers,
    faAddressBook,
    faPercentage,
    faExternalLinkAlt,
    faMoneyCheckAlt,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import { Page, Icon } from "@webtypen/react-components";
import { PageTitle, SidebarLayout } from "../../../components";
import { Addresses } from "./Addresses";
import { AddressesEdit } from "./AddressesEdit";
import { DynamicRecordsCreate } from "./DynamicRecords/DynamicRecordsCreate";
import { DynamicRecordsForm } from "./DynamicRecords/DynamicRecordsForm";
import { DynamicRecordsList } from "./DynamicRecords/DynamicRecordsList";
import { MainSettings } from "./Main";
import { PortalSettings } from "./Portal/PortalSettings";
import { SettingNotFound } from "./SettingNotFound";
import { Taxes } from "./Taxes";
import { TaxesEdit } from "./TaxesEdit";
import { BankDetails } from "./BankDetails";
import { BankDetailsEdit } from "./BankDetailsEdit";
import { UsersList } from "./UsersList";
import { UsersInvite } from "./UsersInvite";

export const SettingsStart = (props) => {
    return (
        <Page fullHeight flex className="test">
            <PageTitle title="Einstellungen" />

            <SidebarLayout
                sidebar={
                    <ul className="sidebar">
                        <li>
                            <NavLink exact to={"/env/" + props.match.params._env + "/settings"}>
                                <span className="icon-prefix">
                                    <Icon icon={faHome} />
                                </span>
                                Allgemein
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/addresses"}>
                                <span className="icon-prefix">
                                    <Icon icon={faAddressBook} />
                                </span>
                                Adressen
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/bank-details"}>
                                <span className="icon-prefix">
                                    <Icon icon={faMoneyCheckAlt} />
                                </span>
                                Bankverbindungen
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/users"}>
                                <span className="icon-prefix">
                                    <Icon icon={faUsers} />
                                </span>
                                Benutzer
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/usergroups"}>
                                <span className="icon-prefix">
                                    <Icon icon={faUsers} />
                                </span>
                                Benutzergruppen
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/dynamic-records"}>
                                <span className="icon-prefix">
                                    <Icon icon={faDatabase} />
                                </span>
                                Datenstrukturen
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/functions"}>
                                <span className="icon-prefix">
                                    <Icon icon={faRocket} />
                                </span>
                                Funktionsumfang
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/taxes"}>
                                <span className="icon-prefix">
                                    <Icon icon={faPercentage} />
                                </span>
                                Steuersätze
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/env/" + props.match.params._env + "/settings/portals"}>
                                <span className="icon-prefix">
                                    <Icon icon={faExternalLinkAlt} />
                                </span>
                                Portal
                            </NavLink>
                        </li>
                    </ul>
                }
            >
                <Switch>
                    <Route exact path={"/env/:_env/settings"} component={MainSettings} />
                    <Route exact path={"/env/:_env/settings/users"} component={UsersList} />
                    <Route exact path={"/env/:_env/settings/users/invite"} component={UsersInvite} />
                    <Route exact path={"/env/:_env/settings/addresses"} component={Addresses} />
                    <Route exact path={"/env/:_env/settings/addresses/:id/edit"} component={AddressesEdit} />
                    <Route exact path={"/env/:_env/settings/bank-details"} component={BankDetails} />
                    <Route exact path={"/env/:_env/settings/bank-details/:id/edit"} component={BankDetailsEdit} />
                    <Route exact path={"/env/:_env/settings/taxes"} component={Taxes} />
                    <Route exact path={"/env/:_env/settings/taxes/:id/edit"} component={TaxesEdit} />
                    <Route exact path={"/env/:_env/settings/dynamic-records"} component={DynamicRecordsList} />
                    <Route exact path={"/env/:_env/settings/dynamic-records/create"} component={DynamicRecordsCreate} />
                    <Route
                        path={"/env/:_env/settings/dynamic-records/:recordType/edit"}
                        component={DynamicRecordsForm}
                    />
                    <Route exact path={"/env/:_env/settings/portals"} component={PortalSettings} />
                    <Route component={SettingNotFound} />
                </Switch>
            </SidebarLayout>
        </Page>
    );
};
