import React, { useEffect, useState } from "react";
import {
    Alert,
    Badge,
    Button,
    ButtonLink,
    Card,
    CardBody,
    CardHeader,
    Content,
    FloatInput,
    Icon,
    Input,
    LoadingSpinner,
    Select,
    Table,
} from "@webtypen/react-components";
import pathToRegexp from "path-to-regexp";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faArrowLeft, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../../../helpers";
import { reduxSetValue, reduxSetValueRecursive } from "../../../redux";
import { WebsiteManagerPagesBlocks } from "./WebsiteManagerPagesBlocks";

const defaultState = {
    title: "",
    url_path: "",
    blocks: [],
    set_as_homepage: false,
    set_as_not_found_page: false,
    record_page: null,
};

export const WebsiteManagerPagesForm = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const data = useSelector((state) => state.app.websitePageEdit);
    const dynamicRecordTypes = useSelector((state) => state.app.environment.dyn_record_types);
    const [alert, setAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isIniting, setIsIniting] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [website, setWebsite] = useState(null);
    const [showOptions, setShowOptions] = useState(null);
    const [showUrlOptions, setShowUrlOptions] = useState(false);
    const [recordPageType, setRecordPageType] = useState(null);
    const [recordPageTypeLoading, setRecordPageTypeLoading] = useState(false);

    const onChange = (e) => {
        const newData = { ...data };
        newData[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        dispatch(reduxSetValue("websitePageEdit", newData));
    };

    const onRecordPageChange = (e) => {
        const newData = { ...data };

        newData.record_page[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        if (e.target.name === "type") {
            newData.record_page.mapping = "";
            loadRecordPageType(e.target.value);
        }
        dispatch(reduxSetValue("websitePageEdit", newData));
    };

    const loadRecordPageType = (type) => {
        setRecordPageTypeLoading(true);

        ApiHelper.request("/api/dynamic-records/type/details", {
            type: type,
        })
            .then((response) => {
                setRecordPageType(
                    response.status === "success" && response.data && response.data._id ? response.data : null
                );
                setRecordPageTypeLoading(false);
            })
            .catch((e) => {
                setRecordPageType(null);
                setRecordPageTypeLoading(false);
            });
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const toggleUrlOptions = () => {
        setShowUrlOptions(!showUrlOptions);
    };

    const onToggleRecordPage = (e) => {
        dispatch(
            reduxSetValue("websitePageEdit", {
                ...data,
                record_page: e.target.checked
                    ? {
                          type: "",
                          mapping: "",
                          url_parameter: "",
                          conditions: [],
                      }
                    : null,
            })
        );
    };

    const save = (e) => {
        e.preventDefault();

        setIsSaving(true);
        ApiHelper.request("/api/website-manager/save-page", { ...data, id: props.match.params.id })
            .then((response) => {
                if (response.status === "success" && response.data && response.data._id) {
                    history.push(
                        pathToRegexp.compile(props.match.path)({ ...props.match.params, id: response.data._id })
                    );

                    setAlert(["success", "Die Seite wurde erfolgreich gespeichert"]);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                console.error(e);
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        setIsIniting(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setWebsite(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsIniting(false);
            })
            .catch((e) => {
                dispatch(reduxSetValueRecursive("websitePageEdit", null));
                setIsIniting(false);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (props.match.params.id === "new") {
            dispatch(reduxSetValueRecursive("websitePageEdit", { ...defaultState }));
            setIsLoading(false);
        } else {
            ApiHelper.request("/api/website-manager/page-details", {
                id: props.match.params.id,
            })
                .then((response) => {
                    dispatch(
                        reduxSetValueRecursive(
                            "websitePageEdit",
                            response.status === "success" &&
                                response.data &&
                                response.data.page &&
                                response.data.page._id
                                ? response.data.page
                                : null
                        )
                    );

                    if (
                        response.status === "success" &&
                        response.data &&
                        response.data.page &&
                        response.data.page.record_page &&
                        response.data.page.record_page.type
                    ) {
                        loadRecordPageType(response.data.page.record_page.type);
                    }

                    setIsLoading(false);
                })
                .catch((e) => {
                    dispatch(reduxSetValueRecursive("websitePageEdit", null));
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [props.match.params.id]);

    if (isLoading || !data || !website || !website._id || isIniting) {
        return (
            <Content>
                {isLoading || isIniting ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Seiten aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    const recordPageTypeFields = recordPageType && recordPageType.fields ? Object.keys(recordPageType.fields) : null;
    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>
                <ButtonLink
                    to={"/env/" + props.match.params._env + "/website-manager/pages"}
                    type="button"
                    className="ml-1 float-right"
                    xs
                    secondary
                >
                    <Icon icon={faArrowLeft} className="mr-1" />
                    Zurück
                </ButtonLink>
                {props.match.params.id === "new" ? "Seite bearbeiten" : "Neue Seite anlegen"}
            </h1>

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

            <form onSubmit={save}>
                <Card className="mb-3">
                    <CardBody>
                        <FloatInput
                            type="text"
                            placeholder="Titel"
                            onChange={onChange}
                            disabled={isSaving}
                            value={data.title}
                            name="title"
                        />

                        {showUrlOptions ? (
                            <div>
                                <FloatInput
                                    type="text"
                                    placeholder="URL-Pfad"
                                    onChange={onChange}
                                    disabled={isSaving}
                                    value={data.url_path}
                                    name="url_path"
                                    className="mt-2"
                                />
                            </div>
                        ) : (
                            <div style={{ fontSize: 12, paddingTop: 3, cursor: "pointer" }} onClick={toggleUrlOptions}>
                                URL-Pfad:{" "}
                                {data.url_path ? (
                                    <Badge light>{data.url_path}</Badge>
                                ) : (
                                    <Badge light>Wird beim Speichern automatisch gesetzt</Badge>
                                )}
                            </div>
                        )}

                        <WebsiteManagerPagesBlocks website={website} disabled={isSaving} />
                    </CardBody>
                </Card>

                {showOptions ? (
                    <Card className="mb-3">
                        <CardHeader style={{ userSelect: "none", cursor: "pointer" }} onClick={toggleOptions}>
                            <Icon icon={faCaretUp} className="mr-1" />
                            Seiteneinstellungen
                        </CardHeader>
                        <CardBody>
                            <div className="mb-1">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="mr-1"
                                        checked={data.set_as_homepage ? true : false}
                                        onChange={onChange}
                                        name="set_as_homepage"
                                        disabled={isSaving}
                                    />
                                    Diese Seite nach dem Speichern als Startseite festlegen.
                                </label>
                            </div>
                            <div className="mb-1">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="mr-1"
                                        checked={data.set_as_not_found_page ? true : false}
                                        onChange={onChange}
                                        name="set_as_not_found_page"
                                        disabled={isSaving}
                                    />
                                    Diese Seite nach dem Speichern als "404 - Seite nicht gefunden" Seite festlegen.
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="mr-1"
                                        checked={data.record_page ? true : false}
                                        onChange={onToggleRecordPage}
                                        disabled={isSaving}
                                    />
                                    Diese Seite ist eine Detailseite für ein Dynamic-Record.
                                </label>
                            </div>

                            {data.record_page ? (
                                <Card className="mt-2">
                                    <CardHeader>Dynamic-Record Einstellungen</CardHeader>

                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: 120, verticalAlign: "middle", fontWeight: 600 }}>
                                                    Record-Typ
                                                </td>
                                                <td>
                                                    <Select
                                                        name="type"
                                                        value={data.record_page.type}
                                                        onChange={onRecordPageChange}
                                                        disabled={isSaving}
                                                    >
                                                        <option value="">-</option>
                                                        {dynamicRecordTypes && dynamicRecordTypes.length > 0
                                                            ? dynamicRecordTypes.map((el, i) => (
                                                                  <option key={i} value={el.key}>
                                                                      {el.plural}
                                                                  </option>
                                                              ))
                                                            : null}
                                                    </Select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: 120, verticalAlign: "middle", fontWeight: 600 }}>
                                                    Mapping
                                                </td>
                                                <td>
                                                    {recordPageTypeLoading ? (
                                                        <LoadingSpinner />
                                                    ) : recordPageTypeFields && recordPageTypeFields.length > 0 ? (
                                                        <Select
                                                            name="mapping"
                                                            value={data.record_page.mapping}
                                                            onChange={onRecordPageChange}
                                                            disabled={isSaving}
                                                        >
                                                            <option value="">-</option>
                                                            {recordPageTypeFields.map((el, i) => (
                                                                <option key={i} value={el}>
                                                                    {recordPageType.fields[el].label
                                                                        ? recordPageType.fields[el].label + " - "
                                                                        : null}
                                                                    {el}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <span className="text-danger">
                                                            Es stehen keine Felder zur Auswahl.
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: 120, verticalAlign: "middle", fontWeight: 600 }}>
                                                    URL-Parameter
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        name="url_parameter"
                                                        value={data.record_page.url_parameter}
                                                        onChange={onRecordPageChange}
                                                        disabled={isSaving}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            ) : null}
                        </CardBody>
                    </Card>
                ) : (
                    <Card className="mb-3">
                        <CardHeader style={{ userSelect: "none", cursor: "pointer" }} onClick={toggleOptions}>
                            <Icon icon={faCaretDown} className="mr-1" />
                            Seiteneinstellungen
                        </CardHeader>
                    </Card>
                )}
                <div className="text-right mb-3">
                    <Button success disabled={isSaving}>
                        {isSaving ? <LoadingSpinner /> : "Speichern"}
                    </Button>
                </div>
            </form>
        </Content>
    );
};
