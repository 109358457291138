import React from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const BooleanField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));
    const useCustomValue = props.customValueStore ? true : false;
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(props.formField, e.target.checked,  props.element ? props.element.rerenderOnChange : null );
        }
        dispatch(reduxSetDynamicRecordsValue(props.formField, e.target.checked,  props.element ? props.element.rerenderOnChange : null ));
    };

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    className="mr-1"
                    onChange={onChange}
                    checked={
                        useCustomValue
                            ? customValue === undefined
                                ? ""
                                : customValue
                            : value === undefined
                            ? false
                            : value !== undefined && value !== null
                            ? value
                            : false
                    }
                />
                {props.field.label}
            </label>
        </div>
    );
};
