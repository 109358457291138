import React from "react";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, FloatSelect, Icon, Table } from "@webtypen/react-components";

export const DynamicRecordsFormsLists = (props) => {
    const { data, onChange, isSaving, removeForm, setCurrentForm, copyForm } = props;

    const formKeys = data && data.forms ? Object.keys(data.forms) : null;
    return (
        <Table>
            <thead>
                {formKeys && formKeys.length > 0 ? (
                    <tr>
                        <th colSpan={4}>
                            <FloatSelect
                                placeholder="Hauptformular"
                                value={data.mainForm}
                                name="mainForm"
                                onChange={onChange}
                                disabled={isSaving}
                            >
                                {formKeys.map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </FloatSelect>
                        </th>
                    </tr>
                ) : null}
                <tr>
                    <th style={{ width: 300 }}>Formular-Key</th>
                    <th>Bezeichnung</th>
                    <th>
                        <Button type="button" success disabled xs className="float-right">
                            <Icon icon={faPlus} className="mr-1" />
                            Formular hinzufügen
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {formKeys && formKeys.length > 0 ? (
                    formKeys.map((key) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{data.forms[key].name}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    {!data.forms[key].isPreset ? (
                                        <Button
                                            type="button"
                                            xs
                                            danger
                                            outline
                                            onClick={() => removeForm(key)}
                                            disabled={isSaving || data.mainForm === key}
                                        >
                                            <Icon icon={faTrashAlt} />
                                        </Button>
                                    ) : null}
                                    {data.forms[key].isPreset ? (
                                        <Button
                                            type="button"
                                            xs
                                            primary
                                            outline
                                            onClick={() => setCurrentForm(key)}
                                            disabled={isSaving}
                                        >
                                            Ansehen
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            xs
                                            primary
                                            onClick={() => setCurrentForm(key)}
                                            disabled={isSaving}
                                        >
                                            Bearbeiten
                                        </Button>
                                    )}
                                    <Button type="button" xs primary onClick={() => copyForm(key)} disabled={isSaving}>
                                        Kopieren
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={3}>Es wurden noch keine Formulare zu diesem Datentyp hinzugefügt</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};
