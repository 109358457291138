import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { Layout } from "../../components";
import { ApiHelper, HeartbeatHelper } from "../../helpers";
import { reduxSetValue } from "../../redux";
import { Error404 } from "../errors/Error404";
import { DynamicRecordsWrapper } from "./DynamicRecords/DynamicRecordsWrapper";
import { EnvironmentError } from "./EnvironmentError";
import { EnvironmentBlocked } from "./EnvironmentBlocked";
import { SettingsStart } from "./Settings/SettingsStart";
import { Start } from "./Start";
import { FilemanagerScreen } from "./Files/FilemanagerScreen";
import { WebsiteManagerScreen } from "./WebsiteManager/WebsiteManagerScreen";
import { BookingsManager } from "./BookingsManager/BookingsManager";
import { QueryForms } from "./QueryForms/QueryForms";

export const EnvironmentIndex = (props) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.app.environmentLoading);
    const environment = useSelector((state) => state.app.environment);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        dispatch(reduxSetValue("environmentLoading", true));

        if (props.match.params._env && props.match.params._env.trim() !== "") {
            setHasError(false);
            ApiHelper.setEnvKey(props.match.params._env);
            ApiHelper.request("/api/environments/details")
                .then((response) => {
                    if (
                        response.status === "success" &&
                        response.data &&
                        response.data.environment &&
                        response.data.environment._id &&
                        response.data.environment.status &&
                        response.data.environmentUser &&
                        response.data.environmentUser.is_active
                    ) {
                        setHasError(false);

                        if (response.data.environment.status !== "blocked") {
                            dispatch(
                                reduxSetValue("environment", {
                                    ...response.data.environment,
                                    dyn_record_types: response.data.dynamicRecordsTypes,
                                })
                            );
                            dispatch(reduxSetValue("environmentUser", response.data.environmentUser));
                            dispatch(
                                reduxSetValue(
                                    "filemanagerDir",
                                    response.data.filemanagerDir && response.data.filemanagerDir.trim() !== ""
                                        ? response.data.filemanagerDir
                                        : null
                                )
                            );
                            dispatch(reduxSetValue("environmentLoading", false));
                        }
                    } else {
                        dispatch(reduxSetValue("environment", null));
                        dispatch(reduxSetValue("environmentUser", null));
                        setHasError("init-error");
                    }
                })
                .catch((e) => {
                    console.error(e);

                    dispatch(reduxSetValue("environment", null));
                    dispatch(reduxSetValue("environmentUser", null));
                    setHasError("init-error");
                });
        } else {
            dispatch(reduxSetValue("environment", null));
            dispatch(reduxSetValue("environmentUser", null));
            setHasError("init-error");
        }

        setTimeout(() => {
            HeartbeatHelper.start();
        }, 1500);

        return () => {
            ApiHelper.setEnvKey(null);
            HeartbeatHelper.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params._env]);

    if (environment && environment.status === "blocked") {
        return <EnvironmentBlocked />;
    }

    return (
        <>
            {hasError ? <EnvironmentError errorType={hasError} /> : null}
            <Layout _env={props.match.params._env} currentPath={props.location.pathname} isLoading={isLoading}>
                <Switch>
                    <Route exact path="/env/:_env/" component={Start} />
                    <Route path="/env/:_env/dyn/:_recordType" component={DynamicRecordsWrapper} />
                    <Route path="/env/:_env/website-manager" component={WebsiteManagerScreen} />
                    <Route path="/env/:_env/bookings-manager" component={BookingsManager} />
                    <Route path="/env/:_env/files" component={FilemanagerScreen} />
                    <Route path="/env/:_env/query-forms" component={QueryForms} />
                    <Route path="/env/:_env/settings" component={SettingsStart} />
                    <Route component={Error404} />
                </Switch>
            </Layout>
        </>
    );
};
