import React, { useState } from "react";
import { faCaretDown, faCaretUp, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Icon, Table, Button, FloatInput } from "@webtypen/react-components";
import { useDispatch } from "react-redux";
import { reduxSetFormBuilder } from "../../../redux";
import { FieldSelector } from "../FieldSelector";

export const EditTabs = (props) => {
    const dispatch = useDispatch();
    const [addSection, setAddSection] = useState(null);
    const [addFields, setAddFields] = useState(null);

    const onAddSection = (e) => {
        e.preventDefault();
        if (!addSection || addSection.trim() === "") {
            return;
        }

        const newSections = [...props.field.sections];
        newSections.push({
            title: addSection,
            fields: [],
        });

        props.setField({
            ...props.field,
            sections: [...newSections],
        });
        setAddSection(null);
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    const sectionUp = (index) => {
        index = parseInt(index);
        const newSections = [...props.field.sections];
        if (newSections[index - 1]) {
            const help = { ...newSections[index - 1] };
            newSections[index - 1] = { ...newSections[index] };
            newSections[index] = help;
        }

        props.setField({
            ...props.field,
            sections: [...newSections],
        });
        setAddSection(null);
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    const sectionDown = (index) => {
        index = parseInt(index);
        const newSections = [...props.field.sections];
        if (newSections[index + 1]) {
            const help = { ...newSections[index + 1] };
            newSections[index + 1] = { ...newSections[index] };
            newSections[index] = help;
        }

        props.setField({
            ...props.field,
            sections: [...newSections],
        });
        setAddSection(null);
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    const remove = (e) => {
        if (!window.confirm("Soll die Sektion wirklich entfernt werden?")) {
            return;
        }

        const newSections = [...props.field.sections];
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        newSections.splice(index, 1);

        props.setField({
            ...props.field,
            sections: [...newSections],
        });
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    return (
        <div>
            {addSection === null ? (
                <Button type="button" success outline xs className="float-right" onClick={() => setAddSection("")}>
                    <Icon icon={faPlus} className="mr-1" />
                    Hinzufügen
                </Button>
            ) : (
                <Button type="button" danger outline xs className="float-right" onClick={() => setAddSection(null)}>
                    <Icon icon={faTimes} className="mr-1" />
                    Abbrechen
                </Button>
            )}

            <b>Tabs:</b>
            <div className="clearfix"></div>
            {addSection !== null ? (
                <div style={{ marginTop: 3, display: "flex" }}>
                    <FloatInput
                        placeholder="Name des Tabs"
                        value={addSection}
                        onChange={(e) => setAddSection(e.target.value)}
                        wrapperAttributes={{ style: { flex: 1 } }}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                    <Button
                        type="button"
                        success
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        disabled={!addSection || addSection.trim() === ""}
                        onClick={onAddSection}
                    >
                        <Icon icon={faPlus} />
                    </Button>
                </div>
            ) : (
                <Table className="mt-1">
                    <tbody>
                        {props.field.sections && props.field.sections.length > 0
                            ? props.field.sections.map((section, i) => (
                                  <React.Fragment key={i}>
                                      <tr>
                                          <td className="pl-0">
                                              <Button
                                                  type="button"
                                                  className="mr-1"
                                                  outline
                                                  danger
                                                  xs
                                                  data-index={i}
                                                  onClick={remove}
                                              >
                                                  <Icon icon={faTrash} />
                                              </Button>
                                              <Button
                                                  type="button"
                                                  className="mr-2"
                                                  outline
                                                  success
                                                  xs
                                                  data-index={i}
                                                  onClick={() =>
                                                      addFields === i ? setAddFields(null) : setAddFields(i)
                                                  }
                                              >
                                                  {addFields === i ? <Icon icon={faTimes} /> : <Icon icon={faPlus} />}
                                              </Button>
                                              {section.title}
                                          </td>
                                          <td className="text-right">{section.fields.length} Felder</td>
                                          <td className="text-right" style={{ width: 68 }}>
                                              <Button
                                                  type="button"
                                                  light
                                                  xs
                                                  disabled={i === 0}
                                                  onClick={() => sectionUp(i)}
                                              >
                                                  <Icon icon={faCaretUp} />
                                              </Button>
                                              <Button
                                                  type="button"
                                                  light
                                                  xs
                                                  disabled={i + 1 === props.field.sections.length}
                                                  onClick={() => sectionDown(i)}
                                              >
                                                  <Icon icon={faCaretDown} />
                                              </Button>
                                          </td>
                                      </tr>
                                      {addFields === i ? (
                                          <tr>
                                              <td colSpan={3}>
                                                  <b>Feld hinzufügen</b>
                                                  <FieldSelector
                                                      pathPrefix={props.active + ".sections[" + i + "].fields"}
                                                  />
                                              </td>
                                          </tr>
                                      ) : null}
                                  </React.Fragment>
                              ))
                            : null}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
