const lodash = require("lodash");

class ConditionsHelperWrapper {
    check(conditions, variables) {
        if (!conditions || conditions.length < 1) {
            return true;
        }

        for (let i in conditions) {
            if (typeof conditions[i] === "object") {
                if (Array.isArray(conditions[i])) {
                    const key = lodash.get(variables, conditions[i][0]);
                    const operator = conditions[i][1];
                    const value = conditions[i][2];

                    const status = this.checkCondition(key, operator, value);
                    if (!status) {
                        return false;
                    }
                } else {
                    if (conditions[i].$or) {
                        let orState = false;
                        for (let o in conditions[i].$or) {
                            const key = lodash.get(variables, conditions[i].$or[o][0]);
                            const operator = conditions[i].$or[o][1];
                            const value = conditions[i].$or[o][2];

                            const status = this.checkCondition(key, operator, value);
                            if (status) {
                                orState = true;
                            }
                        }

                        if (!orState) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    checkCondition(key, operator, value) {
        if (operator === "not_empty") {
            if (!key || !key.toString() || key.toString().trim() === "") {
                return false;
            }
        } else if (operator === "is_empty") {
            if (key && key.toString() && key.toString().trim() !== "") {
                return false;
            }
        } else if (operator === "==") {
            if (key !== value) {
                return false;
            }
        } else if (operator === "!=") {
            if (key === value) {
                return false;
            }
        }
        return true;
    }
}

const ConditionsHelper = new ConditionsHelperWrapper();
export { ConditionsHelper };
