import React from "react";
import { Page, Content } from "@webtypen/react-components";
import { PageTitle } from "../../components";

export const Start = (props) => {
    return (
        <Page flex fullHeight>
            <PageTitle title="Dashboard" />

            <Content
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.6,
                    userSelect: "none",
                }}
            >
                <h3 className="mb-1">Dashboards</h3>
                Dashboards können in den Einstellung konfiguriert werden ...
            </Content>
        </Page>
    );
};
