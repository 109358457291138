import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, LoadingSpinner } from "@webtypen/react-components";
import { AuthHelper } from "../helpers";

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 30px;
`;

export const Logout = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const logout = (e) => {
        setIsLoading(true);

        AuthHelper.logout();
        setTimeout(() => {
            props.history.push("/");
        }, 1500);
    };

    return (
        <Wrapper>
            <Container className="text-center">
                <h1>Ausloggen</h1>
                {isLoading ? (
                    <LoadingSpinner>Sie werden ausgeloggt, einen Moment bitte ...</LoadingSpinner>
                ) : (
                    <>
                        Wollen Sie sich wirklich ausloggen?
                        <div className="mt-2">
                            <Button type="button" danger outline onClick={logout}>
                                Ja, ausloggen
                            </Button>
                        </div>
                        <div className="mt-2">
                            <Link to="/account">Abbrechen</Link>
                        </div>
                    </>
                )}
            </Container>
        </Wrapper>
    );
};
