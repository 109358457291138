import React from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";
import { FloatModelMultiSelector } from "../../FloatModelMultiSelector";

export const ModelsSelectorField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));

    const onChange = (value) => {
        if (props.onChange) {
            return props.onChange(props.formField, value, props.element ? props.element.rerenderOnChange : null);
        }
        dispatch(
            reduxSetDynamicRecordsValue(props.formField, value, props.element ? props.element.rerenderOnChange : null)
        );
    };

    return (
        <FloatModelMultiSelector
            placeholder={props.field.label}
            value={value === undefined ? [] : value}
            onChange={onChange}
            hasError={props.hasError}
            selector={props.selector}
            style={props.definition && props.definition.style ? props.definition.style : null}
        />
    );
};
