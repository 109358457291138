import React from "react";
import { faCheck, faEdit, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink, Card, Content, Icon } from "@webtypen/react-components";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";

export const BookingsManagerRooms = (props) => {
    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>
                <ButtonLink
                    to={"/env/" + props.match.params._env + "/bookings-manager/rooms/new/edit"}
                    primary
                    className="float-right"
                    xs
                >
                    <Icon icon={faPlus} className="mr-1" />
                    Raum anlegen
                </ButtonLink>
                Räume
            </h1>

            <Card>
                <SimpleTable model="DynamicRecord" where={[["type", "=", "bookingElement"]]}>
                    <SimpleTableCell label="Name / Beschreibung" mapping="name" searchable sortable />
                    <SimpleTableCell
                        label="Aktiv"
                        mapping="is_active"
                        render={(data) => (data.is_active ? <Icon icon={faCheck} /> : <Icon icon={faTimes} />)}
                        attributes={{ className: "text-center", style: { width: 100 } }}
                        searchable="boolean"
                        sortable
                    />
                    <SimpleTableCell
                        label="Optionen"
                        attributes={{ className: "text-right", style: { width: 135 } }}
                        buttons={[
                            {
                                type: "button-group",
                                buttons: [
                                    {
                                        type: "button-link",
                                        label: (
                                            <>
                                                <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                            </>
                                        ),
                                        attributes: {
                                            xs: true,
                                            to: (row) => {
                                                return (
                                                    "/env/" +
                                                    props.match.params._env +
                                                    "/bookings-manager/rooms/" +
                                                    row._id +
                                                    "/edit"
                                                );
                                            },
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </SimpleTable>
            </Card>
        </Content>
    );
};
