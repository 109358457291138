import React from "react";
import { Switch, Route } from "react-router-dom";
import { QueryFormsIndex } from "../QueryForms/QueryFormsIndex";
import { QueryFormsEdit } from "../QueryForms/QueryFormsEdit";
import { QueryFormsDetails } from "./QueryFormsDetails";

export const QueryForms = (props) => {
    return (
        <Switch>
            <Route exact path="/env/:_env/query-forms/form/:id/edit" component={QueryFormsEdit} />
            <Route exact path="/env/:_env/query-forms/form/:id/details" component={QueryFormsDetails} />
            <Route exact path="/env/:_env/query-forms/:tab?/:tab2?/:tab3?" component={QueryFormsIndex} />
        </Switch>
    );
};
