import React from "react";
import { Alert, Col, Content, FloatInput, FloatSelect, Row } from "@webtypen/react-components";

export const DynamicRecordsSettings = (props) => {
    const { data, setData } = props;

    const onChange = (e) => {
        const settingValues = data.settingValues ? { ...data.settingValues } : {};
        settingValues[e.target.name] =
            e.target.type === "select" || e.target.type === "select-one"
                ? e.target.value === "false"
                    ? false
                    : e.target.value === "true"
                    ? true
                    : e.target.value
                : e.target.value;

        setData({
            ...data,
            settingValues: settingValues,
        });
    };

    if (!data || !data.settings || data.settings.length < 1) {
        return (
            <Content>
                <Alert light>Diese Datenstruktur hat keine konfigurierbaren Einstellungen.</Alert>
            </Content>
        );
    }

    return (
        <Content className="pb-0">
            <Row>
                {data.settings.map((setting, i) => {
                    return (
                        <Col
                            width={12}
                            key={i}
                            className={(i % 2 !== 0 ? "bg-light" : "") + (i > 0 ? " pt-3" : "") + " pb-3"}
                        >
                            {setting.type === "string" ? (
                                <div className="mt-1">
                                    <FloatInput
                                        type="text"
                                        placeholder={setting.name}
                                        onChange={onChange}
                                        name={setting.key}
                                        value={
                                            data && data.settingValues && data.settingValues[setting.key]
                                                ? data.settingValues[setting.key]
                                                : ""
                                        }
                                    />
                                    {setting.default ? (
                                        <div style={{ marginTop: 4, fontSize: 12 }}>
                                            <b>Standard:</b> {setting.default}
                                        </div>
                                    ) : null}
                                    {setting.description ? (
                                        <div style={{ marginTop: 4, fontSize: 12 }}>{setting.description}</div>
                                    ) : null}
                                </div>
                            ) : setting.type === "boolean" ? (
                                <div className="mt-1 mb-2">
                                    <FloatSelect
                                        placeholder={setting.name}
                                        onChange={onChange}
                                        name={setting.key}
                                        value={
                                            data && data.settingValues && data.settingValues[setting.key] !== undefined
                                                ? data.settingValues[setting.key]
                                                : setting.default !== undefined
                                                ? setting.default
                                                : ""
                                        }
                                    >
                                        <option value={true}>Ja</option>
                                        <option value={false}>Nein</option>
                                    </FloatSelect>
                                    {setting.default ? (
                                        <div style={{ marginTop: 4, fontSize: 12 }}>
                                            <b>Standard:</b> {setting.default}
                                        </div>
                                    ) : null}
                                    {setting.description ? (
                                        <div style={{ marginTop: 4, fontSize: 12 }}>{setting.description}</div>
                                    ) : null}
                                </div>
                            ) : (
                                <Alert danger>Unknown setting-type `{setting.type}`</Alert>
                            )}
                        </Col>
                    );
                })}
            </Row>
        </Content>
    );
};
