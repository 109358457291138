import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatInput, Select } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const SelectField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    if (props.flat) {
        return (
            <Select
                placeholder={props.field.label}
                value={value === undefined ? "" : value}
                onChange={onChange}
                hasError={props.hasError}
                {...props.attributes}
            >
                {props.field && props.field.options && props.field.options.length > 0
                    ? props.field.options.map((option, i) => (
                          <option key={i} value={option.value}>
                              {option.label ? option.label : option.value}
                          </option>
                      ))
                    : null}
            </Select>
        );
    }

    return (
        <FloatInput
            type="text"
            placeholder={props.field.label}
            value={value === undefined ? "" : value}
            onChange={onChange}
            hasError={props.hasError}
            style={props.definition && props.definition.style ? props.definition.style : null}
            {...props.attributes}
        />
    );
};
