import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, FloatInput, Alert, LoadingSpinner } from "@webtypen/react-components";
import { ApiHelper } from "../helpers";
import { LayoutGuest } from "../components/LayoutGuest";
import { SuccessAnimation, SuccessText } from "../components";

export const ForgotPWReset = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isResetting, setIsResetting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [alert, setAlert] = useState(null);
    const [data, setData] = useState(null);
    const [hasSuccess, setHasSuccess] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const reset = (e) => {
        e.preventDefault();

        if (!newPassword || newPassword.trim() === "" || newPassword.length < 4 || newPassword !== newPassword2) {
            return;
        }

        setIsResetting(true);
        setAlert(null);
        setHasSuccess(false);
        setShowSuccess(false);
        ApiHelper.request("/api/users/forgot-pw/reset", {
            user: props.match.params.user,
            hash: props.match.params.hash,
            password: newPassword,
        })
            .then((response) => {
                if (response && response.status === "success") {
                    setAlert(null);
                    setHasSuccess(true);
                    setIsResetting(false);

                    setTimeout(() => {
                        setShowSuccess(true);
                    }, 250);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten.",
                    ]);
                    setIsResetting(false);
                }
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
                ]);
                setIsResetting(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        setNewPassword("");
        setNewPassword2("");
        ApiHelper.request("/api/users/forgot-pw/check", {
            user: props.match.params.user,
            hash: props.match.params.hash,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.email &&
                    response.data.email.trim() !== "" &&
                    response.data._id === props.match.params.user
                ) {
                    setData(response.data);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten.",
                    ]);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
                ]);
                setIsLoading(false);
            });
    }, [props.match.params.user, props.match.params.hash]);

    return (
        <LayoutGuest title="Passwort zurücksetzen">
            {alert ? (
                <Alert className="mt-3" color={alert[0]}>
                    {alert[1]}
                </Alert>
            ) : null}
            {isLoading ? (
                <LoadingSpinner className="mb-5">Accountdaten werden geladen ...</LoadingSpinner>
            ) : hasSuccess ? (
                <>
                    <div style={{ position: "relative" }}>
                        <SuccessAnimation className="float-left" />
                        <SuccessText
                            show={showSuccess}
                            className="text-success"
                            style={{ fontSize: 22, paddingTop: 3, paddingLeft: 5, height: "auto" }}
                        >
                            Password erfolgreich zurückgesetzt
                        </SuccessText>
                        <div className="clearfix" />
                    </div>
                    Das Password wurde erfolgreich zurückgesetzt und der Account <b>{data.email}</b> kann nun wieder
                    verwendet werden.
                    <br />
                    <br />
                </>
            ) : data ? (
                <form className="mb-5" onSubmit={reset}>
                    <FloatInput readOnly placeholder="E-Mail" value={data.email} disabled className="mb-2" />
                    <FloatInput
                        type="password"
                        placeholder="Neues Passwort"
                        value={newPassword}
                        disabled={isResetting}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="mb-2"
                    />
                    <FloatInput
                        type="password"
                        placeholder="Neues Passwort (wiederholen)"
                        value={newPassword2}
                        disabled={isResetting}
                        onChange={(e) => setNewPassword2(e.target.value)}
                        className="mb-2"
                    />
                    <Button
                        block
                        success
                        className="mb-2"
                        disabled={
                            isResetting || !newPassword || newPassword.trim().length < 5 || newPassword !== newPassword2
                        }
                    >
                        {isResetting ? <LoadingSpinner /> : "Passwort zurücksetzen"}
                    </Button>
                </form>
            ) : null}
            <Link to="/">Zurück zum Login</Link>
        </LayoutGuest>
    );
};
