import React, { useEffect, useState } from "react";
import { faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Content, Icon, ButtonLink, Badge } from "@webtypen/react-components";
import { SettingTitle } from "./SettingTitle";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { ApiHelper } from "../../../helpers";

export const BankDetails = (props) => {
    const [environment, setEnvironment] = useState(null);

    useEffect(() => {
        ApiHelper.request("/api/environments/details").then((response) => {
            if (response.status === "success" && response.data && response.data.environment) {
                setEnvironment(response.data.environment);
            } else {
                setEnvironment(null);
            }
        });
    }, []);

    return (
        <Content>
            <ButtonLink
                to={"/env/" + props.match.params._env + "/settings/bank-details/new/edit"}
                className="float-right"
            >
                Bankverbindung anlegen
            </ButtonLink>

            <SettingTitle icon={faMoneyCheckAlt} title="Bankverbindungen" />

            <SimpleTable model="DynamicRecord" where={[["type", "=", "envBankDetails"]]}>
                <SimpleTableCell
                    label="Bezeichnung"
                    mapping="name"
                    searchable
                    sortable
                    render={(row) =>
                        environment && row._id === environment.default_bank_details ? (
                            <>
                                {row.name} <Badge warning>Standard</Badge>
                            </>
                        ) : (
                            row.name
                        )
                    }
                />
                <SimpleTableCell label="IBAN" mapping="iban" searchable sortable />
                <SimpleTableCell label="BIC" mapping="bic" searchable sortable />
                <SimpleTableCell
                    label="Optionen"
                    attributes={{ className: "text-right" }}
                    buttons={[
                        {
                            type: "button-group",
                            buttons: [
                                {
                                    type: "button-link",
                                    label: (
                                        <>
                                            <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                        </>
                                    ),
                                    attributes: {
                                        xs: true,
                                        to: (row) => {
                                            return (
                                                "/env/" +
                                                props.match.params._env +
                                                "/settings/bank-details/" +
                                                row._id +
                                                "/edit"
                                            );
                                        },
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </SimpleTable>
        </Content>
    );
};
