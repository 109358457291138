import React, { useEffect, useState } from "react";
import { Alert, Button, CardFooter, Content, Row, Col, FloatInput } from "@webtypen/react-components";
import { ApiHelper } from "../../helpers";

export const Step3 = (props) => {
    const [data, setData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const finish = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments-setup/step-3", { ...data, _env: props.match.params.envKey })
            .then((response) => {
                props.nextStep();
                props.addDone(props.index);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        if (props.init && props.init.defaultBankDetails) {
            setData({
                name: props.init.defaultBankDetails.name ?? props.init.environment.name,
                bank: props.init.defaultBankDetails.bank ?? "",
                iban: props.init.defaultBankDetails.iban ?? "",
                bic: props.init.defaultBankDetails.bic ?? "",
            });
        } else {
            setData({
                name: props.init.environment.name,
                bank: "",
                iban: "",
                bic: "",
            });
        }
    }, [props.init]);

    if (!data) {
        return null;
    }

    return (
        <form onSubmit={finish}>
            <Content>
                {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                <h3 className="mt-2">Standard-Bankverbindung</h3>
                Die hier angegebene Bankverbindung wird auf Ihren Exporten erscheinen (Rechnungs-PDFs, Angebots-PDFs,
                ...).
                <br />
                In den Einstellungen der Firmenumgebung kann die hier angegebene Bankverbindung jederzeit bearbeitet und
                ergänzt werden.
                <FloatInput
                    placeholder="Name / Zahlungsempfänger"
                    value={data.name}
                    name="name"
                    onChange={onChange}
                    disabled={isSaving}
                    className="mb-2 mt-3"
                />
                <FloatInput
                    placeholder="Bank / Geldinstitut"
                    value={data.bank}
                    name="bank"
                    onChange={onChange}
                    disabled={isSaving}
                    className="mb-2"
                />
                <Row>
                    <Col width={8}>
                        <FloatInput
                            placeholder="IBAN"
                            value={data.iban}
                            name="iban"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                    <Col width={4}>
                        <FloatInput
                            placeholder="BIC"
                            value={data.bic}
                            name="bic"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                </Row>
            </Content>
            <CardFooter className="text-right">
                <Button success>Weiter</Button>
            </CardFooter>
        </form>
    );
};
