import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Table } from "@webtypen/react-components";
import { NumericHelper } from "../../../helpers";

const PriceWrapper = styled.div`
    table {
        max-width: 350px;
        float: right;
        background: #f8f8f8;
        border: 1px solid #ddd;
        border-radius: 3px;

        tr.full-price {
            td {
                border-top: 2px solid #b2b2b2;
            }
        }
    }

    &:after {
        content: "";
        clear: both;
        display: block;
    }
`;

export const AccountingDocumentPriceBox = (props) => {
    const positions = useSelector((state) => state.app.dynamicrecords.positions);
    const dynamicrecordsPositionsChanges = useSelector((state) => state.app.dynamicrecordsPositionsChanges); // eslint-disable-line
    let price_brutto = 0;
    let price_netto = 0;
    const taxes = {};

    for (let i in positions) {
        const price_full = NumericHelper.inputValueToFloat(positions[i].price_full);
        const tax_sum =
            positions[i].taxes_sum_price !== null &&
            positions[i].taxes_sum_price !== false &&
            positions[i].taxes_sum_price !== undefined
                ? typeof positions[i].taxes_sum_price === "string"
                    ? NumericHelper.inputValueToFloat(positions[i].taxes_sum_price)
                    : positions[i].taxes_sum_price
                : 0;
        price_netto += price_full;
        price_brutto += price_full + tax_sum;

        if (positions[i].taxes) {
            for (let t in positions[i].taxes) {
                const percent = NumericHelper.inputValueToFloat(positions[i].taxes[t].percent);
                const tax =
                    positions[i].taxes[t].name.trim().toLowerCase() +
                    "_" +
                    NumericHelper.numberFormat(percent, 2, "_", "_");

                if (!taxes[tax]) {
                    taxes[tax] = {
                        name: positions[i].taxes[t].name.trim(),
                        percent: percent,
                        value: 0,
                    };
                }

                taxes[tax].value += (price_full / 100) * percent;
            }
        }
    }

    return (
        <PriceWrapper className="mt-3">
            <Table>
                <tbody>
                    <tr>
                        <td>Gesamptreis Netto</td>
                        <td className="text-right">{NumericHelper.numberFormat(price_netto)}</td>
                    </tr>
                    {Object.keys(taxes).map((key, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    {taxes[key].name}{" "}
                                    <span style={{ fontSize: 11 }}>
                                        ({NumericHelper.numberFormat(taxes[key].percent)}%)
                                    </span>
                                </td>
                                <td className="text-right">{NumericHelper.numberFormat(taxes[key].value)}</td>
                            </tr>
                        );
                    })}
                    <tr className="full-price">
                        <td>Gesamptreis Brutto</td>
                        <td className="text-right" style={{ fontWeight: 800 }}>
                            {NumericHelper.numberFormat(price_brutto)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </PriceWrapper>
    );
};
