import React, { useEffect, useState } from "react";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Content, Icon, LoadingSpinner, Page, Tabs } from "@webtypen/react-components";
import { faEdit, faEye } from "@fortawesome/free-regular-svg-icons";
import { ApiHelper } from "../../../helpers";
import { PageTitle } from "../../../components";
import { QueryFormsSettings } from "./QueryFormsSettings/QueryFormsSettings";

export const QueryFormsIndex = (props) => {
    const [environment, setEnvironment] = useState(null); // eslint-disable-line no-unused-vars
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiHelper.request("/api/environments/details").then((response) => {
            if (response.status === "success" && response.data && response.data.environment) {
                setEnvironment(response.data.environment);
            } else {
                setEnvironment(null);
            }
            setIsLoading(false);
        });
    }, []);

    return (
        <Page>
            <PageTitle
                title="Formulare"
                actions={[
                    {
                        type: "button-link",
                        label: "Neues Formular anlegen",
                        attributes: {
                            to: "/env/" + props.match.params._env + "/query-forms/form/new/edit",
                        },
                    },
                ]}
            />

            {isLoading ? (
                <Content>
                    <LoadingSpinner>Firmenumgebung-Details werden geladen ...</LoadingSpinner>
                </Content>
            ) : (
                <Tabs
                    inPageTitle
                    withRouter
                    sections={{
                        overview: {
                            title: "Übersicht",
                            exact: true,
                            path: "/env/" + props.match.params._env + "/query-forms",
                            render: () => (
                                <SimpleTable model="QueryForm">
                                    <SimpleTableCell label="Titel / Bezeichnung" mapping="title" searchable sortable />
                                    <SimpleTableCell
                                        label="Optionen"
                                        attributes={{ className: "text-right" }}
                                        buttons={[
                                            {
                                                type: "button-group",
                                                buttons: [
                                                    {
                                                        type: "button-link",
                                                        label: (
                                                            <>
                                                                <Icon icon={faEye} className="mr-1" />
                                                                Details
                                                            </>
                                                        ),
                                                        attributes: {
                                                            xs: true,
                                                            to: (row) => {
                                                                return (
                                                                    "/env/" +
                                                                    props.match.params._env +
                                                                    "/query-forms/form/" +
                                                                    row._id +
                                                                    "/details"
                                                                );
                                                            },
                                                        },
                                                    },
                                                    {
                                                        type: "button-link",
                                                        label: (
                                                            <>
                                                                <Icon icon={faEdit} />
                                                            </>
                                                        ),
                                                        attributes: {
                                                            xs: true,
                                                            to: (row) => {
                                                                return (
                                                                    "/env/" +
                                                                    props.match.params._env +
                                                                    "/query-forms/form/" +
                                                                    row._id +
                                                                    "/edit"
                                                                );
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </SimpleTable>
                            ),
                        },
                        settings: {
                            title: "Einstellungen",
                            path: "/env/" + props.match.params._env + "/query-forms/settings",
                            render: () => {
                                return <QueryFormsSettings match={props.match} />;
                            },
                        },
                    }}
                />
            )}
        </Page>
    );
};
