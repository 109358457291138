import React, { useState } from "react";
import styled from "styled-components";
import { Table, Button, Icon, Input, Content, Alert, Select } from "@webtypen/react-components";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div``;

export const SimpleTableBuilder = (props) => {
    const { definition } = props;
    const [add, setAdd] = useState("");

    const onChange = (e) => {
        if (props.onDefinitionChange) {
            const index = parseInt(e.target.getAttribute("data-index"));
            const newDefinition = [...definition];
            newDefinition[index][e.target.name] = e.target.value;
            props.onDefinitionChange(newDefinition);
        }
    };

    const onAddChange = (e) => {
        setAdd(e.target.value);
    };

    const onAdd = () => {
        if (!add || add.trim() === "") {
            const newDefinition = definition ? [...definition] : [];
            newDefinition.push({
                type: "custom",
                mapping: "",
                label: "",
            });

            if (props.onDefinitionChange) {
                props.onDefinitionChange(newDefinition);
            }
        } else {
            const newDefinition = definition ? [...definition] : [];
            newDefinition.push({
                type: "field",
                mapping: add,
                label: add,
            });

            if (props.onDefinitionChange) {
                props.onDefinitionChange(newDefinition);
            }
        }
    };

    return (
        <Wrapper>
            {!definition || definition.length < 1 ? (
                <Content>
                    <Alert className="mb-0" light>
                        Es wurde noch keiner Tabellenzellen hinzugefügt.
                    </Alert>
                </Content>
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: 50 }}></th>
                            <th>Feld</th>
                            <th>Label</th>
                        </tr>
                    </thead>
                    <tbody>
                        {definition.map((col, i) => (
                            <tr key={i}>
                                <td style={{ verticalAlign: "middle" }}>
                                    <Button type="button" danger outline xs>
                                        <Icon icon={faTrashAlt} />
                                    </Button>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    {col.type === "custom" ? (
                                        <Input
                                            type="text"
                                            value={col.mapping}
                                            onChange={onChange}
                                            data-index={i}
                                            name="mapping"
                                        />
                                    ) : (
                                        col.mapping
                                    )}
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={col.label}
                                        onChange={onChange}
                                        data-index={i}
                                        name="label"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Content className="mt-0 pt-0">
                <Select
                    onChange={onAddChange}
                    value={add}
                    style={{ maxWidth: "200px", minWidth: "100px", display: "inline-block" }}
                >
                    <option value="">Freies-Feld</option>
                </Select>
                <Button type="button" sm className="ml-2" onClick={onAdd}>
                    Hinzufügen
                </Button>
            </Content>
        </Wrapper>
    );
};
