import React, { useEffect, useState } from "react";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { reduxSetFormBuilder, reduxSetFormBuilderForm, store } from "../../redux";
import { Button, Col, Content, Icon, Row, FloatInput, FloatSelect } from "@webtypen/react-components";
import { EditTabs } from "./Sidebar/EditTabs";
import { EditMap } from "./Sidebar/EditMap";
import { fieldTypes } from "./FieldSelector";
import { EditSidebarStyleAttribute } from "./Sidebar/StyleAttribute";
import { FieldSelector } from "./FieldSelector";
import { EditObject } from "./Sidebar/EditObject";

export const SidebarEditField = (props) => {
    const dispatch = useDispatch();
    const hasChanges = useSelector((state) => state.app.formBuilderForm.hasChanges);
    const fieldDefinitions = useSelector((state) => state.app.formBuilderForm.fields);
    const [field, setField] = useState(null);
    const [isInited, setIsInited] = useState(false);

    const close = () => {
        dispatch(reduxSetFormBuilder("active", null));
        dispatch(reduxSetFormBuilder("hasChanges", false));
    };

    const onChange = (e) => {
        setField({
            ...field,
            [e.target.name]: e.target.value,
        });

        if (!hasChanges) {
            dispatch(reduxSetFormBuilder("hasChanges", true));
        }
    };

    const submit = (e) => {
        e.preventDefault();

        const state = store.getState();
        const newFormFields = [...state.app.formBuilderForm.form.fields];
        lodash.set(newFormFields, props.active, { ...field });
        dispatch(reduxSetFormBuilderForm("fields", newFormFields));
        dispatch(reduxSetFormBuilder("hasChanges", false));
    };

    const remove = () => {
        if (!window.confirm("Soll das Feld wirklich entfernt werden?")) {
            return;
        }

        const state = store.getState();
        const newFormFields = [...state.app.formBuilderForm.form.fields];
        let index = props.active.substring(props.active.lastIndexOf("[") + 1);
        index = parseInt(index.substring(0, props.active.indexOf("]") - 1));

        const path = props.active.substring(0, props.active.lastIndexOf("["));
        const array = !path || path.trim() === "" ? newFormFields : lodash.get(newFormFields, path);
        array.splice(index, 1);

        let newFields = [];
        if (!path || path.trim() === "") {
            newFields = array;
        } else {
            newFields = lodash.set(newFormFields, path, array);
        }

        dispatch(reduxSetFormBuilderForm("fields", [...newFields]));
        dispatch(reduxSetFormBuilder("hasChanges", false));
        dispatch(reduxSetFormBuilder("active", null));
    };

    useEffect(() => {
        dispatch(reduxSetFormBuilder("hasChanges", false));
        if (!props.active) {
            setField(null);
            setIsInited(true);
            return null;
        }

        const state = store.getState();
        const reduxField = lodash.get(state.app.formBuilderForm.form.fields, props.active);
        setField(reduxField ? { ...reduxField } : null);
        setIsInited(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.active]);

    if (!isInited) {
        return null;
    }

    if (!field) {
        return (
            <Content className="text-center">
                <h1>Das Feld wurde nicht gefunden</h1>
                <Button type="button" light onClick={close}>
                    <Icon icon={faArrowLeft} className="mr-1" />
                    Zurück
                </Button>
            </Content>
        );
    }

    let fieldDefinition = null;
    if (field.field && field.field.trim() !== "") {
        if (field.parentDefinitionPath && field.parentDefinitionPath.trim() !== "") {
            for (let g in fieldDefinitions) {
                const check = lodash.get(fieldDefinitions[g].fields, field.parentDefinitionPath + "." + field.field);
                if (check) {
                    fieldDefinition = check;
                    break;
                }
            }
        } else {
            for (let g in fieldDefinitions) {
                for (let f in fieldDefinitions[g].fields) {
                    if (f === field.field) {
                        fieldDefinition = fieldDefinitions[g].fields[f];
                    }
                }
            }
        }
    }

    const fieldType =
        fieldDefinition && fieldDefinition.type
            ? fieldTypes[fieldDefinition.type]
            : field && field.type
            ? fieldTypes[field.type]
            : null;

    return (
        <Content>
            <Button type="button" light onClick={close} className="float-right">
                <Icon icon={faTimes} className="mr-1" />
                Abbrechen
            </Button>
            <h1 className="mb-0">Feld bearbeiten{hasChanges ? "*" : null}</h1>
            <div className="mb-3" style={{ fontSize: 10 }}>
                Path: {props.active}
            </div>

            <form onSubmit={submit}>
                <Row>
                    {fieldType && fieldType.attributes && fieldType.attributes.includes("columns") ? (
                        <Col width={4}>
                            <FloatInput
                                type="number"
                                placeholder="Columns"
                                name="columns"
                                value={field.columns ?? ""}
                                onChange={onChange}
                                disabled={props.isPreset}
                                className="mb-2"
                            />
                        </Col>
                    ) : null}
                    {fieldType && fieldType.attributes && fieldType.attributes.includes("prepend") ? (
                        <Col width={4}>
                            <FloatInput
                                type="text"
                                placeholder="Prepend"
                                name="prepend"
                                value={field.prepend ?? ""}
                                onChange={onChange}
                                disabled={props.isPreset}
                                className="mb-2"
                            />
                        </Col>
                    ) : null}
                    {fieldType && fieldType.attributes && fieldType.attributes.includes("append") ? (
                        <Col width={4}>
                            <FloatInput
                                type="text"
                                placeholder="Append"
                                name="append"
                                value={field.append ?? ""}
                                onChange={onChange}
                                disabled={props.isPreset}
                                className="mb-2"
                            />
                        </Col>
                    ) : null}
                    {fieldType && fieldType.attributes && fieldType.attributes.includes("style") ? (
                        <Col width={12}>
                            <EditSidebarStyleAttribute
                                field={field}
                                active={props.active}
                                setField={setField}
                                fieldType={fieldType}
                                stylingDefinitions={props.stylingDefinitions}
                            />
                        </Col>
                    ) : null}

                    {fieldType && fieldType.attributes && fieldType.attributes.length > 0
                        ? fieldType.attributes.map((el, i) => {
                              if (typeof el !== "object") {
                                  return null;
                              }

                              if (el.type === "select") {
                                  return (
                                      <Col key={i} width={el.columns ? el.columns : 12}>
                                          <FloatSelect
                                              placeholder={el.label ? el.label : el.key}
                                              name={el.key}
                                              value={field[el.key]}
                                              onChange={onChange}
                                              disabled={props.isPreset}
                                              className="mb-2"
                                          >
                                              {el.options && el.options.length > 0
                                                  ? el.options.map((opt, o) => (
                                                        <option key={o} value={opt.value}>
                                                            {opt.label}
                                                        </option>
                                                    ))
                                                  : null}
                                          </FloatSelect>
                                      </Col>
                                  );
                              }
                              return null;
                          })
                        : null}
                </Row>

                {fieldType && fieldType.childrenPath && fieldType.childrenPath.trim() !== "" ? (
                    <div className="mt-3">
                        <b>Kind-Elemente:</b>
                        <FieldSelector pathPrefix={props.active + "." + fieldType.childrenPath} />
                    </div>
                ) : null}

                {field.type === "tabs" ? <EditTabs field={field} setField={setField} active={props.active} /> : null}
                {fieldDefinition && fieldDefinition.type === "map" ? (
                    <EditMap
                        field={field}
                        setField={setField}
                        active={props.active}
                        fieldDefinition={fieldDefinition}
                        disabled={props.isPreset}
                    />
                ) : null}
                {fieldDefinition && fieldDefinition.type === "object" ? (
                    <EditObject
                        field={field}
                        setField={setField}
                        active={props.active}
                        fieldDefinition={fieldDefinition}
                        disabled={props.isPreset}
                    />
                ) : null}

                {fieldType && fieldType.attributes && fieldType.attributes.length > 0 ? (
                    <Row>
                        {fieldType.attributes.map((attr, i) => {
                            if (typeof attr !== "object" || !attr.attribute || attr.attribute.trim() === "") {
                                return null;
                            }

                            return (
                                <Col key={i} className="mt-2" width={attr.columns ? attr.columns : 12}>
                                    <FloatInput
                                        placeholder={
                                            attr.title && attr.title.trim() !== "" ? attr.title : attr.attribute
                                        }
                                        name={attr.attribute}
                                        value={field[attr.attribute] ?? ""}
                                        onChange={onChange}
                                        disabled={props.isPreset}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                ) : null}

                <div className="mt-3">
                    <Button success className="mr-1" disabled={!hasChanges || props.isPreset}>
                        Feldänderungen anwenden
                    </Button>
                    <Button type="button" light className="text-danger" onClick={remove} disabled={props.isPreset}>
                        Feld entfernen
                    </Button>
                </div>
            </form>
        </Content>
    );
};
