import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import lodash from "lodash";
import { useDispatch } from "react-redux";
import { reduxSetDynamicRecordsValue, store } from "../../../redux";
import {
    Button,
    Col,
    Content,
    Icon,
    LoadingSpinner,
    FloatInput,
    FloatSelect,
    Row,
    ModalTitle,
} from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";
import { MapFieldAccountingTaxes } from "./MapFieldAccountingTaxes";

const ErrorBackground = styled.div`
    background: ${(props) => props.theme.color.danger};
    color: ${(props) => props.theme.color.light};
    padding: 15px;
    user-select: none;
`;

export const MapFieldAccountingPositionsSettings = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [hasTaxes, setHasTaxes] = useState(false);

    useEffect(() => {
        if (props.formField && props.modalPosition) {
            const state = store.getState();
            const position = lodash.get(state.app.dynamicrecords, props.formField + "[" + props.modalPosition + "]");
            if (position) {
                setData({ ...position });
            } else {
                setData(null);
            }
        } else {
            setData(null);
        }

        if (props.definition && props.definition.taxes) {
            setHasTaxes(
                props.definition && props.definition.taxes && props.definition.taxes.type === "map" ? true : false
            );
        }

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modalPosition, props.formField]);

    const onChange = (e) => {
        const newData = {
            ...data,
            [e.target.name]: e.target.value,
        };

        setData(newData);
    };

    const onTaxChange = (newData) => {
        setData(newData);
    };

    const onSubFormChange = (path, value) => {
        const valuePath = path.replace(props.formField + "[" + props.modalPosition + "].", "");

        if (valuePath.indexOf(".") > -1 || valuePath.indexOf("[") > -1) {
            const newData = { ...data };
            lodash.set(data, valuePath, value);

            setData(newData);
        } else {
            const newData = {
                ...data,
                [valuePath]: value,
            };
            setData(newData);
        }
    };

    const remove = (e) => {
        if (!window.confirm("Soll die Position wirklich entfernt werden?")) {
            return;
        }

        const state = store.getState();
        const newPositions = [...lodash.get(state.app.dynamicrecords, props.formField)];
        newPositions.splice(parseInt(props.modalPosition), 1);

        dispatch(reduxSetDynamicRecordsValue(props.formField, newPositions, props.element.rerenderOnChange));
        dispatch(reduxSetDynamicRecordsValue("last_positions_change", Date.now(), props.element.rerenderOnChange));
        props.closeModal();
    };

    const apply = () => {
        if (!props.formField || !props.modalPosition) {
            return;
        }

        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField + "[" + props.modalPosition + "]",
                { ...data },
                props.element.rerenderOnChange
            )
        );
        dispatch(reduxSetDynamicRecordsValue("last_positions_change", Date.now(), props.element.rerenderOnChange));
        props.closeModal();
    };

    const onKeyDown = (e) => {
        if (e.keyCode !== 13) {
            return;
        }

        if (!e.target || e.target.type === "select" || e.target.type === "select-one" || e.target.type === "textarea") {
            return;
        }

        apply();
    };

    const onBlur = (e) => {
        const newVal = { ...data };

        setData(props.calcPos(newVal));
    };

    if (!isLoading && !data) {
        return (
            <ErrorBackground>
                <h2 className="mt-0 mb-2">Position wurde nicht gefunden</h2>
                Die ausgewählte Position konnte nicht geladen werden. Am besten die Seite aktualisieren ...
                <br />
                <Button className="mt-2" type="button" onClick={props.closeModal} light outline>
                    Schließen
                </Button>
            </ErrorBackground>
        );
    }

    return (
        <>
            <ModalTitle>
                <Button type="button" onClick={props.closeModal} light className="float-right">
                    <Icon icon={faTimes} />
                </Button>

                <h1>Position bearbeiten</h1>
            </ModalTitle>

            <Content>
                {isLoading ? (
                    <LoadingSpinner>Position wird geladen ...</LoadingSpinner>
                ) : (
                    <div onKeyDown={onKeyDown}>
                        <Row>
                            <Col width={12}>
                                <FloatInput
                                    type="text"
                                    placeholder="Position"
                                    value={data.title}
                                    name="title"
                                    className="mb-3"
                                    onChange={onChange}
                                />
                            </Col>
                            <Col width={2}>
                                <FloatInput
                                    type="text"
                                    placeholder="Menge"
                                    value={data.quantity}
                                    name="quantity"
                                    className="mb-3"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </Col>
                            <Col width={2}>
                                <FloatSelect
                                    placeholder="Maßeinheit"
                                    value={data.unit}
                                    name="unit"
                                    className="mb-3"
                                    onChange={onChange}
                                >
                                    {props.definition &&
                                    props.definition.unit &&
                                    props.definition.unit.options &&
                                    props.definition.unit.options.length > 0
                                        ? props.definition.unit.options.map((opt, i) => (
                                              <option key={i} value={opt.value}>
                                                  {opt.label}
                                              </option>
                                          ))
                                        : null}
                                </FloatSelect>
                            </Col>
                            <Col width={4}>
                                <FloatInput
                                    type="text"
                                    placeholder="Einzelpreis"
                                    value={data.price_single}
                                    name="price_single"
                                    className="mb-3"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            </Col>
                            <Col width={4}>
                                <FloatInput
                                    type="text"
                                    placeholder="Gesamtpreis"
                                    value={data.price_full}
                                    name="price_full"
                                    className="mb-3"
                                    onChange={onChange}
                                    disabled={true}
                                />
                            </Col>

                            {hasTaxes ? (
                                <Col width={12} className="mb-3">
                                    <MapFieldAccountingTaxes
                                        data={data}
                                        setData={onTaxChange}
                                        calcPos={props.calcPos}
                                        errorPrefix={
                                            (props.errorPrefix ? props.errorPrefix : "") +
                                            props.formField +
                                            "[" +
                                            props.modalPosition +
                                            "]."
                                        }
                                    />
                                </Col>
                            ) : null}

                            <DynamicRecordFieldsRenderer
                                data={{ fields: props.field.definition }}
                                fields={props.element.form}
                                values={props.values}
                                // prefix={props.formField + "[" + props.modalPosition + "]"}
                                errorPrefix={
                                    (props.errorPrefix ? props.errorPrefix : "") +
                                    props.formField +
                                    "[" +
                                    props.modalPosition +
                                    "]."
                                }
                                onChange={onSubFormChange}
                                customValueStore={data}
                            />

                            <Col width={12} className="text-right">
                                <Button type="button" light className="text-danger mr-1" onClick={remove}>
                                    Position entfernen
                                </Button>
                                <Button type="button" success onClick={apply}>
                                    Änderungen anwenden
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </Content>
        </>
    );
};
