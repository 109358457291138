import React from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { lighten } from "polished";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import lodash from "lodash";
import { Alert, Row, Col, Button, Icon } from "@webtypen/react-components";
import { Field } from "./Fields/Field";
import { Tabs } from "./Fields/Tabs";
import { Card } from "./Fields/Card";
import { Container } from "./Fields/Container";
import { reduxSetFormBuilder, reduxSetFormBuilderForm, store } from "../../redux";

export const FieldWrapper = styled.div`
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 10px;
`;

export const FieldTitle = styled.div`
    display: block;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
    background: #fff;
    width: 100%;
    background: #fff;
    border: none;
    outline: none:
    box-shadow: none;
    text-align: left;

    .path {
        font-size: 10px;
        color: #555;
    }

    .info {
        font-size: 10px;
        color: #555;
    }

    &:hover, &:active, &:focus {
        outline: none;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    ${(props) =>
        props.isActive
            ? `
        background: ${props.theme.color.primary};
        color: #fff;

        .info, .path {
            color: #fff;
        }
        
        &:hover {
            background: ${props.theme.color.primary};
            color: #fff;
        }

        ${
            props.disabled
                ? `background: ${lighten(0.3, props.theme.color.primary)} !important; &:hover { background: ${lighten(
                      0.3,
                      props.theme.color.primary
                  )} !important; }`
                : ``
        }
    `
            : ``}

    ${(props) =>
        props.disabled
            ? `
        background: #fff;
        cursor: default;
        
        &:hover {
            background: #fff;
        }
        `
            : ``}
`;

export const FieldBody = styled.div`
    padding: 15px;
    border-top: 1px solid #eee;
`;

export const FormFields = (props) => {
    const { fields } = props;
    const dispatch = useDispatch();
    const active = useSelector((state) => state.app.formBuilderForm.active);
    const hasChanges = useSelector((state) => state.app.formBuilderForm.hasChanges);
    const pathPrefix = props.pathPrefix && props.pathPrefix.trim() !== "" ? props.pathPrefix.trim() : "";

    const fieldUp = (index) => {
        index = parseInt(index);

        const newFields = [...fields];
        if (newFields[index - 1]) {
            const help = { ...newFields[index - 1] };
            newFields[index - 1] = { ...newFields[index] };
            newFields[index] = help;
        }

        if (!pathPrefix || pathPrefix.trim() === "") {
            dispatch(reduxSetFormBuilderForm("fields", [...newFields]));
        } else {
            const state = store.getState();
            const newFormFields = [...state.app.formBuilderForm.form.fields];
            lodash.set(newFormFields, pathPrefix, [...newFields]);
            dispatch(reduxSetFormBuilderForm("fields", newFormFields));
        }
        dispatch(reduxSetFormBuilder("active", null));
    };

    const fieldDown = (index) => {
        index = parseInt(index);

        const newFields = [...fields];
        if (newFields[index + 1]) {
            const help = { ...newFields[index + 1] };
            newFields[index + 1] = { ...newFields[index] };
            newFields[index] = help;
        }

        if (!pathPrefix || pathPrefix.trim() === "") {
            dispatch(reduxSetFormBuilderForm("fields", [...newFields]));
        } else {
            const state = store.getState();
            const newFormFields = [...state.app.formBuilderForm.form.fields];
            lodash.set(newFormFields, pathPrefix, [...newFields]);
            dispatch(reduxSetFormBuilderForm("fields", newFormFields));
        }
        dispatch(reduxSetFormBuilder("active", null));
    };

    if (!fields || fields.length < 1) {
        return <Alert light>Es wurden noch keine Felder zum Formular hinzugefügt</Alert>;
    }

    return (
        <Row>
            {fields.map((field, i) => {
                const path = pathPrefix + "[" + i + "]";
                const isActive = path === active ? true : false;

                const MoveControls = (
                    <>
                        <Button
                            type="button"
                            light
                            xs
                            disabled={i === 0}
                            onClick={(e) => {
                                e.stopPropagation();
                                fieldUp(i);
                            }}
                        >
                            <Icon icon={faCaretUp} />
                        </Button>
                        <Button
                            type="button"
                            light
                            xs
                            disabled={i + 1 === fields.length}
                            onClick={(e) => {
                                e.stopPropagation();
                                fieldDown(i);
                            }}
                        >
                            <Icon icon={faCaretDown} />
                        </Button>
                    </>
                );

                return (
                    <Col key={i} width={field.columns && parseInt(field.columns) > 0 ? parseInt(field.columns) : 12}>
                        {!field.type || field.type === "field" ? (
                            <Field
                                field={field}
                                path={path}
                                setActive={props.setActive}
                                isActive={isActive}
                                disabled={hasChanges || props.disabled}
                                moveControls={MoveControls}
                            />
                        ) : field.type === "tabs" ? (
                            <Tabs
                                field={field}
                                path={path}
                                setActive={props.setActive}
                                isActive={isActive}
                                disabled={hasChanges || props.disabled}
                                moveControls={MoveControls}
                            />
                        ) : field.type === "card" ? (
                            <Card
                                field={field}
                                path={path}
                                setActive={props.setActive}
                                isActive={isActive}
                                disabled={hasChanges || props.disabled}
                                moveControls={MoveControls}
                            />
                        ) : field.type === "container" ? (
                            <Container
                                field={field}
                                path={path}
                                setActive={props.setActive}
                                isActive={isActive}
                                disabled={hasChanges || props.disabled}
                                moveControls={MoveControls}
                            />
                        ) : (
                            <div style={{ border: "2px dashed #eee", borderRadius: 3, marginBottom: 10, padding: 5 }}>
                                Unknown-Field `{field.type}`
                            </div>
                        )}
                    </Col>
                );
            })}
        </Row>
    );
};
