import React, { useEffect, useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Page, Content, Alert, Icon, ButtonLink } from "@webtypen/react-components";
import { PageTitle } from "../../../components";
import { DynamicRecordForm } from "../../../components";

export const DynamicRecordsEdit = (props) => {
    const { recordType } = props;
    const [isRemoved, setIsRemoved] = useState(false);
    const [stages, setStages] = useState(null);
    const [formIsLoaded, setFormIsLoaded] = useState(false);

    const loadStages = async () => {
        const stages = [];
        if (recordType && recordType.stages && Object.keys(recordType).length > 0) {
            for (let i in recordType.stages) {
                if (recordType.stages[i].required) {
                    // @ToDo: Hier prüfen ob benötigter Zustand in required erfüllt ist
                    continue;
                }

                stages.push({ ...recordType.stages[i] });
            }
        }

        setStages(stages && stages.length > 0 ? stages : null);
    };

    useEffect(() => {
        loadStages();
        // eslint-disable-next-line
    }, []);

    const actions = [];
    if (!isRemoved && recordType && recordType.defaultExport && formIsLoaded) {
        actions.push({
            type: "button-download",
            label: "Vorschau",
            attributes: {
                src: process.env.REACT_APP_API_URL + "api/dynamic-records/exports/preview",
                filename: "preview.pdf",
                preview: true,
                data: {
                    id: props.match.params.id,
                    type: props.match.params._recordType,
                    export: "main",
                },
                method: "POST",
            },
        });
    }

    if (!isRemoved && formIsLoaded && stages && stages.length > 0 && props.match.params.id !== "new") {
        for (let i in stages) {
            actions.push({
                type: "button-link",
                label: recordType.singular + " " + stages[i].actionName,
                attributes: {
                    to:
                        "/env/" +
                        props.match.params._env +
                        "/dyn/" +
                        props.match.params._recordType +
                        "/" +
                        props.match.params.id +
                        "/" +
                        stages[i].key +
                        "/change-stage",
                },
            });
        }
    }

    return (
        <Page>
            <PageTitle
                title={recordType.singular}
                subtitle={props.match.params.id === "new" ? "anlegen" : "bearbeiten"}
                backLink={"/env/" + props.match.params._env + "/dyn/" + props.match.params._recordType}
                backLinkLabel={"Zur " + recordType.plural + "-Übersicht"}
                actions={actions}
            />

            <Content>
                {isRemoved ? (
                    <>
                        <Alert success>
                            <Icon icon={faCheck} className="mr-1" />
                            Der Datensatz wurde erfolgreich entfernt
                        </Alert>
                        <div>
                            <ButtonLink
                                to={"/env/" + props.match.params._env + "/dyn/" + props.match.params._recordType}
                            >
                                Zur {recordType.plural} Übersicht
                            </ButtonLink>
                        </div>
                    </>
                ) : (
                    <DynamicRecordForm
                        type={props.match.params._recordType}
                        recordId={props.match.params.id}
                        match={props.match}
                        form={recordType.mainForm}
                        onRemove={() => setIsRemoved(true)}
                        onLoad={(data) => setFormIsLoaded(true)}
                    />
                )}
            </Content>
        </Page>
    );
};
