import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { faPlusSquare, faUser } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardBody,
    Content,
    Icon,
    ButtonLink,
    LoadingSpinner,
    Alert,
    Row,
    Col,
    Button,
} from "@webtypen/react-components";
import { ContainerUser } from "../../components";
import { ApiHelper, AuthHelper, ConfirmHelper } from "../../helpers";

const CreationCard = styled.div`
    height: 170px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 18;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.5);
    transition: all 200ms;
    cursor: pointer;

    &:hover {
        background: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.light};
    }
`;

const EnvInfo = styled.span`
    font-size: 18px;
    border: 1px solid #555;
    border-radius: 3px;
    display: inline-block;
    height: 30px;
    user-select: none;

    .plan,
    .users {
        padding: 3px 8px;
        display: inline-block;
        height: 30px;

        svg {
            font-size: 14px;
            margin-right: 4px;
        }
    }

    .plan {
        border-right: 1px solid #555;
    }
`;

const InvitationElement = styled.div`
    display: flex;
    background: #fff;
    border-radius: 3px;

    .accept-inv-btn {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .cancel-btn {
        color: ${(props) => props.theme.color.danger};
        outline: none;
        background: #fff;
        border: none;
        box-shadow: none;
        cursor: pointer;
        padding: 0px 15px;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }

        &:disabled {
            cursor: default;
            opacity: 0.7;

            &:hover {
                background: #fff;
            }
        }
    }

    .inv-content {
        font-size: 16px;
        flex: 1;
        padding: 10px 15px;
        user-select: none;

        .inv-date {
            font-size: 10px;
        }
    }

    .inv-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 25px;
    }
`;

export const Start = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [environments, setEnvironments] = useState(null);
    const [invitations, setInvitations] = useState(null);
    const [invitationLoading, setInvitationLoading] = useState(null);

    const acceptInvitation = async (e) => {
        const id = e.currentTarget.getAttribute("data-id");

        setInvitationLoading(id);
        ApiHelper.request("/api/environments/user-invitations/accept", {
            id: id,
        })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.url_slug) {
                    props.history.push("/env/" + response.data.url_slug);
                } else {
                    loadInvitations();
                    setInvitationLoading(null);
                    ConfirmHelper.alert(
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Annehmen der Einladung aufgetreten."
                    );
                }
            })
            .catch((e) => {
                loadInvitations();
                setInvitationLoading(null);
                ConfirmHelper.alert(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Annehmen der Einladung aufgetreten."
                );
            });
    };

    const cancelInvitation = async (e) => {
        const id = e.currentTarget.getAttribute("data-id");
        const status = await ConfirmHelper.confirm("Soll die Einladung wirklich abgelehnt werden?", {
            confirmAttributes: { color: "danger" },
        });
        if (!status) {
            return;
        }

        setInvitationLoading(id);
        ApiHelper.request("/api/environments/user-invitations/decline", {
            id: id,
        })
            .then((response) => {
                loadInvitations();
                setInvitationLoading(null);

                if (response.status === "error") {
                    ConfirmHelper.alert(
                        response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Ablehnen der Einladung aufgetreten."
                    );
                }
            })
            .catch((e) => {
                loadInvitations();
                setInvitationLoading(null);
                ConfirmHelper.alert(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Ablehnen der Einladung aufgetreten."
                );
            });
    };

    const loadInvitations = () => {
        ApiHelper.request("/api/environments/user-invitations")
            .then((response) => {
                setInvitations(
                    response.status === "success" && response.data && response.data.length > 0 ? response.data : null
                );
            })
            .catch((e) => {
                setInvitations(null);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/environments/list")
            .then((response) => {
                setEnvironments(response.data && response.data.length > 0 ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                AuthHelper.logout();
                props.history.push("/");

                setTimeout(() => {
                    window.location.reload();
                }, 150);
            });

        loadInvitations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContainerUser className="mt-3">
            <Content>
                <h1>Firmenumgebung auswählen</h1>

                {invitations && invitations.length > 0 ? (
                    <div className="mb-4">
                        <b>Einladungen:</b>
                        <br />
                        {invitations.map((invitation, i) => (
                            <InvitationElement key={i} className="mb-2">
                                <div className="inv-content">
                                    {invitation.environment.name}
                                    <div className="inv-date">
                                        {moment(invitation.created_at).fromNow()} von {invitation.created_by.firstname}{" "}
                                        {invitation.created_by.name}
                                    </div>
                                </div>

                                {invitationLoading === invitation._id ? (
                                    <div className="inv-loading">
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    <>
                                        <button
                                            className="cancel-btn"
                                            onClick={cancelInvitation}
                                            data-id={invitation._id}
                                            disabled={invitationLoading}
                                        >
                                            Ablehnen
                                        </button>
                                        <Button
                                            type="button"
                                            primary
                                            className="accept-inv-btn"
                                            onClick={acceptInvitation}
                                            data-id={invitation._id}
                                            disabled={invitationLoading}
                                        >
                                            Annehmen
                                        </Button>
                                    </>
                                )}
                            </InvitationElement>
                        ))}
                    </div>
                ) : null}

                {isLoading ? (
                    <LoadingSpinner>Ihre Firmenumgebungen werden geladen ...</LoadingSpinner>
                ) : !environments || environments.length < 1 ? (
                    <Alert light>
                        Sie haben noch keine Firmenumgebung erstellt und/oder wurden noch keiner Firmenumgebung
                        hinzugefügt ...
                        <div className="mt-1">
                            <ButtonLink to="/account/create-environment" success outline>
                                <Icon icon={faPlus} className="mr-1" />
                                Firmenumgebung erstellen
                            </ButtonLink>
                        </div>
                    </Alert>
                ) : (
                    <Row>
                        {environments.map((env, i) => (
                            <Col className="mb-3" key={i} width={4}>
                                <Card>
                                    {env.logo_url ? (
                                        <div
                                            style={{
                                                height: 120,
                                                borderBottom: "1px solid rgba(0,0,0,0.03)",
                                                background: "#fff",
                                                backgroundImage: "url(" + env.logo_url + ")",
                                                backgroundSize: "60%",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center center",
                                            }}
                                        ></div>
                                    ) : (
                                        <div
                                            style={{
                                                height: 120,
                                                borderBottom: "1px solid rgba(0,0,0,0.03)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                userSelect: "none",
                                                fontSize: 18,
                                                fontWeight: 800,
                                                opacity: 0.6,
                                            }}
                                        >
                                            <div>{env.name}</div>
                                        </div>
                                    )}
                                    <CardBody>
                                        <ButtonLink
                                            to={"/env/" + env.url_slug}
                                            sm
                                            style={{ marginTop: -1 }}
                                            className="float-right"
                                        >
                                            Starten
                                        </ButtonLink>

                                        <EnvInfo style={{ fontSize: 16, opacity: 0.5, userSelect: "none" }}>
                                            <span className="plan">Free</span>
                                            <span className="users">
                                                <Icon icon={faUser} />
                                                {env.users_count}
                                            </span>
                                        </EnvInfo>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}

                        <Col className="mb-3" width={4}>
                            <Card>
                                <CreationCard onClick={() => props.history.push("/account/create-environment")}>
                                    <div className="text-center pl-3 pr-3">
                                        <Icon icon={faPlusSquare} />
                                        <br />
                                        Neue
                                        <br />
                                        Firmenumgebung erstellen
                                    </div>
                                </CreationCard>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Content>
        </ContainerUser>
    );
};
