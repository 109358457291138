import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Content,
    FloatInput,
    FloatSelect,
    Icon,
    LoadingSpinner,
    Modal,
    ModalTitle,
    Row,
    FloatTextareaAutogrow,
} from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { FloatFileSelector } from "../../../components";
import { WebsiteManagerDesignSelector } from "./WebsiteManagerDesignSelector";

const DesignSelectorBox = styled.div`
    border: 1px solid #ddd;
    height: 300px;
    background: #eee;
    position: relative;
    display: flex;

    .btn-design-selector {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
`;

export const WebsiteManagerDesign = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [designIsLoading, setDesignIsLoading] = useState(false); // eslint-disable-line
    const [design, setDesign] = useState(null);
    const [data, setData] = useState({});
    const [selectDesign, setSelectDesign] = useState(false);

    const toggleDesignSelector = () => {
        setSelectDesign(!selectDesign);
    };

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onVariableChange = (e) => {
        setData({
            ...data,
            design_config: {
                ...data.design_config,
                [data.design]: {
                    ...data.design_config[data.design],
                    variables: {
                        ...data.design_config[data.design].variables,
                        [e.target.name]: e.target.value,
                    },
                },
            },
        });
    };

    const onVariableChangeBoolean = (e) => {
        setData({
            ...data,
            design_config: {
                ...data.design_config,
                [data.design]: {
                    ...data.design_config[data.design],
                    variables: {
                        ...data.design_config[data.design].variables,
                        [e.target.name]: e.target.value && e.target.value.toString() !== "false" ? true : false,
                    },
                },
            },
        });
    };

    const onFileVariableChange = (files, selector) => {
        setData({
            ...data,
            design_config: {
                ...data.design_config,
                [data.design]: {
                    ...data.design_config[data.design],
                    variables: {
                        ...data.design_config[data.design].variables,
                        [selector.key]: files && files[0] ? files[0]._id : null,
                    },
                },
            },
        });
    };

    const onDesignChange = () => {
        load();
        setSelectDesign(false);
    };

    const load = () => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setData(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                setData({});
                setIsLoading(false);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        setIsSaving(true);
        ApiHelper.request("/api/website-manager/save-design", {
            website: data,
        })
            .then((response) => {
                setIsSaving(false);
            })
            .catch((e) => {
                setIsSaving(false);
            });
    };

    useEffect(() => {
        if (data.design) {
            setDesignIsLoading(true);
            ApiHelper.request("/api/website-manager/design", {
                design: data.design,
            })
                .then((response) => {
                    setDesign(
                        response.status === "success" && response.data && response.data.name ? response.data : null
                    );
                    setDesignIsLoading(false);
                })
                .catch((e) => {
                    setDesign(null);
                    setDesignIsLoading(false);
                });
        } else {
            setDesign(null);
            setDesignIsLoading(false);
        }
    }, [data.design]);

    useEffect(() => {
        load();
    }, []);

    if (isLoading || !data || !data._id) {
        return (
            <Content>
                {isLoading ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Informationen aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    const variableKeys = data.design && design && design.variables ? Object.keys(design.variables) : null;
    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>Website-Design anpassen</h1>

            {selectDesign ? (
                <Modal open={true} onCloseRequest={toggleDesignSelector}>
                    <ModalTitle>
                        <h1>
                            <Button type="button" onClick={toggleDesignSelector} sm light className="float-right">
                                <Icon icon={faTimes} className="mr-1" />
                                Schließen
                            </Button>
                            Website-Design auswählen
                        </h1>
                    </ModalTitle>

                    <WebsiteManagerDesignSelector current={data.design} onChange={onDesignChange} />
                </Modal>
            ) : null}

            <form onSubmit={submit}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col width={4}>
                                <DesignSelectorBox>
                                    {!data.design ? (
                                        <div
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                userSelect: "none",
                                            }}
                                        >
                                            Kein Design ausgewählt
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                userSelect: "none",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    background: "#fff",
                                                    padding: "3px 5px",
                                                    borderRadius: 3,
                                                    userSelect: "none",
                                                }}
                                            >
                                                {design ? design.name : "-"}
                                            </span>
                                        </div>
                                    )}
                                    <Button
                                        type="button"
                                        secondary
                                        sm
                                        className="btn-design-selector"
                                        onClick={toggleDesignSelector}
                                        disabled={isSaving}
                                    >
                                        Design auswählen
                                    </Button>
                                </DesignSelectorBox>
                            </Col>
                            {!data.design ? (
                                <Col
                                    width={8}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    Es muss ein Design ausgewählt werden
                                </Col>
                            ) : (
                                <Col width={8}>
                                    <FloatInput
                                        value={data.title}
                                        placeholder="Titel der Webseite"
                                        name="title"
                                        onChange={onChange}
                                        disabled={isSaving}
                                        className="mb-2"
                                    />
                                    <FloatInput
                                        value={data.meta_description}
                                        name="meta_description"
                                        placeholder="Beschreibung (Meta-Description / SEO)"
                                        onChange={onChange}
                                        disabled={isSaving}
                                        className="mb-2"
                                    />
                                    <FloatInput
                                        value={data.meta_keywords ? data.meta_keywords : ""}
                                        placeholder="Beschreibung (Meta-Keywords / SEO)"
                                        name="meta_keywords"
                                        onChange={onChange}
                                        disabled={isSaving}
                                        className="mb-2"
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>

                {data.design && variableKeys && variableKeys.length > 0 ? (
                    <Card className="mt-3">
                        <CardHeader>Design-Variablen</CardHeader>
                        <CardBody>
                            <Row>
                                {variableKeys.map((key, i) => {
                                    let input;
                                    const label = design.variables[key].name ? design.variables[key].name : key;
                                    if (design.variables[key].type === "image") {
                                        input = (
                                            <FloatFileSelector
                                                placeholder="Firmenlogo"
                                                value={
                                                    data.design_config[data.design].variables[key]
                                                        ? data.design_config[data.design].variables[key]
                                                        : null
                                                }
                                                name={key}
                                                data={{ key: key }}
                                                onChange={onFileVariableChange}
                                                disabled={isSaving}
                                            />
                                        );
                                    } else if (design.variables[key].type === "boolean") {
                                        input = (
                                            <FloatSelect
                                                placeholder={label}
                                                name={key}
                                                value={data.design_config[data.design].variables[key] ? true : false}
                                                onChange={onVariableChangeBoolean}
                                                disabled={isSaving}
                                            >
                                                <option value={true}>Ja</option>
                                                <option value={false}>Nein</option>
                                            </FloatSelect>
                                        );
                                    } else if (design.variables[key].type === "html") {
                                        input = (
                                            <FloatTextareaAutogrow
                                                placeholder={label}
                                                name={key}
                                                value={
                                                    data.design_config[data.design].variables[key]
                                                        ? data.design_config[data.design].variables[key]
                                                        : ""
                                                }
                                                onChange={onVariableChange}
                                                disabled={isSaving}
                                            />
                                        );
                                    } else {
                                        input = (
                                            <FloatInput
                                                placeholder={label}
                                                name={key}
                                                value={
                                                    data.design_config[data.design].variables[key]
                                                        ? data.design_config[data.design].variables[key]
                                                        : ""
                                                }
                                                onChange={onVariableChange}
                                                disabled={isSaving}
                                            />
                                        );
                                    }

                                    return (
                                        <Col
                                            width={design.variables[key].columns ? design.variables[key].columns : 12}
                                            className="mb-2"
                                            key={i}
                                        >
                                            {input}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </CardBody>
                    </Card>
                ) : null}

                <div className="mt-2 text-right">
                    <Button success disabled={isSaving}>
                        {isSaving ? <LoadingSpinner /> : "Speichern"}
                    </Button>
                </div>
            </form>
        </Content>
    );
};
