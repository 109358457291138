import React from "react";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Badge, Button, Dropdown, DropdownMenu, Icon } from "@webtypen/react-components";

export const UploadDropdownBox = (props) => {
    const uploads = useSelector((state) => state.app.uploads);
    const uploadKeys = uploads ? Object.keys(uploads) : null;
    if (!uploadKeys || uploadKeys.length < 1) {
        return null;
    }

    return (
        <Dropdown
            trigger={
                <Button type="button" light disabled={props.isLoading}>
                    <Icon icon={faFileUpload} />

                    <Badge warning style={{ position: "absolute", marginTop: 15 }}>
                        {uploadKeys.length}
                    </Badge>
                </Button>
            }
            right
        >
            <DropdownMenu style={{ width: 280 }}>
                {uploadKeys.map((key, i) => {
                    return (
                        <div
                            key={i}
                            style={{
                                fontSize: 14,
                                lineHeight: "20px",
                                padding: "15px",
                                borderBottom: "1px solid #eee",
                            }}
                        >
                            <div style={{ wordBreak: "break-word" }}>{uploads[key].filename}</div>
                            <div style={{ fontSize: 12 }}>Status: {uploads[key].status}</div>
                        </div>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};
