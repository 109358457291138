import { reduxSetValue, reduxLogout, store } from "../redux";

class AuthHelperWrapper {
    login(token, user) {
        store.dispatch(reduxSetValue("auth", user));

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
    }

    logout() {
        store.dispatch(reduxLogout());
        localStorage.clear();
    }

    check(returnToken) {
        const state = store.getState();

        if (
            state.app &&
            state.app.auth &&
            state.app.auth.email &&
            state.app.auth.email.trim() !== "" &&
            state.app.auth.is_active
        ) {
            const token = localStorage.getItem("token");
            if (token && token.trim() !== "") {
                return returnToken ? token.trim() : true;
            }
        }
        return returnToken ? null : false;
    }

    getToken() {
        return this.check(true);
    }
}

const AuthHelper = new AuthHelperWrapper();
export { AuthHelper };
