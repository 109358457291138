import React, { useEffect, useState } from "react";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Col, Content, FloatInput, FloatSelect, LoadingSpinner, Row } from "@webtypen/react-components";
import { FloatFileSelector } from "../../../components";
import { SettingTitle } from "./SettingTitle";
import { ApiHelper, NumericHelper } from "../../../helpers";

export const MainSettings = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [environment, setEnvironment] = useState(null);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setEnvironment({
            ...environment,
            [e.target.name]: e.target.value,
        });
    };

    const submit = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments/settings/save/main", {
            name: environment.name,
            default_address_invoice: environment.default_address_invoice ? environment.default_address_invoice : "",
            default_address_shipping: environment.default_address_shipping ? environment.default_address_shipping : "",
            default_tax: environment.default_tax,
            default_bank_details: environment.default_bank_details,
            logo: environment.logo,
            tax_number: environment.tax_number,
            tax_ustid: environment.tax_ustid,
            website: environment.website,
            email: environment.email,
            phone: environment.phone,
            fax: environment.fax,
        })
            .then((response) => {
                if (response.status === "success") {
                    setAlert(["success", "Die Firmenumgebung wurde erfolgreich bearbeitet."]);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        ApiHelper.request("/api/environments/details").then((response) => {
            if (response.status === "success" && response.data && response.data.environment) {
                setEnvironment(response.data.environment);
            } else {
                setEnvironment(null);
            }
            setIsLoading(false);
        });
    }, []);

    if (!environment || isLoading) {
        return (
            <Content>
                <SettingTitle icon={faCogs} title="Einstellungen" />

                {isLoading ? (
                    <LoadingSpinner>Einstellungen werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Einstellungen aufgetreten</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <SettingTitle icon={faCogs} title="Allgemeine Einstellungen" />

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

            <form onSubmit={submit}>
                <Row>
                    <Col width={8}>
                        <FloatInput
                            placeholder="Name der Firmenumgebung"
                            value={environment.name}
                            name="name"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={4}>
                        <FloatFileSelector
                            placeholder="Firmenlogo"
                            value={environment.logo}
                            name="logo"
                            onChange={(data) =>
                                setEnvironment({
                                    ...environment,
                                    logo: data && data[0] && data[0]._id ? data[0]._id : null,
                                })
                            }
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={12}>
                        <FloatInput
                            placeholder="Website"
                            type="url"
                            value={environment.website ? environment.website : ""}
                            name="website"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={12}>
                        <FloatInput
                            placeholder="E-Mail"
                            type="email"
                            value={environment.email ? environment.email : ""}
                            name="email"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={6}>
                        <FloatInput
                            placeholder="Telefon"
                            type="phone"
                            value={environment.phone ? environment.phone : ""}
                            name="phone"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={6}>
                        <FloatInput
                            placeholder="Fax"
                            type="fax"
                            value={environment.fax ? environment.fax : ""}
                            name="fax"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                </Row>

                <h3 className="mb-2">Rechnungsinformationen</h3>
                <Row>
                    <Col width={6}>
                        <FloatSelect
                            placeholder="Standard Rechnungsadresse"
                            value={environment.default_address_invoice ? environment.default_address_invoice : ""}
                            name="default_address_invoice"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        >
                            <option value="">-</option>
                            {environment.addresses && environment.addresses.length > 0
                                ? environment.addresses.map((addr, i) => (
                                      <option key={i} value={addr._id}>
                                          {addr.street} {addr.housenumber}, {addr.zip} {addr.city}
                                      </option>
                                  ))
                                : null}
                        </FloatSelect>
                    </Col>
                    <Col width={6}>
                        <FloatSelect
                            placeholder="Standard Lieferadresse"
                            value={environment.default_address_shipping ? environment.default_address_shipping : ""}
                            name="default_address_shipping"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        >
                            <option value="">-</option>
                            {environment.addresses && environment.addresses.length > 0
                                ? environment.addresses.map((addr, i) => (
                                      <option key={i} value={addr._id}>
                                          {addr.street} {addr.housenumber}, {addr.zip} {addr.city}
                                      </option>
                                  ))
                                : null}
                        </FloatSelect>
                    </Col>
                    <Col width={6}>
                        <FloatSelect
                            placeholder="Standard Bankverbindung"
                            value={environment.default_bank_details ? environment.default_bank_details : ""}
                            name="default_bank_details"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        >
                            <option value="">-</option>
                            {environment.bank_details && environment.bank_details.length > 0
                                ? environment.bank_details.map((details, i) => (
                                      <option key={i} value={details._id}>
                                          {details.name} - {details.iban} - {details.bic}
                                      </option>
                                  ))
                                : null}
                        </FloatSelect>
                    </Col>
                    <Col width={6}>
                        <FloatSelect
                            placeholder="Standard Steuersatz"
                            value={environment.default_tax ? environment.default_tax : ""}
                            name="default_tax"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        >
                            <option value="">-</option>
                            {environment.taxes && environment.taxes.length > 0
                                ? environment.taxes.map((tax, i) => (
                                      <option key={i} value={tax._id}>
                                          {NumericHelper.numberFormat(tax.percentage)}%{" - "}
                                          {tax.name}
                                      </option>
                                  ))
                                : null}
                        </FloatSelect>
                    </Col>
                    <Col width={6}>
                        <FloatInput
                            placeholder="Steuernummer"
                            value={environment.tax_number ? environment.tax_number : ""}
                            name="tax_number"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                    <Col width={6}>
                        <FloatInput
                            placeholder="USt - IdNr."
                            value={environment.tax_ustid ? environment.tax_ustid : ""}
                            name="tax_ustid"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-3"
                        />
                    </Col>
                </Row>

                <div className="text-right mt-2">
                    <Button success disabled={isSaving}>
                        {isSaving ? <LoadingSpinner /> : "Speichern"}
                    </Button>
                </div>
            </form>
        </Content>
    );
};
