import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { reduxSetFormBuilderForm, store } from "../../redux";
import { Alert, Badge, Button, Card, Icon, Table } from "@webtypen/react-components";

export const fieldTypes = {
    string: {
        name: "String",
        type: "string",
        attributes: ["columns", "prepend", "append", "style"],
    },
    integer: {
        name: "Integer",
        type: "integer",
        attributes: ["columns", "prepend", "append", "style"],
    },
    float: {
        name: "Float",
        type: "float",
        attributes: ["columns", "prepend", "append", "style"],
    },
    model: {
        name: "Model",
        type: "model",
        attributes: ["columns", "prepend", "append", "style"],
    },
    models: {
        name: "Models",
        type: "models",
        attributes: [
            "columns",
            "prepend",
            "append",
            "style",
            {
                type: "select",
                label: "Anzeigemodus",
                key: "mode",
                options: [
                    { value: "", label: "Standard" },
                    { value: "images-preview", label: "Bilder-Vorschau" },
                ],
            },
        ],
    },
    files: {
        name: "Dateien",
        type: "files",
        attributes: ["columns", "prepend", "append", "style"],
    },
    map: {
        name: "Map",
        type: "map",
        attributes: ["columns"],
        defaults: {
            form: [],
        },
    },
    tabs: {
        name: "Tabs",
        type: "tabs",
        isComponent: true,
        attributes: ["columns", "style"],
        defaults: {
            sections: [],
        },
    },
    container: {
        name: "Container",
        type: "container",
        childrenPath: "children",
        attributes: ["columns", "style"],
        isComponent: true,
        defaults: {
            children: [],
        },
    },
    card: {
        name: "Card",
        type: "card",
        childrenPath: "children",
        attributes: ["columns", "style", { type: "string", attribute: "headerTitle", title: "Kopfzeile" }],
        isComponent: true,
        defaults: {
            children: [],
        },
    },
};

export const getFieldsInForm = (fields) => {
    const keys = [];
    for (let i in fields) {
        if (fields[i] && fields[i].field && fields[i].field.trim() !== "") {
            keys.push(fields[i].field);
        }

        if (fields[i].type === "tabs") {
            if (fields[i].sections && fields[i].sections.length > 0) {
                for (let s in fields[i].sections) {
                    const sectionFields = getFieldsInForm(fields[i].sections[s].fields);
                    for (let f in sectionFields) {
                        keys.push(sectionFields[f]);
                    }
                }
            }
        } else if (fields[i].type === "card") {
            if (fields[i].children && fields[i].children.length > 0) {
                const sectionFields = getFieldsInForm(fields[i].children);
                for (let f in sectionFields) {
                    keys.push(sectionFields[f]);
                }
            }
        }
    }

    return keys;
};

export const FieldSelector = (props) => {
    const dispatch = useDispatch();
    const form = useSelector((state) => state.app.formBuilderForm);

    const add = (key, group) => {
        const state = store.getState();
        const newFields = [...state.app.formBuilderForm.form.fields];

        const fieldGroup = group === "prependFields" ? props.prependFields : form.fields[group].fields;
        const fieldDefinition = fieldGroup[key];

        let newField = null;
        if (fieldDefinition.isComponent) {
            newField = {
                type: fieldDefinition.type,
                columns: 12,
                ...fieldGroup[key].defaults,
            };
        } else {
            let fieldDef = null;
            if (group === "prependFields") {
                for (let i in props.prependFields) {
                    if (i === key) {
                        fieldDef = props.prependFields[i];
                    }
                }
            } else {
                for (let g in form.fields) {
                    for (let f in form.fields[g].fields) {
                        if (f === key) {
                            fieldDef = form.fields[g].fields[f];
                        }
                    }
                }
            }

            const fieldType = fieldDef && fieldDef.type ? fieldTypes[fieldDef.type] : null;
            const defaults = fieldType && fieldType.defaults ? fieldType.defaults : {};

            newField = {
                field: key,
                columns: 6,
                ...(props.parentDefinitionPath && props.parentDefinitionPath.trim() !== ""
                    ? { parentDefinitionPath: props.parentDefinitionPath }
                    : {}),
                ...defaults,
                ...fieldGroup[key].defaults,
            };
        }

        if (props.pathPrefix && props.pathPrefix.trim() !== "") {
            let array = lodash.get(newFields, props.pathPrefix);
            if (!array || array.length < 1) {
                array = [];
            }
            array.push(newField);

            lodash.set(newFields, props.pathPrefix, [...array]);
        } else {
            newFields.push(newField);
        }
        dispatch(reduxSetFormBuilderForm("fields", [...newFields]));
    };

    let hasFields = false;
    const fieldsInForm = getFieldsInForm(form.form.fields);
    return (
        <Card>
            <Table>
                <tbody>
                    {props.prependFields
                        ? Object.keys(props.prependFields).map((key, i) => {
                              const field = props.prependFields[key];

                              hasFields = true;
                              return (
                                  <tr key={i}>
                                      <td>
                                          <Badge light>{field.type}</Badge>
                                      </td>
                                      <td>{key}</td>
                                      <td className="text-right">
                                          <Button
                                              type="button"
                                              outline
                                              success
                                              xs
                                              onClick={() => add(key, "prependFields")}
                                              disabled={field.disabled || props.disabled}
                                          >
                                              <Icon icon={faPlus} className="mr-1" /> Hinzufügen
                                          </Button>
                                      </td>
                                  </tr>
                              );
                          })
                        : null}
                    {form.fields && form.fields.length > 0
                        ? form.fields.map((group, g) => {
                              const fieldsKeys = group.fields ? Object.keys(group.fields) : null;
                              if (!fieldsKeys || fieldsKeys.length < 1) {
                                  return null;
                              }

                              let hasContent = false;
                              const content = fieldsKeys.map((key, i) => {
                                  const isDisabled = !group.fields[key].isComponent && fieldsInForm.includes(key);

                                  if (props.justComponents && !group.fields[key].isComponent) {
                                      return null;
                                  }

                                  if (props.noComponents && group.fields[key].isComponent) {
                                      return null;
                                  }

                                  hasContent = true;
                                  hasFields = true;
                                  return (
                                      <tr key={i}>
                                          <td>
                                              <Badge light>{group.fields[key].type}</Badge>
                                          </td>
                                          <td>{key}</td>
                                          <td className="text-right">
                                              <Button
                                                  type="button"
                                                  outline
                                                  success
                                                  xs
                                                  onClick={() => add(key, g)}
                                                  disabled={isDisabled || props.disabled}
                                              >
                                                  <Icon icon={faPlus} className="mr-1" /> Hinzufügen
                                              </Button>
                                          </td>
                                      </tr>
                                  );
                              });

                              if (!hasContent) {
                                  return null;
                              }

                              return (
                                  <React.Fragment key={g}>
                                      <tr>
                                          <td colSpan={3}>{group.groupTitle}</td>
                                      </tr>
                                      {content}
                                  </React.Fragment>
                              );
                          })
                        : null}

                    {!hasFields ? (
                        <tr>
                            <td colSpan={3}>
                                <Alert warning className="mb-0">
                                    Es stehen keine Felder zur Auswahl.
                                </Alert>
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </Table>
        </Card>
    );
};
