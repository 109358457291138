import styled from "styled-components";

export const PageTitleSmall = styled.div`
    background: #fff;
    height: 52px;
    user-select: none;

    > h1 {
        margin: 0px;
        padding: 0px;
        font-size: 18px;
        height: 52px;
        line-height: 52px;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
    }

    > ul.manager-menu {
        height: 52px;
        display: inline-block;
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
            height: 52px;
            display: inline-block;

            a {
                display: block;
                height: 52px;
                line-height: 52px;
                padding: 0px 5px;
                font-size: 14px;

                &.active {
                    font-weight: bold;
                }
            }
        }
    }
`;

const PageTitleSmallActionsWrapper = styled.h1`
    font-size: 16px;

    .page-actions-wrapper {
        float: right;
    }
`;

export const PageTitleSmallActions = (props) => {
    const { children, actions, ...rest } = props;

    return (
        <PageTitleSmallActionsWrapper {...rest}>
            {actions && actions.length > 0 ? <div className="page-actions-wrapper">{actions}</div> : null}
            {children}
        </PageTitleSmallActionsWrapper>
    );
};
