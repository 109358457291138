import React from "react";
import { Page, Content } from "@webtypen/react-components";
import { PageTitle } from "../../components";

export const Error404 = (props) => {
    return (
        <Page>
            <PageTitle title="404 - Seite nicht gefunden" />
            <Content>Die aufgerufene Seite existiert nicht</Content>
        </Page>
    );
};
