import React from "react";
import { faFileSignature, faFolder, faGlobeEurope, faHome, faTools } from "@fortawesome/free-solid-svg-icons";
import { faBell, faCalendarAlt, faQuestionCircle, faUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Logo } from "./Logo";
import {
    Dropdown,
    DropdownMenu,
    DropdownMenuElement,
    Button,
    Icon,
    Page,
    Content,
    ContentLoader,
} from "@webtypen/react-components";
import { useDispatch, useSelector } from "react-redux";
import { reduxSetValue } from "../redux";
import { PageTitle } from "./PageTitle";
import { iconSelectorLib } from "./IconSelector";
import { GlobalAlerts } from "./GlobalAlerts";
import { UploadDropdownBox } from "./UploadDropdownBox";
import { ConfirmWrapper } from "./ConfirmWrapper";
import { AuthHelper, ConfirmHelper } from "../helpers";
import { HelpSection } from "./Help/HelpSection";

const mobileBreakpoint = 767;
const Wrapper = styled.div``;
const AppBar = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: rgb(255, 255, 255);
    user-select: none;
    box-shadow: rgba(4, 9, 20, 0.03) 0px 0.46875rem 2.1875rem, rgba(4, 9, 20, 0.03) 0px 0.9375rem 1.40625rem,
        rgba(4, 9, 20, 0.05) 0px 0.25rem 0.53125rem, rgba(4, 9, 20, 0.03) 0px 0.125rem 0.1875rem;
    border-bottom: none;
    z-index: 100010;

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        display: block;
        position: relative;
        height: auto;
        box-shadow: none;
    }
`;

const LogoContainer = styled.div`
    width: 250px;
    padding-top: 13px;
    position: absolute;
    left: 0px;
    top: 0px;

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        position: relative;
        width: 100%;
        text-align: center;
    }
`;

const AccountMenu = styled.ul`
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px;
    margin: 0px;
    list-style: none;
    padding-right: 10px;

    > li {
        float: left;
        padding: 0px;
        margin: 0px;
        height: 50px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        /* Dropdown */
        > div > div > div {
            font-size: 14px;
        }
    }

    &:after {
        content: "";
        clear: both;
        display: block;
    }

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        position: relative;
        display: block;
        width: 100%;
        padding-left: 10px;
    }
`;

const Sidebar = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 250px;
    padding-top: 50px;
    background: rgb(255, 255, 255);
    transition: left 200ms ease 0s;
    box-shadow: rgba(0, 0, 0, 0.1) 7px 0px 20px;
    z-index: 100004;

    ul {
        padding: 0px;
        margin: 0px;
        list-style: none;

        li {
            padding: 0px;
            margin: 0px;
            padding: 5px 10px;

            a {
                display: block;
                padding: 15px;
                color: rgb(58, 61, 64);
                border-radius: 5px;
                font-weight: 600;
                text-decoration: none;
                font-size: 16px;
                padding: 8px 15px;
                user-select: none;
                transition: all 150ms;

                svg {
                    position: absolute;
                    margin-top: 2px;
                }

                span {
                    display: inline-block;
                    padding-left: 25px;
                }

                &:hover {
                    background: #eee;
                }
            }

            &.active,
            &.active:hover {
                a {
                    background: rgb(224, 243, 255);
                }
            }

            &.menu-title {
                font-size: 14px;
                padding-bottom: 0px;
                padding-left: 25px;
                padding-top: 20px;
                user-select: none;
            }
        }
    }

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        display: block;
        position: relative;
        padding: 0px;
        margin: 0px;
        height: auto;
        width: auto;
        box-shadow: none;
        padding-top: 5px;
    }
`;

const GlobalNavigationWrapper = styled.div`
    transition: all 200ms;

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        display: block;
        background: #fff;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 250px;
        height: 100vh;
        z-index: 100002;
        left: -250px;
        // visibility: hidden;

        ${(props) =>
            props.mobileActive
                ? `
                left: 0px;
                visibility: visible;
                box-shadow: rgba(0, 0, 0, 0.1) 7px 0px 20px;
                `
                : ``}
    }
`;

const GlobalNavigationContent = styled.div`
    position: relative;
    max-width: 250px;
`;
const GlobalNavigationBG = styled.div`
    transition: all 200ms;

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100001;
        opacity: 0;
        visibility: hidden;

        ${(props) => (props.mobileActive ? `opacity: 1;visibility: visible;` : ``)}
    }
`;

const ContentWrapper = styled.div`
    padding-left: 250px;
    padding-top: 50px;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (max-width: ${(props) => mobileBreakpoint}px) {
        padding: 0px;
        padding-top: 50px;
    }
`;

const Container = styled.div`
    flex: 1;
    overflow: auto;
`;

export const Layout = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const mobileActive = useSelector((state) => state.app.mobileNavigation);
    const environment = useSelector((state) => state.app.environment);
    const helpOpened = useSelector((state) => state.app.helpOpened);

    const hideMobileNavigation = () => {
        dispatch(reduxSetValue("mobileNavigation", false));
    };

    const isPathActive = (path, exact) => {
        if (exact) {
            if (props.currentPath === path) {
                return true;
            }
        } else {
            if (props.currentPath.indexOf(path) === 0) {
                return true;
            }
        }
        return false;
    };

    const logout = async (e) => {
        const status = await ConfirmHelper.confirm("Wirklich ausloggen?", { confirmAttributes: { color: "danger" } });
        if (!status) {
            return;
        }

        AuthHelper.logout();
        setTimeout(() => {
            history.push("/");
        }, 1500);
    };

    const toggleHelp = (e) => {
        dispatch(reduxSetValue("helpOpened", !helpOpened));
    };

    return (
        <Wrapper>
            <GlobalNavigationBG mobileActive={mobileActive} onClick={hideMobileNavigation} />
            <GlobalNavigationWrapper mobileActive={mobileActive}>
                <GlobalNavigationContent>
                    <AppBar>
                        <LogoContainer>
                            <Link to={"/env/" + props._env} className="no-effect" onClick={hideMobileNavigation}>
                                <Logo style={{ fontSize: 22, paddingLeft: 15, marginTop: -3 }} hover />
                            </Link>
                        </LogoContainer>
                        <AccountMenu>
                            <li onClick={toggleHelp} className="cursor-pointer">
                                <Icon icon={faQuestionCircle} />
                            </li>
                            <li>
                                <UploadDropdownBox />
                            </li>
                            <li>
                                <Dropdown
                                    trigger={
                                        <Button type="button" light disabled={props.isLoading}>
                                            <Icon icon={faBell} />
                                        </Button>
                                    }
                                    right
                                >
                                    <DropdownMenu style={{ width: 280 }}>
                                        <div style={{ padding: 30, lineHeight: "18px" }}>
                                            Sie haben aktuell keine neuen Benachrichtigungen
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown
                                    trigger={
                                        <Button type="button" light>
                                            <Icon icon={faUser} />
                                        </Button>
                                    }
                                    right
                                >
                                    <DropdownMenu style={{ width: 150 }}>
                                        <DropdownMenuElement>
                                            <Link to="/account">Firmenumgebung wechseln</Link>
                                        </DropdownMenuElement>
                                        <DropdownMenuElement>
                                            <button type="button" onClick={logout}>
                                                Ausloggen
                                            </button>
                                        </DropdownMenuElement>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                        </AccountMenu>
                    </AppBar>
                    <Sidebar>
                        {props.isLoading ? (
                            <div style={{ padding: 10 }}>
                                <ContentLoader bars={5} barStyle={{ marginBottom: 10, height: 20 }} />
                            </div>
                        ) : (
                            <ul>
                                <li className={isPathActive("/env/" + props._env, true) ? "active" : null}>
                                    <Link to={"/env/" + props._env} onClick={hideMobileNavigation}>
                                        <Icon icon={faHome} />
                                        <span>Dashboard</span>
                                    </Link>
                                </li>

                                {environment && environment.menu && environment.menu.length > 0
                                    ? environment.menu.map((element, i) => {
                                          if (element.type === "title") {
                                              return (
                                                  <li key={i} className="menu-title">
                                                      {element.label}
                                                  </li>
                                              );
                                          }

                                          return (
                                              <li
                                                  key={i}
                                                  className={
                                                      isPathActive("/env/" + props._env + element.path)
                                                          ? "active"
                                                          : null
                                                  }
                                              >
                                                  <Link
                                                      to={"/env/" + props._env + element.path}
                                                      onClick={hideMobileNavigation}
                                                  >
                                                      {element.icon && iconSelectorLib[element.icon] ? (
                                                          <Icon icon={iconSelectorLib[element.icon].icon} />
                                                      ) : null}
                                                      <span>{element.label}</span>
                                                  </Link>
                                              </li>
                                          );
                                      })
                                    : null}

                                {environment.functions &&
                                environment.functions.website &&
                                environment.functions.website.active ? (
                                    <li
                                        className={
                                            isPathActive("/env/" + props._env + "/website-manager") ? "active" : null
                                        }
                                    >
                                        <Link
                                            to={"/env/" + props._env + "/website-manager"}
                                            onClick={hideMobileNavigation}
                                        >
                                            <Icon icon={faGlobeEurope} />
                                            <span>Webseite</span>
                                        </Link>
                                    </li>
                                ) : null}
                                {environment.functions &&
                                environment.functions.bookings &&
                                environment.functions.bookings.active ? (
                                    <li
                                        className={
                                            isPathActive("/env/" + props._env + "/bookings-manager") ? "active" : null
                                        }
                                    >
                                        <Link
                                            to={"/env/" + props._env + "/bookings-manager"}
                                            onClick={hideMobileNavigation}
                                        >
                                            <Icon icon={faCalendarAlt} />
                                            <span>Buchungen</span>
                                        </Link>
                                    </li>
                                ) : null}
                                {environment.functions &&
                                environment.functions.queryForms &&
                                environment.functions.queryForms.active ? (
                                    <li
                                        className={
                                            isPathActive("/env/" + props._env + "/query-forms") ? "active" : null
                                        }
                                    >
                                        <Link to={"/env/" + props._env + "/query-forms"} onClick={hideMobileNavigation}>
                                            <Icon icon={faFileSignature} />
                                            <span>Formulare</span>
                                        </Link>
                                    </li>
                                ) : null}
                                {environment.functions &&
                                environment.functions.filemanager &&
                                environment.functions.filemanager.active ? (
                                    <li className={isPathActive("/env/" + props._env + "/files") ? "active" : null}>
                                        <Link to={"/env/" + props._env + "/files"} onClick={hideMobileNavigation}>
                                            <Icon icon={faFolder} />
                                            <span>Dateimanager</span>
                                        </Link>
                                    </li>
                                ) : null}
                                <li className={isPathActive("/env/" + props._env + "/settings") ? "active" : null}>
                                    <Link to={"/env/" + props._env + "/settings"} onClick={hideMobileNavigation}>
                                        <Icon icon={faTools} />
                                        <span>Einstellungen</span>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </Sidebar>
                </GlobalNavigationContent>
            </GlobalNavigationWrapper>

            <ContentWrapper>
                <GlobalAlerts />
                <Container>
                    {props.isLoading ? (
                        <Page>
                            <PageTitle title={<ContentLoader pageTitle />} />
                            <Content>
                                <ContentLoader bars={4} />
                            </Content>
                        </Page>
                    ) : (
                        props.children
                    )}
                </Container>
            </ContentWrapper>
            <ConfirmWrapper />

            {helpOpened ? <HelpSection onClose={toggleHelp} /> : null}
        </Wrapper>
    );
};
