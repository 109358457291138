import React, { useState } from "react";
import { Button, Card, CardBody, LoadingSpinner } from "@webtypen/react-components";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const ExportBuilderMain = (props) => {
    const [isLoading, setIsLoading] = useState(true); // eslint-disable-line

    if (isLoading) {
        return (
            <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
                <Card>
                    <CardBody>
                        <LoadingSpinner>
                            Export wird geladen ...
                            {props.onClose ? (
                                <div>
                                    <Button type="button" className="mt-1" secondary outline xs onClick={props.onClose}>
                                        Abbrechen
                                    </Button>
                                </div>
                            ) : null}
                        </LoadingSpinner>
                    </CardBody>
                </Card>
            </Wrapper>
        );
    }

    return <Wrapper>asd</Wrapper>;
};
