import React, { useEffect, useState } from "react";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { lighten } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ContainerUser } from "../../components";
import {
    Content,
    Button,
    FloatInput,
    ButtonLink,
    LoadingSpinner,
    Alert,
    FormGroupError,
    Icon,
} from "@webtypen/react-components";
import { ApiHelper } from "../../helpers";

const PresetsList = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
`;

const PresetElementWrapper = styled.div`
    width: 33.33333%;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 767px) {
        width: 100%;
    }
`;

const PresetElement = styled.div`
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 5px;
    user-select: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);

    .preset-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .preset-description {
        font-size: 14px;
        font-weight: 400;
    }

    &:hover {
        background: rgba(255, 255, 255, 1);
    }

    ${(props) =>
        props.active
            ? `
        background: ${props.theme.color.primary};
        color: #fff;

        &:hover {
            background: ${lighten(0.05, props.theme.color.primary)};
            color: #fff;
        }
    `
            : ``}
`;

export const CreateEnvironment = (props) => {
    const [isSaving, setIsSaving] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [errors, setErrors] = useState(null);
    const [presets, setPresets] = useState(null);
    const [data, setData] = useState({
        name: "",
        preset: null,
    });
    const [environment, setEnvironment] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onPresetChange = (e) => {
        const preset = e.currentTarget.getAttribute("data-preset");
        if (!preset || preset === data.preset) {
            setData({
                ...data,
                preset: null,
            });
        } else {
            setData({
                ...data,
                preset: preset,
            });
        }
    };

    const submit = (e) => {
        e.preventDefault();

        setHasError(null);
        setErrors(null);
        setEnvironment(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments/create", data)
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.environment &&
                    response.data.environment.url_slug &&
                    response.data.environment.url_slug.trim() !== ""
                ) {
                    setErrors(null);
                    setHasError(null);
                    setEnvironment(response.data.environment);
                } else {
                    setHasError(
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten."
                    );
                    setEnvironment(null);
                    setErrors(response.errors && Object.keys(response.errors).length > 0 ? response.errors : null);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                setHasError(
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten."
                );
                setEnvironment(null);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        ApiHelper.request("/api/environments/presets").then((response) => {
            setPresets(response.data && response.data.length > 0 ? response.data : null);
        });
    }, []);

    if (environment) {
        return (
            <ContainerUser className="mt-3">
                <Content className="text-center">
                    <div className="text-success" style={{ fontSize: 48 }}>
                        <Icon icon={faCheckCircle} />
                    </div>
                    <h1 className="mb-0 text-success font-weight-bold">Firmenumgebung erfolgreich angelegt</h1>
                    <div className="mb-3 font-weight-bold">{environment.name}</div>
                    Sie haben die Firmenumgebung{" "}
                    <span className="font-weight-bold text-primary">{environment.name}</span> erfolgreich angelegt. Die
                    Firmenumgebung steht Ihnen sofort zu Verfügung:
                    <div className="mt-3">
                        <ButtonLink to={"/create-environment/setup/" + environment.url_slug}>
                            Firmenumgebung konfigurieren
                        </ButtonLink>
                        <div style={{ fontSize: 12 }} className="mt-2">
                            <Link to={"/env/" + environment.url_slug}>Überspringen</Link>
                        </div>
                    </div>
                </Content>
            </ContainerUser>
        );
    }

    return (
        <ContainerUser className="mt-3">
            <Content>
                <ButtonLink to="/account" outline secondary className="float-right">
                    Abbrechen
                </ButtonLink>
                <h1>Firmenumgebung erstellen</h1>

                {hasError ? <Alert danger>{hasError}</Alert> : null}

                <form onSubmit={submit}>
                    <FloatInput
                        placeholder="Name / Bezeichnung"
                        name="name"
                        value={data.name}
                        onChange={onChange}
                        disabled={isSaving}
                        hasError={errors && errors.names && errors.names.length > 0 ? true : false}
                    />
                    {errors && errors.names && errors.names.length > 0 ? (
                        <FormGroupError>
                            {errors.names.map((e, i) => (
                                <div key={i}>{e}</div>
                            ))}
                        </FormGroupError>
                    ) : null}

                    <div className="mt-4 font-weight-bold">Vorlage verwenden:</div>
                    <PresetsList>
                        {presets &&
                            presets.map((preset, i) => {
                                return (
                                    <PresetElementWrapper key={i}>
                                        <PresetElement
                                            active={preset.key === data.preset ? true : false}
                                            data-preset={preset.key}
                                            onClick={onPresetChange}
                                        >
                                            <div className="preset-title">{preset.title}</div>
                                            <div className="preset-description">{preset.description}</div>
                                        </PresetElement>
                                    </PresetElementWrapper>
                                );
                            })}
                    </PresetsList>
                    <label>
                        <input
                            type="checkbox"
                            onChange={onPresetChange}
                            className="mr-1"
                            checked={data.preset === "none"}
                            data-preset="none"
                        />
                        Keine Vorlage verwenden und leere Firmenumgebung erstellen.
                    </label>

                    <div className="text-right mt-2">
                        <Button
                            success
                            disabled={
                                !data.name ||
                                data.name.trim() === "" ||
                                data.preset === null ||
                                data.preset.trim() === "" ||
                                isSaving
                            }
                        >
                            {isSaving ? <LoadingSpinner /> : "Fortfahren"}
                        </Button>
                    </div>
                </form>
            </Content>
        </ContainerUser>
    );
};
