import React, { useEffect, useState } from "react";
import { Alert, Button, Content, FloatInput, FloatSelect, LoadingSpinner, Tabs } from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { WebsiteManagerSettingsInterfaces } from "./WebsiteManagerSettingsInterfaces";
import { WebsiteManagerSettingsCerts } from "./WebsiteManagerSettingsCerts";

export const WebsiteManagerSettings = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState({});
    const [pages, setPages] = useState(null);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        });
    };

    const load = () => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setData(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                setData({});
                setIsLoading(false);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/website-manager/save-settings", {
            website: data,
        })
            .then((response) => {
                setAlert(
                    response.status === "success"
                        ? ["success", "Website-Einstellungen wurden erfolgreich gespeichert."]
                        : [
                              "danger",
                              response.status === "error" && response.message && response.message.trim() !== ""
                                  ? response.message
                                  : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                          ]
                );
                setIsSaving(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "success" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        load();

        ApiHelper.request("/api/website-manager/pages").then((response) => {
            setPages(response.status === "success" && response.data && response.data.length > 0 ? response.data : null);
        });
    }, []);

    if (isLoading || !data || !data._id) {
        return (
            <Content>
                {isLoading ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Informationen aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>Website-Einstellungen anpassen</h1>

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

            <form onSubmit={submit}>
                <Tabs
                    sections={{
                        main: {
                            title: "Allgemein",
                            render: () => (
                                <Content>
                                    <FloatInput
                                        value={data.host ? data.host : ""}
                                        name="host"
                                        placeholder="Domain / Host"
                                        onChange={onChange}
                                        disabled={isSaving}
                                        className="mb-2"
                                    />
                                    <FloatSelect
                                        value={data.homepage ? data.homepage : ""}
                                        name="homepage"
                                        placeholder="Startseite"
                                        onChange={onChange}
                                        disabled={isSaving}
                                        className="mb-2"
                                    >
                                        <option value="">-</option>
                                        {pages && pages.length > 0
                                            ? pages.map((opt, i) => (
                                                  <option key={i} value={opt._id}>
                                                      {opt.title}
                                                  </option>
                                              ))
                                            : null}
                                    </FloatSelect>

                                    <label className="mb-2" style={{ display: "block" }}>
                                        <input
                                            type="checkbox"
                                            checked={data.is_active ? true : false}
                                            name="is_active"
                                            onChange={onChange}
                                            disabled={isSaving}
                                        />
                                        Website ist aktiviert und kann besucht werden
                                    </label>
                                    <label className="mb-2" style={{ display: "block" }}>
                                        <input
                                            type="checkbox"
                                            checked={data.debug ? true : false}
                                            name="debug"
                                            onChange={onChange}
                                            disabled={isSaving}
                                        />
                                        Debug-Modus aktivieren? Es werden Fehlermeldungen auf der Webseite angezeigt
                                    </label>
                                </Content>
                            ),
                        },
                        interfaces: {
                            title: "Schnittstellen",
                            render: () => (
                                <Content>
                                    <WebsiteManagerSettingsInterfaces
                                        data={data}
                                        setData={setData}
                                        isSaving={isSaving}
                                    />
                                </Content>
                            ),
                        },
                        certificates: {
                            title: "Zertifikate",
                            render: () => (
                                <Content>
                                    <WebsiteManagerSettingsCerts />
                                </Content>
                            ),
                        },
                    }}
                />

                <div className="mt-2 text-right">
                    <Button success disabled={isSaving}>
                        {isSaving ? <LoadingSpinner /> : "Speichern"}
                    </Button>
                </div>
            </form>
        </Content>
    );
};
