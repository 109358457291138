import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Content,
    LoadingSpinner,
    FloatInput,
    Row,
    Col,
    FloatSelect,
    Button,
    ButtonLink,
} from "@webtypen/react-components";
import { FloatIconSelector } from "../../../../components";
import { ApiHelper } from "../../../../helpers";
import { SettingTitle } from "../SettingTitle";

export const DynamicRecordsCreate = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState({
        key: "",
        parent: "",
        plural: "",
        singular: "",
        icon: "",
    });
    const [types, setTypes] = useState(null);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const submit = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        setSuccess(null);
        ApiHelper.request("/api/settings/dynamic-records/create", data)
            .then((response) => {
                if (response.status === "success" && response.data && response.data.id && response.data.key) {
                    setAlert(["success", "Die Datenstruktur wurde erfolgreich erstellt"]);
                    setSuccess(response.data);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein Fehler beim Erstellen der Datenstruktur aufgetreten.",
                    ]);
                    setSuccess(null);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e &&
                    e.data &&
                    e.data.status &&
                    e.data.status === "error" &&
                    e.data.message &&
                    e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein Fehler beim Erstellen der Datenstruktur aufgetreten.",
                ]);
                setSuccess(null);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        ApiHelper.request("/api/settings/dynamic-records/types").then((response) => {
            setTypes(response.status === "success" && response.data.types ? response.data.types : null);
            setIsLoading(false);
        });
    }, []);

    return (
        <Content>
            <SettingTitle
                icon={faDatabase}
                title={"Datenstruktur anlegen"}
                backLink={"/env/" + props.match.params._env + "/settings/dynamic-records"}
            />

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
            {isLoading ? (
                <LoadingSpinner>Datenstruktur-Informationen werden geladen ...</LoadingSpinner>
            ) : isSaving ? (
                <LoadingSpinner>Datenstruktur wird erstellt ...</LoadingSpinner>
            ) : success ? (
                <div className="mt-2">
                    <ButtonLink
                        to={"/env/" + props.match.params._env + "/settings/dynamic-records/" + success.key + "/edit"}
                        primary
                    >
                        Datensturktur bearbeiten
                    </ButtonLink>
                    <ButtonLink
                        to={"/env/" + props.match.params._env + "/settings/dynamic-records"}
                        className="ml-1"
                        outline
                    >
                        Zur Übersicht
                    </ButtonLink>
                </div>
            ) : (
                <form onSubmit={submit}>
                    <Row>
                        <Col width={3}>
                            <FloatInput
                                placeholder="Datenstruktur-Key"
                                value={data.key}
                                onChange={onChange}
                                name="key"
                            />
                        </Col>
                        <Col width={2}>
                            <FloatSelect
                                placeholder="Eltern-Datenstruktur"
                                value={data.parent}
                                onChange={onChange}
                                name="parent"
                            >
                                <option value="">Bitte auswählen</option>
                                {types && types && types.length > 0
                                    ? types.map((t, i) => {
                                          if (!t || (t.parent && t.parent.trim() !== "") || !t.isCreatable) {
                                              return null;
                                          }

                                          return (
                                              <option key={i} value={t.key}>
                                                  {t.plural}
                                              </option>
                                          );
                                      })
                                    : null}
                            </FloatSelect>
                        </Col>
                        <Col width={3}>
                            <FloatInput
                                placeholder="Bezeichnung Singular"
                                value={data.singular}
                                onChange={onChange}
                                name="singular"
                            />
                        </Col>
                        <Col width={3}>
                            <FloatInput
                                placeholder="Bezeichnung Plural"
                                value={data.plural}
                                onChange={onChange}
                                name="plural"
                            />
                        </Col>
                        <Col width={1}>
                            <FloatIconSelector
                                placeholder="Icon"
                                value={data.icon}
                                onChange={(icon) => setData({ ...data, icon: icon })}
                            />
                        </Col>
                    </Row>

                    <div className="text-right mt-2">
                        <Button success>Datenstruktur anlegen</Button>
                    </div>
                </form>
            )}
        </Content>
    );
};
