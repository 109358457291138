import React from "react";
import { FieldSelector } from "../FieldSelector";

export const EditObject = (props) => {
    const { active, fieldDefinition } = props;

    const prependFields = { ...fieldDefinition.definition };

    return (
        <div className="mt-2">
            <b>Felder hinzufügen</b>
            <FieldSelector justComponents pathPrefix={active + ".form"} prependFields={prependFields} />
        </div>
    );
};
