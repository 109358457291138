import React from "react";
import { Alert } from "@webtypen/react-components";
import { FormFields, FieldWrapper, FieldTitle, FieldBody } from "../FormFields";

export const Card = ({ field, path, setActive, isActive, disabled, moveControls }) => {
    return (
        <FieldWrapper isActive={isActive} disabled={disabled}>
            <FieldTitle onClick={disabled ? null : () => setActive(path)} isActive={isActive} disabled={disabled}>
                <div className="float-right">{moveControls}</div>
                <b>Card</b> <span className="path">Path: {path}</span>
            </FieldTitle>

            {!field.children || field.children.length < 1 ? (
                <FieldBody>
                    <Alert info className="mb-0">
                        Es wurden noch Felder / Komponenten hinzugefügt
                    </Alert>
                </FieldBody>
            ) : (
                <FieldBody>
                    <FormFields
                        fields={field.children}
                        pathPrefix={path + ".children"}
                        setActive={setActive}
                        disabled={disabled}
                    />
                </FieldBody>
            )}
        </FieldWrapper>
    );
};
