import React, { useEffect, useState } from "react";
import { Alert, Button, CardFooter, Col, LoadingSpinner, Row, Content, FloatInput } from "@webtypen/react-components";
import { ApiHelper } from "../../helpers";

export const Step2 = (props) => {
    const [data, setData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const finish = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments-setup/step-2", { ...data, _env: props.match.params.envKey })
            .then((response) => {
                props.nextStep();
                props.addDone(props.index);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        if (props.init && props.init.defaultAddressInvoice) {
            setData({
                invoice_name: props.init.defaultAddressInvoice.name ?? props.init.environment.name,
                invoice_addition: props.init.defaultAddressInvoice.addition ?? "",
                invoice_street: props.init.defaultAddressInvoice.street ?? "",
                invoice_housenumber: props.init.defaultAddressInvoice.housenumber ?? "",
                invoice_zip: props.init.defaultAddressInvoice.zip ?? "",
                invoice_city: props.init.defaultAddressInvoice.city ?? "",
            });
        } else {
            setData({
                invoice_name: props.init.environment.name,
                invoice_addition: "",
                invoice_street: "",
                invoice_housenumber: "",
                invoice_zip: "",
                invoice_city: "",
                invoice_country: "",
            });
        }
    }, [props.init]);

    if (!data) {
        return null;
    }

    return (
        <form onSubmit={finish}>
            <Content>
                {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                <h3 className="mt-2">Standard-Rechnungsadresse</h3>
                In den Einstellungen der Firmenumgebung kann die hier angegebene Rechnungsadresse jederzeit bearbeitet
                werden. Dort können ebenfalls beliebig viele Adressen ergänzt werden.
                <FloatInput
                    placeholder="Name / Rechnungsempfänger"
                    value={data.invoice_name}
                    name="invoice_name"
                    onChange={onChange}
                    disabled={isSaving}
                    className="mt-3 mb-2"
                />
                <FloatInput
                    placeholder="Zusatz"
                    value={data.invoice_addition}
                    name="invoice_addition"
                    onChange={onChange}
                    disabled={isSaving}
                    className="mb-2"
                />
                <Row>
                    <Col width={8}>
                        <FloatInput
                            placeholder="Straße"
                            value={data.invoice_street}
                            name="invoice_street"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                    <Col width={4}>
                        <FloatInput
                            placeholder="Hausnummer"
                            value={data.invoice_housenumber}
                            name="invoice_housenumber"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                    <Col width={4}>
                        <FloatInput
                            placeholder="Postleitzahl"
                            value={data.invoice_zip}
                            name="invoice_zip"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                    <Col width={8}>
                        <FloatInput
                            placeholder="Stadt"
                            value={data.invoice_city}
                            name="invoice_city"
                            onChange={onChange}
                            disabled={isSaving}
                            className="mb-2"
                        />
                    </Col>
                </Row>
            </Content>
            <CardFooter className="text-right">
                <Button success disabled={isSaving}>
                    {isSaving ? <LoadingSpinner /> : "Weiter"}
                </Button>
            </CardFooter>
        </form>
    );
};
