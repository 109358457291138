import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faPlus, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Content,
    Icon,
    LoadingSpinner,
    Modal,
    ModalTitle,
    Row,
} from "@webtypen/react-components";
import { ApiHelper, ConfirmHelper } from "../../../../helpers";

const FormElement = styled.div`
    padding: 15px;
    border-top: 1px solid #eee;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.light};
    }

    &:first-child {
        border-top: none;
    }

    ${(props) =>
        props.disabled
            ? `
        cursor: default;
        opacity: 0.5;
        
        &:hover {
            background: transparent;
            color: ${props.theme.color.default};
        }
    `
            : ``}
`;

export const DynamicRecordsQueryForms = (props) => {
    const env = useSelector((state) => state.app.environment);
    const [addForm, setAddForm] = useState(false);
    const [formsLoading, setFormsLoading] = useState(true);
    const [forms, setForms] = useState(null);

    const openAddForm = () => {
        setAddForm(true);
    };

    const closeAddForm = () => {
        setAddForm(false);
    };

    const selectForm = (form) => {
        const newData = { ...props.data };
        if (!newData.queryForms) {
            newData.queryForms = [];
        }

        let add = true;
        for (let i in newData.queryForms) {
            if (newData.queryForms[i].form === form._id) {
                add = false;
            }
        }

        if (add) {
            newData.queryForms.push({
                form: form._id,
                portal_fillable: true,
                portal_requirement: false,
                url_fillable: true,
                multiple: false,
                editable: true,
            });
            props.setData({ ...newData });
        }
        setAddForm(false);
    };

    const onFormChange = (e) => {
        const index = parseInt(e.target.getAttribute("data-index"));
        if (e.target.type === "checkbox") {
            const newData = { ...props.data };
            newData.queryForms[index][e.target.name] = e.target.checked;
            props.setData(newData);
        } else {
            const newData = { ...props.data };
            newData.queryForms[index][e.target.name] = e.target.value;
            props.setData(newData);
        }
    };

    const removeForm = async (e) => {
        const index = e.currentTarget.getAttribute("data-index");
        const status = await ConfirmHelper.confirm(
            "Soll das Formular wirklich aus der Datenstruktur entfernt werden?",
            {
                confirmAttributes: {
                    danger: true,
                },
            }
        );
        if (!status) {
            return;
        }

        const newData = { ...props.data };
        newData.queryForms.splice(index, 1);
        props.setData(newData);
    };

    useEffect(() => {
        setFormsLoading(true);
        ApiHelper.request("/api/query-forms/list").then((response) => {
            setForms(response.status === "success" && response.data && response.data.length > 0 ? response.data : null);
            setFormsLoading(false);
        });
    }, []);

    return (
        <Content>
            <Modal
                open={addForm}
                contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 550 } }}
                onCloseRequest={closeAddForm}
            >
                {!addForm ? null : (
                    <>
                        <ModalTitle>
                            <Button type="button" light className="float-right" onClick={closeAddForm}>
                                <Icon icon={faTimes} />
                            </Button>
                            <h1>Formular zur Datenstruktur hinzufügen</h1>
                        </ModalTitle>

                        {formsLoading ? (
                            <Content>
                                <LoadingSpinner>Formulare werden geladen ...</LoadingSpinner>
                            </Content>
                        ) : !forms || forms.length < 1 ? (
                            <Content>
                                <Alert light>Es stehen keine Formulare zur Auswahl.</Alert>
                            </Content>
                        ) : (
                            forms.map((form, i) => {
                                const disabled =
                                    props.data.queryForms && props.data.queryForms.find((el) => el.form === form._id);

                                return (
                                    <FormElement
                                        key={i}
                                        onClick={disabled ? null : () => selectForm(form)}
                                        disabled={disabled}
                                    >
                                        <div className="form-title">{form.title}</div>
                                        <Badge warning>{form.definition.length} Felder</Badge>
                                    </FormElement>
                                );
                            })
                        )}
                    </>
                )}
            </Modal>

            <div style={{ fontSize: 12 }}>
                Hier können Formulare zum Datentyp hinzugefügt werden. Diese Formulare können entweder per Link zum
                Ausfüllen der Zielperson zu Verfügung gestellt werden können oder direkt aus dem Portal beantwortet
                werden. Formulare können in den Einstellungen unter{" "}
                <Link to={"/env/" + env.url_slug + "/settings/query-forms"}>Formulare</Link> bearbeitet werden.
            </div>
            <div className="mt-2">
                <Button type="button" success sm onClick={openAddForm}>
                    <Icon icon={faPlus} className="mr-1" />
                    Formular hinzufügen
                </Button>
            </div>

            {formsLoading ? (
                <LoadingSpinner>Formulare werden geladen ...</LoadingSpinner>
            ) : props.data && props.data.queryForms && props.data.queryForms.length > 0 ? (
                props.data.queryForms.map((form, i) => {
                    const definition = forms && forms.length > 0 ? forms.find((el) => el._id === form.form) : null;
                    if (!definition) {
                        return (
                            <Card key={i} className="mt-2">
                                <Alert danger className="mb-0">
                                    Unbekanntes Formular `{form.form}`
                                </Alert>
                            </Card>
                        );
                    }

                    return (
                        <Card key={i} className="mt-2">
                            <CardBody>
                                <Button
                                    type="button"
                                    outline
                                    danger
                                    sm
                                    className="float-right"
                                    data-index={i}
                                    onClick={removeForm}
                                >
                                    <Icon icon={faTrashAlt} className="mr-1" />
                                    Entfernen
                                </Button>
                                <b>{definition.title}</b>
                                <div style={{ fontSize: 12 }} className="mb-3">
                                    <Badge warning>{definition.definition.length} Felder</Badge>
                                </div>

                                <Row>
                                    <Col width={6} className="mb-1">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="mr-1"
                                                name="portal_fillable"
                                                data-index={i}
                                                checked={form.portal_fillable}
                                                onChange={onFormChange}
                                            />
                                            Im Portal ausfüllbar
                                        </label>
                                    </Col>
                                    <Col width={6} className="mb-1">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="mr-1"
                                                name="portal_requirement"
                                                data-index={i}
                                                checked={form.portal_requirement}
                                                onChange={onFormChange}
                                                disabled={!form.portal_fillable}
                                            />
                                            Muss vor Verwendung des Portals ausgefüllt werden
                                        </label>
                                    </Col>
                                    <Col width={6} className="mb-1">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="mr-1"
                                                name="url_fillable"
                                                data-index={i}
                                                checked={form.url_fillable}
                                                onChange={onFormChange}
                                            />
                                            Es kann eine URL exportiert werden, über die das Formular ausgefüllt werden
                                            kann
                                        </label>
                                    </Col>
                                    <Col width={6} className="mb-1">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="mr-1"
                                                name="multiple"
                                                data-index={i}
                                                checked={form.multiple}
                                                onChange={onFormChange}
                                            />
                                            Das Formular kann mehrfach ausgefüllt werden
                                        </label>
                                    </Col>
                                    <Col width={6} className="mb-1">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="mr-1"
                                                name="editable"
                                                data-index={i}
                                                checked={form.editable}
                                                onChange={onFormChange}
                                            />
                                            Das Formular kann vom Ausfüller nachträglich bearbeitet werden
                                        </label>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    );
                })
            ) : null}
        </Content>
    );
};
