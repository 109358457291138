import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FloatInput, Alert, LoadingSpinner, Row, Col, ButtonLink } from "@webtypen/react-components";
import { SuccessAnimation, SuccessText } from "../components";
import { ApiHelper } from "../helpers";
import { LayoutGuest } from "../components/LayoutGuest";

export const Register = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [firstname, setFirstname] = useState("");
    const [name, setName] = useState("");
    // const [errors, setErrors] = useState(null);

    const register = (e) => {
        e.preventDefault();

        if (
            !email ||
            email.trim() === "" ||
            !password ||
            password.trim() === "" ||
            password !== password2 ||
            !name ||
            name.trim() === "" ||
            !firstname ||
            firstname.trim === ""
        ) {
            return;
        }

        setIsLoading(true);
        setHasError(null);
        // setErrors(null);
        setShowSuccess(false);
        ApiHelper.request("/api/users/register", {
            firstname: firstname,
            name: name,
            email: email,
            password: password,
            password2: password2,
        })
            .then((response) => {
                if (response && response.status === "success") {
                    setHasSuccess(true);
                    setIsLoading(false);

                    setTimeout(() => {
                        setShowSuccess(true);
                    }, 250);
                } else {
                    setHasError(true);
                    setIsLoading(false);
                    setPassword("");
                    setPassword2("");

                    setHasError("Es ist ein Fehler während der Registrierung aufgetreten");
                    // setErrors(response.errors && Object.keys(response.errors).length > 0 ? response.errors : null);
                }
            })
            .catch((e) => {
                setHasError(true);
                setIsLoading(false);
                setPassword("");
                setPassword2("");
                setHasError("Es ist ein Fehler während der Registrierung aufgetreten");
                // setErrors(e.data && e.data.errors && Object.keys(e.data.errors).length > 0 ? e.data.errors : null);
            });
    };

    return (
        <LayoutGuest title="Account erstellen">
            {hasError ? (
                <Alert className="mt-3" danger>
                    Registrierung fehlgeschlagen ...
                </Alert>
            ) : null}

            {hasSuccess ? (
                <>
                    <div style={{ position: "relative" }}>
                        <SuccessAnimation className="float-left" />
                        <SuccessText
                            show={showSuccess}
                            className="text-success"
                            style={{ fontSize: 22, paddingTop: 3, paddingLeft: 5, height: "auto" }}
                        >
                            Account erfolgreich angelegt
                        </SuccessText>
                        <div className="clearfix" />
                    </div>
                    Der Account wurde erfolgreich angelegt. Es wurde eine E-Mail mit einem Bestätigungslink an{" "}
                    <b>{email.trim()}</b> gesendet.
                    <div className="mt-3">
                        <ButtonLink to="/" outline secondary>
                            Zum Login
                        </ButtonLink>
                    </div>
                </>
            ) : (
                <>
                    <form onSubmit={register} className="mb-5">
                        <Row>
                            <Col width={6} size="xs" style={{ paddingRight: 5 }}>
                                <FloatInput
                                    type="text"
                                    placeholder="Vorname"
                                    onChange={(e) => setFirstname(e.target.value)}
                                    value={firstname}
                                    className="mb-2"
                                />
                            </Col>
                            <Col width={6} size="xs" style={{ paddingLeft: 5 }}>
                                <FloatInput
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="mb-2"
                                />
                            </Col>
                        </Row>
                        <FloatInput
                            type="email"
                            placeholder="E-Mail Adresse"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className="mb-2"
                        />
                        <FloatInput
                            type="password"
                            placeholder="Passwort"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            className="mb-2"
                        />
                        <FloatInput
                            type="password"
                            placeholder="Passwort wiederholen"
                            onChange={(e) => setPassword2(e.target.value)}
                            value={password2}
                            className="mb-2"
                        />

                        <Button
                            block
                            success
                            disabled={
                                isLoading ||
                                !email ||
                                email.trim() === "" ||
                                !password ||
                                password.trim() === "" ||
                                !password ||
                                password.trim() === "" ||
                                !name ||
                                name.trim() === "" ||
                                !firstname ||
                                firstname.trim() === ""
                            }
                        >
                            {isLoading ? <LoadingSpinner /> : "Account erstellen"}
                        </Button>
                    </form>
                    Bereits registriert? <Link to="/">Zum Login</Link>
                </>
            )}
        </LayoutGuest>
    );
};
