import React from "react";
import { useSelector } from "react-redux";
import lodash from "lodash";
import { DynamicRecordsFieldContainer } from "./DynamicRecordsFieldContainer";
import { FormTabs } from "./components/FormTabs";
import { FormCard } from "./components/FormCard";
import { StringField } from "./fields/StringField";
import { MapField } from "./fields/MapField";
import { DateField } from "./fields/DateField";
import { DatetimeField } from "./fields/DatetimeField";
import { ModelSelectorField } from "./fields/ModelSelectorField";
import { ModelsSelectorField } from "./fields/ModelsSelectorField";
import { FormContainer } from "./components/FormContainer";
import { ObjectField } from "./fields/ObjectField";
import { Button } from "./fields/Button";
import { IntegerField } from "./fields/IntegerField";
import { FloatField } from "./fields/FloatField";
import { BooleanField } from "./fields/BooleanField";
import { PasswordField } from "./fields/PasswordField";
import { store } from "../../redux";
import { FileField } from "./fields/FileField";
import { FilesField } from "./fields/FilesField";
import { TextField } from "./fields/TextField";

export const DynamicRecordFieldsRenderer = (props) => {
    const { data } = props;
    const errors = useSelector((state) => state.app.dynamicrecordsErrors);
    const state = store.getState();

    return props.fields.map((element, i) => {
        let field = null;
        const fieldKeyOrg = typeof element === "string" ? element : element.field ? element.field : null;
        let fieldKey = fieldKeyOrg;

        if (props.prefix && props.prefix.trim() !== "") {
            fieldKey = props.prefix + "." + fieldKey;
        }

        if (element.visible) {
            const visibleCheck = checkFieldVisibility(element, props.prefix, state);
            if (!visibleCheck) {
                return null;
            }
        }

        const fieldErrors =
            props.errorPrefix &&
            props.errorPrefix.trim() !== "" &&
            fieldKey &&
            errors &&
            errors[props.errorPrefix + "." + fieldKey]
                ? errors[props.errorPrefix + "." + fieldKey]
                : fieldKey && errors && errors[fieldKey]
                ? errors[fieldKey]
                : null;

        if (fieldKey && fieldKeyOrg) {
            if (data.fields[fieldKeyOrg]) {
                if (data.fields[fieldKeyOrg].type === "string") {
                    field = (
                        <StringField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "text") {
                    field = (
                        <TextField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "integer") {
                    field = (
                        <IntegerField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "float") {
                    field = (
                        <FloatField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "password") {
                    field = (
                        <PasswordField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "boolean") {
                    field = (
                        <BooleanField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "map") {
                    field = (
                        <MapField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "object") {
                    field = (
                        <ObjectField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "date") {
                    field = (
                        <DateField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "datetime") {
                    field = (
                        <DatetimeField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "model") {
                    field = (
                        <ModelSelectorField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            selector={data.fields[fieldKeyOrg].selector}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "models") {
                    field = (
                        <ModelsSelectorField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            selector={data.fields[fieldKeyOrg].selector}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "file") {
                    field = (
                        <FileField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            selector={data.fields[fieldKeyOrg].selector}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                } else if (data.fields[fieldKeyOrg].type === "files") {
                    field = (
                        <FilesField
                            formField={fieldKey}
                            field={data.fields[fieldKeyOrg]}
                            element={element}
                            prefix={props.prefix}
                            errorPrefix={props.errorPrefix}
                            data={data}
                            hasError={fieldErrors && fieldErrors.length > 0 ? true : false}
                            selector={data.fields[fieldKeyOrg].selector}
                            onChange={props.onChange}
                            customValueStore={props.customValueStore}
                        />
                    );
                }
            }
        } else {
            if (element.type === "tabs") {
                field = <FormTabs definition={element} data={data} />;
            } else if (element.type === "container") {
                field = <FormContainer definition={element} data={data} />;
            } else if (element.type === "card") {
                field = <FormCard definition={element} data={data} />;
            } else if (element.type === "button") {
                field = <Button definition={element} data={data} />;
            }
        }

        if (!field) {
            field = (
                <div style={{ border: "2px dashed #ddd", padding: 8, borderRadius: 3 }}>
                    Unbekannter Feld-Typ `
                    {element && element.type
                        ? element.type
                        : data.fields[fieldKeyOrg] && data.fields[fieldKeyOrg].type
                        ? data.fields[fieldKeyOrg].type
                        : "?"}
                    ` - Feld: {element.field}
                </div>
            );
        }

        return (
            <DynamicRecordsFieldContainer key={i} element={element} errors={fieldErrors}>
                {field}
            </DynamicRecordsFieldContainer>
        );
    });
};

export const checkFieldVisibility = (element, prefix, state) => {
    if (!element.visible || element.visible.length < 1) {
        return true;
    }

    const path = (prefix && prefix.trim() !== "" ? prefix : "") + element.field;
    if (!path) {
        return false;
    }

    let check = true;
    for (let i in element.visible) {
        const group = checkFieldVisibilityGroup(element.visible[i], prefix, state);

        if (!group) {
            check = false;
            break;
        }
    }

    return check;
};

const checkFieldVisibilityGroup = (group, prefix, state) => {
    let status = true;
    for (let i in group) {
        const val = lodash.get(state.app.dynamicrecords, (prefix ? prefix : "") + group[i].field);
        const path = (prefix ? prefix : "") + group[i].field;
        if (!path || group[i].value === undefined) {
            continue;
        }

        if (group[i].operator === "==") {
            if (val !== group[i].value) {
                status = false;
            }
        }
    }
    return status;
};
