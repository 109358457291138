import React, { useEffect, useState } from "react";
import { Modal, LoadingSpinner } from "@webtypen/react-components";
import { FileSelector } from "@webtypen/react-filemanager";
import { ApiHelper } from "../helpers";
import { useSelector } from "react-redux";

export const FloatFileSelector = (props) => {
    const mainDirectory = useSelector((state) => state.app.filemanagerDir);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (props.value && props.value.toString().trim() !== "") {
            setIsLoading(true);
            setSelectedFile(null);

            ApiHelper.request("/api/filemanager/file", {
                id: props.value,
            })
                .then((response) => {
                    setSelectedFile(
                        response.status === "success" &&
                            response.data &&
                            response.data._id &&
                            response.data._id === props.value
                            ? response.data
                            : null
                    );
                    setIsLoading(false);
                })
                .catch((e) => {
                    setSelectedFile(null);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
            setSelectedFile(null);
        }
    }, [props.value]);

    return (
        <>
            <Modal
                open={isOpen}
                onCloseRequest={close}
                contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 800 } }}
            >
                {isOpen ? (
                    <FileSelector
                        onClose={close}
                        directory={mainDirectory}
                        api={{
                            directory: (params, onSuccess, onError) => {
                                ApiHelper.request("/api/filemanager/directory", params)
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            preview: (params, onSuccess, onError) => {
                                ApiHelper.request("/api/filemanager/actions/download", params)
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                        }}
                        data={props.data}
                        onSelect={props.onChange}
                    />
                ) : null}
            </Modal>
            {props.selectorElement ? (
                React.cloneElement(props.selectorElement, { onClick: open })
            ) : (
                <div
                    style={{
                        border: "1px solid #ddd",
                        borderRadius: 4,
                        height: 52,
                        cursor: "pointer",
                        userSelect: "none",
                        background: "#fff",
                    }}
                    onClick={open}
                >
                    <div style={{ color: "#999", fontSize: 12, padding: "0px 12px", paddingTop: 11 }}>
                        {props.placeholder ? props.placeholder : "Datei"}
                    </div>

                    <div style={{ paddingLeft: 12 }}>
                        {props.value ? (
                            isLoading ? (
                                <div style={{ fontSize: 11, userSelect: "none" }}>
                                    <LoadingSpinner className="mr-1" />
                                    Datei wird geladen ...
                                </div>
                            ) : selectedFile ? (
                                selectedFile.filename
                            ) : (
                                <span className="text-danger">Fehler beim Laden der Datei</span>
                            )
                        ) : (
                            "-"
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
