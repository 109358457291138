import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonLink, Content, LoadingSpinner } from "@webtypen/react-components";
import { Logo } from "../components";
import { ApiHelper } from "../helpers";

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 30px;
`;

export const Activation = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [hasError, setHasError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setHasSuccess(false);
        setHasError(null);

        setTimeout(() => {
            ApiHelper.request("/api/users/activate", {
                user: props.match.params.user,
                token: props.match.params.token,
            })
                .then((response) => {
                    if (response.status === "success") {
                        setHasError(null);
                        setHasSuccess(true);
                    } else {
                        setHasSuccess(false);
                        setHasError(
                            response &&
                                response &&
                                response.status === "error" &&
                                response.message &&
                                response.message.trim() !== ""
                                ? response.message
                                : "Es ist in unerwarteter Fehler beim Aktivieren des Accounts aufgetreten."
                        );
                    }
                    setIsLoading(false);
                })
                .catch((e) => {
                    setHasError(
                        e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                            ? e.data.message
                            : "Es ist in unerwarteter Fehler beim Aktivieren des Accounts aufgetreten."
                    );
                    setHasSuccess(false);
                    setHasSuccess(true);
                    setIsLoading(false);
                });
        }, 1000);
    }, [props.match.params.user, props.match.params.token]);

    return (
        <Wrapper>
            <Container style={{ userSelect: "none" }}>
                <Logo style={{ textAlign: "center", fontSize: 22 }} />
                <Content className="text-center">
                    {isLoading ? (
                        <>
                            <h1>Account wird aktiviert ...</h1>
                            <LoadingSpinner>Einen Moment bitte ...</LoadingSpinner>
                        </>
                    ) : hasSuccess ? (
                        <div className="text-success">
                            <h1>Account erfolgreich aktiviert</h1>

                            <ButtonLink to="/">Zum Login</ButtonLink>
                        </div>
                    ) : (
                        <>
                            <h1>Aktivierung fehlgeschlagen</h1>
                            <div className="text-danger">
                                {hasError
                                    ? hasError
                                    : "Es ist ein unerwarteter Fehler beim Aktivieren des Accounts aufgetreten."}
                            </div>
                        </>
                    )}
                    <div className="mt-4">
                        <ButtonLink to="/" outline secondary>
                            Zur Startseite
                        </ButtonLink>
                    </div>
                </Content>
            </Container>
        </Wrapper>
    );
};
