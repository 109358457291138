import { transparentize } from "polished";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const FloatInputContainer = styled.div`
    display: flex;
    align-items: stretch;
    .input-prepend,
    .input-append {
        color: #999;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const FloatInputWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    transition: all 100ms;
    ${(props) =>
        props.haserror || props.hasError
            ? `
            border-color: ${props.theme.color.danger};
    `
            : ``}
    // textarea {
    //     width: 100%;
    //     box-sizing: border-box;
    //     padding: 14px 16px 0px 10px;
    //     outline: none;
    //     border: none;
    //     font-size: 14px;
    //     // height: 50px;
    //     transform-origin: top left;
    //     transition: all 0.2s ease-out;
    //     color: ${(props) => props.theme.color.default};
    //     border-radius: 4px;
    // }

    textarea {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 22px 16px 0px 10px;
        outline: none;
        border: none;
        font-size: 14px;
        min-height: 42px;
        margin: 0px;
        color: ${(props) => props.theme.color.default};
        resize: none;
    }

    label {
        font-size: 16px;
        padding: 0px 12px;
        color: #999;
        pointer-events: none;
        position: absolute;
        transform: translate(0, 14px) scale(1);
        transform-origin: top left;
        transition: all 0.2s ease-out;
    }
    &:focus-within {
        border: 1px solid #555;
        box-shadow: 0 0 0 0.2rem ${(props) => transparentize(0.75, props.theme.color.primary)};
        label {
            transform: translate(2px, 7px) scale(0.7);
        }
    }

    ${(props) =>
        props.type === "date"
            ? `
        label {
            transform: translate(2px, 7px) scale(0.7);
        }
    `
            : ``}
    ${(props) => (props.hasPrepend ? `border-top-left-radius: 0px;border-bottom-left-radius: 0px;` : ``)}
    ${(props) => (props.hasAppend ? `border-top-right-radius: 0px;border-bottom-right-radius: 0px;` : ``)}
    ${(props) =>
        props.active
            ? `
        label {
            transform: translate(2px, 7px) scale(0.70) !important;
        }
    `
            : ``}
    ${(props) =>
        props.isdisabled
            ? `
        background-color: #e9ecef;
        opacity: 1;
        input {
            background-color: #e9ecef;
        }
    `
            : ``}
`;

// export const FloatTextareaAutogrow = (props) => {
//     const { value, maxHeight, minHeight, placeholder, hasError, className, wrapperAttributes, ...rest } = props;
//     const textareaRef = useRef(null);

//     useEffect(() => {
//         if (textareaRef) {
//             textareaRef.current.style.height = "0px";
//             const scrollHeight = textareaRef.current.scrollHeight;
//             const minHeightTemp = parseInt(minHeight) < 50 ? 50 : minHeight;
//             console.log(minHeight, minHeightTemp, scrollHeight);

//             if (maxHeight && scrollHeight > maxHeight) {
//                 textareaRef.current.style.height = maxHeight + "px";
//             } else if (minHeightTemp && scrollHeight < minHeightTemp) {
//                 textareaRef.current.style.height = minHeightTemp + "px";
//             } else {
//                 textareaRef.current.style.height = scrollHeight + "px";
//             }
//         }
//     }, [value, minHeight, maxHeight]);

//     return (
//         <FloatInputContainer
//             className={className}
//             haserror={hasError ? true : false}
//             hasPrepend={props.prepend ? true : false}
//             hasAppend={props.append ? true : false}
//             {...wrapperAttributes}
//         >
//             <FloatInputWrapper
//                 active={props.value && props.value.toString().trim() !== "" ? true : false}
//                 isdisabled={props.disabled}
//                 hasPrepend={props.prepend ? true : false}
//                 hasAppend={props.append ? true : false}
//                 haserror={hasError ? true : false}
//                 type={rest.type ? rest.type : "text"}
//             >
//                 <textarea ref={textareaRef} {...rest} value={value} />
//                 <label>{placeholder}</label>
//             </FloatInputWrapper>
//         </FloatInputContainer>
//     );
// };

export const FloatTextareaAutogrow = (props) => {
    const { value, maxHeight, minHeight, placeholder, ...rest } = props;
    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;

        if (maxHeight && scrollHeight > maxHeight) {
            textareaRef.current.style.height = maxHeight + "px";
        } else if (minHeight && scrollHeight < minHeight) {
            textareaRef.current.style.height = minHeight + "px";
        } else {
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [value, minHeight, maxHeight]);

    return (
        <FloatInputContainer
        // className={className}
        // haserror={hasError ? true : false}
        // hasPrepend={props.prepend ? true : false}
        // hasAppend={props.append ? true : false}
        // {...wrapperAttributes}
        >
            <FloatInputWrapper
                active={props.value && props.value.toString().trim() !== "" ? true : false}
                // isdisabled={props.disabled}
                // hasPrepend={props.prepend ? true : false}
                // hasAppend={props.append ? true : false}
                // haserror={hasError ? true : false}
                type={rest.type ? rest.type : "text"}
            >
                <textarea ref={textareaRef} {...rest} value={value} />
                <label>{placeholder}</label>
            </FloatInputWrapper>
        </FloatInputContainer>
    );
};
