import React from "react";
import { Page } from "@webtypen/react-components";
import { WebsiteManager } from "./WebsiteManager";

export const WebsiteManagerScreen = (props) => {
    return (
        <Page flex fullHeight>
            <WebsiteManager />
        </Page>
    );
};
