import React from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    Button,
    Card,
    CardHeader,
    Dropdown,
    DropdownMenu,
    DropdownMenuElement,
    Icon,
    Input,
    Table,
} from "@webtypen/react-components";
import { useDispatch } from "react-redux";
import { reduxSetFormBuilder } from "../../../redux";

export const EditSidebarStyleAttribute = (props) => {
    const dispatch = useDispatch();
    const { stylingDefinitions } = props;
    const stylingKeys = stylingDefinitions ? Object.keys(stylingDefinitions) : null;

    const add = (key) => {
        const newField = { ...props.field };
        if (!newField.style) {
            newField.style = {};
        }

        if (newField.style[key] === undefined) {
            newField.style[key] = "";
        }

        props.setField({ ...newField });
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    const onChange = (e) => {
        props.setField({
            ...props.field,
            style: {
                ...props.field.style,
                [e.target.name]: e.target.value,
            },
        });
        dispatch(reduxSetFormBuilder("hasChanges", true));
    };

    const usedStylingKeys = props.field.style ? Object.keys(props.field.style) : null;
    return (
        <Card className="mb-3">
            <CardHeader>
                {stylingKeys && stylingKeys.length > 0 ? (
                    <Dropdown
                        right
                        wrapperAttributes={{ className: "float-right" }}
                        trigger={
                            <Button type="button" success outline xs>
                                <Icon icon={faPlus} className="mr-1" />
                                Style
                            </Button>
                        }
                    >
                        <DropdownMenu style={{ maxHeight: 450 }}>
                            {stylingKeys.map((key, i) => (
                                <DropdownMenuElement key={i}>
                                    <button
                                        type="button"
                                        className="pb-1 pt-2"
                                        style={{ fontSize: 14, lineHeight: "16px" }}
                                        onClick={() => add(key)}
                                    >
                                        {stylingDefinitions[key].name}
                                        <div style={{ fontSize: 10 }}>{key}</div>
                                    </button>
                                </DropdownMenuElement>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                ) : null}
                Styling
            </CardHeader>
            <Table>
                <tbody>
                    {!usedStylingKeys || usedStylingKeys.length < 1 ? (
                        <tr>
                            <td colSpan={2} className="text-center">
                                Es wurde noch kein Styling hinzugefügt
                            </td>
                        </tr>
                    ) : (
                        usedStylingKeys.map((key, i) => {
                            return (
                                <tr key={i}>
                                    <td className="pt-0 pb-0" style={{ verticalAlign: "middle", fontWeight: 600 }}>
                                        {key}
                                    </td>
                                    <td className="pt-0 pb-0 pr-0">
                                        <Input
                                            type="text"
                                            value={props.field.style[key]}
                                            name={key}
                                            onChange={onChange}
                                            className="pt-1 pb-1"
                                            style={{
                                                height: 35,
                                                border: "none",
                                                borderRadius: 0,
                                                borderLeft: "1px solid #eee",
                                            }}
                                        />
                                    </td>
                                    <td style={{ width: 40 }} className="pb-0 pt-0 pr-0 pl-0">
                                        <Button type="button" danger style={{ height: 35, borderRadius: 0 }}>
                                            <Icon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
        </Card>
    );
};
