import React from "react";
import { Row } from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";

export const FormContainer = (props) => {
    const { definition } = props;

    return (
        <div style={definition && definition.style ? definition.style : null}>
            {definition.content}
            {props.definition.children && props.definition.children.length > 0 ? (
                props.definition.noChildrenRow ? (
                    <DynamicRecordFieldsRenderer
                        data={props.data}
                        fields={props.definition.children}
                        setValue={props.setValue}
                        values={props.values}
                    />
                ) : (
                    <Row>
                        <DynamicRecordFieldsRenderer
                            data={props.data}
                            fields={props.definition.children}
                            setValue={props.setValue}
                            values={props.values}
                        />
                    </Row>
                )
            ) : null}
        </div>
    );
};
