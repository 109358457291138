import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    ButtonLink,
    Card,
    CardBody,
    CardHeader,
    Content,
    Icon,
    LoadingSpinner,
    Page,
    Table,
} from "@webtypen/react-components";
import { DynamicRecordStage, PageTitle } from "../../../components";
import { ApiHelper } from "../../../helpers";
import { faAngleDoubleDown, faExclamationTriangle, faFilePdf } from "@fortawesome/free-solid-svg-icons";

export const DynamicRecordsChangeStages = (props) => {
    const { recordType } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isChanging, setIsChanging] = useState(false);
    const [loadError, setLoadError] = useState(null);
    const [data, setData] = useState(null);
    const [alert, setAlert] = useState(null);
    const [hasSuccess, setHasSuccess] = useState(false);

    const submit = () => {
        setIsChanging(true);
        setAlert(null);

        ApiHelper.request("/api/dynamic-records/stages/change", {
            type: props.match.params._recordType,
            stage: props.match.params.newStage,
            id: props.match.params.id,
        })
            .then((response) => {
                if (response.status === "success") {
                    setHasSuccess(true);
                } else {
                    setAlert([
                        "danger",
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Ändern des Status aufgetreten ...",
                    ]);
                }
                setIsChanging(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Ändern des Status aufgetreten ...",
                ]);
                setIsChanging(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        setLoadError(null);
        setData(null);
        setHasSuccess(false);
        ApiHelper.request("/api/dynamic-records/stages/details", {
            type: props.match.params._recordType,
            stage: props.match.params.newStage,
            id: props.match.params.id,
        })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.stage && response.data.stage.name) {
                    setLoadError(null);
                    setData(response.data);
                } else {
                    setLoadError(
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarter Fehler beim Vorbereiten des Datensatzs zur Statusänderung aufgetreten."
                    );
                    setData(null);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setLoadError(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarter Fehler beim Vorbereiten des Datensatzs zur Statusänderung aufgetreten."
                );
                setData(null);
                setIsLoading(false);
            });
    }, [props.match.params.id, props.match.params.newStage, props.match.params._recordType]);

    return (
        <Page fullHeight flex>
            <PageTitle
                title={recordType.singular}
                subtitle="Dokumentstatus ändern"
                backLink={
                    "/env/" + props.match.params._env + "/dyn/" + recordType.key + "/" + props.match.params.id + "/edit"
                }
            />

            <Content
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {isLoading ? (
                    <LoadingSpinner style={{ userSelect: "none" }}>Statusänderung wird überprüft ...</LoadingSpinner>
                ) : loadError || !data ? (
                    <Card style={{ width: "100%", maxWidth: 500 }} className="bg-danger">
                        <CardBody className="bg-danger text-light">
                            <b>Fehler beim Vorbereiten des Datensatzes</b>
                            <br />
                            {loadError}
                            <div className="text-right">
                                <ButtonLink
                                    to={
                                        "/env/" +
                                        props.match.params._env +
                                        "/dyn/" +
                                        recordType.key +
                                        "/" +
                                        props.match.params.id +
                                        "/edit"
                                    }
                                    className="mt-1"
                                    outline
                                    light
                                    xs
                                >
                                    Zurück
                                </ButtonLink>
                            </div>
                        </CardBody>
                    </Card>
                ) : hasSuccess ? (
                    <Card style={{ width: "100%", maxWidth: 500 }}>
                        <CardHeader className="bg-success text-light">Datensatz Status erfolgreich geändert</CardHeader>
                        <CardBody>
                            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                            Der Status des ausgewählten Datensatzes wurde erfolgreich von
                            <DynamicRecordStage
                                stage={data.currentStage}
                                stages={data.stages}
                                className="ml-1 mt-1 mb-1"
                            />{" "}
                            zu
                            <DynamicRecordStage
                                stage={props.match.params.newStage}
                                stages={data.stages}
                                className="ml-1 mt-1 mb-1 mr-1"
                            />
                            geändert
                            <div className="mt-3 text-right">
                                <ButtonLink
                                    to={"/env/" + props.match.params._env + "/dyn/" + props.match.params._recordType}
                                    light
                                >
                                    Zur {recordType.plural}-Übersicht
                                </ButtonLink>

                                <ButtonLink
                                    to={
                                        "/env/" +
                                        props.match.params._env +
                                        "/dyn/" +
                                        props.match.params._recordType +
                                        "/" +
                                        props.match.params.id +
                                        "/page/main"
                                    }
                                    className="ml-1"
                                    success
                                >
                                    Datensatz öffnen
                                </ButtonLink>
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <>
                        <Card style={{ width: "100%", maxWidth: 500 }}>
                            <CardHeader>Status des Datensatzes ändern</CardHeader>
                            <CardBody>
                                {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                                Der Status des ausgewählten Datensatzes vom Typ <i>{recordType.singular}</i> wird wie
                                folgt angepasst:
                                <div className="mt-3 mb-3 text-center">
                                    <DynamicRecordStage stage={data.currentStage} stages={data.stages} />
                                </div>
                                <div style={{ fontSize: 12, textAlign: "center", userSelect: "none" }}>
                                    <div style={{ fontSize: 22 }}>
                                        <Icon icon={faAngleDoubleDown} />
                                    </div>
                                    in den neuen Status
                                </div>
                                <div className="mt-3 mb-3 text-center">
                                    <DynamicRecordStage stage={props.match.params.newStage} stages={data.stages} />
                                </div>
                            </CardBody>
                            <Table>
                                <tbody>
                                    {data.stages[props.match.params.newStage].editDisabled ? (
                                        <tr>
                                            <td style={{ width: 30, paddingRight: 0, textAlign: "center" }}>
                                                <Icon icon={faExclamationTriangle} />
                                            </td>
                                            <td style={{ fontSize: 12, userSelect: "none" }}>
                                                Der Datensatz kann nach der Status-Änderung nicht mehr bearbeitet werden
                                            </td>
                                        </tr>
                                    ) : null}
                                    {data.stages[props.match.params.newStage].export ? (
                                        <tr>
                                            <td style={{ width: 30, paddingRight: 0, textAlign: "center" }}>
                                                <Icon icon={faFilePdf} />
                                            </td>
                                            <td style={{ fontSize: 12, userSelect: "none" }}>
                                                Es wird eine PDF-Datei generiert und festgeschrieben
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </Table>
                        </Card>
                        <div className="mt-2 text-right" style={{ width: "100%", maxWidth: 500 }}>
                            <Button type="button" success disabled={isChanging} onClick={submit}>
                                {isChanging ? <LoadingSpinner /> : "Status ändern"}
                            </Button>
                        </div>
                    </>
                )}
            </Content>
        </Page>
    );
};
