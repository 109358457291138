import lodash from "lodash";
import moment from "moment";
import {
    REDUX_SET_VALUE,
    REDUX_LOGOUT,
    REDUX_SET_DYNAMICRECORDS_VALUE,
    REDUX_SET_FORMBUILDER,
    REDUX_SET_FORMBUILDER_FORM,
    REDUX_SET_GLOBAL_ALERT,
    REDUX_REMOVE_GLOBAL_ALERT,
    REDUX_REGISTER_FILEMANAGER,
    REDUX_REMOVE_FILEMANAGER,
    REDUX_SET_FILEMANAGER,
    REDUX_SET_UPLOAD,
    REDUX_SET_UPLOAD_VALUE,
    REDUX_REMOVE_UPLOAD,
    REDUX_UPLOAD_CHANGE_TRIGGER,
    REDUX_FILEMANAGER_STRUCTURE_CHANGES,
    REDUX_SET_VALUE_RECURSIVE,
} from "./actions";

const authLocalStorage = localStorage.getItem("user");
const defaultAuth =
    authLocalStorage && authLocalStorage.trim() !== "" && authLocalStorage.indexOf("{") > -1
        ? JSON.parse(authLocalStorage)
        : null;
const initialStore = {
    initDate: moment().format(),
    mobileNavigation: false,
    auth:
        defaultAuth && defaultAuth.email && defaultAuth.email.trim() !== "" && defaultAuth.firstname
            ? defaultAuth
            : null,
    authLoading: false,
    environment: null,
    environmentUser: null,
    environmentLoading: true,
    dynamicrecords: {},
    dynamicrecordsRerenderCount: 0,
    dynamicrecordsErrors: {},
    dynamicrecordsPositionsChanges: 0,
    formBuilderForm: {},
    globalAlerts: {},
    filemanager: {},
    filemanagerDir: null,
    filemanagerStructureChanges: 0,
    uploads: {},
    uploadsChangeTrigger: 0,
    confirm: null,
    helpOpened: false,
};

const appReducer = (state = initialStore, action) => {
    switch (action.type) {
        case REDUX_SET_VALUE:
            return {
                ...state,
                [action.payload.setting]: action.payload.value,
            };
        case REDUX_SET_VALUE_RECURSIVE:
            return {
                ...lodash.set({ ...state }, action.payload.setting, action.payload.value),
            };
        case REDUX_LOGOUT:
            return {
                ...state,
                auth: null,
            };
        case REDUX_SET_DYNAMICRECORDS_VALUE:
            const rerenderCount = state.dynamicrecordsRerenderCount + (action.payload.forceRerender ? 1 : 0);
            if (action.payload.key.indexOf(".") > 0 || action.payload.key.indexOf("[") > 0) {
                return {
                    ...state,
                    dynamicrecords: lodash.set({ ...state.dynamicrecords }, action.payload.key, action.payload.value),
                    dynamicrecordsRerenderCount: rerenderCount,
                };
            }
            return {
                ...state,
                dynamicrecords: {
                    ...state.dynamicrecords,
                    [action.payload.key]: action.payload.value,
                },
                dynamicrecordsRerenderCount: rerenderCount,
            };
        case REDUX_SET_FORMBUILDER:
            return {
                ...state,
                formBuilderForm: {
                    ...state.formBuilderForm,
                    [action.payload.key]: action.payload.value,
                },
            };
        case REDUX_SET_FORMBUILDER_FORM:
            return {
                ...state,
                formBuilderForm: {
                    ...state.formBuilderForm,
                    form: {
                        ...state.formBuilderForm.form,
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case REDUX_SET_GLOBAL_ALERT:
            return {
                ...state,
                globalAlerts: {
                    ...state.globalAlerts,
                    [action.payload.key]: action.payload.alert,
                },
            };
        case REDUX_REMOVE_GLOBAL_ALERT:
            const newGlobalAlerts = {};
            for (let i in state.globalAlerts) {
                if (i !== action.payload.key) {
                    newGlobalAlerts[i] = { ...state.globalAlerts[i] };
                }
            }

            return {
                ...state,
                globalAlerts: { ...newGlobalAlerts },
            };
        case REDUX_REGISTER_FILEMANAGER:
            return {
                ...state,
                filemanager: {
                    ...state.filemanager,
                    [action.payload.key]: {
                        key: action.payload.key,
                        ...action.payload.defaults,
                    },
                },
            };
        case REDUX_REMOVE_FILEMANAGER:
            const newFilemanager = {};
            for (let i in state.filemanager) {
                if (i !== action.payload.key) {
                    newFilemanager[i] = { ...state.filemanager[i] };
                }
            }

            return {
                ...state,
                filemanager: {
                    ...newFilemanager,
                },
            };
        case REDUX_SET_FILEMANAGER:
            return {
                ...state,
                filemanager: {
                    ...state.filemanager,
                    [action.payload.filemanager]: {
                        ...state.filemanager[action.payload.filemanager],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case REDUX_FILEMANAGER_STRUCTURE_CHANGES:
            return {
                ...state,
                filemanagerStructureChanges: state.filemanagerStructureChanges + 1,
            };
        case REDUX_SET_UPLOAD:
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [action.payload.key]: action.payload.value,
                },
            };
        case REDUX_SET_UPLOAD_VALUE:
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [action.payload.upload]: {
                        ...state.uploads[action.payload.upload],
                        [action.payload.key]: action.payload.value,
                    },
                },
            };
        case REDUX_REMOVE_UPLOAD:
            const uploads = {};
            for (let i in state.uploads) {
                if (i !== action.payload.key) {
                    uploads[i] = { ...state.uploads[i] };
                }
            }

            return {
                ...state,
                uploads: {
                    ...uploads,
                },
            };
        case REDUX_UPLOAD_CHANGE_TRIGGER:
            return {
                ...state,
                uploadsChangeTrigger: state.uploadsChangeTrigger + 1,
            };
        default:
            return state;
    }
};
export default appReducer;
