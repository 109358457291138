import React from "react";
import { Content } from "@webtypen/react-components";

export const SettingNotFound = (props) => {
    return (
        <Content>
            <h1>Seite nicht gefunden</h1>
            Die von Ihnen aufgerufene Seite wurde nicht gefunden ...
        </Content>
    );
};
