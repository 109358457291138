import moment from "moment";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Button, Icon } from "@webtypen/react-components";
import { reduxSetGlobalAlert, reduxSetValue, store } from "../redux";
import { ApiHelper } from "./ApiHelper";

class HeartbeatHelperWrapper {
    timeout = 15000;

    heartbeat() {
        const state = store.getState();
        ApiHelper.request("/api/environments/heartbeat", {
            init_date: state.app.initDate,
        })
            .then((response) => {
                if (response.status !== "success" || !response.data) {
                    return;
                }

                const state = store.getState();
                if (response.data.envStatus !== state.app.environment.status) {
                    store.dispatch(
                        reduxSetValue("environment", {
                            ...state.app.environment,
                            status: response.data.envStatus,
                        })
                    );
                }

                if (moment(response.data.envLastConfigUpdate) > moment(state.app.initDate)) {
                    store.dispatch(
                        reduxSetGlobalAlert("environment_update", {
                            attributes: {
                                color: "warning",
                            },
                            message: (
                                <>
                                    <Button
                                        type="button"
                                        light
                                        xs
                                        className="float-right"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Icon icon={faSync} className="mr-1" />
                                        Aktualisieren
                                    </Button>
                                    Die Konfiguration der Firmenumgebung wurde aktualisiert. Die Anwendung sollte neu
                                    geladen werden, damit mit der neuesten Version der Firmenumgebung weitergearbeitet
                                    werden kann
                                </>
                            ),
                            disableClose: false,
                        })
                    );
                }
            })
            .catch((e) => {});
    }

    start() {
        window.envHeartbeat = setInterval(this.heartbeat, this.timeout);
        this.heartbeat();
    }

    stop() {
        clearInterval(window.envHeartbeat);
    }
}

const HeartbeatHelper = new HeartbeatHelperWrapper();
export { HeartbeatHelper };
