import React, { useEffect, useState } from "react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Content, FloatInput, LoadingSpinner } from "@webtypen/react-components";
import { ApiHelper } from "../../../../helpers";
import { SettingTitle } from "../SettingTitle";

const defaultState = {
    active: false,
    title: "",
    url_slug: "",
};

export const PortalSettings = (props) => {
    const [data, setData] = useState(defaultState);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setIsSaving(true);
        ApiHelper.request("/api/settings/portals/save", data)
            .then((response) => {
                if (response && response.status === "success") {
                } else {
                    setAlert([
                        "danger",
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/settings/portals/details")
            .then((response) => {
                if (response.status === "success") {
                    setData(response.data && response.data._id ? response.data : { ...defaultState });
                    setIsLoading(false);
                }
            })
            .catch((e) => {});
    }, []);

    return (
        <Content>
            <SettingTitle icon={faExternalLinkAlt} title="Portal" />

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

            {isLoading ? (
                <LoadingSpinner>Portal-Konfiguration wird geladen ...</LoadingSpinner>
            ) : (
                <form onSubmit={onSubmit}>
                    <label>
                        <input
                            type="checkbox"
                            className="mr-1"
                            checked={data.active}
                            name="active"
                            onChange={(e) => setData({ ...data, active: e.target.checked })}
                        />
                        Portal aktivieren?
                    </label>

                    {data.active ? (
                        <div className="mt-2">
                            <FloatInput
                                placeholder="Titel des Portal"
                                value={data.title}
                                name="title"
                                onChange={onChange}
                                disabled={isSaving}
                                className="mb-2"
                            />

                            <FloatInput
                                placeholder={
                                    <>
                                        URL-Key{" "}
                                        <span style={{ marginLeft: 5, opacity: 0.7 }}>
                                            portal.simplebis.com/<b>{data.url_slug ? data.url_slug : "[URL-KEY]"}</b>
                                        </span>
                                    </>
                                }
                                value={data.url_slug ? data.url_slug : ""}
                                name="url_slug"
                                onChange={onChange}
                                disabled={isSaving}
                                className="mb-2"
                            />

                            <div className="text-right">
                                <Button disabled={isSaving} success>
                                    {isSaving ? <LoadingSpinner /> : "Speichern"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert className="mt-2" light>
                            Das Portal ist nicht aktiviert und ist nicht für Ihre Kunden aufrufbar
                        </Alert>
                    )}
                </form>
            )}
        </Content>
    );
};
