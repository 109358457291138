import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FloatInput, Alert, LoadingSpinner } from "@webtypen/react-components";
import { ApiHelper, AuthHelper } from "../helpers";
import { LayoutGuest } from "../components/LayoutGuest";

export const Login = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = (e) => {
        e.preventDefault();

        if (!email || email.trim() === "" || !password || password.trim() === "") {
            return;
        }

        localStorage.clear();
        setIsLoading(true);
        setHasError(false);
        ApiHelper.request("/api/users/login", {
            email: email,
            password: password,
        })
            .then((response) => {
                if (response && response.status === "success" && response.token && response.token.trim() !== "") {
                    AuthHelper.login(response.token, response.user);
                    props.history.push("/account");
                } else {
                    setHasError(true);
                    setIsLoading(false);
                    // setPassword("");
                }
            })
            .catch((e) => {
                setHasError(true);
                setIsLoading(false);
                setPassword("");
            });
    };

    return (
        <LayoutGuest title="Einloggen">
            {hasError ? (
                <Alert className="mt-3" danger>
                    Login fehlgeschlagen: Die angegebene Kombination aus E-Mail und Passwort ist ungültig
                </Alert>
            ) : null}

            <form onSubmit={login} className="mb-5">
                <FloatInput
                    type="email"
                    placeholder="E-Mail Adresse"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="mb-2"
                />
                <FloatInput
                    type="password"
                    placeholder="Passwort"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className="mb-2"
                />
                <Button
                    block
                    success
                    className="mb-2"
                    disabled={isLoading || !email || email.trim() === "" || !password || password.trim() === ""}
                >
                    {isLoading ? <LoadingSpinner /> : "Einloggen"}
                </Button>
            </form>

            <Link to="/register">Neuen Account erstellen</Link>
            <br />
            <Link to="/forgot-pw">Passwort vergessen?</Link>
        </LayoutGuest>
    );
};
