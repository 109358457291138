import React, { useEffect, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ElementRenderer } from "./Renderer/ElementRenderer";
import { Alert } from "@webtypen/react-components";

export const PageBuilderRenderer = (props) => {
    if (props.bla) {
        console.log("bla");
    }

    const [isLoading, setIsLoading] = useState(true);
    const [definition, setDefinition] = useState(null);
    const [components, setComponents] = useState(null);

    useEffect(() => {
        setComponents(props.components ? props.components : null);
        setDefinition(props.definition ? props.definition : null);
        setIsLoading(false);
    }, [props.components, props.definition]);

    if (isLoading) {
        if (props.clean) {
            return null;
        }

        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            </div>
        );
    }

    if (!components || !definition || !definition.elements || definition.elements.length < 0) {
        if (props.isSubElement) {
            return null;
        }
        return <Alert danger>Es ist ein Fehler aufgetreten.</Alert>;
    }

    return (
        <React.Fragment>
            <ElementRenderer elements={definition.elements} components={components} />
        </React.Fragment>
    );
};
