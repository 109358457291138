import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, FloatInput, FloatInputAppend } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const PasswordField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));
    const useCustomValue = props.customValueStore ? true : false;
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;
    const [visible, setVisible] = useState(false);

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    const toggleVisible = (e) => {
        setVisible(!visible);
    };

    return (
        <FloatInput
            type={visible ? "text" : "password"}
            placeholder={props.field.label}
            value={
                useCustomValue
                    ? customValue === undefined || customValue === null
                        ? ""
                        : customValue
                    : value === undefined || value === null
                    ? ""
                    : value
            }
            onChange={onChange}
            hasError={props.hasError}
            prepend={props.element && props.element.prepend ? props.element.prepend : null}
            append={
                props.element && props.element.append ? (
                    props.element.append
                ) : (
                    <FloatInputAppend style={{ padding: 3 }}>
                        <Button type="button" light xs onClick={toggleVisible}>
                            {visible ? <Icon icon={faEyeSlash} /> : <Icon icon={faEye} />}
                        </Button>
                    </FloatInputAppend>
                )
            }
            style={props.definition && props.definition.style ? props.definition.style : null}
            {...props.attributes}
        />
    );
};
