import React from "react";
import styled from "styled-components";
import { Button, ButtonLink } from "@webtypen/react-components";

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
`;

const ErrorBox = styled.div`
    background: #fff;
    padding: 30px;
    border-radius: 3px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 800px;

    h1 {
        margin-top: 0px;
    }
`;

export const EnvironmentError = (props) => {
    return (
        <Wrapper>
            <ErrorBox>
                <h1>Es ist ein Fehler aufgetreten</h1>
                Beim Laden Ihrer simplebis-Firmenumgebung ist ein Fehler aufgetreten.
                <div className="mt-3">
                    <Button type="button" onClick={() => window.location.reload()} className="mr-1 mb-1" secondary>
                        Erneut versuchen
                    </Button>
                    <ButtonLink to="/account" outline secondary>
                        Firmenumgebung wechseln
                    </ButtonLink>
                </div>
            </ErrorBox>
        </Wrapper>
    );
};
