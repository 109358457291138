import React from "react";
import styled from "styled-components";
import { ButtonLink, Content } from "@webtypen/react-components";
import { Logo } from "../../components";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 30px;
`;

export const Error404Guest = (props) => {
    return (
        <Wrapper>
            <Container>
                <Logo style={{ textAlign: "center", fontSize: 22 }} />
                <Content className="text-center" style={{ userSelect: "none" }}>
                    <h1>404 - Seite nicht gefunden</h1>
                    Die von Ihnen aufgerufene Seite existiert nicht
                    <div className="mt-4">
                        <ButtonLink to="/" outline secondary>
                            Zur Startseite
                        </ButtonLink>
                    </div>
                </Content>
            </Container>
        </Wrapper>
    );
};
