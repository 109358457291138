import React, { useState } from "react";
import { Alert, Button, ButtonLink, Card, CardBody, FloatInput, LoadingSpinner } from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";

export const AccountSettingsChangePw = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPw, setCurrentPw] = useState("");
    const [newPw, setNewPw] = useState("");
    const [newPw2, setNewPw2] = useState("");
    const [alert, setAlert] = useState(null);

    const submit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setAlert(null);

        ApiHelper.request("/api/user/settings/change-pw", {
            currentPw: currentPw,
            newPw: newPw,
            newPw2: newPw2,
        })
            .then((response) => {
                if (response.status === "success") {
                    setAlert(["success", "Das Account-Passwort wurde erfolgreich zurückgesetzt"]);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten.",
                    ]);
                }
                setNewPw("");
                setNewPw2("");
                setCurrentPw("");
                setIsLoading(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten.",
                ]);
                setIsLoading(false);
                setNewPw("");
                setNewPw2("");
                setCurrentPw("");
            });
    };

    return (
        <Card className="mb-3">
            <CardBody>
                <form onSubmit={submit}>
                    <h3 className="mt-0">Passwort ändern</h3>
                    {alert ? (
                        <Alert color={alert[0]} className="mb-3">
                            {alert[1]}
                        </Alert>
                    ) : null}
                    Als Sicherheitskontrolle muss einmal das aktuelle Account-Passwort eingegeben werden:
                    <FloatInput
                        type="password"
                        placeholder="Aktuelles Passwort"
                        value={currentPw}
                        onChange={(e) => setCurrentPw(e.target.value)}
                        className="mb-4 mt-1"
                        disabled={isLoading}
                    />
                    Das neue Passwort muss mindestens 5 Zeichen lang sein und sollte neben Buchstaben sowohl Zahlena als
                    auch Sonderzeichen enthalten:
                    <FloatInput
                        type="password"
                        placeholder="Neues Passwort"
                        value={newPw}
                        onChange={(e) => setNewPw(e.target.value)}
                        className="mb-2 mt-2"
                        disabled={isLoading}
                    />
                    <FloatInput
                        type="password"
                        placeholder="Neues Passwort (wiederholen)"
                        value={newPw2}
                        onChange={(e) => setNewPw2(e.target.value)}
                        className="mb-2"
                        disabled={isLoading}
                    />
                    <div className="text-right">
                        <ButtonLink to="/account/settings" disabled={isLoading} light className="text-danger mr-1">
                            Abbrechen
                        </ButtonLink>
                        <Button
                            success
                            disabled={
                                isLoading ||
                                !currentPw ||
                                currentPw.trim() === "" ||
                                !newPw ||
                                newPw.trim().length < 0 ||
                                newPw !== newPw2
                            }
                        >
                            {isLoading ? <LoadingSpinner /> : "Passwort ändern"}
                        </Button>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
};
