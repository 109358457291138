import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export const FooterContent = () => {
    return (
        <>
            <div className="links">
                <Link to="/docs">Dokumentation</Link> | Entwickler | <Link to="/contact">Kontakt</Link> |{" "}
                <Link to="/legal">Nutzungsbedingungen</Link> | <Link to="/imprint">Impressum</Link>
            </div>

            <div className="copyright">
                Copyright &copy; {moment().format("YYYY")} and powered by{" "}
                <a href="https://webtypen.de" target="_blank" rel="noreferrer">
                    webtypen.de
                </a>
            </div>
        </>
    );
};
