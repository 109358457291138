import React from "react";
import { Card, CardBody, CardHeader, Row } from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";

export const FormCard = (props) => {
    const { definition } = props;

    return (
        <Card style={definition && definition.style ? definition.style : null}>
            {definition && definition.headerTitle ? <CardHeader>{definition.headerTitle}</CardHeader> : null}
            <CardBody>
                <Row>
                    <DynamicRecordFieldsRenderer
                        data={props.data}
                        fields={props.definition.children}
                        setValue={props.setValue}
                        values={props.values}
                    />
                </Row>
            </CardBody>
        </Card>
    );
};
