import React from "react";
import { Switch, Route } from "react-router";
import { NavLink } from "react-router-dom";
import { faGlobe, faHome } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@webtypen/react-components";
import { SidebarLayout } from "../../../../components";
import { QueryFormsMainSettings } from "./MainSettings";
import { QueryFormsWebsiteSettings } from "./WebsiteSettings";

export const QueryFormsSettings = (props) => {
    return (
        <SidebarLayout
            sidebar={
                <ul className="sidebar">
                    <li>
                        <NavLink exact to={"/env/" + props.match.params._env + "/query-forms/settings"}>
                            <span className="icon-prefix">
                                <Icon icon={faHome} />
                            </span>
                            Allgemein
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/env/" + props.match.params._env + "/query-forms/settings/html"}>
                            <span className="icon-prefix">
                                <Icon icon={faGlobe} />
                            </span>
                            HTML-/Web-Formulare
                        </NavLink>
                    </li>
                </ul>
            }
        >
            <Switch>
                <Route exact path={"/env/:_env/query-forms/settings"} component={QueryFormsMainSettings} />
                <Route exact path={"/env/:_env/query-forms/settings/html"} component={QueryFormsWebsiteSettings} />
            </Switch>
        </SidebarLayout>
    );
};
