import React from "react";
import { faEdit, faFile } from "@fortawesome/free-regular-svg-icons";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { Page, Icon, Alert, Content } from "@webtypen/react-components";
import { PageTitle } from "../../../components";
import { useSelector } from "react-redux";
import { NumericHelper } from "../../../helpers";
import { ConditionsHelper } from "../../../helpers/ConditionsHelper";

export const DynamicRecordsList = (props) => {
    const { recordType } = props;
    const envStatus = useSelector((state) => state.app.environment.status);

    if (!recordType || !recordType.defaultList || !recordType.lists || !recordType.lists[recordType.defaultList]) {
        return (
            <Page>
                <PageTitle title={recordType.plural} />

                <Content>
                    <Alert warning>Fehlende Listen-Definition für den Datentyp.</Alert>
                </Content>
            </Page>
        );
    }

    if (recordType.lists[recordType.defaultList].type === "simpletable") {
        return (
            <Page>
                <PageTitle
                    title={recordType.plural}
                    actions={[
                        {
                            type: "button-link",
                            label: recordType.singular + " anlegen",
                            attributes: {
                                to:
                                    "/env/" +
                                    props.match.params._env +
                                    "/dyn/" +
                                    props.match.params._recordType +
                                    "/new/edit",
                                disabled: envStatus !== "active" ? true : false,
                            },
                        },
                    ]}
                />

                <SimpleTable
                    model="DynamicRecord"
                    where={[["type", "=", props.match.params._recordType]]}
                    data={{
                        lookup: recordType.lists[recordType.defaultList].lookup,
                        select: recordType.lists[recordType.defaultList].select,
                    }}
                >
                    {recordType.lists[recordType.defaultList].cells.map((cell, i) => {
                        if (cell.mapping === "___options___") {
                            return (
                                <SimpleTableCell
                                    key={i}
                                    {...cell}
                                    attributes={{ className: "text-right" }}
                                    buttons={[
                                        {
                                            type: "button-link",
                                            label: (
                                                <>
                                                    <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                                </>
                                            ),
                                            attributes: {
                                                xs: true,
                                                to: (row) => {
                                                    return envStatus !== "active"
                                                        ? "/env/" +
                                                              +props.match.params._env +
                                                              "/dyn/" +
                                                              props.match.params._recordType
                                                        : "/env/" +
                                                              props.match.params._env +
                                                              "/dyn/" +
                                                              props.match.params._recordType +
                                                              "/" +
                                                              row._id +
                                                              "/edit";
                                                },
                                                disabled: envStatus !== "active" ? true : false,
                                            },
                                        },
                                    ]}
                                />
                            );
                        }

                        if (cell.format) {
                            if (cell.format === "currency") {
                                return (
                                    <SimpleTableCell
                                        key={i}
                                        {...cell}
                                        attributes={{ className: "text-right" }}
                                        render={(data) => NumericHelper.numberFormat(data[cell.mapping] / 100)}
                                    />
                                );
                            } else if (cell.format === "options") {
                                const buttonGroup = [];
                                for (let i in cell.buttons) {
                                    if (typeof cell.buttons[i] === "object" && cell.buttons[i].type === "page") {
                                        buttonGroup.push({
                                            type: "button-link",
                                            label: (
                                                <>
                                                    <Icon icon={faFile} className="mr-1" />
                                                    {cell.buttons[i].label}
                                                </>
                                            ),
                                            attributes: {
                                                xs: true,
                                                to: (row) =>
                                                    "/env/" +
                                                    props.match.params._env +
                                                    "/dyn/" +
                                                    props.match.params._recordType +
                                                    "/" +
                                                    row._id +
                                                    "/page/" +
                                                    cell.buttons[i].page,
                                            },
                                            visible: (row) => {
                                                if (cell.buttons[i].conditions) {
                                                    return ConditionsHelper.check(cell.buttons[i].conditions, row);
                                                }
                                                return true;
                                            },
                                        });
                                    } else if (
                                        (typeof cell.buttons[i] === "string" && cell.buttons[i] === "edit") ||
                                        (typeof cell.buttons[i] === "object" && cell.buttons[i].type === "edit")
                                    ) {
                                        buttonGroup.push({
                                            type: "button-link",
                                            label: (
                                                <>
                                                    <Icon icon={faEdit} className="mr-1" /> Bearbeiten
                                                </>
                                            ),
                                            attributes: {
                                                xs: true,
                                                to: (row) => {
                                                    return envStatus !== "active"
                                                        ? "/env/" +
                                                              +props.match.params._env +
                                                              "/dyn/" +
                                                              props.match.params._recordType
                                                        : "/env/" +
                                                              props.match.params._env +
                                                              "/dyn/" +
                                                              props.match.params._recordType +
                                                              "/" +
                                                              row._id +
                                                              "/edit";
                                                },
                                                disabled: envStatus !== "active" ? true : false,
                                            },
                                            visible: (row) => {
                                                if (cell.buttons[i].conditions) {
                                                    return ConditionsHelper.check(cell.buttons[i].conditions, row);
                                                }
                                                return true;
                                            },
                                        });
                                    }
                                }

                                return (
                                    <SimpleTableCell
                                        key={i}
                                        {...cell}
                                        attributes={{ className: "text-right" }}
                                        buttons={[
                                            {
                                                type: "button-group",
                                                buttons: buttonGroup,
                                            },
                                        ]}
                                    />
                                );
                            }
                        }

                        return <SimpleTableCell key={i} {...cell} />;
                    })}
                </SimpleTable>
            </Page>
        );
    }

    return (
        <Page>
            <PageTitle title={recordType.plural} />

            <Content>
                <Alert warning>Unbekannter Listen-Typ `{recordType.lists[recordType.defaultList].type}`.</Alert>
            </Content>
        </Page>
    );
};
