import React from "react";
import { Col } from "@webtypen/react-components";

export const DynamicRecordsFieldContainer = (props) => {
    const { errors } = props;

    const content = (
        <>
            {props.children}

            {errors && errors.length > 0 ? (
                <div className="text-danger" style={{ fontSize: 12 }}>
                    {errors.map((error, i) => (
                        <div key={i} style={{ marginTop: 2 }}>
                            {error}
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );

    if (props.element && props.element.noWrapper === true) {
        return content;
    }

    return (
        <Col
            width={typeof props.element === "object" && props.element.columns ? parseInt(props.element.columns) : 12}
            className="mb-3"
        >
            {content}

            {props.element && props.element.hint ? <div style={{ fontSize: 12 }}>{props.element.hint}</div> : null}
        </Col>
    );
};
