import React from "react";
import { faArrowLeft, faPercentage } from "@fortawesome/free-solid-svg-icons";
import { Content, Icon, ButtonLink } from "@webtypen/react-components";
import { DynamicRecordForm } from "../../../components";
import { SettingTitle } from "./SettingTitle";

export const BankDetailsEdit = (props) => {
    return (
        <Content>
            <ButtonLink to={"/env/" + props.match.params._env + "/settings/bank-details"} light className="float-right">
                <Icon icon={faArrowLeft} className="mr-1" />
                Zurück
            </ButtonLink>

            <SettingTitle icon={faPercentage} title="Bankverbindung bearbeiten" />

            <DynamicRecordForm
                type="envBankDetails"
                recordId={props.match.params.id}
                match={props.match}
                form="main"
                onRemove={() => props.history.push("/env/" + props.match.params._env + "/settings/bank-details")}
            />
        </Content>
    );
};
