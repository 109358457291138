import React, { useState } from "react";
import lodash from "lodash";
import {
    Button as MainButton,
    Content,
    LoadingSpinner,
    Alert,
    Icon,
    Modal,
    ModalTitle,
} from "@webtypen/react-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../../../helpers";
import styled from "styled-components";
import { handleModelSelectorOnSelect } from "./func/buttonFunctions";
import { reduxSetValue, store } from "../../../redux";
import { useDispatch } from "react-redux";

const SelectorElement = styled.div`
    padding: 8px 15px;
    color: ${(props) => props.theme.color.default};
    cursor: pointer;
    border-bottom: 1px solid #eee;
    white-space: pre;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.light};
    }
`;

export const Button = (props) => {
    const { definition } = props;
    const dispatch = useDispatch();
    const [modalSelectorOpen, setModalSelectorOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const [results, setResults] = useState(null);

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!definition.onClick) {
            return;
        }

        if (definition.onClick.type === "dyn-records-selector") {
            setModalSelectorOpen(true);
            setIsLoading(true);
            setResults(null);
            setAlert(null);

            const selectorData = JSON.parse(JSON.stringify(definition.onClick.selector));
            if (definition.onClick.selector.where && definition.onClick.selector.where.length > 0) {
                const state = store.getState();

                for (let i in definition.onClick.selector.where) {
                    if (!definition.onClick.selector.where[i] || definition.onClick.selector.where[i].length < 1) {
                        continue;
                    }

                    for (let y in definition.onClick.selector.where[i]) {
                        if (typeof definition.onClick.selector.where[i][y] === "object") {
                            if (definition.onClick.selector.where[i][y].field) {
                                selectorData.where[i][y] = lodash.get(
                                    state.app.dynamicrecords,
                                    definition.onClick.selector.where[i][y].field
                                );
                            }
                        }
                    }
                }
            }

            // Model-Selector Endpunkt nutzen
            ApiHelper.request("/api/model-selector/search", {
                selector: selectorData,
            }).then((request) => {
                if (request.status === "success" && request.data !== undefined) {
                    setAlert(null);
                    setResults(request.data);
                } else {
                    setAlert([
                        "danger",
                        request.status === "error" && request.message && request.message.trim() !== ""
                            ? request.message
                            : "Es ist ein unerwarteter Fehler aufgetreten",
                    ]);
                    setResults(null);
                }
                setIsLoading(false);
            });
        }
    };

    const onSelect = (e) => {
        const state = store.getState();
        const newRecord = handleModelSelectorOnSelect(
            definition.onClick.onSelect,
            results[parseInt(e.currentTarget.getAttribute("data-index"))],
            state.app.dynamicrecords
        );

        dispatch(reduxSetValue("dynamicrecords", { ...newRecord }));
        setModalSelectorOpen(false);
    };

    return (
        <>
            <MainButton
                type="button"
                onClick={onClick}
                color={definition.color ? definition.color : "primary"}
                outline={definition.outline ? true : false}
                size={definition.size ? definition.size : null}
                style={definition.style ? definition.style : null}
            >
                {props.definition.label}
            </MainButton>

            {definition.onClick && definition.onClick.type === "dyn-records-selector" ? (
                <Modal
                    open={modalSelectorOpen}
                    onCloseRequest={() => setModalSelectorOpen(false)}
                    contentAttributes={{ style: definition.onClick.style }}
                >
                    {definition.onClick.selectTitle ? (
                        <ModalTitle>
                            <MainButton
                                type="button"
                                className="float-right"
                                light
                                onClick={() => setModalSelectorOpen(false)}
                            >
                                <Icon icon={faTimes} />
                            </MainButton>
                            <h1>{definition.onClick.selectTitle}</h1>
                        </ModalTitle>
                    ) : null}

                    {isLoading ? (
                        <Content>
                            <LoadingSpinner>Auswahlmöglichkeiten werden geladen ...</LoadingSpinner>
                        </Content>
                    ) : (
                        <>
                            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                            {!results || results.length < 1 ? (
                                <Content>
                                    <Alert light>Es wurden keine Datensätze gefunden.</Alert>
                                </Content>
                            ) : (
                                results.map((result, i) => {
                                    return (
                                        <SelectorElement key={i} data-index={i} onClick={onSelect}>
                                            {result.modelSelectorLabel ? result.modelSelectorLabel : null}
                                        </SelectorElement>
                                    );
                                })
                            )}
                        </>
                    )}
                </Modal>
            ) : null}
        </>
    );
};
