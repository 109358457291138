import React from "react";
import { Alert } from "@webtypen/react-components";
import { FormFields, FieldWrapper, FieldTitle, FieldBody } from "../FormFields";

export const Tabs = ({ field, path, setActive, isActive, disabled, moveControls }) => {
    return (
        <FieldWrapper isActive={isActive} disabled={disabled}>
            <FieldTitle onClick={disabled ? null : () => setActive(path)} isActive={isActive} disabled={disabled}>
                <div className="float-right">{moveControls}</div>
                <b>Tabs</b> <span className="path">Path: {path}</span>
            </FieldTitle>

            {!field.sections || field.sections.length < 1 ? (
                <FieldBody>
                    <Alert info className="mb-0">
                        Es wurden noch keine Tabs hinzugefügt
                    </Alert>
                </FieldBody>
            ) : (
                field.sections.map((section, i) => {
                    return (
                        <div key={i} className="pt-3 pb-3 pl-3 pr-3">
                            <div style={disabled ? { opacity: 0.5 } : null}>
                                <b>{section.title}</b>
                            </div>
                            <FormFields
                                fields={section.fields}
                                pathPrefix={path + ".sections[" + i.toString() + "].fields"}
                                setActive={setActive}
                                disabled={disabled}
                            />
                        </div>
                    );
                })
            )}
        </FieldWrapper>
    );
};
