import React from "react";
import { Alert } from "@webtypen/react-components";

export const ElementRenderer = (props) => {
    return (
        <>
            {typeof props.elements === "object" && props.elements ? (
                props.elements.map((element, i) => {
                    if (typeof element === "string") {
                        return element;
                    }

                    if (!element.type) {
                        return null;
                    }

                    if (!props.components[element.type] || !props.components[element.type].render) {
                        return (
                            <Alert key={i} danger>
                                Missing render-information for element-type `{element.type}`
                            </Alert>
                        );
                    }

                    return (
                        <React.Fragment key={i}>
                            {props.components[element.type].render(element, i, props.components)}
                        </React.Fragment>
                    );
                })
            ) : (
                <Alert danger>Fehler beim Anzeigen des Seiten-Elements</Alert>
            )}
        </>
    );
};
