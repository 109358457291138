import React, { useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Content,
    FloatInput,
    FloatSelect,
    Icon,
    Modal,
    ModalTitle,
    Table,
} from "@webtypen/react-components";
import { ConfirmHelper } from "../../../../helpers";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { SimpleTableBuilder } from "../../../../components/SimpleTableBuilder";

export const DynamicRecordsTableConfig = (props) => {
    const { data, setData } = props;
    const [edit, setEdit] = useState(null);
    const [currentEdit, setCurrentEdit] = useState(null);

    const add = async () => {
        const newLists = data.lists ? { ...data.lists } : {};
        const key = await ConfirmHelper.prompt("Eindeutiger interner Key/Name für diese Liste/Tabelle");
        if (!key || key.trim() === "") {
            return;
        }

        if (newLists[key]) {
            ConfirmHelper.alert("Es gibt bereits eine Liste/Tabelle mit diesem Key.");
            return;
        }

        newLists[key.trim()] = {
            type: "simpletable",
            key: key.trim(),
            name: "Neue Tabelle / Liste",
            cells: [],
        };

        setData({
            ...data,
            lists: newLists,
        });
    };

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onEdit = (e) => {
        const key = e.currentTarget.getAttribute("data-key");
        if (!key || !data.lists[key]) {
            ConfirmHelper.alert("Die ausgewählte Liste / Tabelle konnte nicht geladen werden ...");
            return;
        }

        setEdit(key);
        setCurrentEdit(JSON.parse(JSON.stringify(data.lists[key])));
    };

    const applyChanges = () => {
        const newData = { ...data };
        if (!newData.lists) {
            newData.lists = {};
        }

        newData.lists[edit] = currentEdit;
        setData({
            ...newData,
        });
        close();
    };

    const close = () => {
        setEdit(null);
        setCurrentEdit(null);
    };

    const listKeys = data.lists ? Object.keys(data.lists) : null;
    return !listKeys || listKeys.length < 1 ? (
        <Content className="pb-0">
            <Alert light>
                Es wurden noch keine Listen/Tabellen angelegt
                <br />
                <Button type="button" success xs onClick={add}>
                    <Icon icon={faPlus} className="mr-1" />
                    Liste/Tabelle hinzufügen
                </Button>
            </Alert>
        </Content>
    ) : (
        <>
            {edit && currentEdit ? (
                <Modal
                    open={true}
                    contentAttributes={{
                        style: {
                            maxWidth: 700,
                            display: "flex",
                            margin: "auto",
                            maxHeight: "95vh",
                            flexDirection: "column",
                        },
                    }}
                    onCloseRequest={close}
                >
                    <ModalTitle>
                        <Button type="button" className="float-right" secondary onClick={close}>
                            <Icon icon={faTimes} />
                        </Button>
                        <h1>Formular bearbeiten</h1>
                    </ModalTitle>
                    <div style={{ flex: 1, overflow: "auto", position: "relative" }}>
                        <Content>
                            <FloatInput
                                placeholder="Tabellen- / Listen-Name"
                                value={currentEdit.name}
                                onChange={(e) => setCurrentEdit({ ...currentEdit, name: e.target.value })}
                            />
                        </Content>
                        <SimpleTableBuilder
                            definition={currentEdit.cells}
                            onDefinitionChange={(data) => setCurrentEdit({ ...currentEdit, cells: data })}
                        />
                        <Button
                            type="button"
                            success
                            sm
                            style={{ position: "absolute", bottom: 15, right: 15 }}
                            onClick={applyChanges}
                            disabled={!currentEdit.name || currentEdit.name.trim() === "" ? true : false}
                        >
                            Änderungen anwenden
                        </Button>
                    </div>
                </Modal>
            ) : null}

            <Content className="pb-0">
                <FloatSelect
                    placeholder="Hauptformular"
                    value={data.defaultList ? data.defaultList : ""}
                    onChange={onChange}
                    name="defaultList"
                >
                    <option value="">-</option>
                    {listKeys
                        ? listKeys.map((el, i) => (
                              <option key={i} value={el}>
                                  {data.lists[el].name}
                              </option>
                          ))
                        : null}
                </FloatSelect>
            </Content>
            <Table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Tabellen- / Listen-Name</th>
                        <th className="text-right">
                            <Button type="button" success xs onClick={add}>
                                <Icon icon={faPlus} className="mr-1" />
                                Liste/Tabelle hinzufügen
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listKeys.map((key, i) => (
                        <tr key={i}>
                            <td>{key}</td>
                            <td>{data.lists[key].name}</td>
                            <td className="text-right">
                                <ButtonGroup>
                                    <Button type="button" xs danger disabled>
                                        <Icon icon={faTrashAlt} />
                                    </Button>
                                    <Button type="button" xs onClick={onEdit} data-key={key}>
                                        Bearbeiten
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};
