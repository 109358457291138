import React from "react";
import { FieldSelector } from "../FieldSelector";

export const EditMap = (props) => {
    const { active, fieldDefinition } = props;

    const prependFields = { ...fieldDefinition.definition };
    // const fieldsInForm = getFieldsInForm([...field.form]);
    // for (let i in prependFields) {
    //     if (fieldsInForm.includes(i)) {
    //         prependFields[i] = {
    //             ...prependFields[i],
    //             disabled: true,
    //         };
    //     }
    // }

    return (
        <div className="mt-2">
            <b>Felder hinzufügen</b>
            <FieldSelector
                justComponents
                pathPrefix={active + ".form"}
                prependFields={prependFields}
                parentDefinitionPath={
                    props.fieldDefinition.type === "map" || props.fieldDefinition.type === "object"
                        ? props.field.field + ".definition"
                        : null
                }
            />
        </div>
    );
};
