import React from "react";
import { Alert, Badge, Button, ButtonGroup, Content, Icon, Table } from "@webtypen/react-components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const DynamicRecordsFields = (props) => {
    const { isSaving, setCurrentField, removeField, data } = props;

    const fieldKeys = data && data.fields ? Object.keys(data.fields) : null;
    return (
        <>
            {fieldKeys && fieldKeys.length > 0 ? (
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: 100 }}>Typ</th>
                            <th style={{ width: 200 }}>Feld-Key</th>
                            <th>Bezeichnung</th>
                            <th>
                                <Button
                                    type="button"
                                    success
                                    xs
                                    onClick={() => setCurrentField("__new__")}
                                    className="float-right"
                                    disabled={isSaving}
                                >
                                    <Icon icon={faPlus} className="mr-1" />
                                    Feld hinzufügen
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fieldKeys.map((key, i) => {
                            return (
                                <tr key={i}>
                                    <td>{data.fields[key].type}</td>
                                    <td>
                                        {key}

                                        {data.fields[key].subType && data.fields[key].subType.trim() !== "" ? (
                                            <Badge
                                                light
                                                className="ml-1"
                                                style={{
                                                    color: "rgba(0,0,0,0.3)",
                                                    userSelect: "none",
                                                }}
                                            >
                                                {data.fields[key].subType}
                                            </Badge>
                                        ) : null}
                                    </td>
                                    <td>
                                        {data.fields[key].label && data.fields[key].label.trim() !== ""
                                            ? data.fields[key].label
                                            : key}
                                    </td>
                                    <td className="text-right">
                                        {data.fields[key].origin === undefined ||
                                        data.fields[key].origin === null ||
                                        data.fields[key].origin === false ? (
                                            <ButtonGroup>
                                                <Button
                                                    type="button"
                                                    primary
                                                    xs
                                                    onClick={() => setCurrentField(key)}
                                                    disabled={isSaving}
                                                >
                                                    Bearbeiten
                                                </Button>
                                                <Button
                                                    type="button"
                                                    danger
                                                    xs
                                                    onClick={() => removeField(key)}
                                                    disabled={isSaving}
                                                >
                                                    Entfernen
                                                </Button>
                                            </ButtonGroup>
                                        ) : (
                                            <ButtonGroup>
                                                <Button type="button" xs outline secondary disabled>
                                                    Definiert durch: {data.fields[key].origin}
                                                </Button>
                                                {/* {data.fields[key].type === "map" ? (
                                        <Button type="button" xs disabled={isSaving}>
                                            Map ergänzen
                                        </Button>
                                    ) : null} */}
                                            </ButtonGroup>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <Content>
                    <Alert light className="mb-0">
                        Es wurden noch keine Datenfelder angelegt
                        <br />
                        <Button
                            type="button"
                            success
                            xs
                            onClick={() => setCurrentField("__new__")}
                            disabled={isSaving}
                        >
                            <Icon icon={faPlus} className="mr-1" />
                            Feld hinzufügen
                        </Button>
                    </Alert>
                </Content>
            )}
        </>
    );
};
