import React from "react";
import styled from "styled-components";
import { FooterContent } from "./FooterContent";
import { Logo } from "./Logo";

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    flex: 1;
    max-width: 400px;
    padding: 30px;
    background: #fff;

    @media (max-width: 767px) {
        max-width: 100%;
    }
`;

const Footer = styled.div`
    padding: 20px 30px;
    background: #fff;
    border-top: 1px solid #eaeaea;

    .links {
        text-align: center;
        font-size: 12px;
        float: left;

        a {
            color: ${(props) => props.theme.color.default};
        }
    }

    .copyright {
        text-align: right;
        font-size: 12px;
    }

    @media (max-width: 707px) {
        background: #f9f9f9;

        .links {
            float: initial;
            margin-bottom: 10px;
        }

        .copyright {
            text-align: center;
        }
    }
`;

export const LayoutGuest = (props) => {
    return (
        <Wrapper>
            <Container>
                <Logo style={{ textAlign: "left", fontSize: 22 }} className="mb-0" />
                <h1 className="mt-0 mb-5">{props.title}</h1>

                {props.children}
            </Container>

            <Footer>
                <FooterContent />
            </Footer>
        </Wrapper>
    );
};
