import React, { useEffect, useState } from "react";
import { faDatabase, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Content,
    LoadingSpinner,
    FloatInput,
    Row,
    Col,
    Button,
    Modal,
    FloatSelect,
    Tabs,
} from "@webtypen/react-components";
import { FormBuilder } from "../../../../components/FormBuilder/FormBuilder";
import { bindTooltip, ButtonLink, Icon, Input, Tooltip } from "@webtypen/react-components";
import { ApiHelper } from "../../../../helpers";
import { SettingTitle } from "../SettingTitle";
import { DynamicRecordsFieldEditor } from "./DynamicRecordsFieldEditor";
import { FloatIconSelector } from "../../../../components/FloatIconSelector";
import { fieldTypes } from "../../../../components/FormBuilder/FieldSelector";
import { DynamicRecordsFields } from "./DynamicRecordsFields";
import { DynamicRecordsFormsLists } from "./DynamicRecordsFormsLists";
import { DynamicRecordsQueryForms } from "./DynamicRecordsQueryForms";
import { DynamicRecordsSettings } from "./DynamicRecordsSettings";
import { DynamicRecordsExports } from "./DynamicRecordsExports";
import { DynamicRecordsTableConfig } from "./DynamicRecordsTableConfig";

export const DynamicRecordsForm = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [types, setTypes] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [currentField, setCurrentField] = useState(null);
    const [currentForm, setCurrentForm] = useState(null);
    const [alert, setAlert] = useState(null);
    const [stats, setStats] = useState(null);
    const [remove, setRemove] = useState(null);
    const [isDeleting, setIsDeleting] = useState(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const [components, setComponents] = useState(null);
    const [stylingDefinition, setStylingDefinition] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const onIconChange = (icon) => {
        setData({
            ...data,
            icon: icon,
        });
    };

    const setField = (oldFieldKey, newFieldKey, fieldData) => {
        if (newFieldKey !== oldFieldKey) {
            const newFields = {};
            let add = true;
            for (let i in data.fields) {
                if (i === oldFieldKey) {
                    newFields[newFieldKey] = { ...fieldData };
                    add = false;
                } else {
                    newFields[i] = { ...data.fields[i] };
                }
            }

            if (add) {
                newFields[newFieldKey] = { ...fieldData };
            }

            setData({
                ...data,
                fields: newFields,
            });
        } else {
            setData({
                ...data,
                fields: {
                    ...data.fields,
                    [newFieldKey]: fieldData,
                },
            });
        }
    };

    const removeField = (fieldKey) => {
        if (window.confirm("Soll das Feld `" + fieldKey + "` wirklich entfernt werden?")) {
            const newFields = {};
            for (let i in data.fields) {
                if (i !== fieldKey) {
                    newFields[i] = { ...data.fields[i] };
                }
            }

            setData({
                ...data,
                fields: newFields,
            });
        }
    };

    const setForm = (formKey, formData) => {
        setData({
            ...data,
            forms: {
                ...data.forms,
                [formKey]: { ...formData },
            },
        });
        closeModal();
    };

    const closeModal = () => {
        setCurrentField(null);
        setCurrentForm(null);
    };

    const deleteType = (e) => {
        setAlert(null);
        setIsDeleted(false);
        setIsDeleting(true);

        ApiHelper.request("/api/settings/dynamic-records/delete", {
            key: props.match.params.recordType,
        })
            .then((response) => {
                if (response.status === "success") {
                    setIsDeleted(true);
                    setIsDeleting(false);
                } else {
                    setAlert([
                        "danger",
                        "Es ist ein unerwarteter Fehler beim Entfernen der Datenstruktur aufgetreten.",
                    ]);
                    setIsDeleted(false);
                    setIsDeleting(false);
                }
            })
            .catch((e) => {
                setAlert(["danger", "Es ist ein unerwarteter Fehler beim Entfernen der Datenstruktur aufgetreten."]);
                setIsDeleting(false);
                setIsDeleted(false);
            });
    };

    const copyForm = (key) => {
        const newKey = window.prompt("Formular-Key des neuen Formulars:");
        if (!newKey || newKey.trim() === "" || !data.forms[key]) {
            return;
        }

        if (data.forms[newKey]) {
            window.alert("Es gibt bereits ein Formular mit diesem Key");
            return;
        }

        const newForm = { ...data.forms[key] };
        if (newForm.isPreset !== undefined) {
            delete newForm.isPreset;
        }

        setData({
            ...data,
            forms: {
                ...data.forms,
                [newKey]: newForm,
            },
        });
    };

    const removeForm = (key) => {
        if (key === data.mainForm) {
            window.alert("Das Hauptformular kann nicht entfernt werden.");
            return;
        }

        if (!window.confirm("Soll das Formular `" + key + "` wirklich entfernt werden?")) {
            return;
        }

        const forms = {};
        for (let i in data.forms) {
            if (i !== key) {
                forms[i] = { ...data.forms[i] };
            }
        }

        setData({
            ...data,
            forms: forms,
        });
    };

    const submit = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/settings/dynamic-records/save", {
            ...data,
            orgKey: props.match.params.recordType,
        })
            .then((response) => {
                if (response.status === "success") {
                    setAlert(["success", "Die Datenstruktur wurde erfolgreich gespeichert"]);
                } else {
                    setAlert(["danger", "Es ist ein Fehler beim Speichern der Datenstruktur aufgetreten."]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein Fehler beim Speichern der Datenstruktur aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    const load = () => {
        setIsLoading(true);
        ApiHelper.request("/api/settings/dynamic-records/details", {
            type: props.match.params.recordType,
        })
            .then((response) => {
                setData(
                    response.data && response.data.type && response.data.type.key === props.match.params.recordType
                        ? response.data.type
                        : null
                );
                setStats(response.data && response.data.stats);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setData(null);
                setIsLoading(false);

                if (e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== "") {
                    setError(e.data.message);
                }
            });
    };

    useEffect(() => {
        ApiHelper.request("/api/settings/dynamic-records/types").then((response) => {
            setTypes(response.status === "success" && response.data.types ? response.data.types : null);

            load();
        });

        ApiHelper.request("/api/settings/dynamic-records/styling-definitions").then((response) => {
            setStylingDefinition(
                response.status === "success" && response.data && Object.keys(response.data).length > 0
                    ? response.data
                    : null
            );
        });

        const components = {};
        for (let i in fieldTypes) {
            if (fieldTypes[i].isComponent) {
                components[i] = fieldTypes[i];
            }
        }
        setComponents(components);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Content>
            <SettingTitle
                icon={faDatabase}
                title={
                    "Datenstruktur " +
                    (remove !== null
                        ? "entfernen"
                        : props.match.params.recordType === "__new__"
                        ? "anlegen"
                        : "bearbeiten")
                }
                backLink={"/env/" + props.match.params._env + "/settings/dynamic-records"}
            />

            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
            {isLoading ? (
                <LoadingSpinner>Datenstruktur-Informationen werden geladen ...</LoadingSpinner>
            ) : error ? (
                <Alert danger>{error}</Alert>
            ) : !data ? (
                <Alert danger>Es ist ein Fehler beim Laden der Datenstruktur-Informationen aufgetreten.</Alert>
            ) : remove !== null ? (
                isDeleted ? (
                    <>
                        <div className="font-weight-bold text-success">Datenstruktur entfernt</div>
                        Sie haben die Datenstruktur <b>{data.key}</b> erfolgreich entfernt
                        <div className="mt-1">
                            <ButtonLink
                                to={"/env/" + props.match.params._env + "/settings/dynamic-records"}
                                outline
                                secondary
                            >
                                Zurück zur Übersicht
                            </ButtonLink>
                        </div>
                    </>
                ) : (
                    <>
                        Soll die Datenstruktur wirklich entfernt werden?
                        <br />
                        Dieser Schritt kann nicht rückgängig gemacht werden!
                        <div className="mt-3">
                            Bestätigen durch die Eingabe von <b>{data.key}</b>:
                            <Input
                                type="text"
                                className="text-danger"
                                placeholder="Sicherheitsprüfung"
                                value={remove}
                                onChange={(e) => setRemove(e.target.value)}
                                disabled={isDeleting}
                            />
                            <Button
                                type="button"
                                danger
                                outline
                                className="mt-1 mr-2"
                                onClick={deleteType}
                                disabled={isDeleting || remove.trim() !== data.key.trim()}
                            >
                                {isDeleting ? (
                                    <>
                                        <LoadingSpinner className="mr-1" /> wird entfernt ...
                                    </>
                                ) : (
                                    "Datenstruktur entfernen"
                                )}
                            </Button>
                            <Button
                                type="button"
                                light
                                className="mt-1"
                                onClick={() => setRemove(null)}
                                disabled={isDeleting}
                            >
                                Abbrechen
                            </Button>
                        </div>
                    </>
                )
            ) : (
                <>
                    <Modal
                        open={currentField || currentForm ? true : false}
                        onCloseRequest={closeModal}
                        contentAttributes={{ style: { maxWidth: 800, margin: "30px auto" } }}
                        wrapperAttributes={currentForm ? { style: { width: "calc(100% - 500px)" } } : null}
                    >
                        {currentField ? (
                            <DynamicRecordsFieldEditor
                                fieldKey={currentField}
                                fields={data.fields}
                                close={closeModal}
                                setField={setField}
                            />
                        ) : currentForm ? (
                            <Content>
                                <Button type="button" light className="float-right" onClick={closeModal}>
                                    <Icon icon={faTimes} className="mr-1" />
                                    Schließen
                                </Button>
                                <h1>Fomular {data.forms[currentForm].isPreset ? "ansehen" : "bearbeiten"}</h1>
                                <FormBuilder
                                    form={data.forms[currentForm]}
                                    fields={[
                                        {
                                            groupTitle: "Felder",
                                            fields: data.fields,
                                        },
                                        {
                                            groupTitle: "Komponenten",
                                            multiple: true,
                                            fields: components,
                                        },
                                    ]}
                                    stylingDefinitions={stylingDefinition}
                                    onSave={(form) => setForm(currentForm, form)}
                                />
                            </Content>
                        ) : null}
                    </Modal>

                    <form onSubmit={submit}>
                        <Row>
                            <Col width={2} className="mb-2">
                                <FloatInput
                                    placeholder="Struktur-Key"
                                    name="key"
                                    value={data.key}
                                    onChange={onChange}
                                    disabled={true}
                                />
                            </Col>
                            <Col width={3} className="mb-2">
                                <FloatSelect
                                    placeholder="Eltern-Struktur"
                                    name="parent"
                                    value={data.parent ? data.parent : ""}
                                    onChange={onChange}
                                    disabled={true}
                                >
                                    <option value="">Keine Elternstruktur</option>
                                    {types && types.length > 0
                                        ? types.map((t, i) => {
                                              if (t.key === props.match.params.recordType) {
                                                  return null;
                                              }

                                              return (
                                                  <option key={i} value={t.key}>
                                                      {t.plural} ({t.key})
                                                  </option>
                                              );
                                          })
                                        : null}
                                </FloatSelect>
                            </Col>
                        </Row>

                        <Row>
                            <Col width={5} className="mb-3">
                                <FloatInput
                                    placeholder="Bezeichnung Singular"
                                    name="singular"
                                    value={data.singular}
                                    onChange={onChange}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col width={5} className="mb-3">
                                <FloatInput
                                    placeholder="Bezeichnung Plural"
                                    name="plural"
                                    value={data.plural}
                                    onChange={onChange}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col width={2} className="mb-3">
                                <FloatIconSelector
                                    placeholder="Icon"
                                    onChange={onIconChange}
                                    value={data.icon}
                                    disabled={isSaving}
                                />
                            </Col>
                        </Row>

                        <Tabs
                            className="mb-3"
                            withRouter={true}
                            sections={{
                                fields: {
                                    title: "Datenfelder",
                                    path:
                                        "/env/" +
                                        props.match.params._env +
                                        "/settings/dynamic-records/" +
                                        props.match.params.recordType +
                                        "/edit",
                                    exact: true,
                                    render: () => (
                                        <DynamicRecordsFields
                                            isSaving={isSaving}
                                            setCurrentField={setCurrentField}
                                            removeField={removeField}
                                            data={data}
                                        />
                                    ),
                                },
                                forms: {
                                    title: "Formulare",
                                    path:
                                        "/env/" +
                                        props.match.params._env +
                                        "/settings/dynamic-records/" +
                                        props.match.params.recordType +
                                        "/edit/forms",
                                    exact: true,
                                    render: () => (
                                        <DynamicRecordsFormsLists
                                            isSaving={isSaving}
                                            onChange={onChange}
                                            setCurrentForm={setCurrentForm}
                                            copyForm={copyForm}
                                            removeForm={removeForm}
                                            data={data}
                                        />
                                    ),
                                },
                                tables: {
                                    title: "Tabellen",
                                    path:
                                        "/env/" +
                                        props.match.params._env +
                                        "/settings/dynamic-records/" +
                                        props.match.params.recordType +
                                        "/edit/tables",
                                    exact: true,
                                    render: () => (
                                        <DynamicRecordsTableConfig
                                            isSaving={isSaving}
                                            onChange={onChange}
                                            setCurrentForm={setCurrentForm}
                                            copyForm={copyForm}
                                            removeForm={removeForm}
                                            data={data}
                                            setData={setData}
                                        />
                                    ),
                                },
                                exports: data.hasExports
                                    ? {
                                          title: "Exporte",
                                          path:
                                              "/env/" +
                                              props.match.params._env +
                                              "/settings/dynamic-records/" +
                                              props.match.params.recordType +
                                              "/edit/exports",
                                          render: () => (
                                              <DynamicRecordsExports
                                                  isSaving={isSaving}
                                                  data={data}
                                                  setData={setData}
                                                  load={load}
                                                  match={props.match}
                                              />
                                          ),
                                      }
                                    : null,
                                queryForms: data.hasQueryForms
                                    ? {
                                          title: "Abfrage-Formulare",
                                          path:
                                              "/env/" +
                                              props.match.params._env +
                                              "/settings/dynamic-records/" +
                                              props.match.params.recordType +
                                              "/edit/query-forms",
                                          exact: true,
                                          render: () => (
                                              <DynamicRecordsQueryForms
                                                  isSaving={isSaving}
                                                  data={data}
                                                  setData={setData}
                                              />
                                          ),
                                      }
                                    : null,
                                settings: data.settings
                                    ? {
                                          title: "Einstellungen",
                                          path:
                                              "/env/" +
                                              props.match.params._env +
                                              "/settings/dynamic-records/" +
                                              props.match.params.recordType +
                                              "/edit/settings",
                                          exact: true,
                                          render: () => (
                                              <DynamicRecordsSettings
                                                  isSaving={isSaving}
                                                  data={data}
                                                  setData={setData}
                                              />
                                          ),
                                      }
                                    : null,
                            }}
                        />

                        <div className="text-right mt-2">
                            {stats && stats.count < 1 ? (
                                <Button type="button" light className="text-danger mr-2" onClick={() => setRemove("")}>
                                    Datenstruktur löschen
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        type="button"
                                        light
                                        className="text-danger mr-2"
                                        style={{
                                            background: "#f8f9fa",
                                            cursor: "default",
                                            border: "1px solid #f8f9fa",
                                        }}
                                        {...bindTooltip("tooltip-remove-hint")}
                                    >
                                        Datenstruktur löschen
                                    </Button>
                                    <Tooltip id="tooltip-remove-hint">
                                        Es gibt Datensätze dieser Datenstruktur. Dieses müssen entfernt werden, bevor
                                        die Datenstruktur gelöscht werden kann.
                                    </Tooltip>
                                </>
                            )}
                            <Button success disabled={isSaving}>
                                {isSaving ? <LoadingSpinner /> : "Datenstruktur speichern"}
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </Content>
    );
};
