import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Content, Icon } from "@webtypen/react-components";
import { DynamicRecordsFieldEditorForm } from "./DynamicRecordsFieldEditorForm";

export const validations = {
    required: {
        label: "required",
        type: "boolean",
        default: true,
        errorMessage: "Dieses Feld muss ausgefüllt werden",
    },
    unique: {
        label: "unique",
        type: "boolean",
        default: true,
        errorMessage: "Es gibt bereits einen anderen Datensatz mit diesem Wert",
    },
    minLength: {
        label: "minLength",
        type: "number",
        default: "1",
        errorMessage: "Es müssen mindestens {validationValue} Zeichen angegeben werden",
    },
    maxLength: {
        label: "maxLength",
        type: "number",
        default: "10",
        errorMessage: "Es dürfen maximal {validationValue} Zeichen angegeben werden",
    },
    min: {
        label: "min",
        type: "float",
        default: "1",
        errorMessage: "Der Wert muss mindestens {validationValue} betragen",
    },
    max: {
        label: "max",
        type: "float",
        default: "10",
        errorMssage: "Der Wert darf maximal {validationValue} betragen",
    },
    minDate: {
        label: "minDate",
        type: "date",
        default: "",
        errorMessage: "Der Wert muss mindestens {validationValue} betragen",
    },
    maxDate: {
        label: "maxDate",
        type: "date",
        default: "",
        errorMessage: "Der Wert darf maximal {validationValue} betragen",
    },
    email: {
        label: "email",
        type: "boolen",
        default: true,
        errorMessage: "Es muss eine gültige E-Mail Adresse angegeben werden",
    },
    iban: {
        label: "IBAN",
        type: "boolean",
        default: true,
        errorMessage: "Es muss eine gültige IBAN angegeben werden",
    },
    bic: {
        label: "BIC",
        type: "boolean",
        default: true,
        errorMessage: "Es muss eine gültige BIC angegeben werden",
    },
    url: {
        label: "URL",
        type: "boolean",
        default: true,
        errorMessage: "Es muss eine gültige URL angegeben werden",
    },
};

export const types = {
    string: {
        default: "",
        validationRules: ["required", "unique", "minLength", "maxLength", "email", "iban", "bic", "url"],
    },
    date: {
        default: "",
        validationRules: ["required", "unique", "minDate", "maxDate"],
    },
    datetime: {
        default: "",
        validationRules: ["required", "unique", "minDate", "maxDate"],
    },
    integer: {
        default: "",
        validationRules: ["required", "unique", "min", "max"],
    },
    float: {
        default: "",
        validationRules: ["required", "unique", "min", "max"],
    },
    map: {
        default: [],
        validationRules: ["required", "min", "max"],
        definitionTypes: ["string", "date", "datetime", "integer", "float"],
        getValidationError: (field) => {
            if (!field.definition || Object.keys(field.definition).length < 1) {
                return "Es müssen Kind-Felder angegeben werden";
            }
            return null;
        },
    },
    model: {
        default: "",
        validationRules: ["required"],
    },
    models: {
        default: [],
        validationRules: ["required"],
    },
    files: {
        default: [],
        validationRules: ["required"],
    },
};

export const DynamicRecordsFieldEditor = (props) => {
    const saveField = (fieldKey, field) => {
        const { key, ...rest } = field;
        props.setField(fieldKey, key, rest);

        if (props.close) {
            props.close();
        }
    };

    return (
        <Content>
            <Button type="button" light className="float-right" onClick={props.close}>
                <Icon icon={faTimes} className="mr-1" /> Schließen
            </Button>
            <h1>
                {props.fieldKey === "__new__" ? (
                    "Neues Feld anlegen"
                ) : (
                    <>
                        Feld <i>{props.fieldKey}</i> bearbeiten
                    </>
                )}
            </h1>

            <DynamicRecordsFieldEditorForm
                fields={props.fields}
                fieldKey={props.fieldKey}
                isMain={true}
                onSave={saveField}
            />
        </Content>
    );
};
