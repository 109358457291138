import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutogrowTextarea, FloatTextareaAutogrow } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const TextField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));
    const useCustomValue = props.customValueStore ? true : false;
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    if (props.flat) {
        return (
            <AutogrowTextarea
                type="text"
                placeholder={props.field.label}
                value={
                    useCustomValue
                        ? customValue === undefined || customValue === null
                            ? ""
                            : customValue
                        : value === undefined || value === null
                            ? ""
                            : value
                }
                onChange={onChange}
                hasError={props.hasError}
                {...props.attributes}
            />
        );
    }

    return (
        <FloatTextareaAutogrow
            type="text"
            placeholder={props.field.label}
            value={
                useCustomValue
                    ? customValue === undefined || customValue === null
                        ? ""
                        : customValue
                    : value === undefined || value === null
                        ? ""
                        : value
            }
            onChange={onChange}
            // hasError={props.hasError}
            style={props.definition && props.definition.style ? props.definition.style : null}
            {...props.attributes}
        />
    );
};
