import { faArrowLeft, faBars, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { reduxSetValue } from "../redux";
import { ButtonDownload } from "./ButtonDownload";
import { Button, ButtonLink, ButtonLinkExternal, Icon } from "@webtypen/react-components";

const Wrapper = styled.div`
    background: rgba(255, 255, 255, 0.6);
    padding: 30px;

    h1 {
        margin: 0px;
        padding: 0px;
        user-select: none;

        span {
            font-size: 22px;
            margin-left: 10px;
        }
    }

    @media (max-width: 767px) {
        padding: 0px;
        height: 50px;
        line-height: 50px;
        background: #fff;
        padding-left: 10px;
        padding-right: 0px;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;

        h1 {
            font-size: 20px;
            font-weight: 600;
            margin-left: 50px;

            span {
                font-size: 16px;
            }
        }

        ${(props) =>
            props.mobileActive
                ? `
                z-index: 900005;
        `
                : ``}
    }
`;

const MobileNavigationButton = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: block;
        float: left;
    }
`;

const MobileActionsButton = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: block;
        float: right;
        padding: 0px 15px;
        padding-left: 8px;
    }
`;

const MobileActionsBG = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: block;
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        transition: all 200ms;

        ${(props) => (props.mobileActive ? `opacity: 1;visibility: visible;z-index: 900005;` : ``)}
    }
`;

const BackLinkContainer = styled.div`
    float: right;

    .backlink-desktop {
        display: block;
    }

    .backlink-mobile {
        display: none;
    }

    @media (max-width: 767px) {
        .backlink-desktop {
            display: none;
        }

        .backlink-mobile {
            display: block;
            margin-top: 6px;
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
`;

const DesktopActions = styled.div`
    float: right;

    &:after {
        content: "";
        clear: both;
        display: none;
    }

    @media (max-width: 767px) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: #fff;
        width: 300px;
        left: 50%;
        margin-left: -150px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        bottom: -100%;
        transition: all 200ms;

        ${(props) =>
            props.mobileActive
                ? `
            box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 30px;
            bottom: 0px;
        `
                : ``}

        ${(props) =>
            props.mobileActive
                ? `
                z-index: 900005;
        `
                : ``}
    }
`;

const DesktopAction = styled.div`
    float: left;
    padding-left: 3px;

    @media (max-width: 767px) {
        float: initial;
        padding: 0px;

        button,
        button:hover,
        button:active,
        button:focus,
        a,
        a:hover,
        a:active,
        a:focus {
            border: none;
            border-radius: 0px;
            background: transparent;
            outline: none;
            box-shadow: none;
            margin: 0px;
            color: ${(props) => props.theme.color.default};
            display: block;
            padding: 15px 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.02);
            text-align: center;
            font-size: 16px;
            line-height: 16px;
        }

        button:hover,
        a:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    }
`;

export const PageTitle = (props) => {
    const dispatch = useDispatch();
    const [mobileActiveActions, setMobileActiveActions] = useState(false);

    const openMobileNavigation = () => {
        dispatch(reduxSetValue("mobileNavigation", true));
    };

    return (
        <Wrapper mobileActive={mobileActiveActions}>
            <MobileNavigationButton>
                <Button type="button" light onClick={openMobileNavigation}>
                    <Icon icon={faBars} />
                </Button>
            </MobileNavigationButton>

            {props.actions && props.actions.length > 0 ? (
                <>
                    <MobileActionsButton onClick={() => setMobileActiveActions(true)}>
                        <Icon icon={faEllipsisV} />
                    </MobileActionsButton>
                    <MobileActionsBG mobileActive={mobileActiveActions} onClick={() => setMobileActiveActions(false)} />
                </>
            ) : null}

            {props.backLink ? (
                <BackLinkContainer>
                    <ButtonLink
                        to={props.backLink}
                        secondary
                        outline
                        className="backlink-mobile"
                        style={{ marginLeft: 3 }}
                    >
                        <Icon icon={faArrowLeft} />
                    </ButtonLink>
                    <ButtonLink
                        to={props.backLink}
                        secondary
                        outline
                        className="backlink-desktop"
                        style={{ marginLeft: 3 }}
                    >
                        {props.backLinkLabel ? (
                            props.backLinkLabel
                        ) : (
                            <>
                                <Icon icon={faArrowLeft} /> Zurück
                            </>
                        )}
                    </ButtonLink>
                </BackLinkContainer>
            ) : null}

            <DesktopActions mobileActive={mobileActiveActions}>
                {props.actions && props.actions.length > 0
                    ? props.actions.map((action, i) => (
                          <DesktopAction key={i}>
                              {action && action.type === "button-link" ? (
                                  <ButtonLink {...action.attributes}>{action.label}</ButtonLink>
                              ) : action && action.type === "button-external" ? (
                                  <ButtonLinkExternal {...action.attributes}>{action.label}</ButtonLinkExternal>
                              ) : action && action.type === "button-download" ? (
                                  <ButtonDownload {...action.attributes}>{action.label}</ButtonDownload>
                              ) : null}
                          </DesktopAction>
                      ))
                    : null}
            </DesktopActions>

            <h1>
                {props.title}
                {props.subtitle ? <span>{props.subtitle}</span> : null}
            </h1>
        </Wrapper>
    );
};
