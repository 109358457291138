import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Icon, Button } from "@webtypen/react-components";
import { validations } from "./DynamicRecordsFieldEditor";

const Field = styled.div`
    float: left;
    position: relative;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

    .title {
        padding: 0px 13px;
        float: left;
        font-size: 14px;
        line-height: 40px;
        border: 1px solid #ddd;
        border-radius: 20px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
        height: 40px;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    input {
        float: left;
        display: block;
        width: 150px;
        padding: 3px 5px;
        border: 1px solid #ddd;
        border-right: none;
        height: 40px;
        line-height: 40px;
        outline: none;
        box-shadow: none;
    }

    .removebtn {
        border-radius: 20px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 9px;
        height: 40px;
        line-height: 40px;
    }
`;

export const DynamicRecordsFieldValidation = (props) => {
    const { value, validationKey } = props;
    const validation = validations[validationKey];

    const change = (e) => {
        props.changeValidation(e.target.name, e.target.value);
    };

    const remove = (e) => {
        e.preventDefault();

        props.removeValidation(e.currentTarget.getAttribute("data-validation"));
    };

    let input;
    if (validation.type === "number") {
        input = (
            <input
                type="number"
                className="text-right"
                style={{ width: 80 }}
                name={validationKey}
                value={value}
                onChange={change}
            />
        );
    }

    return (
        <Field>
            <div className="title">{validationKey}</div>
            {input}
            <Button type="button" danger className="removebtn" onClick={remove} data-validation={validationKey}>
                <Icon icon={faTrash} />
            </Button>
        </Field>
    );
};
