import React, { useState } from "react";
import { ApiHelper, ConfirmHelper } from "../helpers";
import { Button, ButtonGroup, Icon, LoadingSpinner, Modal } from "@webtypen/react-components";
import { faExclamationTriangle, faFileDownload, faTimes } from "@fortawesome/free-solid-svg-icons";

export const ButtonDownload = (props) => {
    const { children, ...rest } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showModalFile, setShowModalFile] = useState(false);
    const [previewDownloadLoading, setPreviewDownloadLoading] = useState(false);

    const onClick = () => {
        setIsLoading(true);
        setHasError(false);

        let src = props.src;
        if (src.substring(0, 1) === "/") {
            src =
                process.env.REACT_APP_API_URL.substring(process.env.REACT_APP_API_URL.length - 1) === "/" &&
                props.src.substring(0, 1) === "/"
                    ? process.env.REACT_APP_API_URL + props.src.substring(1)
                    : process.env.REACT_APP_API_URL + props.src;
        }

        ApiHelper.request(src, props.data, props.method ? props.method : "POST", {
            raw: true,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.url &&
                    response.data.url.trim() !== ""
                ) {
                    if (response.data.type === "pdf" && response.data.urlPreview) {
                        setShowModalFile(response.data.urlPreview);
                    } else {
                        window.open(response.data.url, "_blank");
                    }
                    setHasError(false);
                } else {
                    setHasError(true);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setHasError(false);
                setIsLoading(false);

                ConfirmHelper.alert("Es ist ein Fehler Vorbereiten des Downloads aufgetreten.");
            });
    };

    const onDownload = (e) => {
        e.stopPropagation();

        setPreviewDownloadLoading(true);
        ApiHelper.request(props.src, props.data, props.method ? props.method : "GET", {
            raw: true,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.url &&
                    response.data.url.trim() !== ""
                ) {
                    window.open(response.data.url, "_blank");
                } else {
                    ConfirmHelper.alert("Es ist ein Fehler beim Herunterladen aufgetreten.");
                }
                setPreviewDownloadLoading(false);
            })
            .catch((e) => {
                console.error(e);
                ConfirmHelper.alert("Es ist ein Fehler beim Herunterladen aufgetreten.");
                setPreviewDownloadLoading(false);
            });
    };

    const onPDFClose = () => {
        setShowModalFile(null);
    };

    return (
        <>
            {showModalFile ? (
                <Modal
                    open={true}
                    contentAttributes={{
                        style: {
                            display: "block",
                            height: "auto",
                            maxHeight: "auto",
                            position: "relative",
                            overflow: "visible",
                            top: "15px",
                        },
                    }}
                    onCloseRequest={onPDFClose}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: -40,
                            right: 0,
                            fontSize: 28,
                            zIndex: 50000,
                            cursor: "pointer",
                        }}
                    >
                        <ButtonGroup>
                            <Button type="button" light sm onClick={onDownload} disabled={previewDownloadLoading}>
                                {previewDownloadLoading ? <LoadingSpinner /> : <Icon icon={faFileDownload} />}
                            </Button>
                            <Button type="button" light sm onClick={onPDFClose}>
                                <Icon icon={faTimes} className="mr-1" />
                                Schließen
                            </Button>
                        </ButtonGroup>
                    </div>
                    <embed src={showModalFile} width="100%" height="100%" type="application/pdf" />
                </Modal>
            ) : null}

            <Button type="button" onClick={onClick} disabled={isLoading} {...rest}>
                {isLoading ? (
                    <LoadingSpinner className="mr-1" />
                ) : hasError ? (
                    <Icon className="mr-1" icon={faExclamationTriangle} />
                ) : null}
                {children}
            </Button>
        </>
    );
};
