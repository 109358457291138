import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Content,
    FloatInput,
    Icon,
    LoadingSpinner,
    Modal,
    ModalTitle,
} from "@webtypen/react-components";
import { ApiHelper, ConfirmHelper } from "../../../helpers";

const InterfaceSelectElement = styled.div`
    padding: 8px 12px;
    user-select: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.025);

    .interface-description {
        font-size: 11px;
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
`;

export const WebsiteManagerSettingsInterfaces = (props) => {
    const { data, setData } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [interfaces, setInterfaces] = useState(null);

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    };

    const add = (e) => {
        const key = e.currentTarget.getAttribute("data-key");

        const newData = { ...data };
        if (!newData.interfaces) {
            newData.interfaces = {};
        }

        if (newData.interfaces[key]) {
            ConfirmHelper.alert("Diese Schnittstelle wurde bereits hinzugefügt.");
            return;
        }

        newData.interfaces[key] = { config: {} };
        setData(newData);
        setIsAdding(false);
    };

    const onChange = (e) => {
        const int = e.target.getAttribute("data-interface");

        setData({
            ...data,
            interfaces: {
                ...data.interfaces,
                [int]: {
                    ...data.interfaces[int],
                    config: {
                        ...data.interfaces[int].config,
                        [e.target.name]: e.target.value,
                    },
                },
            },
        });
    };

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/interfaces").then((response) => {
            const newInterfaces = [];

            if (response.status === "success" && response.data && Object.keys(response.data).length > 0) {
                for (let i in response.data) {
                    newInterfaces.push({ key: i, ...response.data[i] });
                }
            }

            setInterfaces(newInterfaces && newInterfaces.length > 0 ? newInterfaces : null);
            setIsLoading(false);
        });
    }, []);

    const keys = data && data.interfaces ? Object.keys(data.interfaces) : null;
    return (
        <>
            {isAdding ? (
                <Modal
                    open={true}
                    contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 600 } }}
                    onCloseRequest={toggleAdding}
                >
                    <ModalTitle>
                        <Button type="button" className="float-right" secondary onClick={toggleAdding}>
                            <Icon icon={faTimes} />
                        </Button>
                        <h1>Schnittstelle hinzufügen</h1>
                    </ModalTitle>

                    {interfaces && interfaces.length > 0 ? (
                        interfaces.map((el, i) => {
                            return (
                                <InterfaceSelectElement key={i} onClick={add} data-key={el.key}>
                                    <div className="interface-name">{el.name}</div>
                                    {el.description ? (
                                        <div className="interface-description">{el.description}</div>
                                    ) : null}
                                </InterfaceSelectElement>
                            );
                        })
                    ) : (
                        <Content>
                            <Alert className="mb-0" light>
                                Es stehen aktuell keine Schnittstellen zu Verfügung.
                            </Alert>
                        </Content>
                    )}
                </Modal>
            ) : null}

            {isLoading ? (
                <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
            ) : keys && keys.length > 0 ? (
                keys.map((el, i) => {
                    const int = interfaces ? interfaces.find((check) => check.key === el) : null;
                    if (!int) {
                        return null;
                    }

                    const configKeys = int.configuration ? Object.keys(int.configuration) : null;
                    return (
                        <Card key={i} className="mb-2">
                            <CardHeader>{int.name}</CardHeader>
                            <CardBody>
                                {configKeys && configKeys.length > 0
                                    ? configKeys.map((key, x) => {
                                          const config = int.configuration[key];

                                          if (config.type === "string") {
                                              return (
                                                  <FloatInput
                                                      key={x}
                                                      placeholder={config.name ? config.name : key}
                                                      className="mb-2"
                                                      name={key}
                                                      value={
                                                          data.interfaces[int.key].config[key]
                                                              ? data.interfaces[int.key].config[key]
                                                              : ""
                                                      }
                                                      data-interface={int.key}
                                                      onChange={onChange}
                                                  />
                                              );
                                          }
                                          return <Alert danger>Unknown config-type `{config.type}`.</Alert>;
                                      })
                                    : null}
                            </CardBody>
                        </Card>
                    );
                })
            ) : (
                <Alert light>Es wurden noch keine Schnittstelle zur Webseiten-Konfiguration hinzugefügt.</Alert>
            )}

            <Button type="button" sm primary outline onClick={toggleAdding} disabled={isLoading}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Icon icon={faPlus} className="mr-1" />
                        Schnittstelle hinzufügen
                    </>
                )}
            </Button>
        </>
    );
};
