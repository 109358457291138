import React, { useEffect, useState } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Button,
    FloatInput,
    FloatSelect,
    Icon,
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownMenu,
    DropdownMenuElement,
} from "@webtypen/react-components";
import { DynamicRecordsFieldValidation } from "./DynamicRecordsFieldValidation";
import { types, validations } from "./DynamicRecordsFieldEditor";
import styled from "styled-components";

const ChildrenEditor = styled.div`
    border-top: 1px solid #ddd;
    display: flex;

    .children-nav {
        width: 200px;
        border-right: 1px solid #ddd;
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
            padding: 8px 10px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            user-select: none;

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }

            .type-info {
                font-size: 12px;
            }

            &.active {
                color: #fff;
                background: ${(props) => props.theme.color.primary};
            }
        }
    }

    .children-content {
        padding: 15px;
    }
`;

export const DynamicRecordsFieldEditorForm = (props) => {
    const [isInited, setIsInited] = useState(false);
    const [field, setField] = useState(null);
    const [alert, setAlert] = useState(null);
    const [currentChild, setCurrentChild] = useState(null);

    const onChange = (e) => {
        setField({
            ...field,
            [e.target.name]: e.target.value,
        });
    };

    const validate = (f, fields, callback) => {
        if (!f.type || f.type.trim() === "") {
            setAlert(["danger", "Es muss ein Feld-Typ angegeben werden"]);
            return;
        }

        const type = types[f.type];
        if (!type) {
            setAlert("danger", "Unbekannter Feld-Typ");
            return;
        }

        const validationError = type.getValidationError ? type.getValidationError(f) : null;
        if (!f.key || f.key.trim() === "") {
            setAlert(["danger", "Es muss ein eindeutiger Feld-Key angegeben werden"]);
        } else if (!f.label || f.label.trim() === "") {
            setAlert(["danger", "Es muss eine Feld-Bezeichnung angegeben werden"]);
        } else if (validationError && validationError.trim() !== "") {
            setAlert(["danger", validationError]);
        } else {
            callback();
        }
    };

    const submit = (e) => {
        e.preventDefault();

        if (!props.onSave) {
            return;
        }

        validate(field, props.fields, () => {
            props.onSave(props.fieldKey, field);
        });
    };

    const addValidation = (e) => {
        const key = e.currentTarget.getAttribute("data-validation");
        const validation = validations[key];

        setField({
            ...field,
            validation: {
                ...field.validation,
                [key]: validation.default,
            },
        });
    };

    const changeValidation = (key, value) => {
        setField({
            ...field,
            validation: {
                ...field.validation,
                [key]: value,
            },
        });
    };

    const removeValidation = (key) => {
        const newValidation = {};
        for (let i in field.validation) {
            if (i !== key) {
                newValidation[i] = { ...field.validation };
            }
        }

        setField({
            ...field,
            validation: newValidation,
        });
    };

    const addDefinition = (e) => {
        e.stopPropagation();

        let key = null;
        const newDefinition = field.definition ? { ...field.definition } : {};
        while (key === null) {
            const check = window.prompt("Interner-Key des Feldes (eindeutig innerhalb der Definition)");
            if (check === undefined || check === null || check === false || check.trim() === "") {
                return;
            } else if (newDefinition[check] === undefined) {
                key = check;
                break;
            }
            window.alert("Es gibt bereits ein Feld mit diesem Key");
        }

        const type = e.currentTarget.getAttribute("data-type");
        newDefinition[key] = {
            type: type,
            label: "Neues Feld",
        };

        setField({
            ...field,
            definition: newDefinition,
        });
        setCurrentChild(key);
    };

    const applySubDefinition = (f, fieldKey) => {
        validate(f, field.definition, () => {
            let newCurrentChild = currentChild;
            let newDefinition = {};
            if (f.key !== fieldKey) {
                for (let i in field.definition) {
                    if (i === fieldKey) {
                        const { key, ...rest } = f;
                        newDefinition[f.key] = { ...rest };
                        newCurrentChild = f.key;
                    } else {
                        newDefinition[i] = field.definition[i];
                    }
                }
            } else {
                const { key, ...rest } = f;
                newDefinition = {
                    ...(field.definition ? field.definition : {}),
                    [fieldKey]: rest,
                };
            }

            setField({
                ...field,
                definition: newDefinition,
            });
            setCurrentChild(newCurrentChild);
        });
    };

    const changeCurrentChild = (e) => {
        setCurrentChild(e.currentTarget.getAttribute("data-child"));
    };

    useEffect(() => {
        if (props.fieldKey === "__new__") {
            setField({
                type: "string",
                key: "",
                label: "",
                validation: {},
            });
        } else if (props.fields) {
            setField({
                ...props.fields[props.fieldKey],
                key: props.fieldKey,
            });
        } else {
            setField(null);
        }
        setIsInited(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fieldKey]);

    if (!isInited) {
        return null;
    }

    if (!field) {
        return <Alert danger>Es ist ein Fehler beim Laden der Feld-Informationen aufgetreten</Alert>;
    }

    const validationKeys = field && field.validation ? Object.keys(field.validation) : null;
    const definitionKeys = field && field.definition ? Object.keys(field.definition) : null;
    const form = (
        <>
            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
            <Row>
                <Col width={6} className="mb-3">
                    <FloatSelect placeholder="Datentyp" onChange={onChange} name="type" value={field.type}>
                        {Object.keys(types).map((key) => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </FloatSelect>
                </Col>
                <Col width={6} className="mb-3">
                    <FloatInput placeholder="Eindeutiger Key" onChange={onChange} name="key" value={field.key} />
                </Col>
            </Row>
            {console.log(field.type, types[field.type], types)}
            <FloatInput
                placeholder="Bezeichnung"
                onChange={onChange}
                name="label"
                value={field.label}
                className="mb-3"
            />
            {types[field.type].validationRules && types[field.type].validationRules.length > 0 ? (
                <Card className="mb-3">
                    <CardBody>
                        <b>Validierungen:</b>{" "}
                        <Dropdown
                            right
                            trigger={
                                <Button type="button" outline secondary xs>
                                    <Icon icon={faCaretDown} className="mr-1" />
                                    Regel hinzufügen
                                </Button>
                            }
                        >
                            <DropdownMenu>
                                {types[field.type].validationRules.map((key, i) => {
                                    return (
                                        <DropdownMenuElement key={i} data-validation={key} onClick={addValidation}>
                                            <button type="button">{validations[key].label}</button>
                                        </DropdownMenuElement>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <div>
                            {validationKeys && validationKeys.length > 0 ? (
                                validationKeys.map((key) => (
                                    <DynamicRecordsFieldValidation
                                        key={key}
                                        validationKey={key}
                                        value={field.validation[key]}
                                        changeValidation={changeValidation}
                                        removeValidation={removeValidation}
                                    />
                                ))
                            ) : (
                                <Alert light className="mb-0">
                                    Es wurden noch keine Validierungsregeln für dieses Feld hinzugefügt
                                </Alert>
                            )}
                            <div style={{ clear: "both" }} />
                        </div>
                    </CardBody>
                </Card>
            ) : null}
            {types[field.type].definitionTypes && types[field.type].definitionTypes.length > 0 ? (
                <Card className="mb-3">
                    <CardBody>
                        <Dropdown
                            right
                            className="float-right"
                            trigger={
                                <Button type="button" outline secondary xs>
                                    <Icon icon={faCaretDown} className="mr-1" />
                                    Feld hinzufügen
                                </Button>
                            }
                        >
                            <DropdownMenu>
                                {types[field.type].definitionTypes.map((key, i) => {
                                    return (
                                        <DropdownMenuElement key={i} data-type={key} onClick={addDefinition}>
                                            <button type="button">{key}</button>
                                        </DropdownMenuElement>
                                    );
                                })}
                            </DropdownMenu>
                        </Dropdown>
                        <b>Kind-Felder:</b>
                    </CardBody>
                    {definitionKeys && definitionKeys.length > 0 ? (
                        <ChildrenEditor>
                            <ul className="children-nav">
                                {definitionKeys.map((key) => (
                                    <li
                                        key={key}
                                        className={currentChild === key ? "active" : null}
                                        onClick={changeCurrentChild}
                                        data-child={key}
                                    >
                                        {field.definition[key].label}
                                        <div className="type-info">
                                            {key} | {field.definition[key].type}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="children-content">
                                {currentChild ? (
                                    <DynamicRecordsFieldEditorForm
                                        fieldKey={currentChild}
                                        fields={field.definition}
                                        onSave={applySubDefinition}
                                    />
                                ) : null}
                            </div>
                        </ChildrenEditor>
                    ) : (
                        <Alert light className="mb-3 ml-3 mr-3">
                            Es wurden noch keine Felder hinzugefügt
                        </Alert>
                    )}
                </Card>
            ) : null}
            {props.onSave ? (
                <div className="text-right">
                    {props.isMain ? (
                        <Button success>Anwenden</Button>
                    ) : (
                        <Button success sm type="button" onClick={() => props.onSave(field, props.fieldKey)}>
                            Feld übernehmen
                        </Button>
                    )}
                </div>
            ) : null}
        </>
    );

    return props.isMain ? <form onSubmit={submit}>{form}</form> : form;
};
