import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatInput, FloatInputPrepend, Input } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const IntegerField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));
    const useCustomValue = props.customValueStore ? true : false;
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    if (props.flat) {
        return (
            <Input
                type="number"
                placeholder={props.field.label}
                value={
                    useCustomValue
                        ? customValue === undefined
                            ? ""
                            : customValue
                        : value === undefined
                        ? ""
                        : value !== undefined && value !== null
                        ? value
                        : ""
                }
                onChange={onChange}
                hasError={props.hasError}
                prepend={props.element && props.element.prepend ? props.element.prepend : null}
                append={props.element && props.element.append ? props.element.append : null}
                step="1"
                {...props.attributes}
            />
        );
    }

    return (
        <FloatInput
            type="number"
            placeholder={props.field.label}
            value={
                useCustomValue
                    ? customValue === undefined
                        ? ""
                        : customValue
                    : value === undefined
                    ? ""
                    : value !== undefined && value !== null
                    ? value
                    : ""
            }
            onChange={onChange}
            hasError={props.hasError}
            prepend={
                props.element && props.element.prepend ? (
                    <FloatInputPrepend>{props.element.prepend}</FloatInputPrepend>
                ) : null
            }
            append={props.element && props.element.append ? props.element.append : null}
            style={props.definition && props.definition.style ? props.definition.style : null}
            step="1"
            {...props.attributes}
        />
    );
};
