import { createGlobalStyle } from "styled-components";

export const GlobalStylesSimplebis = createGlobalStyle`
    body {
        font-weight: normal;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
    }

    h1 {
        font-size: 36px;
        font-weight: 900;
        color: #000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        font-family: 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
            Helvetica Neue, sans-serif;
    }

    h2 {
        font-size: 28px;
        font-family: 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
            Helvetica Neue, sans-serif;
        font-weight: 900;
    }

    .no-effect {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
`;
