import { reduxSetValue, store } from "../redux";

class ConfirmHelperWrapper {
    confirm(message, options) {
        if (!message) {
            return false;
        }

        return new Promise((resolve) => {
            store.dispatch(
                reduxSetValue("confirm", {
                    type: "confirm",
                    message: message,
                    options: options,
                    onConfirm: () => {
                        resolve(true);
                    },
                    onAbort: () => {
                        resolve(false);
                    },
                })
            );
        });
    }

    alert(message, options) {
        if (!message) {
            return false;
        }

        return new Promise((resolve) => {
            store.dispatch(
                reduxSetValue("confirm", {
                    type: "alert",
                    message: message,
                    options: options,
                    onConfirm: () => {
                        resolve(true);
                    },
                })
            );
        });
    }

    prompt(message, options) {
        if (!message) {
            return false;
        }

        return new Promise((resolve) => {
            store.dispatch(
                reduxSetValue("confirm", {
                    type: "prompt",
                    message: message,
                    options: options,
                    promptValue: options && options.default ? options.default : "",
                    onConfirm: (value) => {
                        resolve(value);
                    },
                    onAbort: () => {
                        resolve(null);
                    },
                })
            );
        });
    }
}

const ConfirmHelper = new ConfirmHelperWrapper();
export { ConfirmHelper };
