import React from "react";
import { Card, Content } from "@webtypen/react-components";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";

export const BookingsManagerStart = (props) => {
    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>Buchungsübersicht</h1>

            <Card>
                <SimpleTable
                    model="DynamicRecord"
                    where={[["type", "=", "booking"]]}
                    data={{
                        lookup: [
                            {
                                type: "dynamic_record",
                                local: "event",
                                foreign: "_id",
                                as: "event",
                            },
                            {
                                type: "dynamic_record",
                                local: "booking_table",
                                foreign: "tables.key",
                                as: "booking_table_element",
                            },
                        ],
                        select: {
                            name: 1,
                            email: 1,
                            phone: 1,
                            birthday: 1,
                            table: 1,
                            event: {
                                name: 1,
                                begin: 1,
                            },
                            booking_table: 1,
                            booking_table_element: 1,
                        },
                    }}
                >
                    <SimpleTableCell label="Veranstaltung" mapping="event.name" searchable sortable />
                    <SimpleTableCell label="Name" mapping="name" searchable sortable />
                    <SimpleTableCell label="E-Mail" mapping="email" searchable sortable />
                    <SimpleTableCell label="Telefon" mapping="phone" searchable sortable />
                    <SimpleTableCell label="Geburtstag" mapping="birthday" searchable sortable />
                    <SimpleTableCell
                        label="Tisch"
                        mapping="booking_table"
                        render={(row) => {
                            if (
                                row.booking_table_element &&
                                row.booking_table_element.tables &&
                                row.booking_table_element.tables.length > 0
                            ) {
                                const table = row.booking_table_element.tables.find(
                                    (el) => el.key === row.booking_table
                                );
                                if (table) {
                                    return table.name;
                                }
                            }

                            return "-";
                        }}
                    />
                    <SimpleTableCell
                        label="Begleitpersonen"
                        mapping="persons"
                        format="array-length"
                        attributes={{ className: "text-right" }}
                    />
                </SimpleTable>
            </Card>
        </Content>
    );
};
