import React from "react";
import { useSelector } from "react-redux";
import { Alert, Content, Page } from "@webtypen/react-components";
import { FileManager } from "@webtypen/react-filemanager";
import { PageTitle } from "../../../components";
import { ApiHelper, ConfirmHelper, FilemanagerHelper } from "../../../helpers";

export const FilemanagerScreen = (props) => {
    const mainDirectory = useSelector((state) => state.app.filemanagerDir);

    return (
        <Page fullHeight style={{ display: "flex", flexDirection: "column" }}>
            <PageTitle title="Dateien" />

            <div style={{ flex: 1 }}>
                {!mainDirectory ? (
                    <Content>
                        <Alert danger>
                            Hauptzereichnis konnte nicht geladen werden ... Bitte den Support kontakieren!
                        </Alert>
                    </Content>
                ) : (
                    <FileManager
                        baseUrl={"/env/" + props.match.params._env + "/files"}
                        mainDirectory={mainDirectory}
                        sidebar={true}
                        filemanagerKey="envFilemanager"
                        alert={(message, options) => {
                            ConfirmHelper.alert(message, options);
                        }}
                        api={{
                            directory: (params, onSuccess, onError) => {
                                ApiHelper.request("/api/filemanager/directory", params)
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            createDirectory: async (params, onSuccess, onError) => {
                                const name = await ConfirmHelper.prompt("Name des neuen Ordners");
                                if (!name || name.trim() === "") {
                                    return;
                                }

                                ApiHelper.request("/api/filemanager/actions/create-directory", {
                                    ...params,
                                    name: name,
                                })
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            rename: async (params, onSuccess, onError) => {
                                const newName = await ConfirmHelper.prompt(
                                    params && params.type === "file"
                                        ? "Datei umbenennen"
                                        : params && params.type === "dir"
                                        ? "Verzeichnis umbenennen"
                                        : "Umbenennen",
                                    {
                                        default: params && params.oldName ? params.oldName : "",
                                    }
                                );
                                if (!newName || newName.trim() === "") {
                                    return;
                                }

                                ApiHelper.request("/api/filemanager/actions/rename", {
                                    type: params.type,
                                    id: params._id,
                                    name: newName,
                                })
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            delete: async (params, onSuccess, onError) => {
                                const confirm = await ConfirmHelper.confirm(
                                    "Soll die Auswahl wirklich entfernt werden?",
                                    {
                                        description:
                                            "Die Dateien können nicht wiederhergestellt werden und sind somit unwiderruflich gelöscht.",
                                        confirmLabel: "Ja, löschen",
                                        confirmAttributes: { color: "danger" },
                                        abortLabel: "Abbrechen",
                                    }
                                );
                                if (!confirm) {
                                    return;
                                }

                                ApiHelper.request("/api/filemanager/actions/delete", params)
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            download: async (params, onSuccess, onError) => {
                                ApiHelper.request("/api/filemanager/actions/download", params)
                                    .then((response) => {
                                        onSuccess(response);
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                            upload: async (params, onSuccess, onError) => {
                                FilemanagerHelper.startUpload(
                                    params,
                                    () => {
                                        onSuccess();
                                    },
                                    () => {
                                        onError();
                                    }
                                );
                            },
                            tree: async (params, onSuccess, onError) => {
                                ApiHelper.request("/api/filemanager/tree", params)
                                    .then((response) => {
                                        if (response.status === "success") {
                                            onSuccess(response);
                                        } else {
                                            onError();
                                        }
                                    })
                                    .catch((e) => {
                                        onError();
                                    });
                            },
                        }}
                    />
                )}
            </div>
        </Page>
    );
};
