import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content, Modal, Button, Input } from "@webtypen/react-components";
import { reduxSetValue } from "../redux";

export const ConfirmWrapper = (props) => {
    const dispatch = useDispatch();
    const confirm = useSelector((state) => state.app.confirm);

    const onConfirm = () => {
        dispatch(reduxSetValue("confirm", null));

        if (confirm.type === "prompt") {
            confirm.onConfirm(confirm.promptValue);
        } else {
            confirm.onConfirm();
        }
    };

    const onAbort = () => {
        dispatch(reduxSetValue("confirm", null));

        if (confirm.type === "prompt") {
            confirm.onAbort(confirm.promptValue);
        } else {
            confirm.onAbort();
        }
    };

    const onPromptChange = (e) => {
        dispatch(
            reduxSetValue("confirm", {
                ...confirm,
                promptValue: e.target.value,
            })
        );
    };

    const onPromptKeyDown = (e) => {
        if (e && e.keyCode === 13) {
            onConfirm();
        }
    };

    if (!confirm) {
        return null;
    }

    if (confirm.type === "confirm" || confirm.type === "alert") {
        return (
            <Modal open={true} contentAttributes={{ style: { maxWidth: "500px", margin: "auto" } }}>
                <Content style={{ userSelect: "none" }}>
                    <h1 style={{ fontSize: 22 }} className="mb-0 mt-0">
                        {confirm.message}
                    </h1>
                    {confirm.options && confirm.options.description ? (
                        <div className="mt-2">{confirm.options.description}</div>
                    ) : null}

                    <div className="mt-2 text-right">
                        {confirm.type === "confirm" ? (
                            <Button
                                type="button"
                                color="secondary"
                                className="mr-1"
                                onClick={onAbort}
                                {...(confirm.options && confirm.options.abortAttributes
                                    ? confirm.options.abortAttributes
                                    : {})}
                            >
                                {confirm.options && confirm.options.abortLabel ? confirm.options.abortLabel : "Nein"}
                            </Button>
                        ) : null}
                        <Button
                            type="button"
                            color={confirm.type === "confirm" ? "success" : "secondary"}
                            onClick={onConfirm}
                            {...(confirm.options && confirm.options.confirmAttributes
                                ? confirm.options.confirmAttributes
                                : {})}
                        >
                            {confirm.options && confirm.options.confirmLabel
                                ? confirm.options.confirmLabel
                                : confirm.type === "confirm"
                                ? "Ja"
                                : "Okay"}
                        </Button>
                    </div>
                </Content>
            </Modal>
        );
    }

    if (confirm.type === "prompt") {
        return (
            <Modal open={true} contentAttributes={{ style: { maxWidth: "500px", margin: "auto" } }}>
                <Content style={{ userSelect: "none" }}>
                    <h1 style={{ fontSize: 22 }} className="mb-0 mt-0">
                        {confirm.message}
                    </h1>
                    {confirm.options && confirm.options.description ? (
                        <div className="mt-2">{confirm.options.description}</div>
                    ) : null}

                    <Input
                        type="text"
                        value={confirm.promptValue}
                        onChange={onPromptChange}
                        onKeyDown={onPromptKeyDown}
                        {...(confirm.options && confirm.options.inputAttributes ? confirm.options.inputAttributes : {})}
                    />

                    <div className="mt-2 text-right">
                        <Button
                            type="button"
                            color="secondary"
                            className="mr-1"
                            onClick={onAbort}
                            {...(confirm.options && confirm.options.abortAttributes
                                ? confirm.options.abortAttributes
                                : {})}
                        >
                            {confirm.options && confirm.options.abortLabel ? confirm.options.abortLabel : "Abbrechen"}
                        </Button>
                        <Button
                            type="button"
                            color="success"
                            onClick={onConfirm}
                            {...(confirm.options && confirm.options.confirmAttributes
                                ? confirm.options.confirmAttributes
                                : {})}
                        >
                            {confirm.options && confirm.options.confirmLabel
                                ? confirm.options.confirmLabel
                                : confirm.type === "confirm"
                                ? "Ja"
                                : "Okay"}
                        </Button>
                    </div>
                </Content>
            </Modal>
        );
    }

    return null;
};
