import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";
import { Alert, Button, Card, CardBody, Col, Icon, LoadingSpinner, Row } from "@webtypen/react-components";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FloatFileSelector } from "../../../components";
import { ApiHelper, ConfirmHelper } from "../../../helpers";

const Wrapper = styled.div``;
const FileElement = styled.div`
    border-radius: 3px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;

    .preview-options {
        position: absolute;
        opacity: 0;
        top: 10px;
        left: 10px;
    }

    .preview-thumb {
        height: 150px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .preview-info {
        padding: 5px 10px;
        overflow: hidden;
        width: 100%;
        height: 27px;
        position: relative;

        .preview-info-gradient {
            position: absolute;
            top: 0px;
            right: 0px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
            width: 30px;
            height: 100%;
        }
    }

    &:hover {
        .preview-options {
            opacity: 1;
        }
    }
`;

export const FilesField = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [previewUrls, setPreviewUrls] = useState(null);
    const useCustomValue = props.customValueStore ? true : false;
    const value = useSelector((state) => state.app.dynamicrecords[props.formField]);
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const onAdd = async (data) => {
        if (data[0] && data[0]._id) {
            const newValue = value && value.length > 0 ? [...value] : [];
            const incl = value ? value.includes(data[0]._id) : false;
            if (incl) {
                const status = await ConfirmHelper.confirm(
                    "Diese Datei wurde bereits hinzugefügt. Trotzdem hinzufügen?"
                );
                if (!status) {
                    return;
                }
            }

            newValue.push(data[0]._id);
            if (props.onChange) {
                return props.onChange(props.formField, newValue, props.element ? props.element.rerenderOnChange : null);
            }

            dispatch(
                reduxSetDynamicRecordsValue(
                    props.formField,
                    newValue,
                    props.element ? props.element.rerenderOnChange : null
                )
            );
        }
    };

    const onRemove = async (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const newValue = [...value];

        const status = await ConfirmHelper.confirm(
            "Soll die ausgewählte Datei wirklich aus der Auswahl entfernt werden?",
            { confirmAttributes: { color: "danger" } }
        );
        if (!status) {
            return;
        }

        newValue.splice(index, 1);
        if (props.onChange) {
            return props.onChange(props.formField, newValue, props.element ? props.element.rerenderOnChange : null);
        }

        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                newValue,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    useEffect(() => {
        if (value && value.length > 0) {
            setIsLoading(true);
            ApiHelper.request("/api/filemanager/preview-urls", {
                files: value,
            })
                .then((response) => {
                    if (
                        response.status === "success" &&
                        response.data &&
                        response.data.files &&
                        Object.keys(response.data.files).length > 0
                    ) {
                        setPreviewUrls(response.data.files);
                    }
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                });
        } else {
            setPreviewUrls(null);
            setIsLoading(false);
        }
    }, [value]);

    return (
        <Wrapper style={props.definition && props.definition.style ? props.definition.style : null}>
            <label>{props.field.label}</label>
            <FloatFileSelector
                value={null}
                onChange={onAdd}
                selectorElement={
                    <Button type="button" className="ml-2 mr-1" outline sm style={{ position: "relative", top: -3 }}>
                        <Icon icon={faPlus} className="mr-1" />
                        Datei hinzufügen
                    </Button>
                }
            />
            {isLoading ? <LoadingSpinner /> : null}

            <Card>
                <CardBody>
                    {!value || value.length < 1 ? (
                        <Alert light className="mb-0">
                            Es wurden noch keine Dateien ausgewählt.
                        </Alert>
                    ) : (
                        <Row>
                            {value.map((el, i) => {
                                const options = (
                                    <div className="preview-options">
                                        <Button type="button" danger xs data-index={i} onClick={onRemove}>
                                            <Icon icon={faTrash} className="mr-1" />
                                            Entfernen
                                        </Button>
                                    </div>
                                );

                                const preview = previewUrls ? previewUrls[el] : null;
                                if (!preview || !preview.url) {
                                    if (isLoading) {
                                        return (
                                            <FileElement key={i}>
                                                <div className="loader">
                                                    <LoadingSpinner />
                                                </div>
                                            </FileElement>
                                        );
                                    }

                                    return (
                                        <FileElement key={i}>
                                            {options}
                                            <div className="error">Vorschau konnte nicht geladen werden ...</div>
                                        </FileElement>
                                    );
                                }

                                return (
                                    <Col key={i} width={3}>
                                        <FileElement key={i}>
                                            {options}
                                            <div
                                                className="preview-thumb"
                                                style={{ backgroundImage: "url(" + preview.url + ")" }}
                                            />
                                            <div className="preview-info">
                                                <div className="preview-info-gradient" />
                                                {preview.filename}
                                            </div>
                                        </FileElement>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </CardBody>
            </Card>
        </Wrapper>
    );
};
