import React from "react";
import { FormFields, FieldWrapper, FieldTitle, FieldBody } from "../FormFields";

export const Field = ({ field, path, setActive, isActive, disabled, moveControls }) => {
    return (
        <FieldWrapper isActive={isActive} disabled={disabled}>
            <FieldTitle onClick={disabled ? null : () => setActive(path)} isActive={isActive} disabled={disabled}>
                <div className="float-right">{moveControls}</div>
                <b>{field.field}</b> <span className="path">Path: {path}</span>
                <div className="info">Type: {field.type ? field.type : "Datenfeld"}</div>
            </FieldTitle>
            {field.form ? (
                <FieldBody>
                    <FormFields fields={field.form} pathPrefix={path + ".form"} setActive={setActive} />
                </FieldBody>
            ) : null}
        </FieldWrapper>
    );
};
