import lodash from "lodash";

export const modelSelectorToFields = (onSelect, selection, element) => {
    let newElement = { ...element };
    for (let i in onSelect.mappings) {
        newElement = lodash.set(newElement, onSelect.mappings[i][0], lodash.get(selection, onSelect.mappings[i][1]));
    }

    return newElement;
};

export const handleModelSelectorOnSelect = (onSelect, selection, element) => {
    if (onSelect.type === "set-in-form") {
        return modelSelectorToFields(onSelect, selection, element);
    }
    return element;
};
