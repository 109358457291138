import React from "react";
import { Provider } from "react-redux";
import { SimpleTableConfig } from "@webtypen/react-simpletable";
import { GlobalStyles } from "@webtypen/react-components";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { store } from "./redux";
import { defaultTheme } from "./theme";
import { ApiHelper, AuthHelper } from "./helpers";
import { Login } from "./screens/Login";
import { Logout } from "./screens/Logout";
import { Register } from "./screens/Register";
import { AccountIndex } from "./screens/account/AccountIndex";
import { EnvironmentIndex } from "./screens/environment/EnvironmentIndex";
import { Error404Guest } from "./screens/errors/Error404Guest";
import { Activation } from "./screens/Activation";
import { EnvironmentSetup } from "./screens/environment-setup/EnvironmentSetup";
import { GlobalStylesSimplebis } from "./components/GlobalStylesSimplebis";
import { ForgotPW } from "./screens/ForgotPW";
import { ForgotPWReset } from "./screens/ForgotPWReset";

const PrivateRoute = (props) => {
    if (AuthHelper.check()) {
        return <Route {...props} />;
    }
    return <Redirect to="/" />;
};

const GuestRoute = (props) => {
    if (AuthHelper.check()) {
        return <Redirect to="/account" />;
    }
    return <Route {...props} />;
};

SimpleTableConfig.setRequestMethod((data) => ApiHelper.request("/api/simpletable/data", data));

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyles />
                <GlobalStylesSimplebis />

                <Router>
                    <Switch>
                        <GuestRoute exact path="/" component={Login} />
                        <GuestRoute exact path="/register" component={Register} />
                        <GuestRoute exact path="/forgot-pw" component={ForgotPW} />
                        <GuestRoute exact path="/reset-pw/:user/:hash" component={ForgotPWReset} />
                        <GuestRoute exact path="/activate/:user/:token" component={Activation} />
                        <PrivateRoute exact path="/logout" component={Logout} />
                        <PrivateRoute path="/account" component={AccountIndex} />
                        <PrivateRoute path="/create-environment/setup/:envKey" component={EnvironmentSetup} />
                        <PrivateRoute path="/env/:_env" component={EnvironmentIndex} />
                        <Route component={Error404Guest} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
