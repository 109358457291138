import React from "react";
import styled from "styled-components";
import { lighten } from "polished";

const Wrapper = styled.div`
    display: flex;
    flex: 1;

    .sidebar-container {
        width: 200px;
        height: 100%;

        > ul.sidebar {
            list-style: none;
            display: block;
            padding: 0px;
            margin: 0px;

            li {
                padding: 0px;
                margin: 0px;

                a {
                    display: block;
                    padding: 10px 15px;
                    color: ${(props) => lighten(0.2, props.theme.color.default)};

                    span.icon-prefix {
                        width: 33px;
                        display: inline-block;
                    }

                    &.active {
                        color: ${(props) => props.theme.color.dark};
                    }
                }
            }
        }
    }

    .content-container {
        flex: 1;
        overflow: auto;
        background: #fff;
    }
`;

export const SidebarLayout = (props) => {
    return (
        <Wrapper>
            <div className="sidebar-container" {...props.sidebarAttributes}>
                {props.sidebar}
            </div>
            <div className="content-container">{props.children}</div>
        </Wrapper>
    );
};
