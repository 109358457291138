import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Page, Content, ContentLoader } from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { PageTitle } from "../../../components";
import { Error404 } from "../../errors/Error404";
import { DynamicRecordsList } from "./DynamicRecordsList";
import { DynamicRecordsEdit } from "./DynamicRecordsEdit";
import { DynamicRecordsPage } from "./DynamicRecordsPage";
import { DynamicRecordsChangeStages } from "./DynamicRecordsChangeStages";

export const DynamicRecordsWrapper = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [recordType, setRecordType] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/dynamic-records/type/details", {
            type: props.match.params._recordType,
        })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.plural) {
                    setRecordType(response.data);
                } else {
                    setRecordType(null);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setRecordType(null);
                setIsLoading(false);
            });
    }, [props.match.params._recordType]);

    if (isLoading) {
        return (
            <Page>
                <PageTitle title={<ContentLoader pageTitle />} />

                <Content>
                    <ContentLoader bars={4} />
                </Content>
            </Page>
        );
    }

    if (!recordType) {
        return (
            <Page>
                <PageTitle title="Fehler" />

                <Content>Seite nicht gefunden ...</Content>
            </Page>
        );
    }

    return (
        <Switch>
            <Route
                exact
                path="/env/:_env/dyn/:_recordType"
                render={(props) => <DynamicRecordsList {...props} recordType={recordType} />}
            />
            <Route
                exact
                path="/env/:_env/dyn/:_recordType/:id/edit"
                render={(props) => <DynamicRecordsEdit {...props} recordType={recordType} />}
            />
            <Route
                path="/env/:_env/dyn/:_recordType/:id/page/:page"
                render={(props) => <DynamicRecordsPage {...props} recordType={recordType} />}
            />
            <Route
                exact
                path="/env/:_env/dyn/:_recordType/:id/:newStage/change-stage"
                render={(props) => <DynamicRecordsChangeStages {...props} recordType={recordType} />}
            />
            <Route component={Error404} />
        </Switch>
    );
};
