import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons";
import { faCaretDown, faCaretUp, faLink, faPlus, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Content,
    Icon,
    FloatInput,
    Button,
    Dropdown,
    DropdownMenu,
    DropdownMenuElement,
    Alert,
    LoadingSpinner,
    Row,
    Col,
    Modal,
    ModalTitle,
    Badge,
    Page,
    Card,
    CardHeader,
    Table,
    InputGroup,
    Input,
    InputLabel,
} from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { PageTitle } from "../../../components";
import { QueryFormsEditTableConfig } from "./QueryFormsEditTableConfig";
import { EditElement } from "./EditElement";

const QuestionElement = styled.div`
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    background: #fff;

    .question-content {
        padding: 10px;
        flex: 1;

        &:hover {
            background: #f9f9f9;
        }
    }

    .question-sort {
        width: 40px;
        border-left: 1px solid #ddd;
        display: flex;
        flex-direction: column;

        button {
            display: block;
            width: 100%;
            border: none;
            outline: none;
            background: transparent;
            box-shadow: none;
            flex: 1;
            cursor: pointer;

            &:hover {
                background: #eee;
            }

            &:disabled {
                cursor: default;

                &:hover {
                    background: transparent;
                }
            }
        }
    }

    &:hover {
        border: 1px solid #cacaca;
    }
`;

const defaultState = {
    title: "",
    publications: {},
    definition: [],
    answer_table_columns: [],
    filled_once: false,
};

export const QueryFormsEdit = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [alert, setAlert] = useState(null);
    const [editDefinition, setEditDefinition] = useState(null);
    const [editDefinitionIndex, setEditDefinitionIndex] = useState(null);
    const [form, setForm] = useState({ ...defaultState });
    const [publishOptions, setPublishOptions] = useState({
        html_form_publish: false,
    });

    const onChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        });
    };

    const addQuestion = (parent) => {
        const questionDefault = {
            type: "question",
            questionType: "string",
            key:
                "question_" +
                Math.round(Date.now() / 1000)
                    .toString()
                    .substring(3),
            title: "Neue Frage ...",
            default: "",
            required: false,
            columns: 12,
        };

        if (parent) {
        } else {
            const newDefinition = [...form.definition];
            newDefinition.push({ ...questionDefault });

            setForm({
                ...form,
                definition: newDefinition,
            });
        }
    };

    const sortUp = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (index > 0) {
            const newDefinition = [...form.definition];
            const help1 = newDefinition[index - 1];
            const help2 = newDefinition[index];

            newDefinition[index] = help1;
            newDefinition[index - 1] = help2;

            setForm({
                ...form,
                definition: newDefinition,
            });
        }
    };

    const sortDown = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (index >= 0 && index + 1 <= form.definition.length) {
            const newDefinition = [...form.definition];
            const help1 = newDefinition[index + 1];
            const help2 = newDefinition[index];

            newDefinition[index] = help1;
            newDefinition[index + 1] = help2;

            setForm({
                ...form,
                definition: newDefinition,
            });
        }
    };

    const removeQuestion = (index) => {
        if (index.toString().indexOf(".") > 0) {
        } else {
            const fieldKey = form.definition[index].key;
            const newDefinition = [...form.definition];
            newDefinition.splice(index, 1);

            const newAnswerTableColumns = [];
            if (form.answer_table_columns && form.answer_table_columns.length > 0) {
                for (let i in form.answer_table_columns) {
                    if (form.answer_table_columns[i].mapping !== fieldKey) {
                        newAnswerTableColumns.push(form.answer_table_columns[i]);
                    }
                }
            }

            setForm({
                ...form,
                definition: newDefinition,
                answer_table_columns: newAnswerTableColumns,
            });
        }
    };

    const saveQuestion = (index, data) => {
        if (index.toString().indexOf(".") > 0) {
        } else {
            const newDefinition = [...form.definition];
            newDefinition[index] = { ...data };

            setForm({
                ...form,
                definition: newDefinition,
            });
        }
    };

    const openDefinitionEditor = (index) => {
        if (index.toString().indexOf(".") > 0) {
        } else {
            if (form.definition[index]) {
                setEditDefinition({ ...form.definition[index] });
                setEditDefinitionIndex(index);
            } else {
                setEditDefinition(null);
                setEditDefinitionIndex(null);
            }
        }
    };

    const toggleWebsitePublish = (e) => {
        const newPublications = form && form.publications ? { ...form.publications } : {};
        if (e.target.checked) {
            if (!newPublications.html_website) {
                newPublications.html_website = { is_active: true, publish_key: null };
            }
            newPublications.html_website.is_active = true;

            setForm({ ...form, publications: newPublications });
        } else {
            if (newPublications.html_website) {
                newPublications.html_website.is_active = false;
                setForm({ ...form, publications: newPublications });
            }
        }
    };

    const submit = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/query-forms/save", { ...form, id: props.match.params.id })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.id) {
                    setAlert(["success", "Das Formular wurde erfolgreich gespeichert."]);
                    load(response.data.id);
                } else {
                    setAlert([
                        "danger",
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern des Formulars aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                console.error(e);
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern des Formulars aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    const closeEdit = () => {
        setEditDefinition(null);
        setEditDefinitionIndex(null);
    };

    const load = (loadId) => {
        ApiHelper.request("/api/query-forms/details", {
            id: loadId,
        })
            .then((response) => {
                if (response && response.status === "success" && response.data._id.toString() === loadId.toString()) {
                    setForm(response.data);
                    setNotFound(false);
                } else {
                    setForm(null);
                    setNotFound(true);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setNotFound(true);
                setForm(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (props.match.params.id === "new") {
            setForm({ ...defaultState });
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setAlert(null);
            setNotFound(false);

            load(props.match.params.id);
        }
    }, [props.match.params.id]);

    useEffect(() => {
        ApiHelper.request("/api/environments/function-settings", {
            function: "query-forms",
            qualifier: "html",
        }).then((response) => {
            if (response.status === "success" && response.data && response.data.is_active) {
                setPublishOptions({
                    ...publishOptions,
                    html_form_publish: true,
                });
            } else {
                setPublishOptions({
                    ...publishOptions,
                    html_form_publish: false,
                });
            }
        });

        // eslint-disable-next-line
    }, []);

    return (
        <Page>
            <PageTitle
                title="Formular"
                subtitle={props.match.params.id === "new" ? "Erstellen" : "Bearbeiten"}
                backLink={"/env/" + props.match.params._env + "/query-forms"}
                actions={[
                    form._id
                        ? {
                              type: "button-link",
                              label: "Details",
                              attributes: {
                                  to: "/env/" + props.match.params._env + "/query-forms/form/" + form._id + "/details",
                              },
                          }
                        : null,
                ]}
            />

            <Content>
                {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                {editDefinition !== null ? (
                    <Modal
                        open={true}
                        contentAttributes={{
                            style: {
                                margin: "auto",
                                width: "100%",
                                maxWidth: 800,
                                maxHeight: "95vh",
                                display: "flex",
                                flexDirection: "column",
                            },
                        }}
                        wrapperAttributes={{ style: { overflow: "auto", height: "100vh" } }}
                    >
                        <ModalTitle>
                            <Button type="button" light className="float-right" onClick={closeEdit}>
                                <Icon icon={faTimes} />
                            </Button>
                            <h1>Element bearbeiten</h1>
                        </ModalTitle>
                        <div style={{ flex: 1, overflow: "auto" }}>
                            <EditElement
                                definition={editDefinition}
                                definitionIndex={editDefinitionIndex}
                                removeQuestion={removeQuestion}
                                saveQuestion={saveQuestion}
                                closeModal={closeEdit}
                            />
                        </div>
                    </Modal>
                ) : null}
                {isLoading ? (
                    <LoadingSpinner>Formular-Informationen werden geladen ...</LoadingSpinner>
                ) : notFound ? (
                    <Alert danger>Das aufgerufene Formular konnte nicht geladen werden ...</Alert>
                ) : (
                    <form onSubmit={submit}>
                        <FloatInput
                            placeholder="Titel / Bezeichnung"
                            value={form.title}
                            onChange={onChange}
                            name="title"
                            className="mb-3"
                        />

                        {form && form.definition && form.definition.length > 0 ? (
                            <Row>
                                {form.definition.map((element, i) => {
                                    if (element.type === "question") {
                                        return (
                                            <Col
                                                key={i}
                                                width={element.columns ? parseInt(element.columns) : null}
                                                className="mb-3"
                                            >
                                                <QuestionElement>
                                                    <div
                                                        className="question-content"
                                                        onClick={() => openDefinitionEditor(i)}
                                                    >
                                                        <div style={{ fontSize: 10, fontWeight: 600 }}>
                                                            Typ: Frage | Fragentyp: {element.questionType}
                                                        </div>
                                                        {element.title}

                                                        <div style={{ fontSize: 10 }}>
                                                            {element.required ? <Badge warning>Required</Badge> : null}
                                                        </div>
                                                    </div>
                                                    <div className="question-sort">
                                                        <button
                                                            type="button"
                                                            onClick={sortUp}
                                                            data-index={i}
                                                            disabled={i === 0}
                                                        >
                                                            <Icon icon={faCaretUp} />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={sortDown}
                                                            data-index={i}
                                                            disabled={i + 1 === form.definition.length}
                                                        >
                                                            <Icon icon={faCaretDown} />
                                                        </button>
                                                    </div>
                                                </QuestionElement>
                                            </Col>
                                        );
                                    }
                                    return null;
                                })}
                            </Row>
                        ) : (
                            <Alert light className="mb-3">
                                Es wurden noch keine Elemente zum Formular hinzugefügt
                            </Alert>
                        )}

                        <Dropdown
                            trigger={
                                <Button type="button" className="mb-3" block light>
                                    <Icon icon={faPlus} className="mr-1" /> Element hinzufügen
                                </Button>
                            }
                        >
                            <DropdownMenu style={{ width: 270 }}>
                                <DropdownMenuElement>
                                    <button type="button" disabled>
                                        <Icon icon={faObjectGroup} className="mr-1" />
                                        Sektion hinzufügen
                                    </button>
                                </DropdownMenuElement>
                                <DropdownMenuElement>
                                    <button type="button" onClick={() => addQuestion(null)}>
                                        <Icon icon={faQuestion} className="mr-2" />
                                        Frage hinzufügen
                                    </button>
                                </DropdownMenuElement>
                            </DropdownMenu>
                        </Dropdown>

                        {publishOptions && publishOptions.html_form_publish ? (
                            <Card className="mb-3">
                                <CardHeader>
                                    <QueryFormsEditTableConfig
                                        form={form}
                                        setForm={setForm}
                                        className="float-right"
                                        light
                                        xs
                                    />
                                    Formular Einstellungen
                                </CardHeader>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 45 }}>
                                                <input
                                                    type="checkbox"
                                                    id="checkboxFilledOnce"
                                                    onChange={onChange}
                                                    checked={form.filled_once ? true : false}
                                                    name="filled_once"
                                                />
                                            </td>
                                            <td>
                                                <label htmlFor="checkboxFilledOnce" style={{ cursor: "pointer" }}>
                                                    Das Formular kann nur einmal ausgefüllt werden.
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id="checkboxHtmlForPublish"
                                                    onChange={toggleWebsitePublish}
                                                    checked={
                                                        form &&
                                                        form.publications &&
                                                        form.publications.html_website &&
                                                        form.publications.html_website.is_active
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                            <td style={{ cursor: "default" }}>
                                                <label htmlFor="checkboxHtmlForPublish" style={{ cursor: "pointer" }}>
                                                    Das Formular kann über eine für das Formular generierte Webseite
                                                    ausgefüllt werden.
                                                </label>
                                            </td>
                                        </tr>
                                        {form &&
                                        form.publications &&
                                        form.publications.html_website &&
                                        form.publications.html_website.is_active ? (
                                            <tr>
                                                <td style={{ borderTop: "none", paddingTop: 0 }}></td>
                                                <td style={{ borderTop: "none", paddingTop: 0 }}>
                                                    {!form.publications.html_website.publish_key ||
                                                    form.publications.html_website.publish_key.trim() === "" ? (
                                                        <Alert light>
                                                            Nach dem Speichern des Formulars wird automatisch der Link
                                                            zum Ausfüllen generiert .
                                                        </Alert>
                                                    ) : (
                                                        <InputGroup
                                                            prepend={
                                                                <InputLabel style={{ fontWeight: 600 }}>
                                                                    URL der Formular-Seite:
                                                                </InputLabel>
                                                            }
                                                            append={
                                                                <InputLabel>
                                                                    <a
                                                                        href={
                                                                            "https://forms.simplebis.com/" +
                                                                            props.match.params._env +
                                                                            "/" +
                                                                            form.publications.html_website.publish_key
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <Icon icon={faLink} className="mr-1" />
                                                                        Öffnen
                                                                    </a>
                                                                </InputLabel>
                                                            }
                                                        >
                                                            <Input
                                                                readOnly
                                                                value={
                                                                    "https://forms.simplebis.com/" +
                                                                    props.match.params._env +
                                                                    "/" +
                                                                    form.publications.html_website.publish_key
                                                                }
                                                                onClick={(e) => e.target.select()}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </InputGroup>
                                                    )}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            </Card>
                        ) : null}

                        <div className="text-right">
                            <Button success disabled={isSaving}>
                                {isSaving ? <LoadingSpinner /> : "Speichern"}
                            </Button>
                        </div>
                    </form>
                )}
            </Content>
        </Page>
    );
};
