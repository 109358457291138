import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Alert, AutogrowTextarea, Badge, Button, FloatInput, FloatSelect, Icon } from "@webtypen/react-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FloatTextareaAutogrow } from "../../../components/FloatTextareaAutogrow";
import { ApiHelper, ConfirmHelper } from "../../../helpers";
import { reduxSetValueRecursive, store } from "../../../redux";

const Wrapper = styled.div`
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;

    .wrapper-options {
        width: 40px;

        .option-button {
            display: block;
            border-radius: 0px;
            width: 100%;
        }
    }

    .wrapper-content {
        border-left: 1px solid #eee;
        flex: 1;

        .block-title {
            padding: 7px 10px;
            font-size: 14px;
            font-weight: 600;
            color: #000;
            background: #fcfcfd;
            border-bottom: 1px solid #eee;
        }

        .block-content {
            padding: 10px;
        }
    }
`;

export const WebsiteManagerPagesBlockEdit = (props) => {
    const { design, blocksList, index } = props;
    const dispatch = useDispatch();
    const block = useSelector((state) => state.app.websitePageEdit.blocks[index]);

    const onChange = (e) => {
        const newBlock = { ...block };
        newBlock.fields[e.target.name] = e.target.value;

        dispatch(reduxSetValueRecursive("websitePageEdit.blocks[" + index + "]", newBlock));
    };

    const removeBlock = async (e) => {
        const status = await ConfirmHelper.confirm("Soll der Block wirklich entfernt werden?");
        if (!status) {
            return;
        }

        const state = store.getState();
        const newBlocks = [...state.app.websitePageEdit.blocks];
        newBlocks.splice(index, 1);

        dispatch(reduxSetValueRecursive("websitePageEdit.blocks", newBlocks));
    };

    const blockUp = () => {
        if (index < 1) {
            return;
        }

        const state = store.getState();
        const newBlocks = [...state.app.websitePageEdit.blocks];

        const help1 = { ...newBlocks[index] };
        const help2 = { ...newBlocks[index - 1] };
        newBlocks[index] = help2;
        newBlocks[index - 1] = help1;

        dispatch(reduxSetValueRecursive("websitePageEdit.blocks", newBlocks));
    };

    const blockDown = () => {
        if (index >= props.blocksCount) {
            return;
        }

        const state = store.getState();
        const newBlocks = [...state.app.websitePageEdit.blocks];

        const help1 = { ...newBlocks[index] };
        const help2 = { ...newBlocks[index + 1] };
        newBlocks[index] = help2;
        newBlocks[index + 1] = help1;

        dispatch(reduxSetValueRecursive("websitePageEdit.blocks", newBlocks));
    };

    if (!block || !block.type) {
        return <Alert danger>Block konnte nicht geladen werden ...</Alert>;
    }

    const blockDefinition = block.designblock
        ? design.blocks && design.blocks[block.type]
            ? design.blocks[block.type]
            : null
        : blocksList && blocksList[block.type]
        ? blocksList[block.type]
        : null;
    if (!blockDefinition) {
        return <Alert danger>Block-Definition wurde nicht gefunden ...</Alert>;
    }

    const fieldKeys = blockDefinition && blockDefinition.fields ? Object.keys(blockDefinition.fields) : null;
    return (
        <Wrapper>
            <div className="wrapper-options">
                <Button
                    type="button"
                    className="option-button"
                    onClick={blockUp}
                    light
                    sm
                    disabled={index < 1 || props.disabled}
                >
                    <Icon icon={faCaretUp} />
                </Button>
                <Button
                    type="button"
                    className="option-button"
                    onClick={blockDown}
                    light
                    sm
                    disabled={parseInt(index) + 1 === props.blocksCount || props.disabled}
                >
                    <Icon icon={faCaretDown} />
                </Button>
            </div>
            <div className="wrapper-content">
                <div className="block-title">
                    <Button
                        type="button"
                        className="float-right"
                        danger
                        xs
                        style={{ marginTop: -2 }}
                        onClick={removeBlock}
                    >
                        <Icon icon={faTrashAlt} />
                    </Button>
                    {blockDefinition.name} <Badge light>{block.type}</Badge>
                </div>
                <div className="block-content">
                    {fieldKeys && fieldKeys.length > 0
                        ? fieldKeys.map((key, i) => {
                              const value = block.fields[key];
                              const field = blockDefinition.fields[key];

                              if (field.type === "string") {
                                  return (
                                      <div key={i}>
                                          <FloatInput
                                              placeholder={field.name ? field.name : key}
                                              name={key}
                                              onChange={onChange}
                                              value={value ? value : ""}
                                              className="mb-2"
                                              disabled={props.disabled}
                                          />
                                      </div>
                                  );
                              } else if (field.type === "html") {
                                  return (
                                      <div key={i}>
                                          <label>{field.name ? field.name : key}</label>
                                          <AutogrowTextarea
                                              name={key}
                                              onChange={onChange}
                                              value={value ? value : ""}
                                              className="mb-2"
                                              disabled={props.disabled}
                                          />
                                      </div>
                                  );
                              } else if (field.type === "text") {
                                  return (
                                      <div key={i}>
                                          <FloatTextareaAutogrow
                                              placeholder={field.name ? field.name : key}
                                              name={key}
                                              onChange={onChange}
                                              value={value ? value : ""}
                                              className="mb-2"
                                              disabled={props.disabled}
                                          />
                                      </div>
                                  );
                              } else if (field.type === "queryforms") {
                                  return (
                                      <div key={i}>
                                          <QueryFormsSelector
                                              placeholder={field.name ? field.name : key}
                                              name={key}
                                              onChange={onChange}
                                              value={value ? value : ""}
                                              className="mb-2"
                                              disabled={props.disabled}
                                          />
                                      </div>
                                  );
                              }

                              return (
                                  <div key={i}>
                                      <i>Unbekannter Feld-Typ `{field.type}`</i>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </Wrapper>
    );
};

const QueryFormsSelector = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [forms, setForms] = useState(false);

    useEffect(() => {
        ApiHelper.request("/api/query-forms/list")
            .then((response) => {
                setForms(
                    response.status === "success" && response.data && response.data.length > 0 ? response.data : null
                );
                setIsLoading(false);
            })
            .catch((e) => {
                setForms(null);
                setIsLoading(false);
            });
    }, []);

    return (
        <FloatSelect {...props} disabled={isLoading || props.disabled}>
            {isLoading ? (
                <option value="">Wird geladen ...</option>
            ) : (
                <>
                    <option value="">-</option>
                    {forms && forms.length > 0
                        ? forms.map((form, i) => (
                              <option key={i} value={form._id}>
                                  {form.title}
                              </option>
                          ))
                        : null}
                </>
            )}
        </FloatSelect>
    );
};
