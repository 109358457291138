import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { ButtonLink, Icon, Content } from "@webtypen/react-components";
import { Logo } from "../components";
import { FooterContent } from "./FooterContent";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { ConfirmWrapper } from "./ConfirmWrapper";

const Wrapper = styled.div`
    padding-top: 50px;
`;

const AppBar = styled.div`
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    height: 50px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 50000;
`;

export const ContainerUser = styled.div`
    width: 100%;
    max-width: 800px;
    margin: auto;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px);
`;

const Footer = styled.div`
    padding: 30px;
    background: #fff;

    .links {
        text-align: center;
        font-size: 12px;
        margin-bottom: 10px;

        a {
            color: ${(props) => props.theme.color.default};
        }
    }

    .copyright {
        text-align: center;
        font-size: 12px;
    }
`;

export const LayoutUserarea = (props) => {
    return (
        <Wrapper>
            <AppBar>
                <ContainerUser>
                    <Content className="pt-1 pb-0">
                        <ButtonLink to="/logout" light className="float-right">
                            <Icon icon={faSignOutAlt} />
                        </ButtonLink>
                        <ButtonLink to="/account/settings" light className="float-right mr-1">
                            <Icon icon={faUser} />
                        </ButtonLink>

                        <div className="logo-container pt-2">
                            <Link to="/account">
                                <Logo style={{ fontSize: 26, marginTop: -6, display: "inline-block" }} />
                            </Link>
                        </div>
                    </Content>
                </ContainerUser>
            </AppBar>

            <Container>
                <div style={{ flex: 1 }}>{props.children}</div>

                <Footer>
                    <FooterContent />
                </Footer>
            </Container>
            <ConfirmWrapper />
        </Wrapper>
    );
};
