import React from "react";
import styled from "styled-components";
import { Content } from "@webtypen/react-components";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import { PageTitleSmall } from "../../../components";
import { BookingsManagerRooms } from "./BookingsManagerRooms";
import { BookingsManagerRoomsEdit } from "./BookingsManagerRoomsEdit";
import { BookingsManagerStart } from "./BookingsManagerStart";

const Wrapper = styled.div``;

export const BookingsManager = (props) => {
    const params = useParams();

    return (
        <Wrapper>
            <PageTitleSmall>
                <h1>Buchungen</h1>
                <ul className="manager-menu">
                    <li>
                        <NavLink exact to={"/env/" + params._env + "/bookings-manager"}>
                            Übersicht
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/env/" + params._env + "/bookings-manager/rooms"}>Räume</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to={"/env/" + params._env + "/bookings-manager/settings"}>Einstellungen</NavLink>
                    </li> */}
                </ul>
            </PageTitleSmall>

            <Switch>
                <Route exact path="/env/:_env/bookings-manager" component={BookingsManagerStart} />
                <Route exact path="/env/:_env/bookings-manager/rooms" component={BookingsManagerRooms} />
                <Route exact path="/env/:_env/bookings-manager/rooms/:id/edit" component={BookingsManagerRoomsEdit} />
                <Route
                    render={() => (
                        <Content>
                            <h1>404 - Seite nicht gefunden</h1>
                            Die aufgerufene Seite existiert nicht
                        </Content>
                    )}
                />
            </Switch>
        </Wrapper>
    );
};
