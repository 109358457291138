import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue, reduxSetValue } from "../../../redux/app/actions";
import { bindTooltip, Icon, InputGroup, Tooltip, Table, Button, Modal } from "@webtypen/react-components";
import { faCogs, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { StringField } from "./StringField";
import { SelectField } from "./SelectField";
import { NumericHelper } from "../../../helpers";
import { store } from "../../../redux";
import { MapFieldAccountingPositionsSettings } from "./MapFieldAccountingPositionsSettings";
import { AccountingDocumentPriceBox } from "./AccountingDocumentPriceBox";

const Wrapper = styled.div`
    border: 1px solid #ddd;
    border-radius: 3px;

    table {
        thead {
            background: #fff;

            th:first-child {
                border-top-left-radius: 3px;
            }

            th:last-child {
                border-top-right-radius: 3px;
            }
        }

        tbody {
            background: #f8f8f8;

            .positions-options-btn {
                cursor: pointer;
                text-align: right;
                vertical-align: middle;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
`;

const renderField = (key, posPath, errors, definition, options) => {
    return (
        <div>
            {options && options.type === "select" ? (
                <SelectField
                    flat
                    formField={posPath + "." + key}
                    field={definition[key]}
                    hasError={errors[posPath + "." + key] ? true : false}
                    attributes={options ? options.attributes : null}
                />
            ) : (
                <StringField
                    flat
                    formField={posPath + "." + key}
                    field={definition[key]}
                    hasError={errors[posPath + "." + key] ? true : false}
                    attributes={options ? options.attributes : null}
                />
            )}
            {errors[posPath + "." + key]
                ? errors[posPath + "." + key].map((error, e) => (
                      <div key={e} className="text-danger">
                          {error}
                      </div>
                  ))
                : null}
        </div>
    );
};

export const MapFieldAccountingPositions = (props) => {
    const { add, value } = props;
    const dispatch = useDispatch();
    const [modalPosition, setModalPosition] = useState(null);
    const errors = useSelector((state) => state.app.dynamicrecordsErrors);
    const taxes = useSelector((state) => state.app.environment.taxes);
    const defaultTax = useSelector((state) => state.app.environment.default_tax);
    const dynamicrecordsPositionsChanges = useSelector((state) => state.app.dynamicrecordsPositionsChanges);

    const calcPos = (newVal) => {
        if (
            newVal.quantity !== undefined &&
            newVal.quantity !== null &&
            newVal.quantity !== false &&
            newVal.quantity.toString().trim() !== "" &&
            newVal.price_single !== undefined &&
            newVal.price_single !== null &&
            newVal.price_single !== false &&
            newVal.price_single.toString().trim() !== ""
        ) {
            newVal.price_full = NumericHelper.numberFormat(
                NumericHelper.inputValueToFloat(newVal.quantity) * NumericHelper.inputValueToFloat(newVal.price_single)
            );
        } else {
            newVal.price_full = null;
        }

        if (
            newVal.quantity !== undefined &&
            newVal.quantity !== null &&
            newVal.quantity !== false &&
            newVal.quantity.toString().trim() !== ""
        ) {
            newVal.quantity = NumericHelper.numberFormat(NumericHelper.inputValueToFloat(newVal.quantity.toString()));
        } else {
            newVal.quantity = null;
        }

        if (
            newVal.price_single !== undefined &&
            newVal.price_single !== null &&
            newVal.price_single !== false &&
            newVal.price_single.toString().trim() !== ""
        ) {
            newVal.price_single = NumericHelper.numberFormat(
                NumericHelper.inputValueToFloat(newVal.price_single.toString())
            );
        } else {
            newVal.price_single = null;
        }

        let taxes_sum_percent = 0;
        let taxes_sum_price = 0;
        for (let i in newVal.taxes) {
            const percent = NumericHelper.inputValueToFloat(newVal.taxes[i].percent);
            taxes_sum_percent = taxes_sum_percent + percent;
            taxes_sum_price = taxes_sum_price + (NumericHelper.inputValueToFloat(newVal.price_full) / 100) * percent;
        }

        dispatch(reduxSetValue("dynamicrecordsPositionsChanges", dynamicrecordsPositionsChanges + 1));

        newVal.taxes_sum_percent = taxes_sum_percent;
        newVal.taxes_sum_price = taxes_sum_price;
        return newVal;
    };

    const addPosition = () => {
        const defaultValues = {};

        if (defaultTax && defaultTax.trim() !== "" && taxes && taxes.length > 0) {
            const taxEl = taxes.find((el) => el._id === defaultTax);
            if (taxEl && taxEl._id) {
                defaultValues.taxes = [
                    {
                        name: taxEl.name ? taxEl.name : "Standard-Steuersatz",
                        percent: taxEl.percentage,
                        number: taxEl.number ? taxEl.number : "",
                    },
                ];
                defaultValues.taxes_sum_percent = taxEl.percentage;
                defaultValues.taxes_sum_price = "0,00";
            }
        }

        add(null, defaultValues);
    };

    const onBlur = (e) => {
        const index = parseInt(e.target.getAttribute("data-index"));

        const state = store.getState();
        const newVal = calcPos({ ...lodash.get(state.app.dynamicrecords, props.formField + "[" + index + "]") });

        dispatch(reduxSetDynamicRecordsValue(props.formField + "[" + index + "]", newVal));
        dispatch(reduxSetDynamicRecordsValue("last_positions_change", Date.now()));
    };

    const openModal = (e) => {
        setModalPosition(e.currentTarget.getAttribute("data-index"));
    };

    const closeModal = () => setModalPosition(null);

    return (
        <div>
            <Wrapper>
                {modalPosition ? (
                    <Modal
                        open={true}
                        onCloseRequest={closeModal}
                        contentAttributes={{ style: { margin: "auto", width: "auto", maxWidth: 1000 } }}
                        wrapperAttributes={{ style: { padding: 30, overflow: "auto" } }}
                    >
                        <MapFieldAccountingPositionsSettings
                            closeModal={closeModal}
                            modalPosition={modalPosition}
                            formField={props.formField}
                            definition={props.field.definition}
                            field={props.field}
                            fields={props.fields}
                            values={props.values}
                            element={props.element}
                            calcPos={calcPos}
                        />
                    </Modal>
                ) : null}

                <Table>
                    {value && value.length > 0 && Array.isArray(value) ? (
                        <thead>
                            <tr>
                                <th style={{ width: 35 }}>#</th>
                                <th>Position</th>
                                <th style={{ width: 215 }} className="text-right">
                                    Menge
                                </th>
                                <th style={{ width: 170 }} className="text-right">
                                    Steuern
                                </th>
                                <th style={{ width: 150 }} className="text-right">
                                    EP
                                </th>
                                <th style={{ width: 150 }} className="text-right">
                                    GP
                                </th>
                                <th style={{ width: 33 }} />
                            </tr>
                        </thead>
                    ) : null}
                    <tbody>
                        {value && value.length > 0 && Array.isArray(value) ? (
                            value.map((el, i) => {
                                const posPath = props.formField + "[" + i + "]";
                                let hasErrors = false;
                                if (errors) {
                                    for (let i in errors) {
                                        if (i.indexOf(posPath) > -1) {
                                            hasErrors = true;
                                            break;
                                        }
                                    }
                                }

                                return (
                                    <tr key={i}>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{i + 1}</td>
                                        <td>{renderField("title", posPath, errors, props.field.definition)}</td>
                                        <td>
                                            <InputGroup
                                                append={renderField("unit", posPath, errors, props.field.definition, {
                                                    type: "select",
                                                    attributes: {
                                                        style: {
                                                            width: 100,
                                                            height: 34,
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                        },
                                                    },
                                                })}
                                            >
                                                {renderField("quantity", posPath, errors, props.field.definition, {
                                                    attributes: {
                                                        onBlur: onBlur,
                                                        "data-index": i,
                                                        className: "text-right",
                                                        style: {
                                                            width: 115,
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                        },
                                                    },
                                                })}
                                            </InputGroup>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    border: "1px solid #ddd",
                                                    borderRadius: 3,
                                                    fontSize: 13,
                                                    textAlign: "right",
                                                    padding: "2px 8px 0px 8px",
                                                    userSelect: "none",
                                                    cursor: "pointer",
                                                }}
                                                data-index={i}
                                                onClick={openModal}
                                            >
                                                {NumericHelper.numberFormat(el.taxes_sum_percent)} %
                                                <div style={{ fontSize: 9 }}>
                                                    Steuersumme:{" "}
                                                    {typeof el.taxes_sum_price === "string"
                                                        ? el.taxes_sum_price
                                                        : NumericHelper.numberFormat(el.taxes_sum_price)}{" "}
                                                    €
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {renderField("price_single", posPath, errors, props.field.definition, {
                                                attributes: {
                                                    onBlur: onBlur,
                                                    "data-index": i,
                                                    className: "text-right",
                                                },
                                            })}
                                        </td>
                                        <td {...bindTooltip("price_" + i)}>
                                            {renderField("price_full", posPath, errors, props.field.definition, {
                                                attributes: {
                                                    onBlur: onBlur,
                                                    "data-index": i,
                                                    className: "text-right",
                                                    disabled: true,
                                                },
                                            })}
                                            <Tooltip id={"price_" + i}>
                                                <PriceCellBrutto index={i} />
                                            </Tooltip>
                                        </td>
                                        <td className="positions-options-btn" data-index={i} onClick={openModal}>
                                            <Icon icon={faCogs} />

                                            {hasErrors ? (
                                                <Icon icon={faExclamationTriangle} className="text-danger" />
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>Es wurden noch keine Positionen hinzugefügt</td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={7} className="pt-2 pb-2 text-center">
                                <Button type="button" xs success outline className="ml-1" onClick={addPosition}>
                                    Hinzufügen
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Wrapper>

            <AccountingDocumentPriceBox />
        </div>
    );
};

const PriceCellBrutto = (props) => {
    const pos = useSelector((state) => state.app.dynamicrecords.positions[parseInt(props.index)]);

    return (
        <div>
            Brutto:
            <span className="ml-1" style={{ fontWeight: "bold" }}>
                {NumericHelper.numberFormat(
                    NumericHelper.inputValueToFloat(pos.price_full) +
                        (pos.taxes_sum_price !== undefined &&
                        pos.taxes_sum_price !== null &&
                        pos.taxes_sum_price !== false
                            ? NumericHelper.inputValueToFloat(pos.taxes_sum_price)
                            : 0)
                )}
            </span>
        </div>
    );
};
