export const REDUX_SET_VALUE = "REDUX_SET_VALUE";
export const REDUX_SET_VALUE_RECURSIVE = "REDUX_SET_VALUE_RECURSIVE";
export const REDUX_LOGOUT = "REDUX_LOGOUT";
export const REDUX_SET_DYNAMICRECORDS_VALUE = "REDUX_SET_DYNAMICRECORDS_VALUE";
export const REDUX_SET_FORMBUILDER = "REDUX_SET_FORMBUILDER";
export const REDUX_SET_FORMBUILDER_FORM = "REDUX_SET_FORMBUILDER_FORM";
export const REDUX_SET_GLOBAL_ALERT = "REDUX_ADD_GLOBAL_ALERT";
export const REDUX_REMOVE_GLOBAL_ALERT = "REDUX_REMOVE_GLOBAL_ALERT";
export const REDUX_REGISTER_FILEMANAGER = "REDUX_REGISTER_FILEMANAGER";
export const REDUX_REMOVE_FILEMANAGER = "REDUX_REMOVE_FILEMANAGER";
export const REDUX_SET_FILEMANAGER = "REDUX_SET_FILEMANAGER";
export const REDUX_FILEMANAGER_STRUCTURE_CHANGES = "REDUX_FILEMANAGER_STRUCTURE_CHANGES";
export const REDUX_SET_UPLOAD = "REDUX_SET_UPLOAD";
export const REDUX_REMOVE_UPLOAD = "REDUX_REMOVE_UPLOAD";
export const REDUX_SET_UPLOAD_VALUE = "REDUX_SET_UPLOAD_VALUE";
export const REDUX_UPLOAD_CHANGE_TRIGGER = "REDUX_UPLOAD_CHANGE_TRIGGER";

export function reduxSetValue(setting, value) {
    return {
        type: REDUX_SET_VALUE,
        payload: {
            setting: setting,
            value: value,
        },
    };
}

export function reduxSetValueRecursive(setting, value) {
    return {
        type: REDUX_SET_VALUE_RECURSIVE,
        payload: {
            setting: setting,
            value: value,
        },
    };
}

export function reduxLogout() {
    return { type: REDUX_LOGOUT };
}

export function reduxSetDynamicRecordsValue(key, value, forceRerender) {
    return {
        type: REDUX_SET_DYNAMICRECORDS_VALUE,
        payload: { key: key, value: value, forceRerender: forceRerender ? true : false },
    };
}

export function reduxSetDynRecordsTypeFormValue(key, value) {
    return { type: "DELETE!!!", payload: { key: key, value: value } };
}

export function reduxSetFormBuilder(key, value) {
    return { type: REDUX_SET_FORMBUILDER, payload: { key: key, value: value } };
}

export function reduxSetFormBuilderForm(key, value) {
    return { type: REDUX_SET_FORMBUILDER_FORM, payload: { key: key, value: value } };
}

export function reduxSetGlobalAlert(key, alertDefinition) {
    return { type: REDUX_SET_GLOBAL_ALERT, payload: { key: key, alert: alertDefinition } };
}

export function reduxRemoveGlobalAlert(key) {
    return { type: REDUX_REMOVE_GLOBAL_ALERT, payload: { key: key } };
}

export function reduxRegisterFilemanager(key, defaults) {
    return { type: REDUX_REGISTER_FILEMANAGER, payload: { key: key, defaults: defaults } };
}

export function reduxRemoveFilemanager(key) {
    return { type: REDUX_REMOVE_FILEMANAGER, payload: { key: key } };
}

export function reduxSetFilemanager(filemanager, key, value) {
    return { type: REDUX_SET_FILEMANAGER, payload: { filemanager: filemanager, key: key, value: value } };
}

export function reduxFilemanagerStructureChanges() {
    return { type: REDUX_FILEMANAGER_STRUCTURE_CHANGES };
}

export function reduxSetUpload(key, value) {
    return { type: REDUX_SET_UPLOAD, payload: { key: key, value: value } };
}

export function reduxSetUploadValue(upload, key, value) {
    return { type: REDUX_SET_UPLOAD_VALUE, payload: { upload: upload, key: key, value: value } };
}

export function reduxRemoveUpload(key, value) {
    return { type: REDUX_REMOVE_UPLOAD, payload: { key: key, value: value } };
}

export function reduxUploadChangeTrigger() {
    return { type: REDUX_UPLOAD_CHANGE_TRIGGER };
}
