import React from "react";
import { ButtonLink, Icon } from "@webtypen/react-components";

export const SettingTitle = (props) => {
    return (
        <h2 className="mt-0">
            {props.backLink ? (
                <ButtonLink to={props.backLink} className="float-right" light>
                    Zurück
                </ButtonLink>
            ) : null}
            {props.icon ? <Icon icon={props.icon} className="mr-3" /> : null}
            {props.title}
        </h2>
    );
};
