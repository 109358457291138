import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ExportBuilderMain } from "./ExportBuilderMain";

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 500000;
`;

export const ExportBuilder = (props) => {
    const [containerEl] = useState(document.createElement("div"));

    useEffect(() => {
        document.body.appendChild(containerEl);
        return () => {
            document.body.removeChild(containerEl);
        };
    }, [containerEl]);

    return ReactDOM.createPortal(
        <Wrapper>
            <ExportBuilderMain {...props} />
        </Wrapper>,
        containerEl
    );
};
