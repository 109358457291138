import React, { useState } from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonLink,
    Col,
    Content,
    FloatInput,
    FloatSelect,
    Icon,
    LoadingSpinner,
    Modal,
    Row,
} from "@webtypen/react-components";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { faFilePdf, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { ApiHelper, ConfirmHelper } from "../../../../helpers";
import { Route, useHistory } from "react-router";
import { ExportBuilder } from "../../../../components/ExportBuilder/ExportBuilder";

const Wrapper = styled.div`
    background: #f1f1f1;
    border-radius: 4px;
    padding: 10px;
`;

export const DynamicRecordsExports = (props) => {
    const history = useHistory();
    const [editExport, setEditExport] = useState(null); // eslint-disable-line
    const [copyExport, setCopyExport] = useState(null);
    const [copyExportName, setCopyExportName] = useState("");
    const [copyExportKey, setCopyExportKey] = useState("");
    const [copyAlert, setCopyAlert] = useState(null);
    const [isCopying, setIsCopying] = useState(false);

    const onChangeDefaultExport = () => {
        // @ToDo: coming soon
    };

    const onCopyExport = () => {
        setIsCopying(true);
        setCopyAlert(null);

        ApiHelper.request("/api/settings/dynamic-records/exports/copy", {
            type: props.data._id,
            from: copyExport,
            name: copyExportName,
            key: copyExportKey,
        })
            .then((response) => {
                console.log("response", response);
                if (response.status === "success") {
                    props.load();
                    setCopyExport(null);
                    setCopyExportKey("");
                    setCopyExportName("");
                    setCopyAlert(null);
                } else {
                    setCopyAlert([
                        "danger",
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Kopieren aufgetreten.",
                    ]);
                }
                setIsCopying(false);
            })
            .catch((e) => {
                setCopyAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Kopieren aufgetreten.",
                ]);
                setIsCopying(false);
            });
    };

    const onDeleteExport = async (exportKey) => {
        const status = await ConfirmHelper.confirm("Soll der Export `" + exportKey + "` wirklich entfernt werden?", {
            confirmAttributes: { color: "danger" },
        });
        if (!status) {
            return;
        }

        ApiHelper.request("/api/settings/dynamic-records/exports/delete", {
            type: props.data._id,
            export: exportKey,
        })
            .then((response) => {
                if (response.status === "success") {
                    props.load();
                } else {
                    ConfirmHelper.alert(
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Entfernen aufgetreten."
                    );
                }
            })
            .catch((e) => {
                ConfirmHelper.alert(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Entfernen aufgetreten."
                );
            });
    };

    const onCloseExportBuilder = () => {
        history.push(
            "/env/" +
                props.match.params._env +
                "/settings/dynamic-records/" +
                props.match.params.recordType +
                "/edit/exports"
        );
    };

    return (
        <Content>
            <Route
                exact
                path={"/env/:_env/settings/dynamic-records/:recordType/edit/exports/:exportKey"}
                render={(props) => <ExportBuilder onClose={onCloseExportBuilder} />}
            />

            {copyExport ? (
                <Modal
                    open={true}
                    onCloseRequest={() => setCopyExport(null)}
                    contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 600 } }}
                >
                    <Content>
                        <Button className="float-right" type="button" light onClick={() => setCopyExport(null)}>
                            <Icon icon={faTimes} />
                        </Button>
                        <h2 className="mt-0 mb-2">Export kopieren</h2>

                        {copyAlert ? <Alert color={copyAlert[0]}>{copyAlert[1]}</Alert> : null}

                        <FloatInput
                            placeholder="Bezeichnung des neuen Exports"
                            onChange={(e) => setCopyExportName(e.target.value)}
                            onBlur={(e) => {
                                if (!copyExportKey || copyExportKey.trim() === "") {
                                    setCopyExportKey(
                                        copyExportName
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_")
                                            .replace(/[^a-zA-Z0-9_]/g, "")
                                    );
                                }
                            }}
                            value={copyExportName}
                            className="mb-2"
                            disabled={isCopying}
                        />
                        <FloatInput
                            placeholder="Key des neuen Exports (eindeutig)"
                            onChange={(e) => setCopyExportKey(e.target.value)}
                            value={copyExportKey}
                            className="mb-2"
                            disabled={isCopying}
                        />
                        <div className="text-right">
                            <Button
                                type="button"
                                success
                                disabled={!copyExportKey || !copyExportName || isCopying}
                                onClick={onCopyExport}
                            >
                                {isCopying ? <LoadingSpinner /> : "Kopieren"}
                            </Button>
                        </div>
                    </Content>
                </Modal>
            ) : null}

            {props.data && props.data.exports && props.data.exports.length > 0 ? (
                <Row>
                    <Col width={12} className="mb-3">
                        <FloatSelect
                            placeholder="Standard Export"
                            onChange={onChangeDefaultExport}
                            value={props.data.defaultExport}
                        >
                            {props.data.exports.map((exp, i) => (
                                <option key={i} value={exp.key}>
                                    {exp.name}
                                </option>
                            ))}
                        </FloatSelect>
                    </Col>

                    {props.data.exports.map((exp, i) => {
                        return (
                            <Col width={4} key={i}>
                                <Wrapper className="mb-2">
                                    <Icon icon={faFilePdf} className="float-right" />
                                    {exp.name}
                                    <div style={{ fontSize: 12 }}>{exp.key}</div>

                                    <div className="mt-1">
                                        <ButtonGroup>
                                            {exp.isParent ? null : (
                                                <ButtonLink
                                                    to={
                                                        "/env/" +
                                                        props.match.params._env +
                                                        "/settings/dynamic-records/" +
                                                        props.match.params.recordType +
                                                        "/edit/exports/" +
                                                        exp.key
                                                    }
                                                    outline
                                                    xs
                                                    onClick={() => setEditExport(exp.key)}
                                                >
                                                    Bearbeiten
                                                </ButtonLink>
                                            )}
                                            <Button type="button" outline xs onClick={() => setCopyExport(exp.key)}>
                                                Kopieren
                                            </Button>
                                            {exp.isParent ? null : (
                                                <Button
                                                    type="button"
                                                    outline
                                                    xs
                                                    danger
                                                    onClick={() => onDeleteExport(exp.key)}
                                                >
                                                    <Icon icon={faTrashAlt} />
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </div>
                                </Wrapper>
                            </Col>
                        );
                    })}
                </Row>
            ) : (
                <Alert light>Es wurden noch keine Exporte für diesen Datentyp angelegt</Alert>
            )}

            <Button type="button" outline success sm>
                <Icon icon={faPlus} className="mr-1" disabled />
                Neuen Export erstellen
            </Button>
        </Content>
    );
};
