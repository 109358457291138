import React, { useEffect, useState } from "react";
import moment from "moment";
import {
    Alert,
    Button,
    CardFooter,
    Col,
    LoadingSpinner,
    Row,
    Content,
    FloatInput,
    FloatSelect,
} from "@webtypen/react-components";
import { ApiHelper } from "../../helpers";

export const Step1 = (props) => {
    const [data, setData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const finish = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments-setup/step-1", { ...data, _env: props.match.params.envKey })
            .then((response) => {
                props.nextStep();
                props.addDone(props.index);
            })
            .catch((e) => {
                setAlert([
                    "danger",
                    e && e.data && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        setData({
            name: props.init.environment.name,
            default_dateformat: props.init.environment.default_dateformat,
            default_currency: props.init.environment.default_currency,
            default_language: props.init.environment.default_language,
        });
    }, [props.init]);

    if (!data) {
        return null;
    }

    return (
        <form onSubmit={finish}>
            <Content>
                {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}
                <FloatInput
                    placeholder="Bezeichnung / Name"
                    value={data.name ? data.name : ""}
                    name="name"
                    onChange={onChange}
                    className="mb-2"
                    disabled={isSaving}
                />

                <Row>
                    <Col width={4}>
                        <FloatSelect
                            placeholder="Standard Datumsformat"
                            value={data.default_dateformat ? data.default_dateformat : ""}
                            name="default_dateformat"
                            onChange={onChange}
                            className="mb-2"
                            disabled={isSaving}
                        >
                            <option value="DD.MM.YYYY">{moment().format("DD.MM.YYYY")} (DD.MM.YYYY)</option>
                            <option value="YYYY-MM-DD">{moment().format("YYYY-MM-DD")} (YYYY-MM-DD)</option>
                        </FloatSelect>
                    </Col>
                    <Col width={4}>
                        <FloatSelect
                            placeholder="Standard Währung"
                            value={data.default_currency ? data.default_currency : ""}
                            name="default_currency"
                            onChange={onChange}
                            className="mb-2"
                            disabled={isSaving}
                        >
                            <option value="euro">Euro €</option>
                            <option value="us_dollar">US Dollar</option>
                        </FloatSelect>
                    </Col>
                    <Col width={4}>
                        <FloatSelect
                            placeholder="Standard Firmenumgebungs-Sprache"
                            value={data.default_language ? data.default_language : ""}
                            name="default_language"
                            onChange={onChange}
                            className="mb-2"
                            disabled={isSaving}
                        >
                            <option value="de_sie">Deutsch</option>
                            <option value="de_du">Deutsch (Dutzen)</option>
                            <option value="en">Englisch</option>
                        </FloatSelect>
                    </Col>
                </Row>

                <h2 className="mb-1">Unternehmenslogo</h2>
                <Alert light>
                    Das Firmenlogo wird hauptsächlich für Exporte der Firmenumgebung verwendet. So können beispielsweise
                    Angebots- und Rechnungsdesigns individualisiert werden.
                </Alert>
            </Content>
            <CardFooter className="text-right">
                <Button success disabled={isSaving}>
                    {isSaving ? <LoadingSpinner /> : "Weiter"}
                </Button>
            </CardFooter>
        </form>
    );
};
