import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ButtonLink, Card, CardBody, Icon, LoadingSpinner } from "@webtypen/react-components";
import { ConfirmWrapper, LayoutUserarea } from "../../components";
import { ApiHelper, ConfirmHelper } from "../../helpers";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const Wrapper = styled.div`
    height: calc(100vh - 50px);
`;

const Container = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: auto;
    padding: 0px 15px;
`;

const TitleWrapper = styled.div``;

const StepsTitle = styled.div`
    .progress {
        height: 3px;

        > div {
            background: ${(props) => props.theme.color.primary};
            transition: all 1000ms;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-grow: stretch;
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            margin: 0px;
            padding: 10px 15px;
            border-right: 1px solid #eee;
            border-bottom: 1px solid #eee;
            flex: 1;
            user-select: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0.5;

            &.clickable {
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }

                &:active {
                    background: rgba(0, 0, 0, 0.05);
                }
            }

            .steps-title {
                font-size: 16px;
            }

            .steps-description {
                font-size: 12px;
            }

            &.active {
                opacity: 1;

                .steps-title {
                    font-weight: 600;
                }
            }

            &.done {
                opacity: 1;
            }
        }

        &:after {
            clear: both;
            display: block;
            content: "";
        }
    }
`;

export const EnvironmentSetup = (props) => {
    const [progress, setProgress] = useState(0);
    const [currentStep, setCurrentStep] = useState(null);
    const [steps, setSteps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [initFailed, setInitFailed] = useState(false);
    const [init, setInit] = useState(null);
    const [doneSteps, setDoneSteps] = useState([]);

    const addDone = (index) => {
        if (!doneSteps.includes(parseInt(index))) {
            setDoneSteps([...doneSteps, parseInt(index)]);
        }
    };

    const nextStep = () => {
        load(() => {
            setCurrentStep(currentStep + 1);
        });
    };

    const loadStep = (index) => {
        load(() => {
            setCurrentStep(parseInt(index));
        });
    };

    const abort = async () => {
        const status = await ConfirmHelper.confirm("Soll die Konfiguration wirklich abgebrochen werden?", {
            confirmAttributes: {
                danger: true,
            },
        });

        if (status) {
            props.history.push("/");
        }
    };

    const load = (callback) => {
        setIsLoading(true);
        ApiHelper.request("/api/environments-setup/init", {
            _env: props.match.params.envKey,
        })
            .then((response) => {
                if (response.status === "success" && response.data) {
                    setInit(response.data);
                    setInitFailed(false);

                    if (callback) {
                        callback();
                    }
                } else {
                    setInitFailed(true);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setInitFailed(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const newSteps = [
            {
                title: "Schritt 1",
                description: "Firmeninformationen erfassen",
                component: Step1,
            },
            {
                title: "Schritt 2",
                description: "Rechnungsadresse",
                component: Step2,
            },
            {
                title: "Schritt 3",
                description: "Bankverbindung",
                component: Step3,
            },
            {
                title: "Abschließen",
            },
        ];
        setSteps(newSteps);

        setIsLoading(true);
        setInitFailed(false);
        setTimeout(() => {
            load(() => {
                setTimeout(() => {
                    setCurrentStep(0);
                }, 100);
            });
        }, 750);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentStep === null) {
            setProgress(0);
            return;
        }

        if (currentStep + 1 === steps.length) {
            setProgress(99);
        } else if (currentStep + 1 > steps.length) {
            setProgress(100);
        } else {
            setProgress(((currentStep + 1) / steps.length) * 100 - 15);
        }
    }, [currentStep, steps]);

    return (
        <LayoutUserarea>
            <ConfirmWrapper />
            <Wrapper>
                <Container>
                    <TitleWrapper>
                        <h1>Firmenumgebung konfigurieren</h1>
                    </TitleWrapper>

                    <Card>
                        {!init ? (
                            <CardBody>
                                <LoadingSpinner>
                                    {currentStep === null
                                        ? "Setup wird geladen ..."
                                        : "Nächster Schritt wird geladen ..."}
                                </LoadingSpinner>
                            </CardBody>
                        ) : initFailed || !init || !init.environment || !init.environment._id ? (
                            <CardBody className="bg-danger text-light">
                                <h3 className="mt-0">Fehler beim Laden des Setup-Assistenten</h3>
                                Bitte später erneut versuchen ...
                                <div className="mt-2">
                                    <ButtonLink to="/" outline light>
                                        Zur Startseite
                                    </ButtonLink>
                                </div>
                            </CardBody>
                        ) : (
                            <>
                                <StepsTitle>
                                    <ul>
                                        {steps
                                            ? steps.map((step, i) => {
                                                  const isDone = doneSteps.includes(i);
                                                  return (
                                                      <li
                                                          key={i}
                                                          className={
                                                              (i === currentStep ? "active" : "") +
                                                              " " +
                                                              (isDone ? "clickable" : "")
                                                          }
                                                          onClick={isDone ? () => loadStep(i) : null}
                                                      >
                                                          <div className="steps-title">
                                                              {step.title}
                                                              {isDone ? (
                                                                  <Icon icon={faCheckCircle} className="ml-1" />
                                                              ) : null}
                                                          </div>
                                                          {step.description ? (
                                                              <div className="steps-description">
                                                                  {step.description}
                                                              </div>
                                                          ) : null}
                                                      </li>
                                                  );
                                              })
                                            : null}
                                    </ul>
                                    <div className="progress">
                                        <div style={{ height: 3, width: progress + "%" }} />
                                    </div>
                                </StepsTitle>
                                {isLoading ? (
                                    <CardBody>
                                        <LoadingSpinner>Schritt wird geladen ...</LoadingSpinner>
                                    </CardBody>
                                ) : steps &&
                                  currentStep !== null &&
                                  currentStep >= 0 &&
                                  steps[currentStep] &&
                                  steps[currentStep].component ? (
                                    React.createElement(steps[currentStep].component, {
                                        ...props,
                                        nextStep: nextStep,
                                        addDone: addDone,
                                        init: init,
                                        index: currentStep,
                                    })
                                ) : currentStep > 0 ? (
                                    <CardBody>
                                        <h1 className="mt-1">Firmenumgebung erfolgreich eingerichtet</h1>
                                        Herzlichen Glückwunsch, Ihre Firmenumgebung wurde erfolgreich konfigurieren und
                                        steht Ihnen ab sofort zu verfügung!
                                        <Button
                                            type="button"
                                            className="mt-1"
                                            onClick={() => {
                                                nextStep();
                                                setTimeout(() => {
                                                    props.history.push("/env/" + props.match.params.envKey);
                                                }, 500);
                                            }}
                                        >
                                            Zur Firmenumgebung
                                        </Button>
                                    </CardBody>
                                ) : null}
                            </>
                        )}
                    </Card>
                    <Button onClick={abort} outline secondary xs className="mt-2 mb-3">
                        Abbrechen
                    </Button>
                </Container>
            </Wrapper>
        </LayoutUserarea>
    );
};
