import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Content, FloatInput, FloatSelect, LoadingSpinner, Row } from "@webtypen/react-components";
import { ApiHelper } from "../../../../helpers";
import { FloatFileSelector } from "../../../../components";

const defaultState = {
    is_active: true,
    website_title: "",
    logo: "",
};

export const QueryFormsWebsiteSettings = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState(null);
    const [alert, setAlert] = useState(null);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const save = (e) => {
        e.preventDefault();

        setAlert(null);
        setIsSaving(true);
        ApiHelper.request("/api/environments/function-settings/save", {
            function: "query-forms",
            qualifier: "html",
            settings: {
                is_active: data.is_active ? true : false,
                website_title:
                    data.website_title && data.website_title.trim() !== "" ? data.website_title.trim() : null,
                logo: data.logo && data.logo.trim() !== "" ? data.logo.trim() : null,
            },
        })
            .then((response) => {
                if (response.status === "success") {
                    setAlert(["success", "Die Einstellungen wurden erfolgreich gespeichert."]);
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern der Einstellungen aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                console.error(e);

                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern der Einstellungen aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);

        ApiHelper.request("/api/environments/function-settings", {
            function: "query-forms",
            qualifier: "html",
        })
            .then((response) => {
                if (response.status === "success") {
                    if (response.data) {
                        setData({ ...defaultState, ...response.data });
                    } else {
                        setData({ ...defaultState });
                    }
                } else {
                    setData(null);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setData(null);
                setIsLoading(false);
            });
    }, []);

    return (
        <Content>
            <h3 className="mt-0">Formular - HTML- / Websiteeinstellungen</h3>

            {isLoading ? (
                <LoadingSpinner>Einstellungen werden geladen ...</LoadingSpinner>
            ) : !data ? (
                <Alert danger>Es ist ein Fehler beim Laden der Einstellungen aufgetreten.</Alert>
            ) : (
                <form onSubmit={save}>
                    {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

                    <Row>
                        <Col width={12}>
                            <FloatSelect
                                placeholder="Firmenumgebung für HTML-Formulare aktivieren?"
                                value={data.is_active}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        is_active: e.target.value === true || e.target.value === "true" ? true : false,
                                    })
                                }
                                disabled={isSaving}
                                className="mb-2"
                            >
                                <option value={true}>Ja</option>
                                <option value={false}>Nein</option>
                            </FloatSelect>
                        </Col>
                        <Col width={10}>
                            <FloatInput
                                placeholder="Titel der Formular-Webseite (<title>-Tag)"
                                name="website_title"
                                value={data.website_title}
                                onChange={onChange}
                                disabled={isSaving}
                                className="mb-2"
                            />
                        </Col>
                        <Col width={2}>
                            <FloatFileSelector
                                placeholder="Website-Logo"
                                value={data.logo}
                                name="logo"
                                onChange={(media) =>
                                    setData({
                                        ...data,
                                        logo: media && media[0] && media[0]._id ? media[0]._id : null,
                                    })
                                }
                                disabled={isSaving}
                                className="mb-2"
                            />
                        </Col>
                        <Col width={12} className="text-right">
                            <Button success disabled={isSaving}>
                                {isSaving ? <LoadingSpinner /> : "Speichern"}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Content>
    );
};
