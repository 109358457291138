import React from "react";
import styled from "styled-components";
import { Row } from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";

const Wrapper = styled.div``;

export const ObjectField = (props) => {
    return (
        <Wrapper style={props.definition && props.definition.style ? props.definition.style : null}>
            <label>{props.field.label}</label>

            <Row>
                <DynamicRecordFieldsRenderer
                    data={{ fields: props.field.definition }}
                    fields={props.element.form}
                    setValue={props.setValue}
                    values={props.values}
                    prefix={props.formField}
                />
            </Row>
        </Wrapper>
    );
};
