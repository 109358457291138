import React, { useEffect, useState } from "react";
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    Col,
    Content,
    Icon,
    LoadingSpinner,
    Page,
    Row,
    Table,
    Input,
    InputGroup,
    InputLabel,
    ButtonLink,
} from "@webtypen/react-components";
import { SimpleTable, SimpleTableCell } from "@webtypen/react-simpletable";
import { ApiHelper } from "../../../helpers";
import { PageTitle } from "../../../components";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

export const QueryFormsDetails = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false); // eslint-disable-line
    const [form, setForm] = useState(null);

    const load = (loadId) => {
        ApiHelper.request("/api/query-forms/details", {
            id: loadId,
        })
            .then((response) => {
                if (response && response.status === "success" && response.data._id.toString() === loadId.toString()) {
                    setForm(response.data);
                    setNotFound(false);
                } else {
                    setForm(null);
                    setNotFound(true);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setNotFound(true);
                setForm(null);
                setIsLoading(false);
            });
    };

    const removeAnswer = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        console.log("index", index);
    };

    useEffect(() => {
        load(props.match.params.id);
    }, [props.match.params.id]);

    if (isLoading || !form) {
        return (
            <Page>
                <PageTitle
                    title="Formular"
                    subtitle={form ? form.name : ""}
                    backLink={"/env/" + props.match.params._env + "/query-forms"}
                />

                <Content>
                    {isLoading ? (
                        <LoadingSpinner>Formular wird geladen ...</LoadingSpinner>
                    ) : (
                        <Alert danger>Es ist ein Fehler beim Laden des Formulars aufgetreten.</Alert>
                    )}
                </Content>
            </Page>
        );
    }

    return (
        <Page>
            <PageTitle
                title="Formular"
                subtitle={form && form.title ? form.title : ""}
                backLink={"/env/" + props.match.params._env + "/query-forms"}
                actions={[
                    {
                        type: "button-link",
                        label: "Bearbeiten",
                        attributes: {
                            to:
                                "/env/" +
                                props.match.params._env +
                                "/query-forms/form/" +
                                props.match.params.id +
                                "/edit",
                        },
                    },
                ]}
            />

            <Content>
                <Row>
                    <Col width={4}>
                        <Card className="mb-3">
                            <CardHeader>Formular-Informationen</CardHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td style={{ fontWeight: 600, width: 145 }}>Titel / Bezeichnung</td>
                                        <td>{form.title}</td>
                                    </tr>
                                    {form.publications &&
                                    form.publications.html_website &&
                                    form.publications.html_website.is_active &&
                                    form.publications.html_website.publish_key ? (
                                        <tr>
                                            <td style={{ fontWeight: 600 }}>Ausfüllen über Webseite</td>
                                            <td>
                                                <InputGroup
                                                    append={
                                                        <InputLabel>
                                                            <a
                                                                href={
                                                                    "https://forms.simplebis.com/" +
                                                                    props.match.params._env +
                                                                    "/" +
                                                                    form.publications.html_website.publish_key
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Öffnen
                                                            </a>
                                                        </InputLabel>
                                                    }
                                                >
                                                    <Input
                                                        readOnly
                                                        value={
                                                            "https://forms.simplebis.com/" +
                                                            props.match.params._env +
                                                            "/" +
                                                            form.publications.html_website.publish_key
                                                        }
                                                        onClick={(e) => e.target.select()}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </InputGroup>
                                            </td>
                                        </tr>
                                    ) : null}
                                    {form.filled_once ? (
                                        <tr>
                                            <td colSpan={2} style={{ userSelect: "none", textAlign: "center" }}>
                                                <Icon icon={faExclamationTriangle} className="mr-1" />
                                                Dieses Formular kann nur ein einziges mal ausgefüllt werden!
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>

                    <Col width={8}>
                        <Card className="mb-3">
                            <CardHeader>Formular Antworten</CardHeader>
                            {!form.answer_table_columns || form.answer_table_columns.length < 1 ? (
                                <CardBody style={{ userSelect: "none" }}>
                                    Es wurde keine Tabelle-Definition für die Antworten dieses Formulars angegeben ...
                                    <br />
                                    <ButtonLink
                                        to={
                                            "/env/" +
                                            props.match.params._env +
                                            "/query-forms/form/" +
                                            form._id +
                                            "/edit"
                                        }
                                        xs
                                        secondary
                                        outline
                                    >
                                        Formular Tabelle bearbeiten
                                    </ButtonLink>
                                </CardBody>
                            ) : (
                                <SimpleTable model="QueryFormAnswer" where={[["formular", "ObjectID", form._id]]}>
                                    {form.answer_table_columns.map((column, i) => {
                                        if (column.type === "setting-field" && column.mapping) {
                                            return (
                                                <SimpleTableCell
                                                    key={i}
                                                    mapping={"data." + column.mapping}
                                                    label={column.label ? column.label : column.mapping}
                                                />
                                            );
                                        } else if (column.type === "table-options") {
                                            // return (
                                            //     <SimpleTableCell
                                            //         key={i}
                                            //         label={column.label ? column.label : "Optionen"}
                                            //         attributes={{ className: "text-right" }}
                                            //         buttons={[
                                            //             {
                                            //                 type: "button",
                                            //                 label: (
                                            //                     <>
                                            //                         <Icon icon={faTrashAlt} className="mr-1" />
                                            //                         Entfernen
                                            //                     </>
                                            //                 ),
                                            //                 attributes: {
                                            //                     xs: true,
                                            //                     danger: true,
                                            //                     onClick: removeAnswer,
                                            //                 },
                                            //             },
                                            //         ]}
                                            //     />
                                            // );
                                        }
                                        return null;
                                    })}
                                </SimpleTable>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Page>
    );
};
