import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxSetFormBuilder, reduxSetFormBuilderForm, reduxSetValue } from "../../redux";
import { Alert, Button, FloatInput } from "@webtypen/react-components";
import { FormFields } from "./FormFields";
import { Sidebar } from "./Sidebar";

export const FormBuilder = (props) => {
    const dispatch = useDispatch();
    const form = useSelector((state) => state.app.formBuilderForm);

    useEffect(() => {
        dispatch(
            reduxSetValue(
                "formBuilderForm",
                props.form && props.form.fields !== undefined && props.form.fields !== null
                    ? {
                          form: props.form,
                          hasChanges: false,
                          active: null,
                          fields: props.fields,
                      }
                    : {
                          form: {
                              name: "",
                              fields: [],
                          },
                          hasChanges: false,
                          active: null,
                          fields: props.fields,
                      }
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form]);

    const onChange = (e) => {
        dispatch(reduxSetFormBuilderForm(e.target.name, e.target.value));
    };

    const setActive = (path) => {
        dispatch(reduxSetFormBuilder("active", path));
    };

    const onSubmit = () => {
        props.onSave({ ...form.form });
    };

    if (!form || !form.form || form.form.name === undefined) {
        return null;
    }

    return (
        <>
            {form.form.isPreset ? (
                <Alert info>
                    Das Formular wurde von einer übergeordneten Datenstruktur erstellt oder ist eine Vorlage von
                    simplebis, daher kann das aktuell ausgewählte Formular nicht verändert werden.
                </Alert>
            ) : null}

            <Sidebar isPreset={form.form.isPreset ? true : false} stylingDefinitions={props.stylingDefinitions} />
            <FloatInput
                placeholder="Bezeichnung"
                name="name"
                value={form.form.name}
                onChange={onChange}
                className="mb-3"
            />
            <b>Felder:</b>
            <FormFields fields={form.form.fields} setActive={setActive} />

            {form.form.isPreset ? null : (
                <div className="mt-1">
                    <Button type="button" success const onClick={onSubmit}>
                        Formularänderungen anwenden
                    </Button>
                </div>
            )}
        </>
    );
};
