import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatInput } from "@webtypen/react-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";

export const DatetimeField = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => lodash.get(state.app.dynamicrecords, props.formField));

    const onChange = (e) => {
        if (props.onChange) {
            return props.onChange(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            );
        }
        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                e.target.value,
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    return (
        <FloatInput
            type="datetime-local"
            placeholder={props.field.label}
            value={value === undefined ? "" : value}
            onChange={onChange}
            hasError={props.hasError}
            style={props.definition && props.definition.style ? props.definition.style : null}
        />
    );
};
