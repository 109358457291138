import React from "react";
import styled from "styled-components";

const Wrapper = styled.span`
    background: #eee;
    padding: 5px 10px;
    border-radius: 4px;
    user-select: none;

    ${(props) => (props.color ? `color: ${props.color};` : `color: ${props.theme.color.default}`)}
    ${(props) => (props.background ? `background: ${props.background};` : ``)}
`;

export const DynamicRecordStage = (props) => {
    const { stage, stages, ...rest } = props;

    if (stage && !stages[stage]) {
        return <span className="text-danger">Unbekannter Stage `{stage}`</span>;
    }

    return (
        <Wrapper
            color={stage ? stages[stage].color : null}
            background={stage ? stages[stage].background : null}
            {...rest}
        >
            {stage ? stages[stage].name : "In Bearbeitung"}
        </Wrapper>
    );
};
