import React, { useEffect, useState } from "react";
import { Tabs, Content, Row } from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";

export const FormTabs = (props) => {
    const [sections, setSections] = useState(null);

    useEffect(() => {
        const newSections = {};
        for (let i in props.definition.sections) {
            newSections[i] = {
                title: props.definition.sections[i].title,
                render: () => (
                    <Content>
                        <Row>
                            <DynamicRecordFieldsRenderer
                                data={props.data}
                                fields={props.definition.sections[i].fields}
                                setValue={props.setValue}
                                values={props.values}
                            />
                        </Row>
                    </Content>
                ),
            };
        }

        setSections(newSections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return sections ? (
        <Tabs sections={sections} style={props.definition && props.definition.style ? props.definition.style : null} />
    ) : null;
};
