export const defaultTheme = {
    color: {
        background: "#f3f3f4",
        default: "#676a6c",
        primary: "#007bff",
        secondary: "#6c757d",
        success: "#28a745",
        danger: "#dc3545",
        warning: "#ffc107",
        info: "#17a2b8",
        light: "#f8f9fa",
        dark: "#343a40",
    },
    components: {
        AppBar: {
            height: "50px",
            borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
            background: "#333333",
            color: "#f8f9fa",
            css: "",
        },
        Sidebar: {
            width: "250px",
            background: "#d2d2d2",
            css: "",
        },
        SidebarElement: {
            background: "#2f4050",
            color: "#d0d3d7",
            fontWeight: 300,
            hover: {
                background: "#445362",
            },
            active: {
                background: "#445362",
                fontWeight: 600,
            },
        },
        PageTitle: {
            background: "#fff",
            title: {
                fontSize: "36px",
                fontWeight: "300",
                margin: "0px",
            },
            subtitle: {
                fontSize: "20px",
                fontWeight: "300",
                margin: "0px 0px 0px 6px",
            },
            css: "",
        },
        Card: {
            css: `
                border-radius: 4px;
                box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2);
            `,
        },
        CardHeader: {
            css: `
                border-top: 0px;
                border-bottom: 1px solid #f9f9f9; 
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                font-weight: 700;
                padding: 15px;
            `,
        },
        CardBody: {
            css: `
                font-size: 14px;
            `,
        },
        CardFooter: {
            css: `
                font-size: 14px;
            `,
        },
        Content: {
            padding: "15px",
        },
        h1: {
            fontSize: "2em",
            fontWeight: "300",
            css: "",
        },
    },
    sizes: {
        spacing: "15px",
        fontSize: "14px",
        breakpoints: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
};
