import React, { useEffect, useState } from "react";
import { ButtonLink, Content, LoadingSpinner, Alert } from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { DynamicRecordForm } from "../../../components";

export const BookingsManagerRoomsEdit = (props) => {
    const [isIniting, setIsIniting] = useState(true);
    const [recordType, setRecordType] = useState(null);

    const onRemove = () => {
        props.history.push("/env/" + props.match.params._env + "/bookings-manager/rooms");
    };

    useEffect(() => {
        setIsIniting(true);
        ApiHelper.request("/api/dynamic-records/type/details", {
            type: "bookingElement",
        })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.plural) {
                    setRecordType(response.data);
                } else {
                    setRecordType(null);
                }
                setIsIniting(false);
            })
            .catch((e) => {
                setRecordType(null);
                setIsIniting(false);
            });
    }, []);

    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>
                <ButtonLink
                    to={"/env/" + props.match.params._env + "/bookings-manager/rooms"}
                    secondary
                    className="float-right"
                    xs
                >
                    Zurück
                </ButtonLink>
                Raum {props.match.params.id === "new" ? "anlegen" : "bearbeiten"}
            </h1>

            {isIniting || !recordType ? (
                isIniting ? (
                    <LoadingSpinner>Formular wird geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>
                        Es konnte keine Buchungs-Element-Konfiguration für die Firmenumgebung geladen werden ...
                    </Alert>
                )
            ) : (
                <DynamicRecordForm
                    type={"bookingElement"}
                    recordId={props.match.params.id}
                    match={props.match}
                    form={recordType.mainForm}
                    onRemove={onRemove}
                    // onLoad={(data) => setFormIsLoaded(true)}
                />
            )}
        </Content>
    );
};
