import { faCaretDown, faCaretUp, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { transparentize } from "polished";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ApiHelper } from "../helpers";
import { Alert, Button, Dropdown, DropdownMenu, Icon, Input, LoadingSpinner } from "@webtypen/react-components";

const FloatInputContainer = styled.div`
    display: flex;
    border-radius: 4px;
    background: #fff;
    align-items: center;
    height: 52px;

    .input-prepend {
        color: #999;
        background: #fff;
        padding-left: 15px;
        padding-right: 5px;
        font-size: 18px;
    }

    ${(props) =>
        props.isopen
            ? `
            box-shadow: 0 0 0 0.2rem ${transparentize(0.75, props.theme.color.primary)};
    `
            : ``}
`;

const FloatInputWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    height: 52px;
    border: 1px solid #ddd;
    border-radius: 4px;

    .no-value {
        width: 100%;
        box-sizing: border-box;
        padding: 24px 16px 0px 10px;
        outline: none;
        border: none;
        font-size: 14px;
        height: 60px;
        color: rgba(0, 0, 0, 0.3);
        user-select: none;
        transform-origin: top left;
        transition: all 0.2s ease-out;
        color: ${(props) => props.theme.color.default};
        -webkit-appearance: none;
        -moz-appearance: none;
        font-style: italic;

        &::-ms-expand {
            display: none;
        }
    }

    .value-element {
        padding-top: 24px;
        padding-left: 10px;
        user-select: none;
        font-size: 14px;
        font-family: Arial;
        line-height: normal;
        white-space: pre;
    }

    label {
        font-size: 16px;
        padding: 0px 12px;
        color: #999;
        pointer-events: none;
        position: absolute;
        transform: translate(2px, 8px) scale(0.7);
        transform-origin: top left;
        transition: all 0.2s ease-out;
        user-select: none;
        white-space: pre;
    }

    svg.select-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 16px;
        height: 16px;
        margin-top: -8px;
        user-select: none;
    }

    svg.remove-icon {
        position: absolute;
        right: 30px;
        top: calc(50% + 2px);
        font-size: 16px;
        height: 16px;
        margin-top: -8px;
        user-select: none;

        &:hover {
            opacity: 0.6;
        }
    }

    ${(props) =>
        props.isdisabled
            ? `
        background-color: #e9ecef;
        opacity: 1;

        select {
            background-color: #e9ecef;
        }
    `
            : ``}

    ${(props) =>
        props.haserror || props.hasError
            ? `
            border-color: ${props.theme.color.danger};
            `
            : ``}
`;

const SearchResultElement = styled.div`
    border-top: 1px solid #eee;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
`;

export const FloatModelSelector = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { placeholder, hasError, className, children, value, onChange, ...rest } = props;
    const [isOpened, setIsOpened] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [hasSearch, setHasSearch] = useState(false);
    const [hasSearchError, setHasSearchError] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [valueElement, setValueElement] = useState(null);
    const [valueElementLoading, setValueElementLoading] = useState(false);

    const onOpen = () => {
        setIsOpened(true);
    };

    const onClose = () => {
        setIsOpened(false);
        setSearchValue("");
        setHasSearch(false);
        setSearchResults(null);
    };

    const onValueChange = (e) => {
        onClose();
        const value = e.currentTarget.getAttribute("data-value");
        onChange(value);
    };

    const createLabel = (element) => {
        if (!props.selector.label) {
            return <i>Missing label information</i>;
        }

        let label = "";
        if (element.modelSelectorLabel) {
            label = element.modelSelectorLabel;
        } else if (typeof props.selector.label === "object" && Array.isArray(props.selector.label)) {
            for (let i in props.selector.label) {
                if (typeof props.selector.label[i] === "object") {
                    if (props.selector.label[i].column) {
                        if (props.selector.label[i].type === "div") {
                            label += (
                                <div {...props.selector.label[i].attributes}>
                                    {element[props.selector.label[i].column]}
                                </div>
                            );
                        } else {
                            label += element[props.selector.label[i].column];
                        }
                    }
                } else {
                    label += element[props.selector.label[i]];
                }
            }
        } else if (typeof props.selector.label === "string") {
            label = element[props.selector.label];
        }

        return label;
    };

    const search = () => {
        setIsSearching(true);
        setHasSearch(true);
        setHasSearchError(null);
        setSearchResults(null);

        ApiHelper.request("/api/model-selector/search", {
            selector: props.selector,
        })
            .then((response) => {
                if (response.status === "success") {
                    setSearchResults(response.data && response.data.length > 0 ? response.data : null);
                    setHasSearchError(null);
                    setIsSearching(false);
                } else {
                    setHasSearchError(
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler aufgetreten."
                    );
                    setIsSearching(false);
                }
            })
            .catch((e) => {
                setHasSearchError(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler aufgetreten."
                );
                setIsSearching(false);
            });
    };

    const reset = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(null);
    };

    useEffect(() => {
        if (!value) {
            setValueElement(null);
            setValueElementLoading(false);
        } else {
            setValueElementLoading(true);

            ApiHelper.request("/api/model-selector/result", {
                selector: props.selector,
                value: value,
            })
                .then((response) => {
                    if (response.status === "success" && response.data && response.data._id === value) {
                        setValueElement(response.data);
                    } else {
                        setValueElement(null);
                    }
                    setValueElementLoading(false);
                })
                .catch((e) => {
                    setValueElement(null);
                    setValueElementLoading(false);
                });
        }
    }, [value, props.selector]);

    let valueRender;
    let hasValue = false;
    if (value === undefined || value === null || value.toString().trim() === "") {
        valueRender = <div className="no-value">Nicht ausgewählt</div>;
    } else {
        hasValue = true;
        valueRender = (
            <div className="value-element">
                {valueElementLoading ? (
                    <LoadingSpinner />
                ) : valueElement ? (
                    createLabel(valueElement)
                ) : (
                    <span className="text-danger">Fehler beim Laden</span>
                )}
            </div>
        );
    }

    return (
        <FloatInputContainer className={className} isopen={isOpened}>
            {props.prepend ? <div className="input-prepend">{props.prepend}</div> : null}

            <Dropdown
                trigger={
                    <FloatInputWrapper
                        active={props.value && props.value.toString().trim() !== "" ? true : false}
                        isdisabled={props.disabled}
                        haserror={hasError ? true : false}
                        isopen={isOpened}
                    >
                        {hasValue ? <Icon icon={faTimes} className="remove-icon text-danger" onClick={reset} /> : null}
                        {isOpened ? (
                            <Icon icon={faCaretUp} className="select-arrow" />
                        ) : (
                            <Icon icon={faCaretDown} className="select-arrow" />
                        )}
                        {valueRender}

                        <label>{placeholder}</label>
                    </FloatInputWrapper>
                }
                wrapperAttributes={{ style: { display: "block", width: "100%" } }}
                style={{ width: "100%" }}
                onOpen={onOpen}
                onClose={onClose}
            >
                <DropdownMenu style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    <FloatModelSelectorFilter
                        isSearching={isSearching}
                        searchValue={searchValue}
                        search={search}
                        setSearchValue={setSearchValue}
                        hasSearch={hasSearch}
                        hasSearchError={hasSearchError}
                        searchResults={searchResults}
                        onChange={onValueChange}
                        createLabel={createLabel}
                    />
                </DropdownMenu>
            </Dropdown>
        </FloatInputContainer>
    );
};

const FloatModelSelectorFilter = (props) => {
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            props.search();
        }
    };

    const onClick = () => props.search();

    const onChange = (e) => {
        props.setSearchValue(e.target.value);
    };

    useEffect(() => {
        props.search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="model-selector-wrapper">
            <div
                className="ml-1 mr-1 mt-1 mb-1"
                style={{ border: "1px solid #ddd", borderTopRightRadius: 3, borderBottomRightRadius: 3 }}
            >
                <Input
                    type="text"
                    placeholder="Suchen ..."
                    onChange={onChange}
                    value={props.searchValue}
                    onKeyDown={onKeyDown}
                    style={{
                        width: "calc(100% - 30px)",
                        minWidth: "100px",
                        display: "inline-block",
                        border: "none",
                    }}
                    disabled={props.isSearching}
                />
                <Button
                    type="button"
                    primary
                    sm
                    style={{ width: 30, height: 35, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, border: "none" }}
                    disabled={props.isSearching}
                    onClick={onClick}
                >
                    {props.isSearching ? <LoadingSpinner /> : <Icon icon={faSearch} />}
                </Button>
            </div>

            {props.hasSearch ? (
                props.isSearching ? (
                    <div className="pl-2 pt-1 pb-1" style={{ userSelect: "none" }}>
                        <LoadingSpinner className="mr-2" />
                        Ergebnisse werden geladen ...
                    </div>
                ) : props.hasSearchError ? (
                    <Alert danger className="mb-0">
                        {props.hasSearchError}
                    </Alert>
                ) : !props.searchResults || props.searchResults.length < 1 ? (
                    <Alert light className="mb-0">
                        Es wurden keine Datensätze gefunden ...
                    </Alert>
                ) : (
                    props.searchResults.map((result, i) => {
                        const label = props.createLabel(result);

                        return (
                            <SearchResultElement key={i} data-value={result._id} onClick={props.onChange}>
                                {label}
                            </SearchResultElement>
                        );
                    })
                )
            ) : null}
        </div>
    );
};
