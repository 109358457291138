import React, { useState } from "react";
import {
    Alert,
    Button,
    Table,
    Content,
    Icon,
    Modal,
    ModalTitle,
    Select,
    Input,
    Badge,
} from "@webtypen/react-components";
import { faTable, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

export const QueryFormsEditTableConfig = (props) => {
    const { children, form, setForm, ...rest } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [add, setAdd] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onAddChange = (e) => {
        setAdd(e.target.value);
    };

    const onAdd = (e) => {
        if (!add) {
            return;
        }

        if (add === "table-options") {
            const newAnswerTableColumns = form.answer_table_columns ? [...form.answer_table_columns] : [];
            newAnswerTableColumns.push({
                type: "table-options",
                label: "Optionen",
            });
            setForm({
                ...form,
                answer_table_columns: newAnswerTableColumns,
            });
            return;
        }

        let label = add;
        for (let i in form.definition) {
            if (form.definition[i].key === add) {
                label =
                    form.definition[i].title && form.definition[i].title.trim() !== "" ? form.definition[i].title : add;
            }
        }

        const newAnswerTableColumns = form.answer_table_columns ? [...form.answer_table_columns] : [];
        newAnswerTableColumns.push({
            type: "setting-field",
            mapping: add,
            label: label,
        });
        setForm({
            ...form,
            answer_table_columns: newAnswerTableColumns,
        });
    };

    const onChange = (e) => {
        const newAnswerTableColumns = form.answer_table_columns ? [...form.answer_table_columns] : [];
        newAnswerTableColumns[parseInt(e.target.getAttribute("data-index"))][e.target.name] = e.target.value;

        setForm({
            ...form,
            answer_table_columns: newAnswerTableColumns,
        });
    };

    const onRemove = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const newAnswerTableColumns = form.answer_table_columns ? [...form.answer_table_columns] : [];
        newAnswerTableColumns.splice(index, 1);

        setForm({
            ...form,
            answer_table_columns: newAnswerTableColumns,
        });
    };

    const fieldOptions = [];
    if (form && form.definition && form.definition.length > 0) {
        for (let i in form.definition) {
            if (form.definition[i].type === "question") {
                fieldOptions.push(form.definition[i]);
            }
        }
    }

    return (
        <>
            {isOpen ? (
                <Modal
                    open={true}
                    onCloseRequest={toggle}
                    contentAttributes={{
                        style: {
                            display: "flex",
                            flexDirection: "column",
                            margin: "auto",
                            maxHeight: "95vh",
                            maxWidth: 700,
                        },
                    }}
                >
                    <ModalTitle>
                        <Button type="button" light className="float-right" onClick={toggle}>
                            <Icon icon={faTimes} />
                        </Button>
                        <h1>Antworten-Tabelle konfigurieren</h1>
                    </ModalTitle>
                    <div style={{ flex: 1, overflow: "auto" }}>
                        {!form.answer_table_columns || form.answer_table_columns.length < 1 ? (
                            <Content>
                                <Alert className="mb-0" light>
                                    Es wurde noch keiner Tabellenzellen hinzugefügt.
                                </Alert>
                            </Content>
                        ) : (
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: 50 }}></th>
                                        <th>Feld</th>
                                        <th>Label</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form.answer_table_columns.map((col, i) => (
                                        <tr key={i}>
                                            <td style={{ verticalAlign: "middle" }}>
                                                <Button
                                                    type="button"
                                                    danger
                                                    outline
                                                    xs
                                                    data-index={i}
                                                    onClick={onRemove}
                                                >
                                                    <Icon icon={faTrashAlt} />
                                                </Button>
                                            </td>
                                            <td style={{ verticalAlign: "middle" }}>
                                                {col.type === "table-options" ? (
                                                    <Badge light>Options-Spalte</Badge>
                                                ) : (
                                                    col.mapping
                                                )}
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    value={col.label}
                                                    onChange={onChange}
                                                    data-index={i}
                                                    name="label"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}

                        <div style={{ borderTop: "1px solid #eee" }}>
                            {fieldOptions && fieldOptions.length > 0 ? (
                                <Content>
                                    <b>Feld hinzufügen:</b>

                                    <Select
                                        onChange={onAddChange}
                                        value={add}
                                        style={{ width: "calc(100% - 95px)", maxWidth: "calc(100% - 95px)" }}
                                    >
                                        <optgroup label="Formular-Felder">
                                            <option value="">-</option>
                                            {fieldOptions.map((opt, i) => (
                                                <option key={i} value={opt.key}>
                                                    {opt.title}
                                                </option>
                                            ))}
                                        </optgroup>
                                        <optgroup label="Funktions-Spalten">
                                            <option value="table-options">Optionen</option>
                                        </optgroup>
                                    </Select>
                                    <Button
                                        type="button"
                                        primary
                                        sm
                                        className="mt-1"
                                        disabled={!add || add.trim() === ""}
                                        onClick={onAdd}
                                    >
                                        Hinzufügen
                                    </Button>
                                </Content>
                            ) : (
                                <Content>
                                    <i>Es stehen keine Felder zum Hinzufügen zu Verfügung</i>
                                </Content>
                            )}
                        </div>
                    </div>
                </Modal>
            ) : null}
            <Button type="button" {...rest} onClick={toggle}>
                {children ? (
                    children
                ) : (
                    <>
                        <Icon icon={faTable} className="mr-1" />
                        Antwort-Tabelle konfigurieren
                    </>
                )}
            </Button>
        </>
    );
};
