import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Content,
    Icon,
    LoadingSpinner,
    Row,
    SmallValueBox,
} from "@webtypen/react-components";
import { ApiHelper } from "../../../helpers";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export const WebsiteManagerOverview = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [stats, setStats] = useState(null);
    const [statsLoading, setStatsLoading] = useState(true);

    const update = () => {
        setStatsLoading(true);
        ApiHelper.request("/api/website-manager/stats")
            .then((response) => {
                setStats(response.status === "success" && response.data ? response.data : null);
                setStatsLoading(false);
            })
            .catch((e) => {
                setStats(null);
                setStatsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setData(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                setData(null);
                setIsLoading(false);
            });
        update();
    }, []);

    if (isLoading || !data) {
        return (
            <Content>
                {isLoading ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Informationen aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <Row>
                <Col width={3}>
                    <Card>
                        <CardBody>
                            <SmallValueBox
                                label="Seiten"
                                value={statsLoading ? <LoadingSpinner /> : stats ? stats.pages : null}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col width={3}>
                    <Card>
                        <CardBody>
                            <SmallValueBox
                                label="Aufrufe letzte 7 Tage"
                                value={statsLoading ? <LoadingSpinner /> : stats ? stats.hits_last_seven : null}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col width={3}>
                    <Card>
                        <CardBody>
                            <SmallValueBox
                                label="Aufrufe letzte 30 Tage"
                                value={statsLoading ? <LoadingSpinner /> : stats ? stats.hits_last_thirty : null}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Button secondary outline xs onClick={update} className="mt-2" disabled={statsLoading}>
                {statsLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <Icon icon={faSyncAlt} className="mr-1" />
                        Aktualisieren
                    </>
                )}
            </Button>
        </Content>
    );
};
