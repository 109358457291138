import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faCaretDown, faCaretUp, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Content,
    FloatInput,
    Icon,
    Input,
    LoadingSpinner,
    Modal,
    ModalTitle,
    Row,
    Select,
} from "@webtypen/react-components";
import { ApiHelper, ConfirmHelper } from "../../../helpers";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const MenuElement = styled.div`
    display: flex;
    border: 1px solid #eee;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0px;
    }

    .element-toolbar {
        width: 40px;

        .element-toolbar-btn {
            border-radius: 0px;
        }
    }

    .element-content {
        padding: 10px;
        flex: 1;
        border-left: 1px solid #eee;
    }
`;

export const WebsiteManagerMenus = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [designIsLoading, setDesignIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState("");
    const [data, setData] = useState({});
    const [design, setDesign] = useState(null);
    const [addMenu, setAddMenu] = useState(null);
    const [isAddingMenu, setIsAddingMenu] = useState(false);
    const [alert, setAlert] = useState(null);
    const [menu, setMenu] = useState(null);

    const toggleAddMenu = () => {
        setAddMenu(addMenu === null ? "" : null);
    };

    const onMenuChange = (e) => {
        setSelectedMenu(e.target.value);

        let newMenu = null;
        if (e.target.value && data && data.menus) {
            for (let i in data.menus) {
                if (data.menus[i].title === e.target.value) {
                    newMenu = { ...data.menus[i] };
                    break;
                }
            }
        }
        setMenu(newMenu);
    };

    const onChange = (e) => {
        setMenu({
            ...menu,
            [e.target.name]: e.target.value,
        });
    };

    const onAddMenu = (e) => {
        e.preventDefault();
        if (!addMenu || addMenu.trim() === "") {
            return;
        }

        setIsAddingMenu(true);
        ApiHelper.request("/api/website-manager/add-menu", { title: addMenu })
            .then((response) => {
                if (response.status === "success") {
                    setAddMenu(null);
                    load();
                } else {
                    ConfirmHelper.alert(
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Anlegen des Menüs aufgetreten."
                    );
                }
                setIsAddingMenu(false);
            })
            .catch((e) => {
                console.error(e);
                ConfirmHelper.alert(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Anlegen des Menüs aufgetreten."
                );
                setIsAddingMenu(false);
            });
    };

    const addEntry = () => {
        const newDefinition = menu.definition ? [...menu.definition] : [];
        newDefinition.push({
            type: "custom",
            label: "",
            url: "",
            attributes: {},
        });

        setMenu({
            ...menu,
            definition: newDefinition,
        });
    };

    const removeEntry = async (e) => {
        const status = await ConfirmHelper.confirm("Soll das Menü-Element wirklich entfernt werden?", {
            confirmAttributes: {
                danger: true,
            },
        });
        if (!status) {
            return;
        }

        const index = parseInt(e.target.getAttribute("data-index"));
        const newDefinition = [...menu.definition];
        newDefinition.splice(index, 1);

        setMenu({
            ...menu,
            definition: newDefinition,
        });
    };

    const entryUp = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (index < 1) {
            return;
        }

        const newDefinition = [...menu.definition];
        const help1 = { ...newDefinition[index] };
        const help2 = { ...newDefinition[index - 1] };

        newDefinition[index] = help2;
        newDefinition[index - 1] = help1;

        setMenu({
            ...menu,
            definition: newDefinition,
        });
    };

    const entryDown = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (index >= menu.definition.length) {
            return;
        }

        const newDefinition = [...menu.definition];
        const help1 = { ...newDefinition[index] };
        const help2 = { ...newDefinition[index + 1] };

        newDefinition[index] = help2;
        newDefinition[index + 1] = help1;

        setMenu({
            ...menu,
            definition: newDefinition,
        });
    };

    const onMenuElementChange = (e) => {
        const index = parseInt(e.target.getAttribute("data-index"));
        const newDefinition = [...menu.definition];
        newDefinition[index][e.target.name] = e.target.value;

        setMenu({
            ...menu,
            definition: newDefinition,
        });
    };

    const toggleMenu = (e) => {
        const newMenu = { ...menu };
        if (!newMenu.design_usage) {
            newMenu.design_usage = {};
        }

        if (!newMenu.design_usage[data.design]) {
            newMenu.design_usage[data.design] = [];
        }

        const key = e.target.name;
        let index = null;
        for (let i in newMenu.design_usage[data.design]) {
            if (newMenu.design_usage[data.design][i] === key) {
                index = i;
                break;
            }
        }

        if (index === null) {
            newMenu.design_usage[data.design].push(key);
        } else {
            newMenu.design_usage[data.design].splice(index, 1);
        }

        setMenu({ ...newMenu });
    };

    const load = () => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/details")
            .then((response) => {
                setData(response.status === "success" && response.data && response.data._id ? response.data : null);
                setIsLoading(false);

                setDesignIsLoading(true);
                ApiHelper.request("/api/website-manager/design", {
                    design: response.data.design,
                })
                    .then((response) => {
                        setDesign(
                            response.status === "success" && response.data && response.data.name ? response.data : null
                        );
                        setDesignIsLoading(false);
                    })
                    .catch((e) => {
                        setDesign(null);
                        setDesignIsLoading(false);
                    });
            })
            .catch((e) => {
                setData({});
                setIsLoading(false);
            });
    };

    const save = (e) => {
        e.preventDefault();

        setIsSaving(true);
        ApiHelper.request("/api/website-manager/save-menu", {
            menu: selectedMenu,
            data: menu,
        })
            .then((response) => {
                if (response.status === "success" && response.data && response.data.title) {
                    setAlert(["success", "Das Menü wurde erfolgreich gespeichert"]);

                    ApiHelper.request("/api/website-manager/details")
                        .then((responseDetails) => {
                            setData(
                                responseDetails.status === "success" && responseDetails.data && responseDetails.data._id
                                    ? responseDetails.data
                                    : null
                            );
                            setSelectedMenu(response.data.title);
                        })
                        .catch((e) => {
                            setData({});
                        });
                } else {
                    setAlert([
                        "danger",
                        response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern des Menüs aufgetreten.",
                    ]);
                }
                setIsSaving(false);
            })
            .catch((e) => {
                console.error(e);
                setAlert([
                    "danger",
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern des Menüs aufgetreten.",
                ]);
                setIsSaving(false);
            });
    };

    useEffect(() => {
        load();
    }, []);

    if (isLoading || !data || !data._id || !design || designIsLoading) {
        return (
            <Content>
                {isLoading || designIsLoading ? (
                    <LoadingSpinner>Daten werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert danger>Es ist ein Fehler beim Laden der Website-Seiten aufgetreten.</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <h1 style={{ fontSize: 16 }}>
                <Button
                    type="button"
                    className="ml-1 float-right"
                    xs
                    secondary
                    onClick={toggleAddMenu}
                    disabled={isSaving}
                >
                    <Icon icon={faPlus} className="mr-1" />
                    Neues Menü anlegen
                </Button>
                Website-Menüs verwalten
                <Select
                    disabled={!data.menus || data.menus.lenth < 1 || isSaving}
                    className="mt-2"
                    value={selectedMenu}
                    onChange={onMenuChange}
                >
                    {!data.menus || data.menus.length < 1 ? (
                        <option>Keine Menüs vorhanden</option>
                    ) : (
                        <>
                            <option value="">Menü auswählen</option>
                            {data.menus.map((opt, i) => (
                                <option key={i} value={opt.title}>
                                    {opt.title}
                                </option>
                            ))}
                        </>
                    )}
                </Select>
            </h1>

            {addMenu !== null ? (
                <Modal open={true} contentAttributes={{ style: { margin: "auto", width: "100%", maxWidth: 600 } }}>
                    <ModalTitle>
                        <Button type="button" className="float-right" secondary onClick={toggleAddMenu}>
                            <Icon icon={faTimes} className="mr-1" />
                            Schließen
                        </Button>
                        <h1>Neues Menü anlegen</h1>
                    </ModalTitle>
                    <Content>
                        <form onSubmit={onAddMenu}>
                            <label>Bezeichnung des Menüs</label>
                            <Input
                                type="text"
                                value={addMenu}
                                onChange={(e) => setAddMenu(e.target.value)}
                                disabled={isAddingMenu}
                            />
                            <div className="text-right mt-1">
                                <Button success disabled={isAddingMenu || !addMenu || addMenu.trim() === ""}>
                                    {isAddingMenu ? <LoadingSpinner /> : "Menü erstellen"}
                                </Button>
                            </div>
                        </form>
                    </Content>
                </Modal>
            ) : null}

            {selectedMenu ? (
                !menu ? (
                    <Alert danger>Es ist ein Fehler beim Laden des Menüs `` aufgetreten.</Alert>
                ) : (
                    <Card className="mt-3">
                        <CardHeader>Menü bearbeiten</CardHeader>
                        <CardBody>
                            {alert ? <Alert color={alert[0]}>{alert[1]}</Alert> : null}

                            <form onSubmit={save}>
                                <FloatInput
                                    className="mb-2"
                                    placeholder="Titel / Bezeichnung des Menüs"
                                    onChange={onChange}
                                    name="title"
                                    value={menu.title}
                                    disabled={isSaving}
                                />

                                <Button
                                    type="button"
                                    style={{ marginTop: -2 }}
                                    secondary
                                    outline
                                    xs
                                    className="float-right"
                                    onClick={addEntry}
                                    disabled={isSaving}
                                >
                                    <Icon icon={faPlus} className="mr-1" />
                                    Menüpunkt hinzufügen
                                </Button>
                                <div style={{ fontWeight: "bold" }}>Menü:</div>
                                <Card>
                                    {!menu.definition || menu.definition.length < 1 ? (
                                        <Alert light className="mb-0">
                                            Es wurden noch keine Menüpunkte zum Menü hinzugefügt.
                                        </Alert>
                                    ) : (
                                        <CardBody>
                                            {menu.definition.map((entry, i) => {
                                                if (entry.type === "custom") {
                                                    return (
                                                        <MenuElement key={i}>
                                                            <div className="element-toolbar">
                                                                <Button
                                                                    type="button"
                                                                    light
                                                                    sm
                                                                    block
                                                                    className="element-toolbar-btn"
                                                                    disabled={parseInt(i) === 0 || isSaving}
                                                                    onClick={entryUp}
                                                                    data-index={i}
                                                                >
                                                                    <Icon icon={faCaretUp} />
                                                                </Button>
                                                                <Button
                                                                    type="button"
                                                                    light
                                                                    sm
                                                                    block
                                                                    className="element-toolbar-btn"
                                                                    onClick={removeEntry}
                                                                    data-index={i}
                                                                >
                                                                    <Icon icon={faTrashAlt} />
                                                                </Button>
                                                                <Button
                                                                    type="button"
                                                                    light
                                                                    sm
                                                                    block
                                                                    className="element-toolbar-btn"
                                                                    disabled={
                                                                        parseInt(i) + 1 === menu.definition.length ||
                                                                        isSaving
                                                                    }
                                                                    onClick={entryDown}
                                                                    data-index={i}
                                                                >
                                                                    <Icon icon={faCaretDown} />
                                                                </Button>
                                                            </div>
                                                            <div className="element-content">
                                                                <Row>
                                                                    <Col width={6}>
                                                                        <FloatInput
                                                                            placeholder="Label / Bezeichnung"
                                                                            name="label"
                                                                            value={entry.label}
                                                                            onChange={onMenuElementChange}
                                                                            disabled={isSaving}
                                                                            data-index={i}
                                                                        />
                                                                    </Col>
                                                                    <Col width={6}>
                                                                        <FloatInput
                                                                            placeholder="URL"
                                                                            name="url"
                                                                            value={entry.url}
                                                                            onChange={onMenuElementChange}
                                                                            disabled={isSaving}
                                                                            data-index={i}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </MenuElement>
                                                    );
                                                }

                                                return (
                                                    <div key={i}>
                                                        <Alert danger>
                                                            Unbekannter Menü-Eintrags-Typ `{entry.type}`.
                                                        </Alert>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    )}
                                </Card>

                                {design.menus && design.menus.length > 0 ? (
                                    <div className="mt-2">
                                        <b>Menü verwenden als:</b>
                                        {design.menus.map((el, i) => (
                                            <div key={i}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="mr-1"
                                                        name={el.key}
                                                        onChange={toggleMenu}
                                                        checked={
                                                            menu &&
                                                            menu.design_usage &&
                                                            menu.design_usage[data.design] &&
                                                            menu.design_usage[data.design].includes(el.key)
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {el.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}

                                <div className="mt-2 text-right">
                                    <Button success disabled={isSaving}>
                                        {isSaving ? <LoadingSpinner /> : "Speichern"}
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                )
            ) : (
                <Alert light>Es muss ein Menü ausgewählt werden um dieses zu bearbeiten</Alert>
            )}
        </Content>
    );
};
