import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Icon } from "@webtypen/react-components";
import styled from "styled-components";
import { reduxRemoveGlobalAlert } from "../redux";

const Wrapper = styled.div``;

export const GlobalAlerts = (props) => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => state.app.globalAlerts);
    const alertKeys = alerts ? Object.keys(alerts) : null;

    const closeAlert = (alertKey) => {
        dispatch(reduxRemoveGlobalAlert(alertKey));
    };

    if (!alerts || !alertKeys || alertKeys.length < 1) {
        return null;
    }

    return (
        <Wrapper>
            {alertKeys.map((key, i) => {
                const element = alerts[key];
                return (
                    <Alert key={i} className="mb-0" {...element.attributes}>
                        {!element.disableClose ? (
                            <Button
                                type="button"
                                outline
                                xs
                                light
                                className="float-right ml-1"
                                onClick={() => closeAlert(key)}
                            >
                                <Icon icon={faTimes} />
                            </Button>
                        ) : null}
                        {element.message}
                    </Alert>
                );
            })}
        </Wrapper>
    );
};
