import React from "react";
import { Alert, Content } from "@webtypen/react-components";

export const QueryFormsMainSettings = (props) => {
    return (
        <Content>
            <h3 className="mt-0">Formulare - Einstellungen</h3>
            <Alert light>Es stehen aktuell keine allgemeinen Einstellungen für Ihre Formulare zu Verfügung.</Alert>
        </Content>
    );
};
