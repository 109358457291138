import React from "react";
import { Card, Col, Content, CardBody, NavList, NavListElement, Row } from "@webtypen/react-components";
import { ContainerUser } from "../../../components";
import { NavLink, Route, Switch } from "react-router-dom";
import { AccountSettingsStart } from "./AccountSettingsStart";
import { AccountSettingsChangePw } from "./AccountSettingsChangePw";

export const AccountSettings = (props) => {
    return (
        <ContainerUser className="mt-3">
            <Content>
                <h1>Account Einstellungen</h1>

                <Row>
                    <Col width={4}>
                        <Card>
                            <NavList>
                                <NavListElement>
                                    <NavLink exact to="/account/settings">
                                        Allgemein
                                    </NavLink>
                                </NavListElement>
                                <NavListElement disabled>
                                    <span to="/account/settings/notifications">Benachrichtigungen</span>
                                </NavListElement>
                                <NavListElement disabled>
                                    <span to="/account/settings/api">API-Zugänge</span>
                                </NavListElement>
                                <NavListElement disabled>
                                    <span to="/account/settings/remove-account">Account löschen</span>
                                </NavListElement>
                            </NavList>
                        </Card>
                    </Col>

                    <Col width={8}>
                        <Switch>
                            <Route exact path="/account/settings" component={AccountSettingsStart} />
                            <Route exact path="/account/settings/change-pw" component={AccountSettingsChangePw} />
                            <Route
                                render={() => (
                                    <Card>
                                        <CardBody>
                                            <h3 className="mt-0">Fehler aufgetreten!</h3>
                                            Die aufgerufene Einstellungsseite existiert nicht.
                                        </CardBody>
                                    </Card>
                                )}
                            />
                        </Switch>
                    </Col>
                </Row>
            </Content>
        </ContainerUser>
    );
};
