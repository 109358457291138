import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Content } from "@webtypen/react-components";
import { FieldSelector } from "./FieldSelector";
import { SidebarEditField } from "./SidebarEditField";

const Wrapper = styled.div`
    width: 400px;
    background: #fff;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 9999999;
    height: 100vh;
    overflow: auto;
`;

export const Sidebar = (props) => {
    const active = useSelector((state) => state.app.formBuilderForm.active);
    return (
        <Wrapper>
            {active ? (
                <SidebarEditField
                    active={active}
                    isPreset={props.isPreset}
                    stylingDefinitions={props.stylingDefinitions}
                />
            ) : (
                <Content>
                    <h1>Formular bearbeiten</h1>
                    <FieldSelector disabled={props.isPreset} />
                </Content>
            )}
        </Wrapper>
    );
};
