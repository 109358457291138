import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import lodash from "lodash";
import { reduxSetDynamicRecordsValue } from "../../../redux/app/actions";
import { Alert, Button, Row } from "@webtypen/react-components";
import { DynamicRecordFieldsRenderer } from "../DynamicRecordFieldsRenderer";
import { MapFieldAccountingPositions } from "./MapFieldAccountingPositions";
import { MapFieldAccountingTaxes } from "./MapFieldAccountingTaxes";

const Wrapper = styled.div``;
const Box = styled.div`
    border: 1px solid #dedede;
    border-bottom: none;
    display: flex;

    &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    &:last-child {
        border-bottom: 1px solid #dedede;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .counter {
        width: 40px;
        border-right: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .element-content {
        padding: 15px;
        flex: 1;
    }
`;

export const MapField = (props) => {
    const dispatch = useDispatch();
    const useCustomValue = props.customValueStore ? true : false;
    const value = useSelector((state) => state.app.dynamicrecords[props.formField]);
    const customValue = useCustomValue ? lodash.get(props.customValueStore, props.formField) : null;

    const add = (e, passedDefaultValues) => {
        const newValue = value !== undefined && Array.isArray(value) ? value : [];

        const defaultValues = {};
        for (let i in props.field.definition) {
            if (props.field.definition[i].default !== undefined) {
                defaultValues[i] = props.field.definition[i].default;
            }
        }

        if (props.onChange) {
            return props.onChange(
                props.formField,
                [...newValue, { ...defaultValues }],
                props.element ? props.element.rerenderOnChange : null
            );
        }

        if (passedDefaultValues && Object.keys(passedDefaultValues).length > 0) {
            for (let i in passedDefaultValues) {
                defaultValues[i] = passedDefaultValues[i];
            }
        }

        dispatch(
            reduxSetDynamicRecordsValue(
                props.formField,
                [...newValue, { ...defaultValues }],
                props.element ? props.element.rerenderOnChange : null
            )
        );
    };

    const remove = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (window.confirm("Datensatz wirklich entfernen?")) {
            const newValue = [...value];
            newValue.splice(index, 1);

            if (props.onChange) {
                return props.onChange(props.formField, [...newValue], "map-remove");
            }
            dispatch(reduxSetDynamicRecordsValue(props.formField, [...newValue]));
        }
    };

    if (props.field && props.field.subType === "positions-map") {
        return (
            <MapFieldAccountingPositions
                add={add}
                value={
                    useCustomValue
                        ? customValue === undefined
                            ? null
                            : customValue
                        : value === undefined
                        ? null
                        : value
                }
                remove={remove}
                field={props.field}
                element={props.element}
                formField={props.formField}
                setValue={props.setValue}
                values={props.values}
                onChange={props.onChange}
                errorPrefix={props.errorPrefix}
            />
        );
    } else if (props.field && props.field.subType === "taxes-map") {
        return (
            <MapFieldAccountingTaxes
                add={add}
                value={
                    useCustomValue
                        ? customValue === undefined
                            ? null
                            : customValue
                        : value === undefined
                        ? null
                        : value
                }
                remove={remove}
                field={props.field}
                element={props.element}
                formField={props.formField}
                setValue={props.setValue}
                values={props.values}
                onChange={props.onChange}
                errorPrefix={props.errorPrefix}
            />
        );
    }

    return (
        <Wrapper style={props.definition && props.definition.style ? props.definition.style : null}>
            <label>{props.field.label}</label>
            <Button type="button" xs success outline className="ml-1" onClick={add}>
                Hinzufügen
            </Button>

            <div>
                {value && value.length > 0 && Array.isArray(value) ? (
                    value.map((el, i) => {
                        return (
                            <Box key={i}>
                                <div className="counter">
                                    <span>{i + 1}</span>
                                </div>
                                <div className="element-content">
                                    <Row>
                                        <DynamicRecordFieldsRenderer
                                            data={{ fields: props.field.definition }}
                                            fields={props.element.form}
                                            setValue={props.setValue}
                                            values={props.values}
                                            prefix={props.formField + "[" + i + "]"}
                                        />
                                    </Row>

                                    <Button type="button" danger outline sm onClick={remove} data-index={i}>
                                        Entfernen
                                    </Button>
                                </div>
                            </Box>
                        );
                    })
                ) : (
                    <Alert light>Es wurden noch keine Datensätze hinzugefügt</Alert>
                )}
            </div>
        </Wrapper>
    );
};
