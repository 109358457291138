import React, { useEffect, useState } from "react";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { Alert, Content, LoadingSpinner, Table, ButtonLink } from "@webtypen/react-components";
import { ApiHelper } from "../../../../helpers";
import { SettingTitle } from "../SettingTitle";

export const DynamicRecordsList = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        ApiHelper.request("/api/settings/dynamic-records/types").then((response) => {
            setData(response.status === "success" && response.data.types ? response.data : null);
            setIsLoading(false);
        });
    }, []);

    let hasElements = false;
    return (
        <Content>
            <ButtonLink
                type="button"
                className="float-right"
                primary
                to={"/env/" + props.match.params._env + "/settings/dynamic-records/create"}
            >
                Datenstruktur erstellen
            </ButtonLink>
            <SettingTitle icon={faDatabase} title="Datenstruktur" />

            {isLoading ? (
                <LoadingSpinner>Datenstruktur-Informationen werden geladen ...</LoadingSpinner>
            ) : !data ? (
                <Alert danger>Es ist ein Fehler beim Laden der Datenstruktur-Informationen aufgetreten.</Alert>
            ) : (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Key</th>
                                <th>Übergeordneter Typ</th>
                                <th className="text-right">Anzahl Datensätze</th>
                                <th className="text-right">Optionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.types && data.types.length > 0
                                ? data.types.map((type, i) => {
                                      if (type.disableEdit) {
                                          return null;
                                      }

                                      hasElements = true;
                                      return (
                                          <tr key={i}>
                                              <td>{type.plural}</td>
                                              <td>{type.key}</td>
                                              <td>{type.parent ? type.parent : "-"}</td>
                                              <td className="text-right">{type.count}</td>
                                              <td className="text-right">
                                                  <ButtonLink
                                                      primary
                                                      xs
                                                      to={
                                                          "/env/" +
                                                          props.match.params._env +
                                                          "/settings/dynamic-records/" +
                                                          type.key +
                                                          "/edit"
                                                      }
                                                  >
                                                      Konfigurieren
                                                  </ButtonLink>
                                              </td>
                                          </tr>
                                      );
                                  })
                                : null}

                            {!hasElements ? (
                                <tr>
                                    <td colSpan={5}>Keine Datentypen vorhanden ...</td>
                                </tr>
                            ) : null}
                        </tbody>
                    </Table>
                </>
            )}
        </Content>
    );
};
