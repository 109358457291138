import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 2.3rem;
    font-weight: 800;
    color: #1f1f1f;
    user-select: none;
    text-decoration: none !important;
    transition: all 150ms;

    span {
        color: #0070f3;
    }

    &:hover {
        text-decoration: none !important;

        ${(props) => (props.hover ? `opacity: 0.8;` : ``)}
    }
`;

export const Logo = (props) => {
    return (
        <Wrapper {...props}>
            <span>simple</span>bis
        </Wrapper>
    );
};
