import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, LoadingSpinner, Content, Row, Col } from "@webtypen/react-components";
import { ApiHelper, ConfirmHelper } from "../../../helpers";

const DesignElement = styled.div`
    border-radius: 3px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    user-select: none;

    .design-preview {
        height: 170px;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .design-title {
        padding: 10px 15px;
        font-weight: 600;
    }

    &:hover {
        .design-title {
            background: rgba(0, 0, 0, 0.015);
        }
    }

    &.is-disabled {
        cursor: default;
        opacity: 0.7;
    }
`;

export const WebsiteManagerDesignSelector = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isApplying, setIsApplying] = useState(null);
    const [data, setData] = useState(null);

    const changeWebsiteDesign = (e) => {
        const key = e.currentTarget.getAttribute("data-key");
        setIsApplying(key);

        ApiHelper.request("/api/website-manager/change-design", {
            design: key,
        })
            .then((response) => {
                if (response.status === "success") {
                    setIsApplying(null);

                    if (props.onChange) {
                        props.onChange();
                    }
                } else {
                    ConfirmHelper.alert("Es ist ein Fehler beim Ändern des Designs aufgetreten.");
                    setIsApplying(null);
                }
            })
            .catch((e) => {
                console.error(e);
                ConfirmHelper.alert("Es ist ein Fehler beim Ändern des Designs aufgetreten.");
                setIsApplying(null);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        ApiHelper.request("/api/website-manager/designs-list")
            .then((response) => {
                setData(
                    response.status === "success" &&
                        response.data &&
                        response.data.designs &&
                        response.data.designs.length > 0
                        ? response.data
                        : null
                );
                setIsLoading(false);
            })
            .catch((e) => {
                setData(null);
                setIsLoading(false);
            });
    }, []);

    if (isLoading || !data || !data.designs || data.designs.length < 1) {
        return (
            <Content>
                {isLoading ? (
                    <LoadingSpinner>Designs werden geladen ...</LoadingSpinner>
                ) : (
                    <Alert>Es stehen keine Designs zu Verfügung.</Alert>
                )}
            </Content>
        );
    }

    return (
        <Content>
            <Row>
                <Col width={3}>
                    {data.designs.map((design, i) => (
                        <DesignElement
                            key={i}
                            onClick={changeWebsiteDesign}
                            className={isApplying ? "is-disabled" : null}
                            data-key={design.key}
                        >
                            <div className="design-preview">
                                {isApplying === design.key ? <LoadingSpinner /> : "Keine Vorschau verfügbar"}
                            </div>
                            <div className="design-title">
                                {design.name}
                                {isApplying === design.key ? <LoadingSpinner className="ml-1" /> : null}
                            </div>
                        </DesignElement>
                    ))}
                </Col>
            </Row>
        </Content>
    );
};
